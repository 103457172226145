import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Autocomplete,
	Chip,
	TextField,
	InputAdornment,
	Box,
	Typography,
} from '@mui/material';
import { STEPTheme } from '../utils/Theme';
import { setSurveyPayload, surveyListingRequest } from '../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { ISurveyListingRequest } from '../types/CommonTypes';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import {
	setError,
	setHelperText,
	toggleResetInputValues,
} from '../redux/QuestionnaireSurveySlice';
import { RootState } from '../app/Store';
import {
	ADMIN_SCREEN_VALIDATION,
	EVENT_KEYS,
} from '../constants/StepConstants';
import { commonLabels } from '../utils/CommonLabels';
import { getFilterLabel } from './FilterActions';

const StyledBox = styled(Box)({
	maxWidth: '220px',
	height: '48px',
	justifyContent: 'space-between',
	border: `1px ${STEPTheme.colors.text}`,
	'.MuiAutocomplete-inputRoot': {
		backgroundColor: `${STEPTheme.colors.background} !important`,
		padding: '0 !important',
		maxWidth: '220px',
		height: '32px',
		borderRadius: '8px',
		border: `1px solid ${STEPTheme.colors.darkGray}`,
	},
});

const StyledTypography = styled(Typography)`
	font-family: JohnsonText-Medium;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: #212121;
	margin-bottom: 2px;
	width: 220px;
	height: 12px;
`;
const FilterSegments: React.FC<{
	type: string;
	filterItems: string[];
	handleFilterChange: () => void;
}> = (props: {
	type: string;
	filterItems: string[];
	handleFilterChange: () => void;
}) => {
	const dispatch = useAppDispatch();
	const reset = useAppSelector(
		(state: RootState) => state.QuestionnaireSurvey.resetInputValues
	);

	const surveyReqData = useAppSelector(surveyListingRequest);

	const error = useAppSelector(
		(state: RootState) => state.Questionnaire.error[props.type]
	);
	const helperText = useAppSelector(
		(state: RootState) => state.Questionnaire.helperText[props.type]
	);

	const setFilterValue = (
		item: string,
		surveyRequestData: ISurveyListingRequest
	) => {
		if (item === 'location') {
			return surveyRequestData?.location ? surveyRequestData?.location : '';
		} else if (item === 'status') {
			return surveyRequestData?.status ? surveyRequestData?.status : '';
		} else if (item === 'questionnaireName') {
			return surveyRequestData?.questionnaireName
				? surveyRequestData?.questionnaireName
				: '';
		} else {
			return surveyRequestData?.questionnaireAuthor
				? surveyRequestData?.questionnaireAuthor
				: '';
		}
	};

	const ResetFilterValue = async (item: string) => {
		dispatch(
			setSurveyPayload({
				item: item,
				val: null,
			})
		);
	};
	const getErrorMessage = (type: string) => {
		switch (type) {
			case 'questionnaireName':
				return commonLabels.incorrectQuestionnaireNameFormatErrorMsg;
			case 'location':
				return commonLabels.incorrectQuestionnaireLocationFormatErrorMsg;
			case 'questionnaireAuthor':
				return commonLabels.incorrectQuestionnaireAuthorFormatErrorMsg;
			case 'status':
				return commonLabels.incorrectQuestionnaireStatusFormatErrorMsg;
			default:
				return '';
		}
	};
	useEffect(() => {
		setInputValue(setFilterValue(props.type, surveyReqData));
	}, [props.type, surveyReqData]);

	useEffect(() => {
		if (reset) {
			setInputValue('');
			dispatch(toggleResetInputValues());
		}
	}, [reset, dispatch]);

	const [inputValue, setInputValue] = useState(
		setFilterValue(props.type, surveyReqData)
	);

	const autoClear = useRef(null);
	return (
		<>
			<StyledBox key={props.type}>
				<StyledTypography key={props.type}>
					{getFilterLabel(props.type)}
				</StyledTypography>
				<Autocomplete
					data-testid="autocomplete-filter"
					ref={autoClear}
					value={inputValue}
					onKeyPress={(event) => {
						const target = event.target as HTMLInputElement;
						if (event.charCode === EVENT_KEYS.SPACE && !target.value) {
							event.preventDefault();
						}
					}}
					filterOptions={(options, params) => {
						const trimmedInputValue = params.inputValue.trimEnd();
						return options.filter((option) =>
							option.toLowerCase().includes(trimmedInputValue.toLowerCase())
						);
					}}
					onChange={(_event: React.SyntheticEvent, newValue: string | null) => {
						const patterns: { [key: string]: RegExp } = {
							questionnaireName:
								ADMIN_SCREEN_VALIDATION.VALIDATION_QUESTIONNAIRE_NAME_ID,
							location: ADMIN_SCREEN_VALIDATION.VALIDATION_LOCATION,
							questionnaireAuthor: ADMIN_SCREEN_VALIDATION.VALIDATION_AUTHOR,
							status: ADMIN_SCREEN_VALIDATION.VALIDATION_STATUS,
						};
						const pattern = patterns[props.type];
						if (!pattern.test(newValue || '')) {
							dispatch(setError({ filterType: props.type, error: true }));
							dispatch(
								setHelperText({
									filterType: props.type,
									helperText: getErrorMessage(props.type),
								})
							);
						} else {
							dispatch(setError({ filterType: props.type, error: false }));
							dispatch(
								setHelperText({ filterType: props.type, helperText: '' })
							);
							dispatch(
								setSurveyPayload({
									item: props.type,
									val: newValue,
								})
							);
						}
						setInputValue(newValue || '');
						if (!newValue) {
							dispatch(setError({ filterType: props.type, error: false }));
							dispatch(
								setHelperText({ filterType: props.type, helperText: '' })
							);
						}
					}}
					onInputChange={(_event, value, reason) => {
						setInputValue(value);
						const patterns: { [key: string]: RegExp } = {
							questionnaireName:
								ADMIN_SCREEN_VALIDATION.VALIDATION_QUESTIONNAIRE_NAME_ID,
							location: ADMIN_SCREEN_VALIDATION.VALIDATION_LOCATION,
							questionnaireAuthor: ADMIN_SCREEN_VALIDATION.VALIDATION_AUTHOR,
							status: ADMIN_SCREEN_VALIDATION.VALIDATION_STATUS,
						};
						const pattern = patterns[props.type];
						if (!pattern.test(value)) {
							dispatch(setError({ filterType: props.type, error: true }));
							dispatch(
								setHelperText({
									filterType: props.type,
									helperText: getErrorMessage(props.type),
								})
							);
						} else {
							dispatch(setError({ filterType: props.type, error: false }));
							dispatch(
								setHelperText({ filterType: props.type, helperText: '' })
							);
							dispatch(
								setSurveyPayload({
									item: props.type,
									val: value,
								})
							);
						}
						if (reason === 'clear') {
							ResetFilterValue(props.type);
						}
						if (!value) {
							dispatch(setError({ filterType: props.type, error: false }));
							dispatch(
								setHelperText({ filterType: props.type, helperText: '' })
							);
						}
					}}
					onKeyUp={(event) => {
						const target = event.target as HTMLInputElement;
						if (!target.value) {
							ResetFilterValue(props.type);
						}
					}}
					id={`"${props.type}_id"`}
					options={props.filterItems}
					freeSolo
					renderTags={(value: readonly string[], getTagProps) =>
						value.map((option: string, index: number) => (
							<Box key={`wwid_${index}`}>
								<Chip
									variant="outlined"
									label={option}
									{...getTagProps({ index })}
									key={index}
								/>
							</Box>
						))
					}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="filled"
							placeholder="Enter Text"
							error={error}
							helperText={helperText}
							onBlur={(event) => {
								const patterns: { [key: string]: RegExp } = {
									questionnaireName:
										ADMIN_SCREEN_VALIDATION.VALIDATION_QUESTIONNAIRE_NAME_ID,
									location: ADMIN_SCREEN_VALIDATION.VALIDATION_LOCATION,
									questionnaireAuthor:
										ADMIN_SCREEN_VALIDATION.VALIDATION_AUTHOR,
									status: ADMIN_SCREEN_VALIDATION.VALIDATION_STATUS,
								};
								const pattern = patterns[props.type];
								if (pattern.test(event.target.value)) {
									dispatch(
										setSurveyPayload({
											item: props.type,
											val: event.target.value,
										})
									);
								}
							}}
							InputProps={{
								...params.InputProps,
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true,
								sx: {
									'& .MuiAutocomplete-input': {
										maxWidth: '65%',
										textOverflow: 'ellipsis',
									},
									'&.Mui-focused': {
										borderColor: STEPTheme.colors.red,
									},
								},
							}}
						/>
					)}
				/>
			</StyledBox>
		</>
	);
};
export default FilterSegments;
