import React, { useEffect, useState } from 'react';
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
	Paper,
	styled,
	TablePagination,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { commonLabels } from '../../utils/CommonLabels';
import { Numbers, PageRows } from '../../utils/Enum';
import { useAppSelector } from '../../app/Hooks';
import { selectedFilterRequestPayloadData } from '../../redux/SurveySlice';
import { IUsersDataItems } from '../../types/CommonTypes';
import { slotProps } from '../questions/common/QuestionStyles';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: '700',
		fontFamily: '"JohnsonText-Regular"',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontFamily: '"JohnsonText-Regular"',
	},
}));

const StyledTableContainer = styled(TableContainer)({
	height: '300px',
	margin: 'auto',
});

interface FilterAudienceDataTableProps {
	data: IUsersDataItems[];
	paginationState: { pageNumber: number; pageSize: number };
	onPageChange: (newPaginationState: {
		pageNumber: number;
		pageSize: number;
	}) => void;
	totalCount: number;
}

export const FilterAudienceSelectionTable: React.FC<
	FilterAudienceDataTableProps
> = ({ data, onPageChange, totalCount }) => {
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	const [currentPage, setCurrentPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(PageRows.firstOption);
	// If you directly update payload clearing any particular filter table changes its pagination to original state
	useEffect(() => {
		if (filterRequestPayload?.pageNumber === 1 && currentPage !== 0) {
			setCurrentPage(0);
			onPageChange({ pageNumber: 1, pageSize: rowsPerPage });
		}
		if (
			filterRequestPayload?.pageSize === PageRows.firstOption &&
			rowsPerPage !== PageRows.firstOption
		) {
			setRowsPerPage(PageRows.firstOption);
			onPageChange({ pageNumber: Numbers.one, pageSize: PageRows.firstOption });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRequestPayload]);

	const handlePageChange = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setCurrentPage(newPage);
		onPageChange({ pageNumber: newPage + 1, pageSize: rowsPerPage });
	};

	const handleRowsPerPageChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const newPageSize = parseInt(event.target.value, PageRows.firstOption);
		setRowsPerPage(newPageSize);
		setCurrentPage(0); // Reset to first page when page size changes
		onPageChange({ pageNumber: Numbers.one, pageSize: newPageSize });
	};
	return (
		<Paper elevation={0}>
			<StyledTableContainer>
				<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow>
							<StyledTableCell>{commonLabels.wwid}</StyledTableCell>
							<StyledTableCell>{commonLabels.name}</StyledTableCell>
							<StyledTableCell>{commonLabels.emailId}</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data &&
							data.map((row) => (
								<TableRow key={row?.wwid}>
									<StyledTableCell>{row?.wwid}</StyledTableCell>
									<StyledTableCell>{row?.fullName}</StyledTableCell>
									<StyledTableCell>{row?.workEmail}</StyledTableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<TablePagination
				rowsPerPageOptions={[
					PageRows.firstOption,
					PageRows.secondOption,
					PageRows.thirdOption,
				]}
				component="div"
				count={totalCount}
				rowsPerPage={rowsPerPage}
				page={currentPage}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleRowsPerPageChange}
				slotProps={slotProps}
			/>
		</Paper>
	);
};
export default FilterAudienceSelectionTable;
