import React, { useEffect, useState, ChangeEvent } from 'react';
import {
	MenuItem,
	Typography,
	FormControl,
	Checkbox,
	Box,
	FormControlLabel,
	FormGroup,
	Button,
	DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import { IOSSwitch } from '../utils/Switch';
import { STEPTheme } from '../utils/Theme';
import {
	getFilterAllJobFunctions,
	getFilterJobFunction,
	getFilterRegion,
	getFilterRegionCountry,
	getSurveyAudienceExcludedFilterData,
	getSurveyAudienceIncludeFilterData,
	setDummySelectedFilterRequestPayload,
	selectedFilterRequestPayloadData,
	getUserWWID,
	userWWIDSearchedData,
	userWWIDAPILoadingStatus,
} from '../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	getFilterLabelById,
	getKey,
	transformUserWWIDSearchData,
} from '../utils/Helpers';
import { audiencePatterns, IAudienceModalFilter } from '../types/CommonTypes';
import { commonLabels } from '../utils/CommonLabels';
import { Numbers } from '../utils/Enum';
import { AudienceFilterLabels } from '../utils/SurveyAudience';
import { GenericAutocomplete } from '../utils/GenericAutoComplete';
import { questionnaireAPIKeys } from '../utils/ApiKeys';
import StyledTooltip from './StyledTooltip';

const SelectFilter = styled(Typography)({
	width: '497px',
	height: '32px',
	fontfamily: 'JohnsonDisplay-Regular',
	fontSize: '31px',
	fontWeight: '700',
	lineHeight: '32px',
	letterSpacing: '0px',
	textAlign: 'left',
});

const StyledFilterRowsContainer = styled(Box)({
	display: 'grid',
	gridTemplateColumns: '1fr 1fr 1fr',
	gap: '1.5rem',
});

const StyledFilterRows = styled(Box)({
	flex: '0 1 calc(1 / 4 * 100%)',
});

const StyledTypography = styled(Typography)({
	fontfamily: 'JohnsonText-Regular',
	fontsize: '14px',
	fontWeight: '700',
	lineHeight: '16px',
	letterspacing: '0px',
	textalign: 'left',
	color: STEPTheme.colors.text,
	width: 'auto',
	marginLeft: '5px',
	gap: '5px',
});

const StyledFormControl = styled(FormControl)({
	margin: '0px',
	width: '100%',
	borderRadius: '8px',
});

const StyledSelectField = styled(Select)({
	height: '32px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '4px',
	borderRadius: '8px',
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.primary,
	},
	'&.Mui-disabled': {
		backgroundColor: `${STEPTheme.colors.disabledButton} !important`,
		borderColor: `${STEPTheme.colors.linkGray} !important`,
	},
});

const StyledBox = styled(Box)({
	height: '48px',
	justifyContent: 'space-between',
	border: `1px ${STEPTheme.colors.text}`,
	'.MuiAutocomplete-inputRoot': {
		backgroundColor: `${STEPTheme.colors.white} !important`,
		padding: '0 !important',
		height: '32px',
		borderRadius: '8px',
		border: `1px solid ${STEPTheme.colors.darkGray}`,
	},
});

const Reset = styled(Button)({
	width: '123px',
	height: '16px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '400px',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.blue,
	textTransform: 'none',
});

const FilterBoxHeader = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0px',
	mb: '20px',
	marginTop: '30px',
});

const initialFilterValue = {
	people_leader: [],
	worker_type: [],
	work_region: [],
	work_country: [],
	sector: [],
	mrc_code: [],
	legal_entity: [],
	job_function: [],
	job_subfunction: [],
	job_group: [],
	employee_function: [],
	manager_wwid: [],
	wwid: [],
};
interface AudienceFilterPopupProps {
	hasIncPeopleLeaderError: boolean;
	hasExcPeopleLeaderError: boolean;
	includeFilterErrorMessage: string | null;
	setIncludeFilterErrorMessage: React.Dispatch<
		React.SetStateAction<string | null>
	>;
	excludeFilterErrorMessage: string | null;
	setExcludeFilterErrorMessage: React.Dispatch<
		React.SetStateAction<string | null>
	>;
}

const AudienceFilterPopup: React.FC<AudienceFilterPopupProps> = ({
	hasIncPeopleLeaderError,
	hasExcPeopleLeaderError,
}) => {
	const dispatch = useAppDispatch();
	const [includedFilterValue, setIncludedFilterValue] =
		React.useState<IAudienceModalFilter>(initialFilterValue);
	const [excludedFilterValue, setExcludedFilterValue] =
		React.useState<IAudienceModalFilter>(initialFilterValue);
	const surveyAudienceIncludedFilterList = useAppSelector(
		getSurveyAudienceIncludeFilterData
	);
	const surveyAudienceExcludedFilterList = useAppSelector(
		getSurveyAudienceExcludedFilterData
	);
	const userWWIDSearchData = useAppSelector(userWWIDSearchedData);
	const userWWIDSearchStatus = useAppSelector(userWWIDAPILoadingStatus);
	const [incRegion, setIncRegion] = useState<string[]>([]);
	const [excRegion, setExcRegion] = useState<string[]>([]);
	const [incCountries, setIncCountries] = useState<string[]>([]);
	const [excCountries, setExcCountries] = useState<string[]>([]);

	const [incJobFunction, setIncJobFunction] = useState<string[]>([]);
	const [excJobFunction, setExcJobFunction] = useState<string[]>([]);
	const [incJobSubFunctions, setIncJobSubFunctions] = useState<string[]>([]);
	const [excJobSubFunctions, setExcJobSubFunctions] = useState<string[]>([]);

	const [incAllIndividualWWIDs, setIncAllIndividualWWIDs] = useState<string[]>(
		[]
	);
	const [excAllIndividualWWIDs, setExcAllIndividualWWIDs] = useState<string[]>(
		[]
	);
	const [includedWWIDs, setIncludedWWIDs] = useState<string[]>([]);
	const [includedLegalEntity, setIncludedLegalEntity] = useState<string[]>([]);
	const [excludedWWIDs, setExcludedWWIDs] = useState<string[]>([]);
	const [excludedLegalEntity, setExcludedLegalEntity] = useState<string[]>([]);

	const filterRegion = useAppSelector(getFilterRegion);
	const filterRegionCountry = useAppSelector(getFilterRegionCountry);
	const filterJobFunction = useAppSelector(getFilterJobFunction);
	const filterAllJobFunctions = useAppSelector(getFilterAllJobFunctions);
	const [filterHasChildren, setFilterHasChildren] = useState<string[]>([]);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);

	const getIncludedWWIDArr = (manager_wwid: []) => {
		if (Object.values(manager_wwid).length > Numbers.zero) {
			setIncludedWWIDs([]);
			const currentIncludedWWIDs: string[] = [];
			Object.values(manager_wwid)?.map((value) => {
				const newWWID = Object.values(value)[Numbers.zero];
				currentIncludedWWIDs.push(newWWID as string);
			});
			setIncludedWWIDs(currentIncludedWWIDs);
			setShowIncludeSwitchSelected(true);
		}
	};
	const getIncludedLegalEntityArr = (legal_entity: []) => {
		if (Object.values(legal_entity).length > Numbers.zero) {
			setIncludedLegalEntity([]);
			const currentIncludedWWIDs: string[] = [];
			Object.values(legal_entity)?.map((value) => {
				const legalEntity = Object.values(value)[Numbers.zero];
				currentIncludedWWIDs.push(legalEntity as string);
			});
			setIncludedLegalEntity(currentIncludedWWIDs);
			setShowIncludeLegalEntitySwitchSelected(true);
		}
	};

	const getExcludedLegalEntityArr = (legal_entity: []) => {
		if (Object.values(legal_entity).length > Numbers.zero) {
			setExcludedLegalEntity([]);
			const currentExcludedWWIDs: string[] = [];
			Object.values(legal_entity)?.map((value) => {
				const legalEntity = Object.values(value)[Numbers.zero];
				currentExcludedWWIDs.push(legalEntity as string);
			});
			setExcludedLegalEntity(currentExcludedWWIDs);
			setShowExcludeLegalEntitySwitchSelected(true);
		}
	};

	const getExcludedWWIDArr = (manager_wwid: []) => {
		if (Object.values(manager_wwid).length > Numbers.zero) {
			setExcludedWWIDs([]);
			const currentExcludedWWIDs: string[] = [];
			Object.values(manager_wwid)?.map((value) => {
				const newWWID = Object.values(value)[Numbers.zero];
				currentExcludedWWIDs.push(newWWID as string);
			});
			setExcludedWWIDs(currentExcludedWWIDs);
			setShowExcludeSwitchSelected(true);
		}
	};

	const getIncIndividualWWIDArr = (wwid: []) => {
		if (wwid && Object.values(wwid)?.length > Numbers.zero) {
			setIncIndividualWWIDs([]);
			const currentIncIndividualWWIDs: string[] = [];
			Object.values(wwid)?.map((value) => {
				const newWWID = Object.values(value)[Numbers.zero];
				currentIncIndividualWWIDs.push(newWWID as string);
			});
			setIncIndividualWWIDs(currentIncIndividualWWIDs);
			setShowIncIndividualSwitchSelected(true);
		}
	};

	const getExcIndividualWWIDArr = (wwid: []) => {
		if (wwid && Object.values(wwid)?.length > Numbers.zero) {
			setExcIndividualWWIDs([]);
			const currentExcIndividualWWIDs: string[] = [];
			Object.values(wwid)?.map((value) => {
				const newWWID = Object.values(value)[Numbers.zero];
				currentExcIndividualWWIDs.push(newWWID as string);
			});
			setExcIndividualWWIDs(currentExcIndividualWWIDs);
			setShowExcIndividualSwitchSelected(true);
		}
	};

	useEffect(() => {
		if (userWWIDSearchData.length > Numbers.zero && individualWWIDType !== '') {
			individualWWIDType === AudienceFilterLabels.includeFilters
				? setIncAllIndividualWWIDs(
						transformUserWWIDSearchData(userWWIDSearchData)
					)
				: setExcAllIndividualWWIDs(
						transformUserWWIDSearchData(userWWIDSearchData)
					);
		} else {
			individualWWIDType === AudienceFilterLabels.includeFilters
				? setIncAllIndividualWWIDs([])
				: setExcAllIndividualWWIDs([]);
		}
		setIndividualWWIDType('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userWWIDSearchData]);

	useEffect(() => {
		if (userWWIDSearchStatus === 'loading' && individualWWIDType !== '') {
			individualWWIDType === AudienceFilterLabels.includeFilters
				? setIncIndividualWWIDLoading(true)
				: setExcIndividualWWIDLoading(true);
		} else {
			individualWWIDType === AudienceFilterLabels.includeFilters
				? setIncIndividualWWIDLoading(false)
				: setExcIndividualWWIDLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userWWIDSearchStatus]);

	useEffect(() => {
		if (
			filterRequestPayload?.includeFilters &&
			JSON.parse(filterRequestPayload?.includeFilters) !== initialFilterValue
		) {
			const includedFilters = JSON.parse(filterRequestPayload?.includeFilters);

			setIncludedFilterValue(includedFilters);
			getIncludedWWIDArr(includedFilters?.manager_wwid);
			getIncIndividualWWIDArr(includedFilters?.wwid);
			getIncludedLegalEntityArr(includedFilters?.legal_entity);
		}
		if (
			filterRequestPayload?.excludeFilters &&
			JSON.parse(filterRequestPayload?.excludeFilters) !== initialFilterValue
		) {
			const excludeFilters = JSON.parse(filterRequestPayload?.excludeFilters);
			setExcludedFilterValue(excludeFilters);
			getExcludedWWIDArr(excludeFilters?.manager_wwid);
			getExcIndividualWWIDArr(excludeFilters?.wwid);
			getExcludedLegalEntityArr(excludeFilters?.legal_entity);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRequestPayload]);
	useEffect(() => {
		const filterRegionCountries = Object.entries(filterRegionCountry);
		const filterjobFunctionJobSubFunctions = Object.entries(
			filterAllJobFunctions
		);
		if (Object.keys(includedFilterValue?.work_region).length > Numbers.zero) {
			includedFilterValue?.work_region.map((value) => {
				Object.values(value).forEach((item) => {
					if (filterRegionCountries.length > Numbers.zero) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								if (filterCountryRegion === item) {
									incCountries.push(
										...filterRegionCountries[filterCountryRegionIndex][1]
									);
								}
							}
						);
					}
				});
			});
		}

		if (Object.keys(includedFilterValue?.job_function).length > Numbers.zero) {
			includedFilterValue?.job_function.map((value) => {
				Object.values(value).forEach((item) => {
					if (filterjobFunctionJobSubFunctions.length > Numbers.zero) {
						Object.keys(filterAllJobFunctions).forEach(
							(filterAllJobFunction, filterAllJobFunctionIndex) => {
								if (filterAllJobFunction === item) {
									incJobSubFunctions.push(
										...filterjobFunctionJobSubFunctions[
											filterAllJobFunctionIndex
										][1]
									);
								}
							}
						);
					}
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		includedFilterValue?.work_region,
		includedFilterValue?.job_function,
		filterRegionCountry,
		filterAllJobFunctions,
		incCountries,
		incJobSubFunctions,
	]);
	useEffect(() => {
		const filterRegionCountries = Object.entries(filterRegionCountry);
		const filterjobFunctionJobSubFunctions = Object.entries(
			filterAllJobFunctions
		);
		if (
			excludedFilterValue?.work_region &&
			Object.keys(excludedFilterValue?.work_region).length > Numbers.zero
		) {
			excludedFilterValue?.work_region.map((value) => {
				Object.values(value).forEach((item) => {
					if (filterRegionCountries.length > Numbers.zero) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								if (filterCountryRegion === item) {
									excCountries.push(
										...filterRegionCountries[filterCountryRegionIndex][1]
									);
								}
							}
						);
					}
				});
			});
		}

		if (
			excludedFilterValue?.job_function &&
			Object.keys(excludedFilterValue?.job_function).length > Numbers.zero
		) {
			excludedFilterValue?.job_function.map((value) => {
				Object.values(value).forEach((item) => {
					if (filterjobFunctionJobSubFunctions.length > Numbers.zero) {
						Object.keys(filterAllJobFunctions).forEach(
							(filterAllJobFunction, filterAllJobFunctionIndex) => {
								if (filterAllJobFunction === item) {
									excJobSubFunctions.push(
										...filterjobFunctionJobSubFunctions[
											filterAllJobFunctionIndex
										][1]
									);
								}
							}
						);
					}
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		excludedFilterValue?.work_region,
		excludedFilterValue?.job_function,
		filterRegionCountry,
		filterAllJobFunctions,
		excCountries,
		excJobSubFunctions,
	]);
	useEffect(() => {
		const updatedFilterRequestPayload = {
			includeFilters: JSON.stringify({ ...includedFilterValue }),
			excludeFilters: JSON.stringify({ ...excludedFilterValue }),
			filterLabelJson: JSON.stringify({
				includeFilters: includedFilterValue,
				excludeFilters: excludedFilterValue,
			}),
			includeClause: '',
			excludeClause: '',
			pageSize: Numbers.ten,
			pageNumber: Numbers.one,
		};
		dispatch(setDummySelectedFilterRequestPayload(updatedFilterRequestPayload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [excludedFilterValue, includedFilterValue]);

	/**
	 * @method
	 * @summary Returns the selected filter count
	 * @param currentKey
	 * @param filterValue
	 * @returns Number
	 */
	const getValCount = (
		currentKey: string,
		filterValue: IAudienceModalFilter
	) => {
		return filterValue[currentKey as audiencePatterns]?.length || Numbers.zero;
	};

	/**
	 * @method
	 * @summary Returns if the particular filter option selected
	 * @param currentKey: string
	 * @param surveyAudienceFilterValueKey: string
	 * @param filterValue: IAudienceModalFilter
	 * @returns Boolean
	 */
	const checkVal = (
		currentKey: string,
		surveyAudienceFilterValueKey: string,
		filterValue: IAudienceModalFilter
	) => {
		return (
			(filterValue[currentKey as audiencePatterns] &&
				filterValue[currentKey as audiencePatterns]?.findIndex(
					(item: Record<string, string>) =>
						item[currentKey as audiencePatterns] ===
						surveyAudienceFilterValueKey
				) > -1) ||
			false
		);
	};

	/**
	 * @description This function execute by passing dynamic filter value
	 *  to uncheck or reset the related child dropdown to the parent.
	 * @param filterdValue
	 * @param childElementList
	 * @param filterType
	 */
	const childElemReset = (
		filterdValue: IAudienceModalFilter,
		childElementList: [],
		filterType: string
	) => {
		const filteredChildValueList = Object.entries(filterdValue)?.find((t) =>
			t.includes(filterType)
		)?.[1];
		let newElementObj = {};
		let newFilteredChildValueList = filteredChildValueList.map(
			(element: Record<string, string>) => {
				newElementObj = { ...element };
				if (filterType in element) {
					const filteredChildValue =
						element[filterType as keyof IAudienceModalFilter];
					if (childElementList.includes(filteredChildValue as never)) {
						newElementObj = {};
					}
				}
				return {
					...newElementObj,
				};
			}
		);
		newFilteredChildValueList = newFilteredChildValueList.filter(
			(value: object) => Object.keys(value).length > Numbers.zero
		);
		filterdValue[filterType as keyof IAudienceModalFilter] = [
			...newFilteredChildValueList,
		];
	};

	const handleIncludeFilterChanges = (
		event: React.ChangeEvent<HTMLInputElement>,
		type: string,
		hasChildren = false,
		childArray = Array
	) => {
		const value = event.target.value;
		const isChecked = event.target.checked;

		const filterRegionCountries = Object.entries(filterRegionCountry);
		const filterjobFunctionJobSubFunctions = Object.entries(
			filterAllJobFunctions
		);
		switch (type) {
			case questionnaireAPIKeys.work_country:
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = includedFilterValue?.work_country.findIndex(
						(item) => item.work_country === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.work_country.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_country: [...includedFilterValue.work_country],
					}));
				} else {
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_country: [...prevState.work_country, { work_country: value }],
					}));
				}
				break;
			case questionnaireAPIKeys.job_subfunction:
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = includedFilterValue?.job_subfunction.findIndex(
						(item) => item.job_subfunction === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.job_subfunction.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_subfunction: [...includedFilterValue.job_subfunction],
					}));
				} else {
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_subfunction: [
							...prevState.job_subfunction,
							{ job_subfunction: value },
						],
					}));
				}
				break;
			case questionnaireAPIKeys.job_function:
				if (!isChecked) {
					setIncJobSubFunctions([]);
					// unchecking the box...
					const jobSubFunctionList = filterjobFunctionJobSubFunctions?.find(
						(t) => t.includes(value)
					);
					if (jobSubFunctionList) {
						childElemReset(
							includedFilterValue,
							jobSubFunctionList[1],
							questionnaireAPIKeys.job_subfunction
						);
					}
					const currentIndex = includedFilterValue?.job_function.findIndex(
						(item) => item.job_function === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.job_function.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_function: [...includedFilterValue.job_function],
					}));

					if (filterjobFunctionJobSubFunctions.length > Numbers.zero) {
						Object.keys(filterjobFunctionJobSubFunctions).forEach(
							(
								filterjobFunctionJobSubFunction,
								filterjobFunctionJobSubFunctionIndex
							) => {
								const selectedJobFunctionArr =
									includedFilterValue?.job_function.filter(
										(item) =>
											item.job_function === filterjobFunctionJobSubFunction
									);
								if (selectedJobFunctionArr.length) {
									setIncJobSubFunctions([
										...(incJobSubFunctions as []),
										...filterjobFunctionJobSubFunctions[
											filterjobFunctionJobSubFunctionIndex
										][1],
									]);
								}
							}
						);
					}
				} else {
					setIncJobFunction([...(incJobFunction as []), ...value]);
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_function: [...prevState.job_function, { job_function: value }],
					}));

					if (filterjobFunctionJobSubFunctions.length > Numbers.zero) {
						Object.keys(filterAllJobFunctions).forEach(
							(flterJobFunction, flterJobFunctionIndex) => {
								if (flterJobFunction === value) {
									setIncJobSubFunctions([
										...(incJobSubFunctions as []),
										...filterjobFunctionJobSubFunctions[
											flterJobFunctionIndex
										][1],
									]);
								}
							}
						);
					}
				}
				break;
			case questionnaireAPIKeys.work_region:
				if (!isChecked) {
					setIncCountries([]);
					// unchecking the box...
					const workCountryList = filterRegionCountries?.find((t) =>
						t.includes(value)
					);
					if (workCountryList) {
						childElemReset(
							includedFilterValue,
							workCountryList[1],
							questionnaireAPIKeys.work_country
						);
					}
					const currentIndex = includedFilterValue?.work_region.findIndex(
						(item) => item.work_region === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.work_region.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_region: [...includedFilterValue.work_region],
					}));

					if (filterRegionCountries.length > Numbers.zero) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								const selectedRegionarr =
									includedFilterValue?.work_region.filter(
										(item) => item.work_region === filterCountryRegion
									);
								if (selectedRegionarr.length) {
									setIncCountries([
										...(incCountries as []),
										...filterRegionCountries[filterCountryRegionIndex][1],
									]);
								}
							}
						);
					}
				} else {
					setIncRegion([...(incRegion as []), ...value]);
					setIncludedFilterValue((prevState) => ({
						...prevState,
						work_region: [...prevState.work_region, { work_region: value }],
					}));

					if (filterRegionCountries.length > Numbers.zero) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								if (filterCountryRegion === value) {
									setIncCountries([
										...(incCountries as []),
										...filterRegionCountries[filterCountryRegionIndex][1],
									]);
								}
							}
						);
					}
				}
				break;
			case questionnaireAPIKeys.people_leader:
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					if (childArray instanceof Array) {
						childArray.map((element: string) => {
							const childItemIndex =
								includedFilterValue?.people_leader.findIndex(
									(item) => item.people_leader === element
								);
							if (childItemIndex > -1) {
								includedFilterValue?.people_leader.splice(childItemIndex, 1);
							}
						});
					}

					const currentIndex = includedFilterValue?.people_leader.findIndex(
						(item) => item.people_leader === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.people_leader.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						people_leader: [...includedFilterValue.people_leader],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						people_leader: [
							...prevState.people_leader,
							{ people_leader: value },
						],
					}));
				}
				break;
			case 'worker_type':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.worker_type.findIndex(
						(item) => item.worker_type === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.worker_type.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						worker_type: [...includedFilterValue.worker_type],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						worker_type: [...prevState.worker_type, { worker_type: value }],
					}));
				}
				break;
			case 'sector':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.sector.findIndex(
						(item) => item.sector === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.sector.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						sector: [...includedFilterValue.sector],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						sector: [...prevState.sector, { sector: value }],
					}));
				}
				break;
			case 'mrc_code':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.mrc_code.findIndex(
						(item) => item.mrc_code === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.mrc_code.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...includedFilterValue.mrc_code],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...prevState.mrc_code, { mrc_code: value }],
					}));
				}
				break;
			case questionnaireAPIKeys.legal_entity:
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.legal_entity.findIndex(
						(item) => item.legal_entity === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.legal_entity.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						legal_entity: [...includedFilterValue.legal_entity],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						legal_entity: [...prevState.legal_entity, { legal_entity: value }],
					}));
				}
				break;
			case 'job_group':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.job_group.findIndex(
						(item) => item.job_group === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.job_group.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_group: [...includedFilterValue.job_group],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						job_group: [...prevState.job_group, { job_group: value }],
					}));
				}
				break;
			case 'employee_function':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = includedFilterValue?.employee_function.findIndex(
						(item) => item.employee_function === value
					);
					if (currentIndex > -1) {
						includedFilterValue?.employee_function.splice(currentIndex, 1);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						employee_function: [...includedFilterValue.employee_function],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setIncludedFilterValue((prevState) => ({
						...prevState,
						employee_function: [
							...prevState.employee_function,
							{ employee_function: value },
						],
					}));
				}
				break;
			default:
				break;
		}
	};

	/**
	 * @method
	 * @summary - Transforms the option (user name + user WWID) to user WWID
	 * @parameter - item<string>
	 * @returns string
	 */ const transformItemOption = (item: string) => {
		return (
			item?.split('(')[Numbers.one]?.split(')')[Numbers.zero]?.trim() || item
		);
	};

	const handleExcludeFilterChanges = (
		event: React.ChangeEvent<HTMLInputElement>,
		type: string,
		hasChildren = false,
		childArray = Array
	) => {
		const value = event.target.value;
		const isChecked = event.target.checked;

		const filterRegionCountries = Object.entries(filterRegionCountry);
		const filterjobFunctionJobSubFunctions = Object.entries(
			filterAllJobFunctions
		);
		switch (type) {
			case questionnaireAPIKeys.work_country:
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = excludedFilterValue?.work_country?.findIndex(
						(item) => item.work_country === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.work_country.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						work_country: [...excludedFilterValue.work_country],
					}));
				} else {
					setExcludedFilterValue((prevState) => ({
						...prevState,
						work_country: [...prevState.work_country, { work_country: value }],
					}));
				}
				break;
			case questionnaireAPIKeys.job_subfunction:
				if (!isChecked) {
					// unchecking the box...
					const currentIndex = excludedFilterValue?.job_subfunction?.findIndex(
						(item) => item.job_subfunction === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.job_subfunction.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						job_subfunction: [...excludedFilterValue.job_subfunction],
					}));
				} else {
					setExcludedFilterValue((prevState) => ({
						...prevState,
						job_subfunction: [
							...prevState.job_subfunction,
							{ job_subfunction: value },
						],
					}));
				}
				break;
			case questionnaireAPIKeys.job_function:
				if (!isChecked) {
					setExcJobSubFunctions([]);
					// unchecking the box...
					const jobSubFunctionList = filterjobFunctionJobSubFunctions?.find(
						(t) => t.includes(value)
					);
					if (jobSubFunctionList) {
						childElemReset(
							excludedFilterValue,
							jobSubFunctionList[1],
							questionnaireAPIKeys.job_subfunction
						);
					}
					const currentIndex = excludedFilterValue?.job_function?.findIndex(
						(item) => item.job_function === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.job_function.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						job_function: [...excludedFilterValue.job_function],
					}));

					if (filterjobFunctionJobSubFunctions.length > Numbers.zero) {
						Object.keys(filterjobFunctionJobSubFunctions).forEach(
							(
								filterjobFunctionJobSubFunction,
								filterjobFunctionJobSubFunctionIndex
							) => {
								const selectedJobFunctionArr =
									excludedFilterValue?.job_function.filter(
										(item) =>
											item.job_function === filterjobFunctionJobSubFunction
									);
								if (selectedJobFunctionArr.length) {
									setExcJobSubFunctions([
										...(excJobSubFunctions as []),
										...filterjobFunctionJobSubFunctions[
											filterjobFunctionJobSubFunctionIndex
										][1],
									]);
								}
							}
						);
					}
				} else {
					setExcJobFunction([...(excJobFunction as []), ...value]);
					setExcludedFilterValue((prevState) => ({
						...prevState,
						job_function: [...prevState.job_function, { job_function: value }],
					}));

					if (filterjobFunctionJobSubFunctions.length > Numbers.zero) {
						Object.keys(filterAllJobFunctions).forEach(
							(flterJobFunction, flterJobFunctionIndex) => {
								if (flterJobFunction === value) {
									setExcJobSubFunctions([
										...(excJobSubFunctions as []),
										...filterjobFunctionJobSubFunctions[
											flterJobFunctionIndex
										][1],
									]);
								}
							}
						);
					}
				}
				break;
			case questionnaireAPIKeys.work_region:
				if (!isChecked) {
					setExcCountries([]);
					// unchecking the box...
					const workCountryList = filterRegionCountries?.find((t) =>
						t.includes(value)
					);
					if (workCountryList) {
						childElemReset(
							excludedFilterValue,
							workCountryList[1],
							questionnaireAPIKeys.work_country
						);
					}
					const currentIndex = excludedFilterValue?.work_region?.findIndex(
						(item) => item.work_region === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.work_region.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						work_region: [...excludedFilterValue.work_region],
					}));

					if (filterRegionCountries.length > Numbers.zero) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								const selectedRegionarr =
									includedFilterValue?.work_region.filter(
										(item) => item.work_region === filterCountryRegion
									);
								if (selectedRegionarr.length) {
									setExcCountries([
										...(excCountries as []),
										...filterRegionCountries[filterCountryRegionIndex][1],
									]);
								}
							}
						);
					}
				} else {
					setExcRegion([...(excRegion as []), ...value]);
					setExcludedFilterValue((prevState) => ({
						...prevState,
						work_region: [...prevState.work_region, { work_region: value }],
					}));

					if (filterRegionCountries.length > Numbers.zero) {
						Object.keys(filterRegionCountry).forEach(
							(filterCountryRegion, filterCountryRegionIndex) => {
								if (filterCountryRegion === value) {
									setExcCountries([
										...(excCountries as []),
										...filterRegionCountries[filterCountryRegionIndex][1],
									]);
								}
							}
						);
					}
				}
				break;
			case questionnaireAPIKeys.people_leader:
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					if (childArray instanceof Array) {
						childArray.map((element: string) => {
							const childItemIndex =
								excludedFilterValue?.people_leader?.findIndex(
									(item) => item.people_leader === element
								);
							if (childItemIndex > -1) {
								excludedFilterValue?.people_leader.splice(childItemIndex, 1);
							}
						});
					}

					const currentIndex = excludedFilterValue?.people_leader?.findIndex(
						(item) => item.people_leader === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.people_leader.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						people_leader: [...excludedFilterValue.people_leader],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						people_leader: [
							...prevState.people_leader,
							{ people_leader: value },
						],
					}));
				}
				break;
			case 'worker_type':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = excludedFilterValue?.worker_type?.findIndex(
						(item) => item.worker_type === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.worker_type.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						worker_type: [...excludedFilterValue.worker_type],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						worker_type: [...prevState.worker_type, { worker_type: value }],
					}));
				}
				break;
			case 'sector':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = excludedFilterValue?.sector?.findIndex(
						(item) => item.sector === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.sector.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						sector: [...excludedFilterValue.sector],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						sector: [...prevState.sector, { sector: value }],
					}));
				}
				break;
			case 'mrc_code':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = excludedFilterValue?.mrc_code?.findIndex(
						(item) => item.mrc_code === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.mrc_code.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...excludedFilterValue.mrc_code],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						mrc_code: [...prevState.mrc_code, { mrc_code: value }],
					}));
				}
				break;
			case questionnaireAPIKeys.legal_entity:
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = excludedFilterValue?.legal_entity?.findIndex(
						(item) => item.legal_entity === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.legal_entity.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						legal_entity: [...excludedFilterValue.legal_entity],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						legal_entity: [...prevState.legal_entity, { legal_entity: value }],
					}));
				}
				break;
			case 'job_group':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex = excludedFilterValue?.job_group?.findIndex(
						(item) => item.job_group === value
					);
					if (currentIndex > -1) {
						excludedFilterValue?.job_group.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						job_group: [...excludedFilterValue.job_group],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						job_group: [...prevState.job_group, { job_group: value }],
					}));
				}
				break;
			case 'employee_function':
				if (!isChecked) {
					// unchecking the box...
					if (hasChildren) {
						const newFilterHasChildren = filterHasChildren.filter(
							(item: string) => item !== value
						);
						setFilterHasChildren(newFilterHasChildren);
					}
					const currentIndex =
						excludedFilterValue?.employee_function?.findIndex(
							(item) => item.employee_function === value
						);
					if (currentIndex > -1) {
						excludedFilterValue?.employee_function.splice(currentIndex, 1);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						employee_function: [...excludedFilterValue.employee_function],
					}));
				} else {
					if (hasChildren) {
						setFilterHasChildren([...filterHasChildren, value]);
					}
					setExcludedFilterValue((prevState) => ({
						...prevState,
						employee_function: [
							...prevState.employee_function,
							{ employee_function: value },
						],
					}));
				}
				break;
			default:
				break;
		}
	};

	const surveyAudienceIncludedFilters = Object.entries(
		surveyAudienceIncludedFilterList
	);
	const surveyAudienceExcludedFilters = Object.entries(
		surveyAudienceExcludedFilterList
	);

	const handleResetExcludeFilters = () => {
		setExcludedFilterValue(initialFilterValue);
		setExcCountries([]);
		setExcJobSubFunctions([]);
		setFilterHasChildren([]);
		setExcludedWWIDs([]);
		setShowExcludeSwitchSelected(false);
		setExcIndividualWWIDs([]);
		setExcAllIndividualWWIDs([]);
		setExcludedLegalEntity([]);
		setShowExcIndividualSwitchSelected(false);
		setShowExcludeLegalEntitySwitchSelected(false);
	};

	const handleResetIncludeFilters = () => {
		setIncludedFilterValue(initialFilterValue);
		setIncCountries([]);
		setIncJobSubFunctions([]);
		setFilterHasChildren([]);
		setIncludedWWIDs([]);
		setIncludedLegalEntity([]);
		setShowIncludeSwitchSelected(false);
		setShowIncludeLegalEntitySwitchSelected(false);
		setIncIndividualWWIDs([]);
		setIncAllIndividualWWIDs([]);
		setShowIncIndividualSwitchSelected(false);
	};
	const [individualWWIDType, setIndividualWWIDType] = useState<string>('');
	/** Include Individual WWID Switch filter changes */
	const [incIndividualWWIDLoading, setIncIndividualWWIDLoading] =
		useState<boolean>(false);
	const [incIndividualWWIDs, setIncIndividualWWIDs] = useState<string[]>([]);
	const handleIncIndividualWWIDsAsyncChange = (newValue: string[]) => {
		const searchText = newValue.join('').toString();
		if (searchText.length >= Numbers.three) {
			setIndividualWWIDType(AudienceFilterLabels.includeFilters);
			dispatch(getUserWWID(searchText));
		} else {
			setIncAllIndividualWWIDs([]);
		}
	};
	const handleIncIndividualWWIDsChange = (newValue: string[]) => {
		setIncludedFilterValue((prevState) => ({
			...prevState,
			wwid: newValue.map((item) => ({
				wwid: transformItemOption(item),
			})),
		}));
		if (newValue.length === Numbers.zero) setIncAllIndividualWWIDs([]);
	};
	const [showIncIndividualSwitchSelected, setShowIncIndividualSwitchSelected] =
		useState<boolean>(false);
	const handleIncIndividualSwitchChange = (
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setShowIncIndividualSwitchSelected(checked);
	};
	/** Exclude Individual WWID Switch filter changes */
	const [excIndividualWWIDLoading, setExcIndividualWWIDLoading] =
		useState<boolean>(false);
	const [excIndividualWWIDs, setExcIndividualWWIDs] = useState<string[]>([]);
	const handleExcIndividualWWIDsAsyncChange = (newValue: string[]) => {
		const searchText = newValue.join('').toString();
		if (searchText.length >= Numbers.three) {
			setIndividualWWIDType(AudienceFilterLabels.excludeFilters);
			dispatch(getUserWWID(searchText));
		} else {
			setExcAllIndividualWWIDs([]);
		}
	};
	const handleExcIndividualWWIDsChange = (newValue: string[]) => {
		setExcludedFilterValue((prevState) => ({
			...prevState,
			wwid: newValue.map((item) => ({
				wwid: transformItemOption(item),
			})),
		}));
		if (newValue.length === Numbers.zero) setExcAllIndividualWWIDs([]);
	};
	const [showExcIndividualSwitchSelected, setShowExcIndividualSwitchSelected] =
		useState<boolean>(false);
	const handleExcIndividualSwitchChange = (
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setShowExcIndividualSwitchSelected(checked);
	};
	/** Include Switch filter changes */
	const handleIncludeWWIDsChange = (newValue: string[]) => {
		setIncludedFilterValue((prevState) => ({
			...prevState,
			manager_wwid: newValue.map((item) => ({
				manager_wwid: transformItemOption(item),
			})),
		}));
	};
	const handleIncludeLegalEntityChange = (newValue: string[]) => {
		setIncludedFilterValue((prevState) => ({
			...prevState,
			legal_entity: newValue.map((item) => ({
				legal_entity: item,
			})),
		}));
	};
	const handleExcludeLegalEntityChange = (newValue: string[]) => {
		setExcludedFilterValue((prevState) => ({
			...prevState,
			legal_entity: newValue.map((item) => ({
				legal_entity: item,
			})),
		}));
	};
	const [showIncludeSwitchSelected, setShowIncludeSwitchSelected] =
		useState<boolean>(false);
	const [
		showIncludeLegalEntitySwitchSelected,
		setShowIncludeLegalEntitySwitchSelected,
	] = useState<boolean>(false);

	const handleIncludeSwitchChange = (
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setShowIncludeSwitchSelected(checked);
	};
	const handleIncludeLegalEntitySwitchChange = (
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setShowIncludeLegalEntitySwitchSelected(checked);
	};
	/** Exclude Switch filter changes */
	const handleExcludeWWIDsChange = (newValue: string[]) => {
		setExcludedFilterValue((prevState) => ({
			...prevState,
			manager_wwid: newValue.map((item) => ({
				manager_wwid: transformItemOption(item),
			})),
		}));
	};
	const [showExcludeSwitchSelected, setShowExcludeSwitchSelected] =
		useState<boolean>(false);
	const [
		showExcludeLegalEntitySwitchSelected,
		setShowExcludeLegalEntitySwitchSelected,
	] = useState<boolean>(false);
	const handleExcludeSwitchChange = (
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setShowExcludeSwitchSelected(checked);
	};
	const handleExcludeLegalEntitySwitchChange = (
		_event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setShowExcludeLegalEntitySwitchSelected(checked);
	};
	const switchHeading = (isSwitchOn: boolean, currentKey: string) => {
		if (isSwitchOn) {
			return currentKey !== questionnaireAPIKeys.legal_entity
				? commonLabels.selectedLeaderWWID
				: commonLabels.selectedLegalEntity;
		} else {
			return getFilterLabelById(currentKey);
		}
	};

	return (
		<>
			<DialogTitle sx={{ padding: '0px' }} data-testid="dialogTitleCheck">
				<FilterBoxHeader
					sx={{ marginTop: '30px', marginBottom: '30px' }}
					className="d-flex"
				>
					<SelectFilter>{AudienceFilterLabels.includeFilters}</SelectFilter>
					<Reset
						data-testid="reset-all-include-button"
						className="pr-0 justify-end"
						variant="text"
						onClick={() => {
							handleResetIncludeFilters();
						}}
					>
						{AudienceFilterLabels.resetAll}
					</Reset>
				</FilterBoxHeader>
			</DialogTitle>
			{/** Included filter block Starts */}
			{surveyAudienceIncludedFilters.length > Numbers.zero && (
				<StyledFilterRowsContainer>
					{surveyAudienceIncludedFilters.map((surveyAudienceIncludedFilter) => {
						const currentKey = surveyAudienceIncludedFilter[Numbers.zero];
						const currentMultipleKeys = currentKey.split('-');
						const surveyAudienceFilterValues = surveyAudienceIncludedFilter[1];
						const surveyAudienceFilterValueKeys = getKey(
							surveyAudienceFilterValues
						);
						return currentKey !== questionnaireAPIKeys.manager_wwid &&
							currentKey !== questionnaireAPIKeys.legal_entity ? (
							currentMultipleKeys.length > 1 ? (
								<>
									{currentMultipleKeys.map((currentMultipleKey) => {
										return (
											<>
												{filterRegion.length > Numbers.zero &&
												currentMultipleKey ===
													questionnaireAPIKeys.work_region ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																placeholder={commonLabels.selectLabel}
																displayEmpty
																multiple
																value={[]}
																renderValue={() =>
																	getValCount(
																		currentMultipleKey,
																		includedFilterValue
																	) > Numbers.zero
																		? `${getValCount(
																				currentMultipleKey,
																				includedFilterValue
																			)} Selected`
																		: commonLabels.selectLabel
																}
															>
																<>
																	{filterRegion.map((filterRegn: string) => {
																		return (
																			<>
																				<MenuItem
																					key={filterRegn}
																					value={filterRegn}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${filterRegn.replace(/\s/g, '')}`}
																								value={filterRegn}
																								onChange={(e) => {
																									handleIncludeFilterChanges(
																										e,
																										currentMultipleKey,
																										false,
																										surveyAudienceFilterValues[
																											currentMultipleKey
																										]
																									);
																								}}
																								checked={checkVal(
																									currentMultipleKey,
																									filterRegn,
																									includedFilterValue
																								)}
																							/>
																						}
																						label={filterRegn}
																						sx={{
																							width: '100%',
																						}}
																						htmlFor={`aud_filter_${filterRegn.replace(/\s/g, '')}`}
																					/>
																				</MenuItem>
																			</>
																		);
																	})}
																</>
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
												{currentMultipleKey ===
												questionnaireAPIKeys.work_country ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																disabled={incCountries.length === Numbers.zero}
																value={[]}
																placeholder={commonLabels.selectLabel}
																displayEmpty
																multiple
																renderValue={() =>
																	getValCount(
																		currentMultipleKey,
																		includedFilterValue
																	) > Numbers.zero
																		? `${getValCount(
																				currentMultipleKey,
																				includedFilterValue
																			)} Selected`
																		: commonLabels.selectLabel
																}
															>
																{incCountries
																	.sort()
																	.filter(function (v, i, self) {
																		return i === self.indexOf(v);
																	})
																	.map((cntrs: string) => {
																		return (
																			<MenuItem key={cntrs} value={cntrs}>
																				<FormControlLabel
																					control={
																						<Checkbox
																							id={`aud_filter_${cntrs.replace(/\s/g, '')}`}
																							value={cntrs}
																							onChange={(e) => {
																								handleIncludeFilterChanges(
																									e,
																									currentMultipleKey,
																									false,
																									surveyAudienceFilterValues[
																										currentMultipleKey
																									]
																								);
																							}}
																							checked={checkVal(
																								currentMultipleKey,
																								cntrs,
																								includedFilterValue
																							)}
																						/>
																					}
																					label={cntrs}
																					sx={{
																						width: '100%',
																					}}
																					htmlFor={`aud_filter_${cntrs.replace(/\s/g, '')}`}
																				/>
																			</MenuItem>
																		);
																	})}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
												{currentMultipleKey ===
												questionnaireAPIKeys.job_function ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																disabled={filterJobFunction.indexOf('') > -1}
																placeholder={commonLabels.selectLabel}
																displayEmpty
																multiple
																value={[]}
																renderValue={() =>
																	getValCount(
																		currentMultipleKey,
																		includedFilterValue
																	) > Numbers.zero
																		? `${getValCount(
																				currentMultipleKey,
																				includedFilterValue
																			)} Selected`
																		: commonLabels.selectLabel
																}
															>
																{filterJobFunction.map(
																	(filterJobFunc: string) => {
																		return (
																			<>
																				<MenuItem
																					key={filterJobFunc}
																					value={filterJobFunc}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${filterJobFunc.replace(/\s/g, '')}`}
																								value={filterJobFunc}
																								onChange={(e) => {
																									handleIncludeFilterChanges(
																										e,
																										currentMultipleKey,
																										false,
																										surveyAudienceFilterValues[
																											currentMultipleKey
																										]
																									);
																								}}
																								checked={checkVal(
																									currentMultipleKey,
																									filterJobFunc,
																									includedFilterValue
																								)}
																							/>
																						}
																						label={filterJobFunc}
																						sx={{
																							width: '100%',
																						}}
																						htmlFor={`aud_filter_${filterJobFunc.replace(/\s/g, '')}`}
																					/>
																				</MenuItem>
																			</>
																		);
																	}
																)}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
												{currentMultipleKey ===
												questionnaireAPIKeys.job_subfunction ? (
													<StyledFilterRows>
														<StyledTypography>
															{getFilterLabelById(currentMultipleKey)}
														</StyledTypography>
														<StyledFormControl>
															<StyledSelectField
																disabled={
																	incJobSubFunctions.length === Numbers.zero
																}
																value={[]}
																placeholder={commonLabels.selectLabel}
																displayEmpty
																multiple
																renderValue={() =>
																	getValCount(
																		currentMultipleKey,
																		includedFilterValue
																	) > Numbers.zero
																		? `${getValCount(
																				currentMultipleKey,
																				includedFilterValue
																			)} Selected`
																		: commonLabels.selectLabel
																}
															>
																{incJobSubFunctions
																	.sort()
																	.filter(function (v, i, self) {
																		return i === self.indexOf(v);
																	})
																	.map((jobSubFnction: string) => {
																		return (
																			<>
																				<MenuItem
																					key={jobSubFnction}
																					value={jobSubFnction}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${jobSubFnction.replace(/\s/g, '')}`}
																								value={jobSubFnction}
																								onChange={(e) => {
																									handleIncludeFilterChanges(
																										e,
																										currentMultipleKey,
																										false,
																										surveyAudienceFilterValues[
																											currentMultipleKey
																										]
																									);
																								}}
																								checked={checkVal(
																									currentMultipleKey,
																									jobSubFnction,
																									includedFilterValue
																								)}
																							/>
																						}
																						label={jobSubFnction}
																						sx={{
																							width: '100%',
																						}}
																						htmlFor={`aud_filter_${jobSubFnction.replace(/\s/g, '')}`}
																					/>
																				</MenuItem>
																			</>
																		);
																	})}
															</StyledSelectField>
														</StyledFormControl>
													</StyledFilterRows>
												) : null}
											</>
										);
									})}
								</>
							) : (
								<StyledFilterRows>
									<StyledTypography sx={{ height: '18px' }}>
										{getFilterLabelById(currentKey)}
									</StyledTypography>
									<StyledFormControl key={`form-control-${currentKey}`}>
										<StyledSelectField
											placeholder={commonLabels.selectLabel}
											displayEmpty
											multiple
											value={surveyAudienceFilterValueKeys}
											renderValue={() =>
												getValCount(currentKey, includedFilterValue) >
												Numbers.zero
													? `${getValCount(currentKey, includedFilterValue)} Selected`
													: commonLabels.selectLabel
											}
											disabled={surveyAudienceFilterValueKeys.indexOf('') > -1}
										>
											{surveyAudienceFilterValueKeys.map(
												(surveyAudienceFilterValueKey) => {
													return (
														<>
															<MenuItem
																key={surveyAudienceFilterValueKey}
																value={surveyAudienceFilterValueKey}
															>
																<FormControlLabel
																	control={
																		<Checkbox
																			id={`aud_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																			value={surveyAudienceFilterValueKey}
																			onChange={(e) =>
																				handleIncludeFilterChanges(
																					e,
																					currentKey,
																					surveyAudienceFilterValues[
																						surveyAudienceFilterValueKey
																					].length > Numbers.zero,
																					surveyAudienceFilterValues[
																						surveyAudienceFilterValueKey
																					]
																				)
																			}
																			checked={checkVal(
																				currentKey,
																				surveyAudienceFilterValueKey,
																				includedFilterValue
																			)}
																		/>
																	}
																	label={surveyAudienceFilterValueKey}
																	sx={{
																		width: '100%',
																	}}
																	htmlFor={`aud_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																/>
															</MenuItem>
															{/* 1st Level Children  */}
															{surveyAudienceFilterValues[
																surveyAudienceFilterValueKey
															].length > Numbers.zero &&
																checkVal(
																	currentKey,
																	surveyAudienceFilterValueKey,
																	includedFilterValue
																) &&
																surveyAudienceFilterValues[
																	surveyAudienceFilterValueKey
																].map(
																	(surveyAudienceFilterValueVal: string) => {
																		return (
																			<>
																				<FormGroup
																					sx={{
																						display: 'flex',
																						direction: 'column',
																						justifyContent: 'center',
																						alignItems: 'center',
																					}}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_filter_${surveyAudienceFilterValueVal.replace(/\s/g, '')}`}
																								value={
																									surveyAudienceFilterValueVal
																								}
																								onChange={(e) =>
																									handleIncludeFilterChanges(
																										e,
																										currentKey,
																										false,
																										surveyAudienceFilterValues[
																											surveyAudienceFilterValueKey
																										]
																									)
																								}
																								checked={checkVal(
																									currentKey,
																									surveyAudienceFilterValueVal,
																									includedFilterValue
																								)}
																							/>
																						}
																						label={surveyAudienceFilterValueVal}
																						htmlFor={`aud_filter_${surveyAudienceFilterValueVal.replace(/\s/g, '')}`}
																					/>
																				</FormGroup>
																			</>
																		);
																	}
																)}
														</>
													);
												}
											)}
										</StyledSelectField>
									</StyledFormControl>
									{hasIncPeopleLeaderError &&
										currentKey === questionnaireAPIKeys.people_leader && (
											<p className="error-msg">
												{AudienceFilterLabels.selectSubgroupMsg}
											</p>
										)}
								</StyledFilterRows>
							)
						) : (
							<StyledFilterRows>
								<StyledBox key={currentKey}>
									<StyledTypography
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										{switchHeading(
											currentKey === questionnaireAPIKeys.manager_wwid
												? showIncludeSwitchSelected
												: showIncludeLegalEntitySwitchSelected,
											currentKey
										)}
										<StyledTooltip
											title={
												currentKey === questionnaireAPIKeys.manager_wwid
													? commonLabels.selectLabeledLeaderWWIDTooltipMsg
													: commonLabels.selectLabeledLegalEntityTooltipMsg
											}
											placement="top"
										>
											<Box sx={{ marginBottom: '-26px' }}>
												<IOSSwitch
													checked={
														currentKey === questionnaireAPIKeys.manager_wwid
															? showIncludeSwitchSelected
															: showIncludeLegalEntitySwitchSelected
													}
													onChange={
														currentKey === questionnaireAPIKeys.manager_wwid
															? handleIncludeSwitchChange
															: handleIncludeLegalEntitySwitchChange
													}
												/>
											</Box>
										</StyledTooltip>
									</StyledTypography>
									<GenericAutocomplete
										key={
											currentKey === questionnaireAPIKeys.manager_wwid
												? includedWWIDs.length
												: includedLegalEntity.length
										}
										defaultValue={
											currentKey === questionnaireAPIKeys.manager_wwid
												? includedWWIDs
												: includedLegalEntity
										}
										options={surveyAudienceFilterValueKeys}
										id={
											currentKey === questionnaireAPIKeys.manager_wwid
												? 'include_manager_wwid'
												: 'include_legal_entity'
										}
										placeholder={commonLabels.search}
										onItemChange={
											currentKey === questionnaireAPIKeys.manager_wwid
												? handleIncludeWWIDsChange
												: handleIncludeLegalEntityChange
										}
										showSelected={
											currentKey === questionnaireAPIKeys.manager_wwid
												? showIncludeSwitchSelected
												: showIncludeLegalEntitySwitchSelected
										}
										sx={{ marginTop: '-10px', width: '100%' }}
										isLegalEntity={
											currentKey === questionnaireAPIKeys.legal_entity
										}
									/>
								</StyledBox>
							</StyledFilterRows>
						);
					})}
					<StyledFilterRows>
						<StyledBox key={'include_individual_wwid'}>
							<StyledTypography className="d-flex justify-between">
								{showIncIndividualSwitchSelected
									? commonLabels.selectedIndividualWWID
									: getFilterLabelById('wwid')}
								<StyledTooltip
									title={commonLabels.selectLabeledIndividualWWIDTooltipMsg}
									placement="top"
								>
									<Box sx={{ marginBottom: '-26px' }}>
										<IOSSwitch
											checked={showIncIndividualSwitchSelected}
											onChange={handleIncIndividualSwitchChange}
										/>
									</Box>
								</StyledTooltip>
							</StyledTypography>
							<GenericAutocomplete
								isAsync={true}
								loading={incIndividualWWIDLoading}
								key={'include_individual_wwid'}
								defaultValue={incIndividualWWIDs}
								options={incAllIndividualWWIDs}
								id="include_individual_wwid"
								placeholder={commonLabels.search}
								onItemAsyncChange={handleIncIndividualWWIDsAsyncChange}
								onItemChange={handleIncIndividualWWIDsChange}
								showSelected={showIncIndividualSwitchSelected}
								sx={{ marginTop: '-10px', width: '100%' }}
							/>
						</StyledBox>
					</StyledFilterRows>
				</StyledFilterRowsContainer>
			)}
			{/** Excluded filter block Starts */}
			{surveyAudienceExcludedFilters.length > Numbers.zero && (
				<>
					<FilterBoxHeader
						sx={{ marginTop: '60px', marginBottom: '30px' }}
						className="d-flex"
					>
						<SelectFilter>{AudienceFilterLabels.excludeFilters}</SelectFilter>
						{surveyAudienceExcludedFilters.length > Numbers.zero && (
							<Reset
								data-testid="reset-all-exclude-button"
								className="pr-0 justify-end"
								variant="text"
								onClick={() => {
									handleResetExcludeFilters();
								}}
							>
								{AudienceFilterLabels.resetAll}
							</Reset>
						)}
					</FilterBoxHeader>
					<StyledFilterRowsContainer>
						{surveyAudienceExcludedFilters.map(
							(surveyAudienceExcludedFilter) => {
								const currentKey = surveyAudienceExcludedFilter[Numbers.zero];
								const currentMultipleKeys = currentKey.split('-');
								const surveyAudienceFilterValues =
									surveyAudienceExcludedFilter[1];
								const surveyAudienceFilterValueKeys = getKey(
									surveyAudienceFilterValues
								);

								return currentKey !== questionnaireAPIKeys.manager_wwid &&
									currentKey !== questionnaireAPIKeys.legal_entity ? (
									currentMultipleKeys?.length > 1 ? (
										<>
											{currentMultipleKeys?.map((currentMultipleKey) => {
												return (
													<>
														{filterRegion.length > Numbers.zero &&
														currentMultipleKey ===
															questionnaireAPIKeys.work_region ? (
															<StyledFilterRows>
																<StyledTypography>
																	{getFilterLabelById(currentMultipleKey)}
																</StyledTypography>
																<StyledFormControl>
																	<StyledSelectField
																		placeholder={commonLabels.selectLabel}
																		displayEmpty
																		multiple
																		value={[]}
																		renderValue={() =>
																			getValCount(
																				currentMultipleKey,
																				excludedFilterValue
																			) > Numbers.zero
																				? `${getValCount(
																						currentMultipleKey,
																						excludedFilterValue
																					)} Selected`
																				: commonLabels.selectLabel
																		}
																	>
																		{filterRegion.map((filterRegn: string) => {
																			return (
																				<MenuItem
																					key={filterRegn}
																					value={filterRegn}
																				>
																					<FormControlLabel
																						control={
																							<Checkbox
																								id={`aud_exc_filter_${filterRegn.replace(/\s/g, '')}`}
																								value={filterRegn}
																								onChange={(e) => {
																									handleExcludeFilterChanges(
																										e,
																										currentMultipleKey,
																										false,
																										surveyAudienceFilterValues[
																											currentMultipleKey
																										]
																									);
																								}}
																								checked={checkVal(
																									currentMultipleKey,
																									filterRegn,
																									excludedFilterValue
																								)}
																							/>
																						}
																						label={filterRegn}
																						sx={{
																							width: '100%',
																						}}
																						htmlFor={`aud_exc_filter_${filterRegn.replace(/\s/g, '')}`}
																					/>
																				</MenuItem>
																			);
																		})}
																	</StyledSelectField>
																</StyledFormControl>
															</StyledFilterRows>
														) : null}
														{currentMultipleKey ===
														questionnaireAPIKeys.work_country ? (
															<StyledFilterRows>
																<StyledTypography>
																	{getFilterLabelById(currentMultipleKey)}
																</StyledTypography>
																<StyledFormControl>
																	<StyledSelectField
																		disabled={
																			excCountries.length === Numbers.zero
																		}
																		value={[]}
																		placeholder={commonLabels.selectLabel}
																		displayEmpty
																		multiple
																		renderValue={() =>
																			getValCount(
																				currentMultipleKey,
																				excludedFilterValue
																			) > Numbers.zero
																				? `${getValCount(
																						currentMultipleKey,
																						excludedFilterValue
																					)} Selected`
																				: commonLabels.selectLabel
																		}
																	>
																		{excCountries
																			.sort()
																			.filter(function (v, i, self) {
																				return i === self.indexOf(v);
																			})
																			.map((cntrs: string) => {
																				return (
																					<MenuItem key={cntrs} value={cntrs}>
																						<FormControlLabel
																							control={
																								<Checkbox
																									id={`aud_exc_filter_${cntrs.replace(/\s/g, '')}`}
																									value={cntrs}
																									onChange={(e) => {
																										handleExcludeFilterChanges(
																											e,
																											currentMultipleKey,
																											false,
																											surveyAudienceFilterValues[
																												currentMultipleKey
																											]
																										);
																									}}
																									checked={checkVal(
																										currentMultipleKey,
																										cntrs,
																										excludedFilterValue
																									)}
																								/>
																							}
																							label={cntrs}
																							sx={{
																								width: '100%',
																							}}
																							htmlFor={`aud_exc_filter_${cntrs.replace(/\s/g, '')}`}
																						/>
																					</MenuItem>
																				);
																			})}
																	</StyledSelectField>
																</StyledFormControl>
															</StyledFilterRows>
														) : null}
														{currentMultipleKey ===
														questionnaireAPIKeys.job_function ? (
															<StyledFilterRows>
																<StyledTypography>
																	{getFilterLabelById(currentMultipleKey)}
																</StyledTypography>
																<StyledFormControl>
																	<StyledSelectField
																		disabled={
																			filterJobFunction.indexOf('') > -1
																		}
																		placeholder={commonLabels.selectLabel}
																		displayEmpty
																		multiple
																		value={[]}
																		renderValue={() =>
																			getValCount(
																				currentMultipleKey,
																				excludedFilterValue
																			) > Numbers.zero
																				? `${getValCount(
																						currentMultipleKey,
																						excludedFilterValue
																					)} Selected`
																				: commonLabels.selectLabel
																		}
																	>
																		{filterJobFunction.map(
																			(filterJobFunc: string) => {
																				return (
																					<>
																						<MenuItem
																							key={filterJobFunc}
																							value={filterJobFunc}
																						>
																							<FormControlLabel
																								control={
																									<Checkbox
																										id={`aud_exc_filter_${filterJobFunc.replace(/\s/g, '')}`}
																										value={filterJobFunc}
																										onChange={(e) => {
																											handleExcludeFilterChanges(
																												e,
																												currentMultipleKey,
																												false,
																												surveyAudienceFilterValues[
																													currentMultipleKey
																												]
																											);
																										}}
																										checked={checkVal(
																											currentMultipleKey,
																											filterJobFunc,
																											excludedFilterValue
																										)}
																									/>
																								}
																								label={filterJobFunc}
																								sx={{
																									width: '100%',
																								}}
																								htmlFor={`aud_exc_filter_${filterJobFunc.replace(/\s/g, '')}`}
																							/>
																						</MenuItem>
																					</>
																				);
																			}
																		)}
																	</StyledSelectField>
																</StyledFormControl>
															</StyledFilterRows>
														) : null}
														{currentMultipleKey ===
														questionnaireAPIKeys.job_subfunction ? (
															<StyledFilterRows>
																<StyledTypography>
																	{getFilterLabelById(currentMultipleKey)}
																</StyledTypography>
																<StyledFormControl>
																	<StyledSelectField
																		disabled={
																			excJobSubFunctions.length === Numbers.zero
																		}
																		value={[]}
																		placeholder={commonLabels.selectLabel}
																		displayEmpty
																		multiple
																		renderValue={() =>
																			getValCount(
																				currentMultipleKey,
																				excludedFilterValue
																			) > Numbers.zero
																				? `${getValCount(
																						currentMultipleKey,
																						excludedFilterValue
																					)} Selected`
																				: commonLabels.selectLabel
																		}
																	>
																		{excJobSubFunctions
																			.sort()
																			.filter(function (v, i, self) {
																				return i === self.indexOf(v);
																			})
																			.map((jobSubFnction: string) => {
																				return (
																					<>
																						<MenuItem
																							key={jobSubFnction}
																							value={jobSubFnction}
																						>
																							<FormControlLabel
																								control={
																									<Checkbox
																										id={`aud_exc_filter_${jobSubFnction.replace(/\s/g, '')}`}
																										value={jobSubFnction}
																										onChange={(e) => {
																											handleExcludeFilterChanges(
																												e,
																												currentMultipleKey,
																												false,
																												surveyAudienceFilterValues[
																													currentMultipleKey
																												]
																											);
																										}}
																										checked={checkVal(
																											currentMultipleKey,
																											jobSubFnction,
																											excludedFilterValue
																										)}
																									/>
																								}
																								label={jobSubFnction}
																								sx={{
																									width: '100%',
																								}}
																								htmlFor={`aud_exc_filter_${jobSubFnction.replace(/\s/g, '')}`}
																							/>
																						</MenuItem>
																					</>
																				);
																			})}
																	</StyledSelectField>
																</StyledFormControl>
															</StyledFilterRows>
														) : null}
													</>
												);
											})}
										</>
									) : (
										<StyledFilterRows>
											<StyledTypography sx={{ height: '18px' }}>
												{getFilterLabelById(currentKey)}
											</StyledTypography>
											<StyledFormControl>
												<StyledSelectField
													placeholder={commonLabels.selectLabel}
													displayEmpty
													multiple
													value={surveyAudienceFilterValueKeys}
													renderValue={() =>
														getValCount(currentKey, excludedFilterValue) >
														Numbers.zero
															? `${getValCount(currentKey, excludedFilterValue)} Selected`
															: commonLabels.selectLabel
													}
													disabled={
														surveyAudienceFilterValueKeys.indexOf('') > -1
													}
												>
													{surveyAudienceFilterValueKeys.map(
														(surveyAudienceFilterValueKey) => {
															return (
																<>
																	<MenuItem
																		key={surveyAudienceFilterValueKey}
																		value={surveyAudienceFilterValueKey}
																	>
																		<FormControlLabel
																			control={
																				<Checkbox
																					id={`aud_exc_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																					value={surveyAudienceFilterValueKey}
																					onChange={(e) =>
																						handleExcludeFilterChanges(
																							e,
																							currentKey,
																							surveyAudienceFilterValues[
																								surveyAudienceFilterValueKey
																							].length > Numbers.zero,
																							surveyAudienceFilterValues[
																								surveyAudienceFilterValueKey
																							]
																						)
																					}
																					checked={checkVal(
																						currentKey,
																						surveyAudienceFilterValueKey,
																						excludedFilterValue
																					)}
																				/>
																			}
																			label={surveyAudienceFilterValueKey}
																			sx={{
																				width: '100%',
																			}}
																			htmlFor={`aud_exc_filter_${surveyAudienceFilterValueKey.replace(/\s/g, '')}`}
																		/>
																	</MenuItem>
																	{/* 1st Level Children  */}
																	{surveyAudienceFilterValues[
																		surveyAudienceFilterValueKey
																	].length > Numbers.zero &&
																		checkVal(
																			currentKey,
																			surveyAudienceFilterValueKey,
																			excludedFilterValue
																		) &&
																		surveyAudienceFilterValues[
																			surveyAudienceFilterValueKey
																		].map(
																			(
																				surveyAudienceFilterValueVal: string
																			) => {
																				return (
																					<>
																						<FormGroup
																							sx={{
																								display: 'flex',
																								direction: 'column',
																								justifyContent: 'center',
																								alignItems: 'center',
																							}}
																						>
																							<FormControlLabel
																								control={
																									<Checkbox
																										id={`aud_exc_filter_${surveyAudienceFilterValueVal.replace(/\s/g, '')}`}
																										value={
																											surveyAudienceFilterValueVal
																										}
																										onChange={(e) =>
																											handleExcludeFilterChanges(
																												e,
																												currentKey,
																												false,
																												surveyAudienceFilterValues[
																													currentKey
																												]
																											)
																										}
																										checked={checkVal(
																											currentKey,
																											surveyAudienceFilterValueVal,
																											excludedFilterValue
																										)}
																									/>
																								}
																								label={
																									surveyAudienceFilterValueVal
																								}
																								htmlFor={`aud_exc_filter_${surveyAudienceFilterValueVal.replace(/\s/g, '')}`}
																							/>
																						</FormGroup>
																					</>
																				);
																			}
																		)}
																</>
															);
														}
													)}
												</StyledSelectField>
											</StyledFormControl>
											{hasExcPeopleLeaderError &&
												currentKey === questionnaireAPIKeys.people_leader && (
													<p className="error-msg">
														{AudienceFilterLabels.selectSubgroupMsg}
													</p>
												)}
										</StyledFilterRows>
									)
								) : (
									<StyledFilterRows>
										<StyledBox key={currentKey}>
											<StyledTypography
												sx={{
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												{switchHeading(
													currentKey === questionnaireAPIKeys.manager_wwid
														? showExcludeSwitchSelected
														: showExcludeLegalEntitySwitchSelected,
													currentKey
												)}
												<StyledTooltip
													title={
														currentKey === questionnaireAPIKeys.manager_wwid
															? commonLabels.selectLabeledLeaderWWIDTooltipMsg
															: commonLabels.selectLabeledLegalEntityTooltipMsg
													}
													placement="top"
												>
													<Box sx={{ marginBottom: '-26px' }}>
														<IOSSwitch
															checked={
																currentKey === questionnaireAPIKeys.manager_wwid
																	? showExcludeSwitchSelected
																	: showExcludeLegalEntitySwitchSelected
															}
															onChange={
																currentKey === questionnaireAPIKeys.manager_wwid
																	? handleExcludeSwitchChange
																	: handleExcludeLegalEntitySwitchChange
															}
														/>
													</Box>
												</StyledTooltip>
											</StyledTypography>
											<GenericAutocomplete
												key={
													currentKey === questionnaireAPIKeys.manager_wwid
														? excludedWWIDs.length
														: excludedLegalEntity.length
												}
												defaultValue={
													currentKey === questionnaireAPIKeys.manager_wwid
														? excludedWWIDs
														: excludedLegalEntity
												}
												options={surveyAudienceFilterValueKeys}
												id="exclude_manager_wwid"
												placeholder={commonLabels.search}
												onItemChange={
													currentKey === questionnaireAPIKeys.manager_wwid
														? handleExcludeWWIDsChange
														: handleExcludeLegalEntityChange
												}
												showSelected={
													currentKey === questionnaireAPIKeys.manager_wwid
														? showExcludeSwitchSelected
														: showExcludeLegalEntitySwitchSelected
												}
												sx={{ marginTop: '-10px', width: '100%' }}
												isLegalEntity={
													currentKey === questionnaireAPIKeys.legal_entity
												}
											/>
										</StyledBox>
									</StyledFilterRows>
								);
							}
						)}
						<StyledFilterRows>
							<StyledBox key={'exclude_individual_wwid'}>
								<StyledTypography className="d-flex justify-between">
									{showExcIndividualSwitchSelected
										? commonLabels.selectedIndividualWWID
										: getFilterLabelById('wwid')}
									<StyledTooltip
										title={commonLabels.selectLabeledIndividualWWIDTooltipMsg}
										placement="top"
									>
										<Box sx={{ marginBottom: '-26px' }}>
											<IOSSwitch
												checked={showExcIndividualSwitchSelected}
												onChange={handleExcIndividualSwitchChange}
											/>
										</Box>
									</StyledTooltip>
								</StyledTypography>
								<GenericAutocomplete
									isAsync={true}
									loading={excIndividualWWIDLoading}
									key={'exclude_individual_wwid'}
									defaultValue={excIndividualWWIDs}
									options={excAllIndividualWWIDs}
									id="exclude_individual_wwid"
									placeholder={commonLabels.search}
									onItemAsyncChange={handleExcIndividualWWIDsAsyncChange}
									onItemChange={handleExcIndividualWWIDsChange}
									showSelected={showExcIndividualSwitchSelected}
									sx={{ marginTop: '-10px', width: '100%' }}
								/>
							</StyledBox>
						</StyledFilterRows>
					</StyledFilterRowsContainer>
				</>
			)}
		</>
	);
};

export default AudienceFilterPopup;
