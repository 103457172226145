/* eslint-disable no-useless-escape */
export const initialEmailTemplate = `<style>
<!--
/* Font Definitions */
@font-face
	 {font-family: Arial;
	 panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	 {font-family: Arial;
	 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	 {font-family: Arial;
	 panose-1:2 15 5 2 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	 {margin:0in;
	 font-size:13.0pt;
	 font-family:"Arial",sans-serif;}
a:link,a:visited, span.MsoHyperlink
	 {mso-style-priority:99;
	 color:white;
	 text-decoration:none;}
span.MsoSmartlink
	 {mso-style-priority:99;
	 color:blue;
	 background:#F3F2F1;
	 text-decoration:underline;}
span.EmailStyle20
	 {mso-style-type:personal-reply;
	 font-family:"Arial",sans-serif;
	 color:windowtext;}
.MsoChpDefault
	 {mso-style-type:export-only;
	 font-size:10.0pt;
	 mso-ligatures:none;}
@page WordSection1
	 {size:8.5in 11.0in;
	 margin:0in;}
div.WordSection1
	 {page:WordSection1;}
/* List Definitions */
@list l0
	 {mso-list-id:822544210;
	 mso-list-template-ids:-57240054;}
@list l0:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l0:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1
	 {mso-list-id:1942451187;
	 mso-list-template-ids:208016620;}
@list l1:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l1:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Wingdings;}
@list l1:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
ol
	 {margin-bottom:0in;}
ul
	 {margin-bottom:0in;}
-->
</style>



<table role="presentation" style="font-family: Arial; padding-left:20px;">
    <tbody>
        <tr>
            <td colspan="3" style="padding:20px;"><img src="https://qa.step.dth.jnj.com/johnson-johnson-logo.svg"></td>
        </tr>
        <tr>
            <td colspan="3" width="100%" style="background-color: #EB1700;">
                <table>
                    <tbody>
                        <tr>
                            <td style="padding: 20px; background-color: #EB1700;">
                                <h1 style="color:white; font-family: Arial;">Action Required</h1>
                                <br>
                                <h1 style="font-weight: 700; font-family: Arial; color: white; font-size: 28px;">Complete {{QUESTIONNAIRE_NAME}}</h1>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px; font-family: Arial;"><strong>Hello</strong>,</td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="color: rgb(153, 153, 153);">
                <p style="padding: 16px; font-family: Arial; font-size: 16px;"><em>Add information specific to your questionnaire. For example, include the name of the deploying department, purpose of the questionnaire, importance of completion.</em>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="color: rgb(235, 23, 0); font-family: Arial; font-size: 16px;">Action Required&nbsp;</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: Arial; font-size: 16px;">Click the below link to <span style="font-size: 16px; font-family: Aptos, sans-serif;">access</span> <span style="font-size: 16px; font-family: Aptos, sans-serif;">the STEP Questionnaire.&nbsp; Please complete the assigned questionnaire
                                    as soon as possible and no later than&nbsp;</span>{{DUE_DATE}}.</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr style="padding-top:20px;display:flex;justify-content:space-between;align-items: center;">
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
            <td align="center" style="width:105px; height:auto;vertical-align: middle;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:5px;padding:5px; text-decoration: none;">
                {{QUESTIONNAIRELINK}}
            </td>
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="color: rgb(235, 23, 0); font-family: Arial; font-size: 16px;">Questions?</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: Arial; font-size: 16px;"><span style="font-size: 16px; font-family: Aptos, sans-serif;">For questions regarding the</span> {{QUESTIONNAIRE_NAME}} <span style="font-size: 16px; font-family: Aptos, sans-serif;">please click the link below.</span></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr style="padding-top:20px;display:flex;justify-content:space-between;align-items: center;">
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
            <td align="center" style="width:105px; height:auto;vertical-align: middle;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:5px;padding:5px; text-decoration: none;">
                <a href="mailto:RA-RNDUS-BRMU-TechSu@its.jnj.com?subject=STEP%20Questionnaire%20help%20" style="display:inline-block; background:#EB1700; color:white; border:none; border-radius:5px; text-decoration: none;"> Click Here </a>
            </td>
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
        </tr>
        <tr style="margin:16px; margin-left: 0px">
            <td colspan="3" style="padding-top: 20px; padding-bottom: 20px; font-family: Arial; font-size: 16px;">
                <span style="font-size: 16px; font-family: Aptos, sans-serif;">Thank you for completing the {{QUESTIONNAIRE_NAME}} and supporting a culture
                    of compliance.</span>
            </td>
        </tr>
        <tr>
        <td colspan="3" style="color: rgb(153, 153, 153);">
            <p style="padding: 16px; font-family: Arial; font-size: 16px;"><em>Add any additional information that may be required.</em>
            </p>
        </td>
    </tr>
    </tbody>
</table>`;

export const reminderEmailTemplate = `<style>
<!--
/* Font Definitions */
@font-face
	 {font-family: Arial;
	 panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	 {font-family: Arial;
	 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	 {font-family: Arial;
	 panose-1:2 15 5 2 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	 {margin:0in;
	 font-size:13.0pt;
	 font-family:"Arial",sans-serif;}
a:link,a:visited, span.MsoHyperlink
	 {mso-style-priority:99;
	 color:white;
	 text-decoration:none;}
span.MsoSmartlink
	 {mso-style-priority:99;
	 color:blue;
	 background:#F3F2F1;
	 text-decoration:underline;}
span.EmailStyle20
	 {mso-style-type:personal-reply;
	 font-family:"Arial",sans-serif;
	 color:windowtext;}
.MsoChpDefault
	 {mso-style-type:export-only;
	 font-size:10.0pt;
	 mso-ligatures:none;}
@page WordSection1
	 {size:8.5in 11.0in;
	 margin:0in;}
div.WordSection1
	 {page:WordSection1;}
/* List Definitions */
@list l0
	 {mso-list-id:822544210;
	 mso-list-template-ids:-57240054;}
@list l0:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l0:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1
	 {mso-list-id:1942451187;
	 mso-list-template-ids:208016620;}
@list l1:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l1:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Wingdings;}
@list l1:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
ol
	 {margin-bottom:0in;}
ul
	 {margin-bottom:0in;}
-->
</style>



<table role="presentation" style="font-family: Arial; padding-left:20px;">
    <tbody>
        <tr>
            <td colspan="3" style="padding:20px;"><img src="https://qa.step.dth.jnj.com/johnson-johnson-logo.svg"></td>
        </tr>
        <tr>
            <td colspan="3" width="100%" style="background-color: #EB1700;">
                <table>
                    <tbody>
                        <tr>
                            <td style="padding: 20px; background-color: #EB1700;">
                                <h1 style="color:white; font-family: Arial;">Action Required</h1>
                                <br>
                                <h1 style="font-weight: 700; font-family: Arial; color: white; font-size: 28px;">Complete {{QUESTIONNAIRE_NAME}}</h1>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px; font-family: Arial;"><strong>Hello</strong>,</td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="color: rgb(153, 153, 153);">
                <p style="padding: 16px; font-family: Arial; font-size: 16px;"><em>Add information specific to your questionnaire. For example, include the name of the deploying department, purpose of the questionnaire, importance of completion.</em>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="color: rgb(235, 23, 0); font-family: Arial; font-size: 16px;">Action Required&nbsp;</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: Arial; font-size: 16px;">Click the below link to <span style="font-size: 16px; font-family: Aptos, sans-serif;">access</span> <span style="font-size: 16px; font-family: Aptos, sans-serif;">the STEP Questionnaire.&nbsp; Please complete the assigned questionnaire
                                    as soon as possible and no later than&nbsp;</span>{{DUE_DATE}}.</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr style="padding-top:20px;display:flex;justify-content:space-between;align-items: center;">
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
            <td align="center" style="width:105px; height:auto;vertical-align: middle;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:5px;padding:5px; text-decoration: none;">
                {{QUESTIONNAIRELINK}}
            </td>
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="color: rgb(235, 23, 0); font-family: Arial; font-size: 16px;">Questions?</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: Arial; font-size: 16px;"><span style="font-size: 16px; font-family: Aptos, sans-serif;">For questions regarding the</span> {{QUESTIONNAIRE_NAME}} <span style="font-size: 16px; font-family: Aptos, sans-serif;">please click the link below.</span></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr style="padding-top:20px;display:flex;justify-content:space-between;align-items: center;">
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
            <td align="center" style="width:105px; height:auto;vertical-align: middle;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:5px;padding:5px; text-decoration: none;">
                <a href="mailto:RA-RNDUS-BRMU-TechSu@its.jnj.com?subject=STEP%20Questionnaire%20help%20" style="display:inline-block; background:#EB1700; color:white; border:none; border-radius:5px; text-decoration: none;"> Click Here </a>
            </td>
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
        </tr>
        <tr style="margin:16px; margin-left: 0px">
            <td colspan="3" style="padding-top: 20px; padding-bottom: 20px; font-family: Arial; font-size: 16px;">
                <span style="font-size: 16px; font-family: Aptos, sans-serif;">Thank you for completing the {{QUESTIONNAIRE_NAME}} and supporting a culture
                    of compliance.</span>
            </td>
        </tr>
        <tr>
        <td colspan="3" style="color: rgb(153, 153, 153);">
            <p style="padding: 16px; font-family: Arial; font-size: 16px;"><em>Add any additional information that may be required.</em>
            </p>
        </td>
    </tr>
    </tbody>
</table>`;

export const overdueEmailTemplate = `<style>
<!--
/* Font Definitions */
@font-face
	 {font-family: Arial;
	 panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	 {font-family: Arial;
	 panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	 {font-family: Arial;
	 panose-1:2 15 5 2 2 2 4 3 2 4;}
/* Style Definitions */
p.MsoNormal, li.MsoNormal, div.MsoNormal
	 {margin:0in;
	 font-size:13.0pt;
	 font-family:"Arial",sans-serif;}
a:link,a:visited, span.MsoHyperlink
	 {mso-style-priority:99;
	 color:white;
	 text-decoration:none;}
span.MsoSmartlink
	 {mso-style-priority:99;
	 color:blue;
	 background:#F3F2F1;
	 text-decoration:underline;}
span.EmailStyle20
	 {mso-style-type:personal-reply;
	 font-family:"Arial",sans-serif;
	 color:windowtext;}
.MsoChpDefault
	 {mso-style-type:export-only;
	 font-size:10.0pt;
	 mso-ligatures:none;}
@page WordSection1
	 {size:8.5in 11.0in;
	 margin:0in;}
div.WordSection1
	 {page:WordSection1;}
/* List Definitions */
@list l0
	 {mso-list-id:822544210;
	 mso-list-template-ids:-57240054;}
@list l0:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l0:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l0:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1
	 {mso-list-id:1942451187;
	 mso-list-template-ids:208016620;}
@list l1:level1
	 {mso-level-number-format:bullet;
	 mso-level-text:•;
	 mso-level-tab-stop:.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Symbol;}
@list l1:level2
	 {mso-level-number-format:bullet;
	 mso-level-text:o;
	 mso-level-tab-stop:1.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:"Arial";
	 mso-bidi-font-family:"Arial";}
@list l1:level3
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:1.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level4
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level5
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:2.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level6
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level7
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:3.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Wingdings;}
@list l1:level8
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.0in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
@list l1:level9
	 {mso-level-number-format:bullet;
	 mso-level-text:;
	 mso-level-tab-stop:4.5in;
	 mso-level-number-position:left;
	 text-indent:-.25in;
	 mso-ansi-font-size:10.0pt;
	 font-family:Arial;}
ol
	 {margin-bottom:0in;}
ul
	 {margin-bottom:0in;}
-->
</style>



<table role="presentation" style="font-family: Arial; padding-left:20px;">
    <tbody>
        <tr>
            <td colspan="3" style="padding:20px;"><img src="https://qa.step.dth.jnj.com/johnson-johnson-logo.svg"></td>
        </tr>
        <tr>
            <td colspan="3" width="100%" style="background-color: #EB1700;">
                <table>
                    <tbody>
                        <tr>
                            <td style="padding: 20px; background-color: #EB1700;">
                                <h1 style="color:white; font-family: Arial;">Action Required</h1>
                                <br>
                                <h1 style="font-weight: 700; font-family: Arial; color: white; font-size: 28px;">Complete {{QUESTIONNAIRE_NAME}}</h1>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px; font-family: Arial;"><strong>Hello</strong>,</td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="color: rgb(153, 153, 153);">
                <p style="padding: 16px; font-family: Arial; font-size: 16px;"><em>Add information specific to your questionnaire. For example, include the name of the deploying department, purpose of the questionnaire, importance of completion.</em>
                </p>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="color: rgb(235, 23, 0); font-family: Arial; font-size: 16px;">Action Required&nbsp;</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: Arial; font-size: 16px;">Click the below link to <span style="font-size: 16px; font-family: Aptos, sans-serif;">access</span> <span style="font-size: 16px; font-family: Aptos, sans-serif;">the STEP Questionnaire.&nbsp; Please complete the assigned questionnaire
                                    as soon as possible and no later than&nbsp;</span>{{DUE_DATE}}.</td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr style="padding-top:20px;display:flex;justify-content:space-between;align-items: center;">
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
            <td align="center" style="width:105px; height:auto;vertical-align: middle;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:5px;padding:5px; text-decoration: none;">
                {{QUESTIONNAIRELINK}}
            </td>
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr>
            <td colspan="3" style="background: #ecebeb ;border-left: 8px solid #EB1700;padding:10px; padding:10px 10px 24px;">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style="color: rgb(235, 23, 0); font-family: Arial; font-size: 16px;">Questions?</h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="font-family: Arial; font-size: 16px;"><span style="font-size: 16px; font-family: Aptos, sans-serif;">For questions regarding the</span> {{QUESTIONNAIRE_NAME}} <span style="font-size: 16px; font-family: Aptos, sans-serif;">please click the link below.</span></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
        <tr>
            <td colspan="3" style="padding-left:20px;padding-top:20px;"> <br></td>

        </tr>
        <tr style="padding-top:20px;display:flex;justify-content:space-between;align-items: center;">
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
            <td align="center" style="width:105px; height:auto;vertical-align: middle;text-align: center; display:inline-block; background:#EB1700; color:white;  border:none; border-radius:5px;padding:5px; text-decoration: none;">
                <a href="mailto:RA-RNDUS-BRMU-TechSu@its.jnj.com?subject=STEP%20Questionnaire%20help%20" style="display:inline-block; background:#EB1700; color:white; border:none; border-radius:5px; text-decoration: none;"> Click Here </a>
            </td>
            <td style="width:40%;">
                <hr height="2" style="height:2px; border:0 none; color: #EB1700; background-color: #EB1700;">
            </td>
        </tr>
        <tr style="margin:16px; margin-left: 0px">
            <td colspan="3" style="padding-top: 20px; padding-bottom: 20px; font-family: Arial; font-size: 16px;">
                <span style="font-size: 16px; font-family: Aptos, sans-serif;">Thank you for completing the {{QUESTIONNAIRE_NAME}} and supporting a culture
                    of compliance.</span>
            </td>
        </tr>
        <tr>
        <td colspan="3" style="color: rgb(153, 153, 153);">
            <p style="padding: 16px; font-family: Arial; font-size: 16px;"><em>Add any additional information that may be required.</em>
            </p>
        </td>
    </tr>
    </tbody>
</table>`;
