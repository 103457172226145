import { styled } from '@mui/material';
import ArrowUpward from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownward from '@mui/icons-material/ArrowDownwardOutlined';
import { ReactComponent as DownArrow } from '../../../assets/images/Doarrow-narrow-up.svg';
import { questionnaireAPIKeys } from '../../../utils/ApiKeys';
import { ITableHeader } from '../../../types/CommonTypes';

const HeadText = styled('div')({
	width: 'auto',
	minHeight: '22px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '700',
	lineHeight: '16px',
	letterSpacing: '1px',
	textAlign: 'left',
	display: 'flex',
	cursor: 'pointer',
	svg: {
		cursor: 'pointer',
		width: '22px',
		height: '18px',
	},
	'.up-arrow': {
		svg: {
			transform: 'rotate(180deg)',
		},
	},
});

const TableHeader = (props: ITableHeader) => {
	return (
		<HeadText
			sx={props.width ? { width: props.width } : {}}
			onClick={() => props.onClick(props.sortKey, props.sortObj)}
		>
			{props.label}
			{props.sortObj === ' ' && (
				<div className="up-arrow grayscale-[50%]">
					<DownArrow
						onClick={() => props.onClick(props.sortKey, props.sortObj)}
					/>
				</div>
			)}
			{props.sortObj === questionnaireAPIKeys.asc && (
				<ArrowUpward
					color="error"
					fontSize="inherit"
					className="text-base"
					onClick={() => props.onClick(props.sortKey, props.sortObj)}
				/>
			)}
			{props.sortObj === questionnaireAPIKeys.desc && (
				<ArrowDownward
					color="error"
					fontSize="inherit"
					className="text-base"
					onClick={() => props.onClick(props.sortKey, props.sortObj)}
				/>
			)}
		</HeadText>
	);
};

export default TableHeader;
