import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { commonLabels } from '../../utils/CommonLabels';
import { STEPTheme } from '../../utils/Theme';
import StyledTooltip from '../StyledTooltip';
import { useAppSelector } from '../../app/Hooks';
import {
	getSurveyDueDate,
	getSurveyStartDate,
	selectedAudienceCount,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { formatDate } from '../../utils/DateFormatting';
import { QUE_JOURNEY_STEPS } from '../../utils/Enum';
import DateDisplay from './common/DateDisplay';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		borderRadius: '8px',
	},
}));

const useStyles = makeStyles({
	editIcon: {
		marginRight: '5px',
		width: '0.8em !important',
		color: '#000',
		cursor: 'pointer',
	},
	rightAlign: {
		textAlign: 'right',
	},
	gridItem: {
		paddingLeft: '150px',
	},
	dateTypography: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 700,
		lineHeight: '16px',
		fontSize: '14px',
	},
	dateTextTypography: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 500,
		lineHeight: '16px',
		fontSize: '14px',
	},
});
const StyledTypography = styled(Box)`
	height: 12px;
	width: 120%;
	display: inline-flex;
	align-items: center;
	word-break: break-all;
	margin-top: 4px;
`;
const StyledBlueTypography = styled(Box)`
	font-family: JohnsonText-Regular;
	font-size: 14px;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: ${STEPTheme.colors.lightBlue};
	margin-top: 2px;
	height: 12px;
	display: inline-flex;
	align-items: center;
`;
const SubHeaderBox = styled(Box)<{ width: string }>`
	margin-bottom: 12px;
	width: ${(props) => props.width};
`;

interface IQuestionSubHeaderProps {
	showEditIcon: boolean;
	handleBack: () => void;
	backToDetailsPage: () => void;
	activeStep: number;
	width: string;
}

const QuestionSubHeader: React.FC<IQuestionSubHeaderProps> = ({
	showEditIcon,
	handleBack,
	backToDetailsPage,
	activeStep,
	width,
}) => {
	const totalAudience = useAppSelector(selectedAudienceCount);
	const classes = useStyles();
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const surveyStartDate = useAppSelector(getSurveyStartDate);
	const surveyDueDate = useAppSelector(getSurveyDueDate);
	const formattedDueDate = formatDate(surveyDueDate);
	const formattedStartDate = formatDate(surveyStartDate);

	return (
		<SubHeaderBox width={width}>
			<Grid container xs={12} spacing={3}>
				<Grid item xs={2}>
					<Box>
						<StyledTypography>
							{showEditIcon && (
								<BootstrapTooltip
									title={commonLabels.editTooltip}
									placement="top"
								>
									<DriveFileRenameOutlineOutlinedIcon
										className={classes.editIcon}
										onClick={backToDetailsPage}
										data-testid="editIcon"
									/>
								</BootstrapTooltip>
							)}
							<Typography variant="h6">
								{surveyQuestionnaireInfo?.surveyFormsDetails?.surveyName}
							</Typography>
						</StyledTypography>
					</Box>
				</Grid>
				{activeStep === QUE_JOURNEY_STEPS.STEP_QUESTIONS && (
					<Grid container item xs={6} spacing={3}>
						<Grid item>
							<Box className={classes.gridItem}>
								<Box>
									<DateDisplay
										label={commonLabels.startDate}
										date={formattedStartDate}
										classes={classes}
									/>
								</Box>
							</Box>
						</Grid>
						<Grid item>
							<Box>
								<DateDisplay
									label={commonLabels.dueDate}
									date={formattedDueDate}
									classes={classes}
								/>
							</Box>
						</Grid>
					</Grid>
				)}

				{activeStep === QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS && (
					<Grid container item xs={6} spacing={3} justifyContent="flex-end">
						<Grid item>
							<Box>
								<DateDisplay
									label={commonLabels.startDate}
									date={formattedStartDate}
									classes={classes}
								/>
							</Box>
						</Grid>
						<Grid item>
							<Box>
								<DateDisplay
									label={commonLabels.dueDate}
									date={formattedDueDate}
									classes={classes}
								/>
							</Box>
						</Grid>
					</Grid>
				)}
				<Grid item xs={4} className={classes.rightAlign}>
					<Box>
						<StyledBlueTypography>
							{showEditIcon && (
								<StyledTooltip
									title={commonLabels.audienceTooltip}
									placement="top"
								>
									<DriveFileRenameOutlineOutlinedIcon
										className={classes.editIcon}
										onClick={handleBack}
										data-testid="backIcon"
									/>
								</StyledTooltip>
							)}
							{`${commonLabels.totalAudience}: ${totalAudience}`}
						</StyledBlueTypography>
					</Box>
				</Grid>
			</Grid>
		</SubHeaderBox>
	);
};
export default QuestionSubHeader;
