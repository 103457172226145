import { Box, Card, styled } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Instruction from '../components/endUser/CW/Instruction';
import QuestionCW from '../components/endUser/QuestionCw';
import TableCW from '../components/endUser/TableCw';
import TableFilter from '../components/endUser/TableFilter';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	API_STATUS_CONSTANT,
	BASIC_CONSTANT,
} from '../constants/StepConstants';
import { IType } from '../types/CommonTypes';
import {
	getQuestionnaireData,
	getQuestionnaireDetailsCWAPIStatus,
	getTodoMessage,
	questionnaireData,
} from '../redux/QuestionnarieUserSlice';
import GenericModalPopup from '../components/common/GenericModalPopup';
import { questionnaireAPIKeys } from '../utils/ApiKeys';
import { addPageViewEventForGA } from '../utils/CommonMethods';
import { Numbers } from '../utils/Enum';

const StyledCard = styled(Card)({
	width: '80%',
	marginLeft: '8%',
	marginBottom: '5%',
	borderRadius: '16px',
	padding: '2.33%',
	paddingTop: '30px',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	height: 'auto',
	justifyContent: 'center',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
});

const CWQuestionnairePage = function CWQuestionnairePage() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isSelf, setIsSelf] = useState(false);
	const questionData = useAppSelector(questionnaireData);
	const { id: questionnaireId } = useParams();
	const questionnaireDetailsCWAPIStatus = useAppSelector(
		getQuestionnaireDetailsCWAPIStatus
	);
	const [isGenericModalOpen, setGenericModalOpen] = useState(false);
	const genericModalMsg = useAppSelector(getTodoMessage);
	useEffect(() => {
		const url = new URL(window.location.href);
		const urlArray = url.pathname.split('/');

		addPageViewEventForGA(
			'pageview',
			urlArray.length > Numbers.two
				? `/${urlArray[Numbers.one]}/%`
				: url.pathname,
			questionnaireAPIKeys.SinglePageQuestionnaire
		);
	}, []);
	useEffect(() => {
		const endUserReq: IType = {
			id: questionnaireId ? questionnaireId : '',
		};
		dispatch(getQuestionnaireData(endUserReq));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (questionData?.data?.targetAudience === BASIC_CONSTANT.SELF) {
			setIsSelf(true);
		} else {
			setIsSelf(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionData, isSelf]);
	useEffect(() => {
		if (
			questionnaireDetailsCWAPIStatus ===
			API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
		) {
			setGenericModalOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireDetailsCWAPIStatus]);
	const goToHome = () => {
		navigate('/');
		navigate(0);
	};

	return (
		<>
			<Box>
				<Instruction />
			</Box>
			<StyledCard>
				{!isSelf && (
					<Box>
						<TableFilter></TableFilter>
						<TableCW></TableCW>
						<QuestionCW></QuestionCW>
					</Box>
				)}
				{isSelf && (
					<Box>
						<QuestionCW></QuestionCW>
					</Box>
				)}
			</StyledCard>
			{isGenericModalOpen && (
				<GenericModalPopup
					openProp={true}
					msgBody={genericModalMsg}
					mainMsg=""
					rightBtnText={'Go To Home'}
					leftBtnText={''}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={goToHome}
					leftBtnHandler={() => {}}
					hideCloseBtn={true}
				/>
			)}
		</>
	);
};
export default CWQuestionnairePage;
