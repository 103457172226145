export const commonLabels: { [key: string]: string } = {
	asc: 'asc',
	desc: 'desc',
	save: 'Save',
	enterTxt: 'Enter Text',
	addUrl: 'Add Url',
	cancel: 'Cancel',
	back: 'Back',
	confirm: 'Confirm',
	next: 'Next',
	submit: 'Submit',
	allRightsReserved: 'All Rights Reserved',
	yes: 'Yes',
	no: 'No',
	ok: 'OK',
	saveAsDraft: 'Save As Draft',
	saveNext: 'Save & Next',
	proceed: 'Proceed',
	saveProceed: 'Save & Proceed',
	createdByMe: 'Created By Me',
	meText: 'Me',
	allText: 'All',
	createNewQuestionnaire: 'Create New Questionnaire',
	allQuestionnaire: 'All Questionnaire',
	edit: 'Edit',
	copy: 'Copy',
	notification: 'Notification',
	results: 'Results',
	result: 'Result',
	filters: 'Filters',
	deactivate: 'Deactivate',
	delete: 'Delete',
	backToDTH: 'Back To DTH',
	logout: 'Logout',
	days: 'Days',
	selectLabel: 'Select',
	selected: 'Selected',
	selectedLeaderWWID: 'Selected Leader WWID',
	selectedLegalEntity: 'Selected Legal Entity',
	selectedIndividualWWID: 'Selected Individual WWID',
	selectedLeaderWWIDTooltipMsg:
		'Please search with Leader WWID or Name. You can view your selected items by turning on the toggle button.',
	selectedLegalEntityTooltipMsg:
		'Search with the Legal Entity. You can view your selected items by turning on the toggle button.',
	selectedIndividualWWIDTooltipMsg:
		'Please search with Individual WWID or Name. You can view your selected items by turning on the toggle button.',
	search: 'Search',
	saveAsDraftText: 'Your changes have been saved successfully in the draft',
	deactivatedText: 'The questionnaire has been successfully deactivated',
	deleteDraftSuccessText: 'The questionnaire has been successfully deleted',
	copyText: 'The questionnaire has been successfully copied',
	addQuestionnaireDetails: 'Add Questionnaire Details',
	questionnaireNameRequired: 'Questionnaire name is required',
	questionnaireChar: 'Questionnaire name should be at most 100 characters',
	questionnaireDes: 'Questionnaire description is required',
	questionnaireDesChar:
		'Questionnaire description should be at most 250 characters',
	questionnaireType: 'Questionnaire type is required',
	startDateReq: 'Start date is required',
	date: 'Date',
	duration: 'Duration',
	selectedUserCriteria: 'Selected User Criteria',
	includedUserCriteria: 'Included User Criteria',
	excludedUserCriteria: 'Excluded User Criteria',
	inNext: 'in next',
	noSurveyFound: 'No Survey Found',
	dueDateReq: 'Due date is required',
	selectReason: 'Select Reason',
	addValidDropDownOptionFile:
		'Please upload a valid file. It should have a column with header as options',
	incorrectQuestionnaireNameFormatErrorMsg: 'Invalid Questionnaire Name format',
	incorrectQuestionnaireLocationFormatErrorMsg:
		'Invalid Owning Location format',
	incorrectQuestionnaireAuthorFormatErrorMsg: 'Invalid Author Name format',
	incorrectQuestionnaireStatusFormatErrorMsg: 'Invalid Status format',
	incorrectQuestionnaireDescFormatErrorMsg: 'Please Provide a correct format',
	questionnaire: 'Questionnaire',
	sessionExpiredTitle: 'Your Session has Expired',
	sessionExpiredText:
		'To protect privacy and enhance security, the page you are trying to access is no longer available. You will be redirected to DTH',
	redirectButtonLabel: 'Redirect',
	redirectingMsg: 'Redirecting in 00:0',
	waitMsg: 'Please Wait..',
	answerWarningMsg: 'Your changes will not be saved for this question.',
	alertMessageForAnswerChange:
		'Your answered data beyond this question wiil be erased based on the current change.',
	audienceWarningMsg: 'Are you sure you want to switch?',
	questionnaireWarningMsg: 'Are you sure you want to exit the Questionnaire?',
	questionnaireDataLossMessage: 'Your existing data will be lost',
	audienceDataLossMessage:
		'In case you continue, your currently selected audience will be removed!',
	sessionGoingToExpireInactivity:
		'Your session is going to expire in next 60 seconds due to inactivity!',
	userIdlePopupTitle: 'You have been idle!',
	extendButtonText: 'Extend Session',
	close: 'Close',
	noOptions: 'No options',
	errorMessage: 'WWID is not valid. Only alphanumeric characters are allowed.',
	duplicateOptionErrorMessage:
		'Option names cannot be the same. Please change option names',
	noAccessPageHeader: 'No Access!',
	noAccessPageBody: "You don't have access to STEP",
	noAccessPageButtonText: 'Go To DTH',
	duplicateQuestionMessage:
		'This question with the exact same details previously exists as well. Please add a unique question.',
	duplicateQuestionTitle: 'Duplicate question found!',
	noAccessToSurveyTxt:
		'The questionnaire you are trying to access is not currently assigned to you. ',
	invalidUsersIDs: 'Invalid User IDs',
	continue: 'Continue',
	invalidUserIdMesage:
		'User IDs are invalid as they are not found in our Database.',
	invalidUSerIdConfirmationMessage:
		'Click "Continue" to proceed without them being selected, or you can "Cancel" and reupload excel using correct User IDs.',
	addQueIns: 'Add Questionnaire Instructions',
	resetAll: 'Reset All',
	addQueInsDes: 'Add Instructions',
	char825: '1000 characters.',
	deleteQueTitle: 'Are you sure, you want to delete Question?',
	addQuestions: 'Add Questions',
	multiOptionQue: 'Multiple Option Question',
	addCondition: 'Add Condition',
	condition: 'Condition',
	continueTo: 'Continue to Que',
	addOption: 'Add Option',
	multiSelectQue: 'Multiple Select Question',
	selectYourQuestionType: 'Select Your Question Type',
	multipleChoice: 'Multiple Choice',
	yesNo: 'Yes & No',
	multiSelect: 'Multi-Select',
	openEnded: 'Open Ended',
	yesNoQue: 'Yes or No Question',
	questionnaireName: 'Questionnaire Name',
	startDate: 'Start Date',
	dueDate: 'Due Date',
	totalAudience: 'Total Audience',
	addNewQue: 'Add New Question',
	dropDownQue: 'Dropdown List',
	dropDownQuestion: 'Dropdown List Question',
	uploadDropDownQuestion: 'Upload Dropdown List',
	openEndedQue: 'Open Ended Question',
	openEndedDisabledText:
		'There is no data present for this question as it is an Open Ended Question',
	queRequired: 'Question Is required',
	optionRequired: 'Option Is required',
	queMaxLength: 'Question should be at most 250 characters',
	minOption: 'Minimum 2 options required',
	queReorderError: 'Please save the question before changing its order!',
	uploadEmailTemplate: 'Upload Email Template',
	onBehalf: 'On Behalf',
	questionnaireForRequired: 'Completing questionnaire for is required',
	owningRequired: 'Questionnaire owning location is required',
	addTemplate: 'Add Template',
	viewTemplate: 'View Template',
	emailTemplate: 'Email Template',
	subject: 'Subject',
	exportUserList: 'Export User List',
	endQuestionnaire: 'End Questionnaire',
	pleaseUploadFileAndQuestion: 'Please enter question and file',
	fileRequired: 'Please upload a file',
	downloadTemplate: 'Download Template',
	editTooltip:
		'Click to edit Questionnaire Name, Questionnaire Start Date,Questionnaire Due Date',
	audienceExportTooltip: 'Export to CSV',
	manageFilterTooltip: 'Click to view data options',
	addQuestionnaireInstructionTooltip:
		'Add questionnaire specific user instructions (Max 1000 CHAR). ',
	questionFieldTooltip: 'Type question here (Max 250 CHAR)',
	dropDownQuestionTooltip: 'User selects response from dropdown',
	multiSelectQuestionTooltip: 'Can select more than one answer',
	multiChoiceQuestionTooltip: 'Only one answer',
	openEndQuestionTooltip: 'Free text response',
	questionnaireNameTooltip:
		' Name of the questionnaire that will be visible to the target audience',
	instructionTooltip:
		'Add questions-specific user instructions (Max 250 CHAR).',
	audienceTooltip: '# of target audience',
	identicalOptionsValidation:
		'Option name cannot be same, Please change option names.',
	wwid: 'WWID',
	name: 'Name',
	emailId: 'Email ID',
	chooseAudienceForQuestionnaire: 'Choose Audience for Questionnaire',
	EXCEL_UPLOAD: 'excelUpload',
	FILTER: 'filter',
	issueProcessingRequest:
		'There is some issue in processing this request! Please retry the operation.',
	issueValidatingIdentity:
		'There is some issue in validating your identity. We suggest you to relogin and try again!',
	permissionLevelRequired:
		"You don't have the required permission level. Please get the necessary privilege.",
	itemNotExist: "The item you are trying to access doesn't exist!",
	linkNotExist: "The link you are trying to access doesn't exist!",
	serviceUnavailable:
		'The service is not available at the moment. Please try after some time.',
	serverError:
		'We encountered a server error for your request. Please retry the operation.',
	databaseConnectionError:
		"We couldn't connect to our database. Please try after some time.",
	databaseTimeoutError:
		"We're experiencing a heavy load, please try again later",
	recordNotFound: "We couldn't find the requested records at our end.",
	duplicateRecord:
		'A record exists with the same details at our end. Please validate your request once.',
	invalidRequest: 'Please validate your request.',
	processingRequestIssue:
		'There is some issue in processing this request! Please retry later.',
	apiError: 'We encountered an issue. Please try again! ',
	apiErrorMsgTxt: 'Record Count >100K.',
	apiExceptionCode: 'BR1001',
	mapping: 'Mapping',
	reset: 'Reset',
	errorMessageDuration: 'Please provide a valid duration',
	setGroupingCriteriaLabel: 'Set Grouping Criteria',
	groupListLabel: 'List of Created Groups',
	createGroupLabel: 'Create New Group',
	noGroupLabel: 'You have no groups to view. Create a new group!',
	selectGroupLabel:
		'Choose a group from left panel to view data or create a new group',
	groupLabel: 'Groups',
	questionNotFound: 'Questionnaire not responded',
	deleteGroupConfirm:
		'group(s) will be deleted. Are you sure you want to continue? This action cannot be undone!',
	groupName: 'Grouping Name',
	chooseCriteria: 'Choose Criteria',
	applicableLabel: 'Applicable',
	notApplicableLabel: 'Not Applicable',
	applicableQIdText: 'isApp_',
	enterTextLabel: 'Enter Text',
	saveLabel: 'Save',
	groupNameCharExceedLabel: 'Group name should be at most 100 characters',
	groupNameRequiredLabel: 'Group name is mandatory',
	groupNameAllowedCharacterMsg:
		'Please make sure the group name matches with the SUMMIT report id.',
	selectOptionIfQuestionApplicableLabel:
		'Answers considered for an applicable question can not be blank',
	saveGroupConfirmationLabel: 'Are you sure you want to save this mapping?',
	exitWithoutSavingLabel:
		'You have unsaved changes! Are you sure you want to go back?',
	mappingDataChangeLabel: 'Your existing changes will be lost.',
	groupConfigurationInvalidLabel:
		'One or more configuration are invalid, they are highlighted in red. Please review',
	duplicateGroupNameLabel:
		'Please modify the group name! Another group with same name already exists.',
	groupCreatedMsg: 'The group has been successfully saved',
	groupCopiedMsg: 'The group has been copied successfully',
	groupDeletedMsg: 'The selected group(s) have been deleted successfully!',
	noQuestionConfigured: 'Please select atleast one question configuration',
	groupNameNotMatchedWithSummit:
		"The group doesn't exist in SUMMIT or not synched yet. Do you still want to proceed?",

	summitGroupLoadFailMsg:
		'Due to technical issue, group information is not retrieved. Please try loading the page again. If issue Persists, please contact System Administrator',
	mappingAudienceLoadFailMsg:
		'Due to technical issue, group information is not retrieved. Please try loading the page again. If issue Persists, please contact System Administrator',
	effectiveFromRequired: 'Effective from is required',
	effectiveFrom: 'Effective From:',
	effectiveDateRequired: 'Date is required',
	effectiveDateRequiredWarningMsg: 'Please select a date',
	effectiveDurationRequired: 'Duration is required',
	clickToViewOrEdit: 'Click on the group name to view/edit the group',
	clickToCopy: 'Click on this icon to copy this group',
	groupMappingFor: 'Group Mapping for',
	questionniareDescriptionTooltip:
		'Description of the questionnaire that will be visible to the target audience',
	completingQuestionnaireForTooltip:
		'Depicts whether this questionnaire has to be filled up by the J&J users for themselves or on behalf of their direct reportees.',
	questionnaireOwingLocationTooltip:
		'Depicts the owning location of the questionnaire. It could be HCC (Health Care Compliance) or RDQ (R&D Quality)',
	questionnaireStartDateTooltip:
		'Depicts the date from when the questionnaire would be active so that end users can respond to it',
	questionnaireDueDateTooltip:
		'Last date questionnaire available for user to respond. Select calendar date or questionnaire duration in days.',
	columnHeaderTooltip: 'Click on the icons to sort  or filter records',
	startDateTooltip: 'First date responses can  be added or modified.',
	dueDateTooltip:
		'Last date/remaining days that responses can be added or modified.',
	rowsPerPageTooltip:
		'•Choose from dropdown options 10,25,50,100.\n•Click to scroll between pages ',
	backArrowTooltip: 'Click here to return to the questionnaire listing page.',
	checkBoxTooltip: 'Check box to select all rows on current page',
	applyTooltip: 'Click here to apply search terms/filters.',
	resetTooltip: 'Click here to clear search terms/filters.',
	rowsPerPageListingScreenTooltip:
		'•Choose from dropdown options 10,15,20,25,50,100.\n•Click to scroll between pages ',
	confirmationPopupHeading: 'You are about to submit the Questionnaire.',
	confirmationPopupQuestionChange: 'Are you sure you want to save the changes?',
	confirmationPopupAnswerChange: 'Are you sure you want to go back?',
	incorrectWWIDsFormatMsg: 'WWID must be a number',
	incorrectNotificationMailSubjectFormatMsg: 'Invalid Subject format',
	incorrectFirstNameEndUserFormatMsg: 'Invalid First name format',
	incorrectLastNameEndUserFormatMsg: 'Invalid Last name format',
	incorrectQuesTypeOpenEndUserFormatMsg: 'Invalid Question type format',
	incorrectTypeFormat: 'Invalid type format',
	Active: 'Active',
	Completed: 'Completed',
	Complete_Questionnaire: 'Complete Questionnaire',
	Edit_Response: 'Edit Response',
	RESPONDED: 'Responded',
	NOTRESPONDED: 'Not Responded',
	EMPLOYEE: 'Employee',
	CONTIGENTWORKER: 'Contingent Worker',
	DEFAULTER: 'Non-Compliant',
	RISKOVERDUE: 'Coming Due',
	ALREADYOVERDUE: 'Already Overdue',
	MULTIPLESURVEYGROUPS: 'With Multiple Groups',
	NOTINSUMMIT: 'Not in SUMMIT',
	SUMMITMAPPED: 'Mapped in SUMMIT Group',
	NON_COMPLIANT: 'Non-Compliant',
	NAME: 'Name',
	WWID: 'WWID',
	EMAIL: 'Email ID',
	SECTOR: 'Sector',
	REGION: 'Region',
	COMPLETION_DATE: 'Completion Date',
	DUE_DATE: 'Due Date',
	LAST_NAME: 'Last Name',
	FIRST_NAME: 'First Name',
	PARTNER_COMPANY: 'Partner Company',
	ASSESMENT_STATUS: 'Assessment Status',
	mapped: 'Mapped',
	completed: 'Completed',
	mappedIn: 'Mapped in',
	summitGroup: 'SUMMIT Group',
	summitGroupStatus: 'Summit Group Status',
	mappedSummitGroup: 'Mapped in Summit Group',
	mappedNotSummitGroup: 'Not Mapped in Summit Group',
	respondedAfterDueDate: 'Overdue - Completed Late',
	notRespondedYet: 'Overdue - Incomplete',
	question: 'Question',
	recordSubmittedMsg: 'Your records have been successfully submitted',
	someThingWrongMsg: 'Some thing went wrong',
	questionnaireResults: 'Questionnaire Results',
	lastRefreshDate: 'Last Refresh Date',
	showingResultsFor: 'Showing results for',
	allBtnTxt: 'All',
	medTechBtnTxt: 'Med Tech',
	pharmaBtnTxt: 'Pharma',
	nonOperatingBtnTxt: 'Non-Operating',
	consumerTxt: 'Consumer',
	dataDisplayTypePercentage: 'Percentage',
	dataDisplayTypeValues: 'Values',
	responseGraphTxt: 'Response Graph',
	inScopeTxt: 'In Scope',
	outOfScopeTxt: 'Out of Scope',
	notAssessedTxt: 'Not Assessed',
	asiaPacificTxt: 'Asia-Pacific',
	emeaTxt: 'EMEA',
	northAmericaTxt: 'North America',
	latinAmericaTxt: 'Latin America',
	completedByRegionTxt: 'Status by region',
	groupWiseTxt: 'User Group Distribution',
	totalNoOfUsersTxt: 'Users',
	contingentWorkerTxt: 'Contingent Worker',
	employeeTxt: 'Employee',
	respondedTxt: 'Responded',
	notRespondedTxt: 'Not Responded',
	defaulterTxt: 'Defaulters',
	lastRefreshTxt: 'Last Refresh',
	titleInScope: 'You are about to submit selected records as In Scope.',
	titleOutOfScope: 'You are about to submit selected records as Out Of Scope.',
	descriptionScope:
		'Are you sure you want to submit your selected responses for ',
	startQuestionnaire: 'Start Questionnaire',
	selectStartDate: 'Select Start Date',
	selectDueDate: 'Select Due Date',
	instructions: 'Instructions',
	download: 'Download',
	completeDownload: 'Complete Download',
	downloadInProgress: 'Downloading is in progress...',
	reportDownloadErrorTitle:
		'There is no consolidated report as nobody has filled the Questionnaire yet!',
	completeDownloadTooltip: 'Export results to Excel',
	keyMetrics: 'Key Metrics',
	metricsTypeAtRisk: 'AtRisk',
	metricsTypeOverdue: 'Overdue',
	metricsTypeDefaulter: 'Defaulter',
	metricsTypeSummitGroup: 'Summit Group',
	metricsTypeMappedInSummit: 'Summit',
	comingDue: 'Coming Due',
	riskOfOverDue: 'Risk of Overdue',
	employees: 'Employees',
	defaulters: 'Defaulters',
	notCompliant: 'Non-Compliant',
	after: 'after',
	viewEmployees: 'SEE MORE',
	thirtyDays: '30 Days',
	sixtyDays: '60 Days',
	ninetyDays: '90 Days',
	zeroDays: '0 Days',
	sevenDays: '7 Days',
	error: 'Error',
	errorFromOurEnd: 'Sorry! There is an error from our end',
	refreshHint: 'Please click “Refresh” to try again.',
	refresh: 'Refresh',
	noDataFound: 'No data found',
	noNonCompliantUsers: 'No Non-Compliant Users',
	noDataOpenEnded: 'We do not have data for this open-ended question.',
	noDataGenericQuestion: 'We do not have data for this question.',
	noDataLineOne: "We couldn't find any data for the applied filters.",
	noDataLineTwo: 'Please try with different filters.',
	graphViewButtonLabel: 'Select this to see Graphical view',
	listViewButtonLabel: 'Select this to see Tabular view',
	refreshTxt: 'Refresh',
	errorText: 'Error',
	graphRenderErrorText:
		'Sorry! There is an error from our end. Please click “Refresh” to try again.',
	noDataGraphTxt:
		"We couldn't find any data for the applied filters. Please try with different filters.",
	downloadButtonTooltip: 'Download the complete report without any filters',
	graphView: 'graphView',
	summitDownload: 'SUMMIT Download',
	summitSuccessPopupMsg: 'The Summit Report has been sent successfully!',
	summitNoContentPopupMsg: 'There is no data available for the Summit Report!',
	dataLoadingTxt: 'Data Loading',
	dataLoadingMsgTxt: 'Please wait while the data is being loaded',
	noDataFoundForQuestion: 'We do not have data for this question.',
	noDataDefaulterMessage:
		'There are 0 non-compliant users or none fall in the applied filters',
	noSummitGroupMessage: "We couldn't find any data for Summit Group Status",
	groupTxt: 'Groups',
	filterTxt: 'Filter',
	overallDownloadReport: 'Overall Report',
	completedResponseStatusReport: 'Completed Response Status Report',
	summitGroupStatusReport: 'Summit Group Status Report',
	nonCompliantStatusReport: 'Non-Compliant Status Report',
	statusByRegionReport: 'Status By Region Report',
	userGroupDistributionReport: 'User Group Distribution Report',
	comingDueTooltipTxt:
		'No. of people whose Coming Due date within the given days',
	noCompliantTooltipTxt:
		'No. of people whose Due date passed and are non-compliant within the given days',
	MappedInSummitTooltipTxt: 'List of people who are mapped in groups',
	previewQuestionnaire: 'Preview Questionnaire',
	uploadFromExcel: 'Upload from Excel',
	fallbackText: 'Upload from Excel to View Results',
	noResults: 'No Results',
	audience: 'Audience',
	userExport: 'USER_EXPORT',
	resultExport: 'RESULT_EXPORT',
	audienceMaxRowsTitle: 'Record count is more than 100K.',
	audienceMaxRowsMessage:
		'You will shortly receive an email with the link to access the report.',
	audienceMaxRowsSuccessMessage:
		'The report is ready and you should receive the email with the link of shared path where you can navigate & access the report.',
	audienceLargeRowsMessage:
		'You are going to export a large number of users details. Are you sure you want to continue?',
	reminderMail: 'Reminder Mail',
	preview: 'Preview',
	selectTriggerDays: 'Select Trigger Days',
	configureNotificationAlerts: 'Configure Notification Alerts',
	initialMail: 'Initial Mail',
	questionnaireStartDate: 'Questionnaire start date',
	overdueMail: 'Overdue Mail',
	confirmSubmitText: 'Are you sure you want to publish this Questionnaire?',
	publishSurveyConfirmationText: 'NOTE: No edits can be made after publishing.',
	totalSelectedUser: 'Total Selected Users:',
	startDatePopupLabel: 'Start Date:',
	notificationTriggerDays: 'Selected Trigger Days',
	noQuestionnaireResposeTxt: 'There are no responses yet for the Questionnaire',
	errorMsg: 'Something went Wrong. Please try again.',
	responseErrorTxt: 'Please try again after sometime.',
	networkError:
		'Too many users are connected to the application. Please try after sometime.',
	errorTitle: 'Error Message',
	errorMessageTriggerDays: 'No Trigger Days Selected',
	deactivatedWarningMessage: 'Are you sure you want to de-activate',
	deleteWarningMessage:
		'Are you sure you want to delete your draft questionnaire named',
	deactivateNoteMessage:
		'NOTE: You will not be able to re-activate once it has been deactivated.',
	deleteNoteMessage:
		'NOTE: You will not be able to restore once it has been deleted.',
	initialMailTooltip:
		'Mandatory notification sent to users on questionnaire start date',
	reminderMailTooltip:
		'Mandatory notification sent to incomplete users on selected Trigger Days BEFORE questionnaire due date.',
	overdueMailTooltip:
		'Optional notification sent to incomplete users on selected Trigger Dates AFTER questionnaire due date.',
	previewTooltip: 'View the  draft Notification content.',
	noTooltip:
		'Questionnaire will remain in-draft and will not be sent to users on questionnaire start date.',
	yesTooltip:
		'Questionnaire will be sent to users on questionnaire start date.',
	details: 'DETAILS',
	audienceLabel: 'AUDIENCE',
	questions: 'QUESTIONS',
	previewLabel: 'PREVIEW',
	notificationLabel: 'NOTIFICATION',
	publish: 'PUBLISH',
	yesOrNo: 'Yes & No',
	choice: 'Multiple Choice',
	select: 'Multi Select',
	open: 'Open Ended',
	dropdown: 'Dropdown',
	QUESTIONNAIRE_NAME: 'Questionnaire Name',
	QUESTIONNAIRE_CATEGORY: 'Questionnaire Owning Location',
	QUESTIONNAIRE_ID: 'Questionnaire ID',
	CREATE_BY: 'Created By',
	CREATED_DATE: 'Date Created',
	START_DATE: 'Start Date',
	END_DATE: 'Due Date',
	STATUS: 'Status',
	RESPONSE: 'Response Status',
	ACTION: 'Action',
	completeTxt: 'Complete',
	incompleteTxt: 'Incomplete',
};
