import { styled } from '@material-ui/core';
import { Button } from 'antd';
import { STEPTheme } from '../../../utils/Theme';

export const TextBlueButton = styled(Button)({
	color: STEPTheme.colors.blue,
	textTransform: 'none',
	border: 'none',
	fontWeight: 500,
	padding: 0,
	boxShadow: 'none',
	paddingRight: '15px',
});
