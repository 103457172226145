import React from 'react';
import { Box, Typography } from '@mui/material';

interface DateDisplayProps {
	label: string;
	date: string;
	classes: {
		dateTypography: string;
		dateTextTypography: string;
	};
}

const DateDisplay: React.FC<DateDisplayProps> = ({ label, date, classes }) => (
	<Box
		display="inline-flex"
		alignItems="center"
		marginBottom="2px"
		height="12px"
	>
		<Typography variant="subtitle2" className={classes.dateTypography}>
			{label}
		</Typography>
		:
		<Box component="span" sx={{ marginLeft: '4px' }}>
			<Typography variant="subtitle1" className={classes.dateTextTypography}>
				{date}
			</Typography>
		</Box>
	</Box>
);

export default DateDisplay;
