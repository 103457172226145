import React from 'react';
import { Avatar } from '@mui/material';
import StyledTooltip from '../StyledTooltip';

interface KeyMetricsPercentageAvatarProps {
	percentage: string;
	diameterInPixel: string;
	color: string;
	tooltipTxt: string;
}

export const KeyMetricsPercentageAvatar: React.FC<
	KeyMetricsPercentageAvatarProps
> = ({ percentage, diameterInPixel, color, tooltipTxt }) => {
	return (
		<StyledTooltip title={tooltipTxt} placement="top-start">
			<Avatar
				sx={{
					bgcolor: color,
					width: diameterInPixel,
					height: diameterInPixel,
					fontFamily: '"JohnsonText-Regular"',
					fontWeight: 700,
					fontSize: '16px',
					lineHeight: '16px',
				}}
				data-testid="metrics-percentage-avatar"
			>
				{percentage}
			</Avatar>
		</StyledTooltip>
	);
};

export default KeyMetricsPercentageAvatar;
