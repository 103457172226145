import React, { useEffect, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	Grid,
	TablePagination,
	Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowUpward from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownward from '@mui/icons-material/ArrowDownwardOutlined';
import { tableRowClasses } from '@mui/material/TableRow';
import { useParams } from 'react-router-dom';
import { configurationData } from '../../redux/SurveySlice';
import { EndUserFilter, User } from '../../types/CommonTypes';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { ReactComponent as DownArrow } from '../../assets/images/Doarrow-narrow-up.svg';
import { commonLabels } from '../../utils/CommonLabels';
import {
	fetchUserListingData,
	pageIndex,
	pageSize,
	setRowsPerPageValue,
	setUserFilter,
	userFilter,
	userListingData,
	setPageIndex,
	setUsers,
	myQuestionnaireAPILoadingStatus,
} from '../../redux/QuestionnarieUserSlice';
import { Numbers, PageRows } from '../../utils/Enum';
import {
	API_STATUS_CONSTANT,
	ASSESMENT_STATUS_CONSTANT,
	END_USER_CONSTANT,
} from '../../constants/StepConstants';
import { STEPTheme } from '../../utils/Theme';
import FallbackUI from '../common/FallbackUi';
import { messages } from '../../utils/Messages';
import { AppDispatch } from '../../app/Store';
import StyledTooltip from '../StyledTooltip';
import { slotProps } from '../questions/common/QuestionStyles';

const StyledTableContainer = styled(TableContainer)({
	height: '200px',
	marginTop: '30px',
});
const OutOfScopeText = styled(Box)({
	color: STEPTheme.colors.errorText,
	textTransform: 'capitalize',
});
const InScopeText = styled(Box)({
	color: STEPTheme.colors.lightGreen,
	textTransform: 'capitalize',
});
const OtherStatusText = styled(Box)({
	textTransform: 'capitalize',
});
const HeadText = styled('div')({
	width: 'auto',
	height: 'auto',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	display: 'flex',
	svg: {
		cursor: 'pointer',
	},
	'.up-arrow': {
		svg: {
			cursor: 'pointer',
			transform: 'rotate(180deg)',
		},
	},
});
const BodyText = styled('div')({
	width: 'auto',
	height: '20px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	display: 'flex',
});
const StyledTableRow = styled(TableRow)(() => ({
	[`&.${tableRowClasses.root}`]: {
		'&:nth-child(odd)': {
			backgroundColor: '#f2edf9',
		},
	},
}));

const TableCW = () => {
	const [checked, setChecked] = useState(false);
	//Get API response-----
	const [users, setUsersData] = useState<User[]>([]);
	const configs = useAppSelector(configurationData);
	const userListing = useAppSelector(userListingData);
	const currentPageIndex: number = useAppSelector(pageIndex);
	const currentPageSize: number = useAppSelector(pageSize);
	const currentFilters: EndUserFilter = useAppSelector(userFilter);
	const { id: questionnaireId } = useParams();
	const endUsersPageSize = [
		Numbers.ten,
		Numbers.twentyFive,
		Numbers.fifty,
		Numbers.hundred,
	];
	const rowPage =
		configs?.end_users_page_size?.length > 0
			? configs?.end_users_page_size?.map((i) => Number(i))
			: endUsersPageSize;
	const [page, setPage] = React.useState(0);
	const [sortBy, setSortBy] = React.useState('');
	const [sortOrder, setSortOrder] = React.useState('');
	const [rowsPerPage, setRowsPerPage] = React.useState(
		Number(
			configs?.end_users_page_size_default
				? configs.end_users_page_size_default[0]
				: PageRows.fifthOption
		)
	);
	const dispatch = useAppDispatch();
	const [selectedWWID, setSelectedWWID] = useState<string[]>([]);
	const [selectAll, setSelectAll] = React.useState(false);
	const updateMyQuestionnairesAPIStatus = useAppSelector(
		myQuestionnaireAPILoadingStatus
	);

	const resetTable = () => {
		const filters: EndUserFilter = {
			wwiDs: '',
			lastName: '',
			firstName: '',
			partnerCompany: '',
			assessmentStatus: '',
		};
		dispatch(setUserFilter(filters));
		dispatch(
			setRowsPerPageValue(
				Number(
					configs?.end_users_page_size_default
						? configs.end_users_page_size_default[0]
						: PageRows.fifthOption
				)
			)
		);
		dispatch(setPageIndex(Numbers.zero));
		fetchUserList(
			questionnaireId,
			PageRows.fourthOption,
			Numbers.one,
			'',
			'',
			filters
		);
		setSelectedWWID([]);
		dispatch(setUsers([]));
	};
	useEffect(() => {
		if (updateMyQuestionnairesAPIStatus === API_STATUS_CONSTANT.SUCCESS) {
			resetTable();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMyQuestionnairesAPIStatus]);

	useEffect(() => {
		setPage(currentPageIndex);
		setRowsPerPage(currentPageSize);
		setUsersData(userListing.recipients);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userListing, users]);

	useEffect(() => {
		resetTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchUserList = (
		id: string | undefined,
		userListPageSize: number,
		currentPageNumber: number,
		currentSortBy: string,
		currentSortOrder: string,
		filters: EndUserFilter
	) => {
		dispatch(
			fetchUserListingData({
				endUserListingObj: {
					questionnaireId: id ? id : '',
					pageSize: userListPageSize,
					pageNumber: currentPageNumber,
					sortBy: currentSortBy,
					sortOrder: currentSortOrder,
					filter: filters,
				},
			})
		);
	};
	const handleCheckBoxAllChange = (event: boolean) => {
		updateSelectAllChange(
			event,
			users,
			setSelectedWWID,
			dispatch,
			setSelectAll
		);
	};
	const handleCheckBoxChange = (id: string, status: boolean) => {
		setChecked(!checked);
		updateCheckboxOnChange(status, setSelectedWWID, selectedWWID, id, dispatch);
	};
	useEffect(() => {
		updateSelectAll(users, selectedWWID, setSelectAll);
	}, [selectedWWID, users]);
	const checkVal = (WWID: string) => {
		if (selectedWWID.indexOf(WWID) === -1) {
			return false;
		}
		return true;
	};
	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		dispatch(setPageIndex(newPage));
		setSelectedWWID([]);
		dispatch(setUsers([]));
		const pageNumber = newPage + Numbers.one;
		fetchUserList(
			questionnaireId,
			currentPageSize,
			pageNumber,
			'',
			'',
			currentFilters
		);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(Number(event.target.value));
		dispatch(setRowsPerPageValue(Number(event.target.value)));
		setSelectedWWID([]);
		dispatch(setUsers([]));
		fetchUserList(
			questionnaireId,
			Number(event.target.value),
			Numbers.one,
			'',
			'',
			currentFilters
		);
	};
	const handleSortClick = (sortByKey: string, sortOrderKey: string) => {
		setSortBy(sortByKey);
		setSortOrder(sortOrderKey);
		const pageNumber = page + Numbers.one;
		fetchUserList(
			questionnaireId,
			currentPageSize,
			pageNumber,
			sortByKey,
			sortOrderKey,
			currentFilters
		);
	};

	return users?.length > 0 ? (
		<Box>
			<StyledTableContainer>
				<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow>
							<TableCell>
								<HeadText>
									<StyledTooltip
										title={commonLabels.checkBoxTooltip}
										placement="top"
									>
										<Checkbox
											size="small"
											checked={selectAll}
											sx={{ padding: '0 9px' }}
											onChange={(e) => {
												handleCheckBoxAllChange(e.target.checked);
											}}
										/>
									</StyledTooltip>

									{commonLabels.WWID}

									{checkNoSortApplied(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.WWIDS
									) && (
										<div className="up-arrow">
											<StyledTooltip
												title={commonLabels.columnHeaderTooltip}
												placement="top"
											>
												<DownArrow
													onClick={() => {
														handleSortClick(
															END_USER_CONSTANT.WWIDS,
															END_USER_CONSTANT.ASCENDING
														);
													}}
												/>
											</StyledTooltip>
										</div>
									)}
									{checkSortAscending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.WWIDS
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowUpward
												data-testid="sortByName"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.WWIDS,
														END_USER_CONSTANT.DESCENDING
													);
												}}
											/>
										</StyledTooltip>
									)}
									{checkSortDescending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.WWIDS
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowDownward
												data-testid="sortByNameDesc"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(END_USER_CONSTANT.WWIDS, '');
												}}
											/>
										</StyledTooltip>
									)}
								</HeadText>
							</TableCell>
							<TableCell>
								<HeadText>
									{commonLabels.LAST_NAME}

									{checkNoSortApplied(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.LAST_NAME
									) && (
										<div className="up-arrow">
											<StyledTooltip
												title={commonLabels.columnHeaderTooltip}
												placement="top"
											>
												<DownArrow
													onClick={() => {
														handleSortClick(
															END_USER_CONSTANT.LAST_NAME,
															END_USER_CONSTANT.ASCENDING
														);
													}}
												/>
											</StyledTooltip>
										</div>
									)}
									{checkSortAscending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.LAST_NAME
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowUpward
												data-testid="sortByName"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.LAST_NAME,
														END_USER_CONSTANT.DESCENDING
													);
												}}
											/>
										</StyledTooltip>
									)}
									{checkSortDescending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.LAST_NAME
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowDownward
												data-testid="sortByNameDesc"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(END_USER_CONSTANT.LAST_NAME, '');
												}}
											/>
										</StyledTooltip>
									)}
								</HeadText>
							</TableCell>{' '}
							<TableCell>
								<HeadText>
									{commonLabels.FIRST_NAME}

									{checkNoSortApplied(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.FIRST_NAME
									) && (
										<div className="up-arrow">
											<StyledTooltip
												title={commonLabels.columnHeaderTooltip}
												placement="top"
											>
												<DownArrow
													onClick={() => {
														handleSortClick(
															END_USER_CONSTANT.FIRST_NAME,
															END_USER_CONSTANT.ASCENDING
														);
													}}
												/>
											</StyledTooltip>
										</div>
									)}
									{checkSortAscending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.FIRST_NAME
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowUpward
												data-testid="sortByName"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.FIRST_NAME,
														END_USER_CONSTANT.DESCENDING
													);
												}}
											/>
										</StyledTooltip>
									)}
									{checkSortDescending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.FIRST_NAME
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowDownward
												data-testid="sortByNameDesc"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(END_USER_CONSTANT.FIRST_NAME, '');
												}}
											/>
										</StyledTooltip>
									)}
								</HeadText>
							</TableCell>
							<TableCell>
								<HeadText>
									{commonLabels.PARTNER_COMPANY}

									{checkNoSortApplied(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.PARTNER_COMPANY
									) && (
										<div className="up-arrow">
											<StyledTooltip
												title={commonLabels.columnHeaderTooltip}
												placement="top"
											>
												<DownArrow
													onClick={() => {
														handleSortClick(
															END_USER_CONSTANT.PARTNER_COMPANY,
															END_USER_CONSTANT.ASCENDING
														);
													}}
												/>
											</StyledTooltip>
										</div>
									)}
									{checkSortAscending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.PARTNER_COMPANY
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowUpward
												data-testid="sortByName"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.PARTNER_COMPANY,
														END_USER_CONSTANT.DESCENDING
													);
												}}
											/>
										</StyledTooltip>
									)}
									{checkSortDescending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.PARTNER_COMPANY
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowDownward
												data-testid="sortByNameDesc"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.PARTNER_COMPANY,
														''
													);
												}}
											/>
										</StyledTooltip>
									)}
								</HeadText>
							</TableCell>
							<TableCell>
								<HeadText>
									{commonLabels.ASSESMENT_STATUS}

									{checkNoSortApplied(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.ASSESSMENT_STATUS
									) && (
										<div className="up-arrow">
											<StyledTooltip
												title={commonLabels.columnHeaderTooltip}
												placement="top"
											>
												<DownArrow
													onClick={() => {
														handleSortClick(
															END_USER_CONSTANT.ASSESSMENT_STATUS,
															END_USER_CONSTANT.ASCENDING
														);
													}}
												/>
											</StyledTooltip>
										</div>
									)}
									{checkSortAscending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.ASSESSMENT_STATUS
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowUpward
												data-testid="sortByName"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.ASSESSMENT_STATUS,
														END_USER_CONSTANT.DESCENDING
													);
												}}
											/>
										</StyledTooltip>
									)}
									{checkSortDescending(
										sortBy,
										sortOrder,
										END_USER_CONSTANT.ASSESSMENT_STATUS
									) && (
										<StyledTooltip
											title={commonLabels.columnHeaderTooltip}
											placement="top"
										>
											<ArrowDownward
												data-testid="sortByNameDesc"
												color="error"
												fontSize="inherit"
												className="text-base"
												onClick={() => {
													handleSortClick(
														END_USER_CONSTANT.ASSESSMENT_STATUS,
														''
													);
												}}
											/>
										</StyledTooltip>
									)}
								</HeadText>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{users?.map((row, index) => (
							<StyledTableRow key={row.wwid + index} hover tabIndex={-1}>
								<TableCell component="th" scope="row">
									<BodyText>
										<Checkbox
											size="small"
											checked={checkVal(row.wwid)}
											onChange={(e) => {
												handleCheckBoxChange(row.wwid, e.target.checked);
											}}
										/>
										{row.wwid}
									</BodyText>
								</TableCell>
								<TableCell>
									<BodyText>{row.lastName}</BodyText>
								</TableCell>
								<TableCell>
									<BodyText>{row.firstName}</BodyText>
								</TableCell>
								<TableCell>
									<BodyText>{row.partnerCompany}</BodyText>
								</TableCell>
								<TableCell>
									<BodyText>
										{row.assessmentStatus ===
											ASSESMENT_STATUS_CONSTANT.OUT_OF_SCOPE.toLowerCase() && (
											<OutOfScopeText>{row.assessmentStatus}</OutOfScopeText>
										)}
										{row.assessmentStatus ===
											ASSESMENT_STATUS_CONSTANT.IN_SCOPE.toLowerCase() && (
											<InScopeText>{row.assessmentStatus}</InScopeText>
										)}
										{row.assessmentStatus !==
											ASSESMENT_STATUS_CONSTANT.IN_SCOPE.toLowerCase() &&
											row.assessmentStatus !==
												ASSESMENT_STATUS_CONSTANT.OUT_OF_SCOPE.toLowerCase() && (
												<OtherStatusText>
													{row.assessmentStatus}
												</OtherStatusText>
											)}
									</BodyText>
								</TableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<Grid container sx={{ justifyContent: 'flex-end' }}>
				<StyledTooltip title={commonLabels.rowsPerPageTooltip} placement="top">
					<TablePagination
						rowsPerPageOptions={rowPage}
						component="div"
						count={userListing?.recordCount}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						slotProps={slotProps}
					/>
				</StyledTooltip>
			</Grid>
		</Box>
	) : (
		<FallbackUI fallbackText={messages.common.NO_USER_FOUND} />
	);
};
export default TableCW;
const checkSortAscending = (sortBy: string, sortOrder: string, key: string) => {
	return sortBy === key && sortOrder === END_USER_CONSTANT.ASCENDING;
};

const checkSortDescending = (
	sortBy: string,
	sortOrder: string,
	key: string
) => {
	return sortBy === key && sortOrder === END_USER_CONSTANT.DESCENDING;
};

const checkNoSortApplied = (sortBy: string, sortOrder: string, key: string) => {
	return sortBy !== key || sortOrder === '';
};

const updateSelectAll = (
	users: User[],
	selectedWWID: string[],
	setSelectAll: React.Dispatch<React.SetStateAction<boolean>>
) => {
	if (users?.length === selectedWWID.length) {
		setSelectAll(true);
	} else {
		setSelectAll(false);
	}
};

const updateSelectAllChange = (
	event: boolean,
	users: User[],
	setSelectedWWID: React.Dispatch<React.SetStateAction<string[]>>,
	dispatch: AppDispatch,
	setSelectAll: React.Dispatch<React.SetStateAction<boolean>>
) => {
	if (event) {
		const ids = users.map((userObj) => userObj.wwid);
		setSelectedWWID(ids);
		dispatch(setUsers(ids));
		setSelectAll(true);
	} else {
		setSelectedWWID([]);
		dispatch(setUsers([]));
		setSelectAll(false);
	}
};

const updateCheckboxOnChange = (
	status: boolean,
	setSelectedWWID: React.Dispatch<React.SetStateAction<string[]>>,
	selectedWWID: string[],
	id: string,
	dispatch: AppDispatch
) => {
	if (status) {
		setSelectedWWID([...selectedWWID, id]);
		dispatch(setUsers([...selectedWWID, id]));
	} else {
		const newFilterHasChildren = selectedWWID.filter(
			(item: string) => item !== id
		);
		setSelectedWWID(newFilterHasChildren);
		dispatch(setUsers(newFilterHasChildren));
	}
};
