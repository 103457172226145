import { createTheme } from '@mui/material/styles';
import { Theme } from '../types/CommonTypes';

export const STEPTheme: Theme = {
	colors: {
		primary: '#EB1700',
		white: '#fff',
		disabledButton: '#F1EFED',
		disabledText: '#888B8D',
		green: '#359942',
		text: '#212121',
		border: 'rgba(33, 33, 33, 0.10)',
		darkGray: '#81766F',
		lightGray: '#eaeaf0',
		middlGray: '#564C47',
		shadowGray: '#00000029',
		lightBlue: '#004685',
		subText: '#918781',
		background: '#F9F8F7',
		blue: '#1C75BC',
		darkBlue: '#004685',
		linkGray: '#A39992',
		totalLightGray: '#63666A',
		red: '#FF0000',
		arrowGray: '#777575',
		black: '#000000',
		lightGreen: '#6EBD44',
		asiaPacificGreen: '#C6EFD1',
		lightRed: '#C62828',
		latinBlue: '#00A0DF',
		emeaBlue: '#AFCDE5',
		yellow: '#F5AC25',
		amber: '#F37820',
		errorText: '#D32F2F',
		toastSuccess: '#EAF2EA',
		shadow: 'rgba(0, 0, 0, 0.16)',
		grayBackground: '#2121211A',
		purple: '#8C3BBB',
		powderBlue: '#E6F3FA',
		responseGraphMultipleOptionsColor: '#9E0000',
		responseGraphNoOptionColor: '#FF6017',
		radianceBlue: '#007bff',
		cyanBlue: '#e0f7fa',
		borderColor: '#C5C6C7',
		inputBaseBorder: '#676765',
		lightPink: '#EFBABA',
	},
	pieChartColors: {
		pattern1: '#EB1700',
		pattern2: '#FBE058',
		pattern3: '#9E0000',
		pattern4: '#B19BEB',
		pattern5: '#8C3BBB',
		pattern6: '#541980',
		pattern7: '#69D0FF',
		pattern8: '#0F68B2',
		pattern9: '#004685',
		pattern10: '#53CE76',
		pattern11: '#328714',
		pattern12: '#3B5A0D',
		pattern13: '#C1BBB3',
		pattern14: '#A39992',
		pattern15: '#564C47',
	},
};

export const stepMuiTheme = createTheme({
	palette: {
		primary: {
			main: '#EB1700',
		},
		secondary: {
			main: '#CA001B',
		},
	},
	breakpoints: {
		values: {
			xs: 300,
			sm: 375,
			md: 768,
			lg: 1300,
			xl: 1500,
		},
	},
	typography: {
		fontFamily: '"JohnsonText-Regular"',
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'secondary' && {
							backgroundColor: '#fff',
							color: '#212121',
							border: '1px solid #212121',
							borderRadius: '8px',
							padding: '2px 8px',
							textTransform: 'capitalize',
							fontFamily: '"JohnsonText-Regular"',
							fontWeight: '700',
							hover: {
								'&:hover': {
									backgroundColor: '#fff',
									color: '#212121',
									border: '1px solid #212121',
								},
							},
						}),
					...(ownerState.variant === 'contained' &&
						ownerState.color === 'primary' && {
							borderRadius: '8px',
							padding: '2px 10px',
							textTransform: 'capitalize',
							fontFamily: '"JohnsonText-Regular"',
						}),
					...(ownerState.variant === 'text' &&
						ownerState.color === 'secondary' && {
							borderRadius: '8px',
							padding: '2px 10px',
							textTransform: 'capitalize',
							fontFamily: '"JohnsonText-Regular"',
							color: '#81766F',
						}),
					...(ownerState.variant === 'outlined' &&
						ownerState.color === 'primary' && {
							backgroundColor: '#fff',
							color: '#212121',
							border: '1px solid #EB1700',
							borderRadius: '8px',
							padding: '2px 8px',
							textTransform: 'capitalize',
							fontFamily: '"JohnsonText-Regular"',
							fontWeight: '700',
							hover: {
								'&:hover': {
									backgroundColor: '#fff',
									color: '#212121',
									border: '1px solid #EB1700',
								},
							},
						}),
				}),
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'h1' && {
						color: '#212121',
						fontFamily: '"JohnsonDisplay-Bold"',
						fontWeight: '700',
						fontSize: '25px',
						lineHeight: '32px',
					}),
					...(ownerState.variant === 'h2' && {
						fontFamily: '"JohnsonDisplay-Bold"',
						fontWeight: '700',
						fontSize: '24px',
						lineHeight: '24px',
					}),
					...(ownerState.variant === 'h3' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '400',
						fontSize: '18px',
						lineHeight: '24px',
					}),
					...(ownerState.variant === 'h4' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '700',
					}),
					...(ownerState.variant === 'h5' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '700',
					}),
					...(ownerState.variant === 'h6' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '700',
						fontSize: '14px',
						lineHeight: '16px',
					}),
					...(ownerState.variant === 'subtitle1' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '400',
						fontSize: '14px',
						lineHeight: '16px',
					}),
					...(ownerState.variant === 'subtitle2' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '700',
					}),
					...(ownerState.variant === 'subtitle2' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '700',
					}),
					...(ownerState.variant === 'body1' && {
						color: '#212121',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '700',
					}),
					...(ownerState.variant === 'body2' && {
						color: '#81766F',
						fontFamily: '"JohnsonText-Regular"',
						fontWeight: '400',
					}),
				}),
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				menuItem: {
					fontWeight: 700,
					fontFamily: '"JohnsonText-Regular"',
				},
				displayedRows: {
					fontWeight: 700,
					fontFamily: '"JohnsonText-Regular"',
				},
				selectRoot: {
					fontWeight: 700,
					fontFamily: '"JohnsonText-Regular"',
				},
				selectLabel: {
					fontWeight: 700,
					fontFamily: '"JohnsonText-Regular"',
				},
			},
		},
	},
});
