import { Box, styled } from '@mui/material';
import { ReactComponent as LoadingSpinner } from '../../assets/images/loader.svg';

const LoaderContainer = styled(Box)({
	paddingBottom: '2vh',
	svg: {
		animation: `circle linear 3s infinite`,
		height: '6vh',
	},
});

const LoadingIcon = () => {
	return (
		<LoaderContainer data-testid="loader-container">
			<LoadingSpinner data-testid="loading-spinner" />
		</LoaderContainer>
	);
};

export default LoadingIcon;
