import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { commonLabels } from '../../utils/CommonLabels';
import { STEPTheme } from '../../utils/Theme';
import SelectedFilters from '../SelectedFilters';
import AudiencePopup from './AudiencePopup';

// Component to display the selected criteria for group
const MappingGroupCriteria = (props: { groupId: string }) => {
	const [criteriaSelectionOpen, setCriteriaSelectionOpen] = useState(false);
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: '10px',
				}}
			>
				<Box
					sx={{
						fontWeight: 500,
						color: STEPTheme.colors.linkGray,
						minWidth: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							justifyContent: 'space-between',
							marginBottom: '15px',
						}}
					>
						<Box
							sx={{
								fontFamily: 'JohnsonText-Regular',
								fontSize: '14px',
								fontWeight: 500,
								lineHeight: '12px',
								color: STEPTheme.colors.text,
								marginBottom: '1vh',
								width: '220px',
								height: '12px',
							}}
						></Box>
						<Button
							data-testid="btn"
							variant="outlined"
							sx={{
								textTransform: 'none',
								border: '1px solid black',
								fontSize: '14px',
								fontFamily: 'JohnsonText-Regular',
								height: '24px',
								minWidth: 'fit-content',
							}}
							onClick={() => {
								setCriteriaSelectionOpen(true);
							}}
						>
							{commonLabels.chooseCriteria}
						</Button>
					</Box>
					{<SelectedFilters isVertical={false} />}
				</Box>
			</Box>
			<Box>
				{criteriaSelectionOpen && (
					<AudiencePopup
						data-testid="audience-popup"
						closePopup={() => {
							setCriteriaSelectionOpen(false);
						}}
						groupId={props.groupId}
					/>
				)}
			</Box>
		</>
	);
};

export default MappingGroupCriteria;
