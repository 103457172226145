import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { commonLabels } from '../../../utils/CommonLabels';
import { useAppSelector } from '../../../app/Hooks';
import {
	getSurveyDueDate,
	getSurveyStartDate,
	surveyQuestionnaireData,
} from '../../../redux/SurveySlice';
import { formatDate } from '../../../utils/DateFormatting';
import DateDisplay from '../common/DateDisplay';

const useStyles = makeStyles({
	dateTypography: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 700,
		lineHeight: '16px',
		fontSize: '14px',
	},
	dateTextTypography: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 500,
		lineHeight: '16px',
		fontSize: '14px',
	},
});

const StyledTypography = styled(Box)`
	margin-bottom: 2px;
	height: 12px;
	display: inline-flex;
	align-items: center;
`;
const SubHeaderBox = styled(Box)`
	margin-bottom: 0px;
	width: 101%;
`;

const PreviewQuestionnaireSubHeader: React.FC<unknown> = () => {
	const classes = useStyles();
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const surveyStartDate = useAppSelector(getSurveyStartDate);
	const surveyDueDate = useAppSelector(getSurveyDueDate);
	const formattedDueDate = formatDate(surveyDueDate);
	const formattedStartDate = formatDate(surveyStartDate);
	return (
		<SubHeaderBox data-testid="preview-sub-header">
			<Grid container xs={12} spacing={1}>
				<Grid item xs={8}>
					<Box>
						<StyledTypography>
							<Typography variant="h6">
								{surveyQuestionnaireInfo?.surveyFormsDetails?.surveyName}
							</Typography>
						</StyledTypography>
					</Box>
				</Grid>
				<Grid item xs={2} textAlign={'right'} justifyContent="right">
					<Box>
						<DateDisplay
							label={commonLabels.startDate}
							date={formattedStartDate}
							classes={classes}
						/>
					</Box>
				</Grid>
				<Grid item xs={2} textAlign={'right'}>
					<Box>
						<DateDisplay
							label={commonLabels.dueDate}
							date={formattedDueDate}
							classes={classes}
						/>
					</Box>
				</Grid>
			</Grid>
		</SubHeaderBox>
	);
};

export default PreviewQuestionnaireSubHeader;
