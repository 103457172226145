/* eslint-disable no-console */
/* eslint-disable no-global-assign */
export const GlobalDebug = (function () {
	const savedConsoles = console;
	/**
	 * @param {boolean} debugOn
	 */
	return function (debugOn) {
		if (!debugOn) {
			// supress the default console functionality
			console = {};
			console.log = function () {}; //NOSONAR
			console.info = function () {}; //NOSONAR
			console.warn = function () {}; //NOSONAR
			console.error = function () {}; //NOSONAR
		} else {
			console = savedConsoles;
		}
	};
})();
