import React from 'react';
import { Grid, Typography, Stack, styled } from '@mui/material';
import { commonLabels } from '../../utils/CommonLabels';
import { STEPTheme } from '../../utils/Theme';
import { IMetricsData } from '../../types/QuestionnaireResultsType';
import { prepareKeyMetricsData } from '../../utils/CommonMethods';
import { determineSummitMappedColor } from '../../utils/QuestionnaireResult';
import { keyMetricsSummitAPIStatus } from '../../redux/QuestionnaireResultsSlice';
import { useAppSelector } from '../../app/Hooks';
import { Numbers } from '../../utils/Enum';
import { KeyMetricsItemError } from './KeyMetricsItemError';
import KeyMetricsItem from './KeyMetricsItem';

const KeyMetricsContainer = styled(Stack)({
	gap: '20px',
	width: '100%',
});

const KeyMetricsItemContainer = styled(Grid)({
	width: '100%',
	gridTemplateColumns: '1fr 1fr 1fr',
	justifyContent: 'space-between',
	marginBottom: '30px',
	borderRadius: '12px',
});

interface KeyMetricsProps {
	type: string;
	employeesAtRiskOfOverdue: IMetricsData;
	employeesOverdue: IMetricsData;
	employeesSummitMapped: IMetricsData;
	triggerSelectedDays: (selectedDay: number, metricsType: string) => void;
	daysNonCompliant: number;
	daysComingDue: number;
	refreshFn: (refreshOnlySummit: boolean) => void;
	filterViewEmployees: (metricsType: string) => void;
}

export const KeyMetrics: React.FC<KeyMetricsProps> = ({
	type,
	employeesAtRiskOfOverdue,
	employeesOverdue,
	employeesSummitMapped,
	triggerSelectedDays,
	daysNonCompliant,
	daysComingDue,
	filterViewEmployees,
	refreshFn,
}) => {
	const keyMetricesSummitAPIStatus = useAppSelector(keyMetricsSummitAPIStatus);
	return (
		<KeyMetricsContainer>
			<Typography variant="h1">{commonLabels.keyMetrics}</Typography>
			<KeyMetricsItemContainer container>
				<KeyMetricsItem
					percentage={prepareKeyMetricsData(
						type,
						employeesAtRiskOfOverdue?.impactedEmployees,
						employeesAtRiskOfOverdue?.totalEmployees
					)}
					avatarDiameterInPixel={'68px'}
					avatarColor={STEPTheme.colors.yellow}
					metricsType={commonLabels.RISKOVERDUE}
					metricsLabelLineOne={commonLabels.comingDue}
					metricsLabelLineTwo={commonLabels.inNext}
					triggerSelectedDays={triggerSelectedDays}
					daysNonCompliant={daysNonCompliant}
					daysComingDue={daysComingDue}
					filterViewEmployees={() =>
						filterViewEmployees(commonLabels.RISKOVERDUE)
					}
				/>

				<KeyMetricsItem
					percentage={prepareKeyMetricsData(
						type,
						employeesOverdue?.impactedEmployees,
						employeesOverdue?.totalEmployees
					)}
					avatarDiameterInPixel={'68px'}
					avatarColor={STEPTheme.colors.amber}
					metricsType={commonLabels.NON_COMPLIANT}
					metricsLabelLineOne={commonLabels.notCompliant}
					metricsLabelLineTwo={commonLabels.after}
					triggerSelectedDays={triggerSelectedDays}
					daysNonCompliant={daysNonCompliant}
					daysComingDue={daysComingDue}
					filterViewEmployees={() =>
						filterViewEmployees(commonLabels.NON_COMPLIANT)
					}
				/>

				{keyMetricesSummitAPIStatus === 'failed' ? (
					<KeyMetricsItemError
						metricsType={commonLabels.metricsTypeMappedInSummit}
						metricsLabelLineOne={commonLabels.mappedIn}
						metricsLabelLineTwo={commonLabels.summitGroup}
						triggerSelectedDays={triggerSelectedDays}
						selectedDay={Numbers.zero}
						refreshFn={() => refreshFn(true)}
						status={commonLabels.error}
					/>
				) : (
					<KeyMetricsItem
						percentage={prepareKeyMetricsData(
							type,
							employeesSummitMapped?.impactedEmployees,
							employeesSummitMapped?.totalEmployees
						)}
						avatarDiameterInPixel={'68px'}
						avatarColor={determineSummitMappedColor(
							type,
							employeesSummitMapped
						)}
						metricsType={commonLabels.metricsTypeMappedInSummit}
						metricsLabelLineOne={commonLabels.mappedIn}
						metricsLabelLineTwo={commonLabels.summitGroup}
						triggerSelectedDays={triggerSelectedDays}
						daysNonCompliant={daysNonCompliant}
						daysComingDue={daysComingDue}
						filterViewEmployees={() =>
							filterViewEmployees(commonLabels.SUMMITMAPPED)
						}
					/>
				)}
			</KeyMetricsItemContainer>
		</KeyMetricsContainer>
	);
};

export default KeyMetrics;
