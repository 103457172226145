import React from 'react';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PreviewProps } from '../../types/CommonTypes';
import {
	initialEmailBody,
	initialEmailSubject,
	overdueEmailBody,
	overdueEmailSubject,
	reminderEmailBody,
	reminderEmailSubject,
} from '../../redux/SurveySlice';
import { PREVIEW_CONSTANT } from '../../constants/StepConstants';
import { commonLabels } from '../../utils/CommonLabels';

const useStyles = makeStyles({
	subject: {
		fontFamily: "'JohnsonText-Regular'",
		fontSize: '14px',
		lineHeight: '16px',
		fontWeight: 400,
		paddingTop: '40px',
		paddingLeft: '65px',
	},
	title: {
		fontFamily: "'JohnsonText-Regular'",
		fontWeight: 700,
		fontSize: '20px',
		paddingLeft: '65px',
	},
	content: {
		fontFamily: "'JohnsonText-Regular'",
		marginBottom: '20px',
		marginRight: '10px',
	},
	box: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '10px',
	},
	iconBox: {
		cursor: 'pointer',
		paddingRight: '50px',
	},
});

const DialogPaperStyle = {
	height: 'auto',
	width: 'auto',
	maxWidth: '90%',
	borderRadius: '10px',
};

const Preview: React.FC<PreviewProps> = ({ open, onClose, type }) => {
	const classes = useStyles();
	const initialEmailBodyContent = useSelector(initialEmailBody);
	const initialEmailSubjectCont = useSelector(initialEmailSubject);
	const reminderEmailBodyContent = useSelector(reminderEmailBody);
	const reminderEmailSubjectCont = useSelector(reminderEmailSubject);
	const overDueEmailBodyContent = useSelector(overdueEmailBody);
	const overDueEmailSubjectCont = useSelector(overdueEmailSubject);

	let title = '';
	let content: string;
	let subject = '';

	switch (type) {
		case PREVIEW_CONSTANT.INITIAL_MAIL:
			title = commonLabels.initialMail;
			subject = initialEmailSubjectCont;
			content = initialEmailBodyContent;
			break;
		case PREVIEW_CONSTANT.REMINDER_MAIL:
			title = commonLabels.reminderMail;
			subject = reminderEmailSubjectCont;
			content = reminderEmailBodyContent;
			break;
		case PREVIEW_CONSTANT.OVERDUE_MAIL:
			title = commonLabels.overdueMail;
			subject = overDueEmailSubjectCont;
			content = overDueEmailBodyContent;
			break;
		default:
			content = '';
	}
	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperProps={{ style: DialogPaperStyle }}
		>
			<Box className={classes.box}>
				<Box className={classes.title} data-testid="data-title">
					{title}
				</Box>
				<Box className={classes.iconBox}>
					<CloseOutlinedIcon onClick={onClose}></CloseOutlinedIcon>
				</Box>
			</Box>
			<Box className={classes.subject} data-testid="data-subject">
				{subject}
			</Box>
			<DialogContent
				className={classes.content}
				dangerouslySetInnerHTML={{ __html: content }}
				data-testid="data-content"
			/>
		</Dialog>
	);
};

export default Preview;
