import { Box } from '@mui/material';

const InputCharacterRemaining = (props: {
	remainingCharacterCount: number;
}) => {
	return (
		<Box
			sx={{
				fontWeight: '400',
				fontSize: '12px',
				lineHeight: '24px',
				fontStyle: 'italic',
				fontFamily: '"JohnsonText-Medium"',
			}}
		>{`${props.remainingCharacterCount} Characters`}</Box>
	);
};

export default InputCharacterRemaining;
