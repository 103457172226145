import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	fetchQuestionnaireResultsData,
	getQuestionnaireResultsData,
} from '../../redux/QuestionnaireSpecificResultsSlice/QuestionnaireSpecificResultsResponseSlice';
import { IQuestionnaireDataRequest } from '../../types/SurveyProps';
import { fetchResponseGraphData } from '../../redux/QuestionnaireResultsSlice';
import { selectedFilterRequestPayloadData } from '../../redux/SurveySlice';
import Questions from './Questions';

const Main: React.FC<unknown> = () => {
	const QuestionnaireResultContainer = styled(Box)({
		background: '#F9F8F7',
		paddingTop: '20px',
		width: '100%',
	});
	const ResponseGraphContainers = styled(Box)({
		display: 'flex',
		padding: '20px',
		boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
		borderRadius: '12px',
		background: '#fff',
	});

	const dispatch = useAppDispatch();
	const questionsAnswers: IQuestionnaireDataRequest = useAppSelector(
		getQuestionnaireResultsData
	);
	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);

	const includeFilters = JSON.parse(filterRequestPayload.includeFilters);
	const excludeFilters = JSON.parse(filterRequestPayload.excludeFilters);
	const includeFilterStr = JSON.stringify(includeFilters);
	const excludeFilterStr = JSON.stringify(excludeFilters);
	/**
	 * @useEffect
	 * @summary - Onpage load, collects questainnaire information
	 */
	useEffect(() => {
		questionnaireID && dispatch(fetchQuestionnaireResultsData(questionnaireID));
	}, [questionnaireID, dispatch]);

	useEffect(() => {
		const reqPayload = {
			questionnaireId: `${questionnaireID}`,
			includeFilter: JSON.stringify(includeFilters),
			excludeFilter: JSON.stringify(excludeFilters),
		};
		if (questionnaireID) {
			dispatch(fetchResponseGraphData(reqPayload));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireID, includeFilterStr, excludeFilterStr]);

	return (
		<Box
			className="results-response-graph-container"
			data-testid="graph-container"
			sx={{ width: '100%' }}
		>
			<QuestionnaireResultContainer>
				<ResponseGraphContainers
					sx={{
						width: '98%',
						gap: '40px',
						overflow: 'hidden',
					}}
				>
					{questionsAnswers?.qAndA && (
						<Questions
							questionJSONString={questionsAnswers?.qAndA?.questionJSONString}
						/>
					)}
				</ResponseGraphContainers>
			</QuestionnaireResultContainer>
		</Box>
	);
};

export default Main;
