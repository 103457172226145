import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import {
	IGroup,
	IGroupState,
	IGroupReq,
	IGroupSaveReq,
	IGroupDeleteReq,
} from '../types/MappingTypes';
import { RootState } from '../app/Store';
import {
	FetchGroupList,
	FetchSummitSurveyName,
	createUpdateGroup,
	deleteGroupList,
	FetchAudienceSubset,
} from '../middleware/MappingService';
import { API_STATUS_CONSTANT } from '../constants/StepConstants';
import { getKey } from '../utils/Helpers';
import {
	setAudienceFilterResponse,
	setFilterAllJobFunctions,
	setFilterJobFunction,
	setFilterRegion,
	setFilterRegionCountryData,
	setSurveyAudienceExcludedFilterResponse,
	setSurveyAudienceIncludedFilterResponse,
} from './SurveySlice';

const initialMappingState: IGroupState = {
	questionnaireGroupList: [],
	groupSaveRequest: {
		isDefaulter: false,
		groupName: '',
		includeClause: '',
		excludeClause: '',
		questionnaireJson: '',
		filterJson: '',
		isSummitGroup: false,
	},
	saveCompleted: false,
	createAPILoadingStatus: 'idle',
	copyAPILoadingStatus: 'idle',
	deleteCompleted: false,
	deleteAPILoadingStatus: 'idle',
	summitSurveyList: [],
	clearGroupName: false,
	apiError: '',
	isGroupListUpdated: false,
};

export const getGroupsList = createAsyncThunk(
	'mapping/GetGroupList',
	async (req: IGroupReq, { dispatch }) => {
		FetchGroupList(req)
			.then((res) => {
				if (res.status === HttpStatusCode.NoContent) {
					dispatch(updateGroupList([]));
					dispatch(setUpdatedTheGroupList(true));
				} else {
					// update redux with group list
					dispatch(updateGroupList(res?.data?.data?.questionnaireGroupList));
					dispatch(setUpdatedTheGroupList(true));
				}
			})
			.catch(() => {
				dispatch(updateGroupList([]));
				dispatch(setUpdatedTheGroupList(true));
			});
	}
);

export const getSummitInfo = createAsyncThunk(
	'mapping/getSummitInfo',
	async (groupName: string, { dispatch }) => {
		FetchSummitSurveyName(groupName, { skipGlobalErrorHandling: true })
			.then((res) => {
				// update redux with group list
				if (res.data) {
					const groupList: string[] = [];
					const response = res?.data?.data;
					// create list of group names
					response.forEach(
						(element: { GroupName: string; QuestionnaireName: string }) => {
							groupList.push(element.GroupName);
						}
					);

					dispatch(updateSummitInfo(groupList));
				} else {
					dispatch(updateSummitInfo([]));
				}
			})
			.catch(() => {
				dispatch(setAPIErrorType('summitGroupFail'));
				dispatch(updateSummitInfo([]));
			});
	}
);

export const getAudienceSubset = createAsyncThunk(
	'mapping/getAudienceSubset',
	async (req: { questionnaireId: string }, { dispatch }) => {
		FetchAudienceSubset(req, { skipGlobalErrorHandling: true })
			.then((res) => {
				const audience = res?.data?.data;
				if (audience) {
					dispatch(setSurveyAudienceExcludedFilterResponse(audience));
					dispatch(setSurveyAudienceIncludedFilterResponse(audience));

					if (Object.hasOwn(audience, 'work_region-work_country')) {
						dispatch(
							setFilterRegion(getKey(audience['work_region-work_country']))
						);

						dispatch(
							setFilterRegionCountryData(audience['work_region-work_country'])
						);
					}
					if (Object.hasOwn(audience, 'job_function-job_subfunction')) {
						dispatch(
							setFilterJobFunction(
								getKey(audience['job_function-job_subfunction'])
							)
						);
						dispatch(
							setFilterAllJobFunctions(audience['job_function-job_subfunction'])
						);
					}
					dispatch(setAudienceFilterResponse('success'));
				} else {
					dispatch(setAPIErrorType('mappingAudienceFail'));
					dispatch(setAudienceFilterResponse('failed'));
				}
			})
			.catch(() => {
				dispatch(setAPIErrorType('mappingAudienceFail'));
				dispatch(setAudienceFilterResponse('failed'));
			});
	}
);

export const saveGroup = createAsyncThunk(
	'mapping/saveGroup',
	async (request: IGroupSaveReq, { dispatch }) => {
		dispatch(updateSaveCompleted(false));
		createUpdateGroup(request)
			.then(({ data }) => {
				dispatch(updateGroup(data?.data));
				dispatch(setMappingCreateAPILoadingStatus(API_STATUS_CONSTANT.SUCCESS));
				dispatch(updateSaveCompleted(true));
			})
			.catch(() => {
				dispatch(setMappingCreateAPILoadingStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);

export const deleteGroup = createAsyncThunk(
	'mapping/deleteGroup',
	async (requestPayload: IGroupDeleteReq, { dispatch }) => {
		dispatch(updateDeleteCompleted(false));
		deleteGroupList(requestPayload)
			.then(({ data }) => {
				dispatch(
					updateGroupListAfterDeletion(
						data?.data?.deletedQuestionnaireGroupList
					)
				);
				dispatch(setDeleteAPILoadingStatus(API_STATUS_CONSTANT.SUCCESS));
				dispatch(updateDeleteCompleted(true));
			})
			.catch(() => {
				dispatch(setDeleteAPILoadingStatus(API_STATUS_CONSTANT.FAILED));
			});
	}
);

const mappingSlice = createSlice({
	name: 'mapping',
	initialState: initialMappingState,
	reducers: {
		addGroup: (state, action: PayloadAction<IGroup>) => {
			state.questionnaireGroupList.push(action.payload);
		},
		updateGroupFilters: (
			state,
			action: PayloadAction<{ groupId: string; filters: string }>
		) => {
			const filters = JSON.parse(action.payload.filters);
			const groupId = action.payload.groupId;
			state.questionnaireGroupList.map((group) => {
				if (group.groupId === groupId) {
					const updated_group = { ...group, filters };
					return updated_group;
				} else {
					return group;
				}
			});
		},
		updateGroupList: (state, action) => {
			state.questionnaireGroupList = action.payload;
		},
		setUpdatedTheGroupList: (state, action) => {
			state.isGroupListUpdated = action.payload;
		},
		updateSummitInfo: (state, action) => {
			state.summitSurveyList = action.payload;
		},
		updateGroupListAfterDeletion: (state, action: PayloadAction<IGroup[]>) => {
			const idsToRemove = new Set(action.payload.map((group) => group.groupId));

			state.questionnaireGroupList = state.questionnaireGroupList.filter(
				(group) => !idsToRemove.has(group.groupId)
			);
		},
		updateSaveGroupReq: (state, action: PayloadAction<IGroupSaveReq>) => {
			state.groupSaveRequest = action.payload;
		},
		updateGroup: (state, action) => {
			const savedGroup = action.payload;
			const isCreated =
				state.questionnaireGroupList.filter((group) => {
					return group.groupId === savedGroup.groupId;
				}).length === 0;

			// if created remove the one with id-0 and add this new group
			if (isCreated) {
				state.questionnaireGroupList.push(savedGroup);
			} else {
				const index = state.questionnaireGroupList.findIndex((group) => {
					return group.groupId === savedGroup.groupId;
				});
				if (index > -1) {
					state.questionnaireGroupList[index] = action.payload;
				}
			}
		},
		updateSaveCompleted: (state, action) => {
			state.saveCompleted = action.payload;
		},
		setMappingCreateAPILoadingStatus: (state, action) => {
			state.createAPILoadingStatus = action.payload;
		},
		setMappingCopyAPILoadingStatus: (state, action) => {
			state.copyAPILoadingStatus = action.payload;
		},
		setDeleteAPILoadingStatus: (state, action) => {
			state.deleteAPILoadingStatus = action.payload;
		},
		updateDeleteCompleted: (state, action) => {
			state.deleteCompleted = action.payload;
		},
		clearGroupName: (state, action) => {
			state.clearGroupName = action.payload;
		},
		setAPIErrorType: (state, action) => {
			state.apiError = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(saveGroup.pending, (state) => {
				state.createAPILoadingStatus = 'loading';
			})
			.addCase(saveGroup.rejected, (state) => {
				state.createAPILoadingStatus = 'failed';
			})
			.addCase(deleteGroup.pending, (state) => {
				state.deleteAPILoadingStatus = 'loading';
			})
			.addCase(deleteGroup.rejected, (state) => {
				state.deleteAPILoadingStatus = 'failed';
			});
	},
});

export const groupsList = (state: RootState) =>
	state.mapping.questionnaireGroupList;
export const hasGroupListUpdated = (state: RootState) =>
	state.mapping.isGroupListUpdated;
export const groupSaveRequest = (state: RootState) =>
	state.mapping.groupSaveRequest;
export const saveCompleted = (state: RootState) => state.mapping.saveCompleted;
export const getMappingCreateAPILoadingStatus = (state: RootState) =>
	state.mapping.createAPILoadingStatus;
export const getMappingCopyAPILoadingStatus = (state: RootState) =>
	state.mapping.copyAPILoadingStatus;
export const deleteCompleted = (state: RootState) =>
	state.mapping.deleteCompleted;
export const getDeleteAPILoadingStatus = (state: RootState) =>
	state.mapping.deleteAPILoadingStatus;
export const getSummitSurveyList = (state: RootState) =>
	state.mapping.summitSurveyList;
export const isGroupNameCleared = (state: RootState) =>
	state.mapping.clearGroupName;

export const getAPIErrorType = (state: RootState) => state.mapping.apiError;
export default mappingSlice.reducer;
export const {
	addGroup,
	updateGroupFilters,
	updateGroupList,
	setUpdatedTheGroupList,
	updateSaveGroupReq,
	updateGroup,
	updateSaveCompleted,
	updateGroupListAfterDeletion,
	setMappingCreateAPILoadingStatus,
	setMappingCopyAPILoadingStatus,
	setDeleteAPILoadingStatus,
	updateDeleteCompleted,
	updateSummitInfo,
	clearGroupName,
	setAPIErrorType,
} = mappingSlice.actions;
