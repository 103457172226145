import React from 'react';
import { Box } from '@mui/material';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { QUECONSTANT } from '../../constants/StepConstants';
import QuestionDeletePopup from './QuestionDeletePopup';
import QuestionMultiSelect from './QuestionMultiSelect';
import QuestionMultiOption from './QuestionMultiOption';
import QuestionSingleSelect from './QuestionSingleSelect';
import QuestionDropDownList from './QuestionDropDownList';
import QuestionOpenEnded from './QuestionOpenEnded';

interface QuestionFormProps {
	questionType: string;
	index: number;
	item: ISurveyFormsQuestion;
	onDeleteQue: (index: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion) => void;
	onQueInputChange: (
		inputValue: string,
		questionId: string,
		questionObj?: ISurveyFormsQuestion
	) => void;
}
const QuestionForm: React.FC<QuestionFormProps> = ({
	item,
	questionType,
	index,
	onDeleteQue,
	onQueClone,
	onQueSave,
	onQueInputChange,
}) => {
	const [popupOpen, setPopupOpen] = React.useState(false);
	const handleClosePopup = (e: string) => {
		setPopupOpen(false);
		if (e !== 'close') {
			onDeleteQue(item.questionId);
		}
	};

	const handlerSaveQuestion = (itemQuestion: ISurveyFormsQuestion) => {
		onQueSave(itemQuestion);
	};

	const handleDeleteQue = () => {
		setPopupOpen(true);
	};

	const handleQueClone = (data: ISurveyFormsQuestion) => {
		onQueClone(data);
	};

	const renderInputBasedOnType = () => {
		switch (questionType) {
			case QUECONSTANT.QUESTION_TYPE_YES_NO:
				return (
					<QuestionSingleSelect
						onQueSave={handlerSaveQuestion}
						onQueClone={handleQueClone}
						item={item}
						index={index}
						onDelete={handleDeleteQue}
						onQueInputChange={onQueInputChange}
					></QuestionSingleSelect>
				);
			case QUECONSTANT.QUESTION_TYPE_MULTI_SELECT:
				return (
					<QuestionMultiSelect
						onQueSave={handlerSaveQuestion}
						onQueClone={handleQueClone}
						item={item}
						index={index}
						onDelete={handleDeleteQue}
						onQueInputChange={onQueInputChange}
					></QuestionMultiSelect>
				);
			case QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION:
				return (
					<QuestionMultiOption
						onQueSave={handlerSaveQuestion}
						onQueClone={handleQueClone}
						item={item}
						index={index}
						onDelete={handleDeleteQue}
						onQueInputChange={onQueInputChange}
					></QuestionMultiOption>
				);
			case QUECONSTANT.QUESTION_TYPE_DROPDOWN:
				return (
					<QuestionDropDownList
						onQueSave={handlerSaveQuestion}
						onQueClone={handleQueClone}
						item={item}
						index={index}
						onDelete={handleDeleteQue}
						onQueInputChange={onQueInputChange}
					></QuestionDropDownList>
				);
			case QUECONSTANT.QUESTION_TYPE_OPEN:
				return (
					<QuestionOpenEnded
						onQueSave={handlerSaveQuestion}
						onQueClone={handleQueClone}
						item={item}
						index={index}
						onDelete={handleDeleteQue}
						onQueInputChange={onQueInputChange}
					></QuestionOpenEnded>
				);
			default:
				return null;
		}
	};

	return (
		<Box data-testid="question-form">
			{renderInputBasedOnType()}
			<QuestionDeletePopup
				open={popupOpen}
				onClose={handleClosePopup}
				index={index}
			/>
		</Box>
	);
};
export default QuestionForm;
