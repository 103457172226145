/* eslint-disable react/prop-types */
import { Box, Button, styled } from '@mui/material';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import AudiencePopup from '../../../components/mapping/AudiencePopup';
import SelectedFilters from '../../../components/SelectedFilters';
import { commonLabels } from '../../../utils/CommonLabels';
import {
	AudienceFilterLabels,
	initialFilterLabelJson,
	initialFilterValue,
} from '../../../utils/SurveyAudience';
import { STEPTheme } from '../../../utils/Theme';
import {
	resetAppliedFilterData,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setSurveyAudienceIncludeClause,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
} from '../../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';

const Reset = styled(Button)({
	width: '123px',
	height: '16px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: 400,
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	marginTop: '62px',
	color: STEPTheme.colors.blue,
	textTransform: 'none',
});

interface ResultFilterProps {
	hasAudienceDataLoaded: boolean;
}

const ResultFilter: React.FC<ResultFilterProps> = ({
	hasAudienceDataLoaded,
}) => {
	const [filterPopupOpen, setFilterPopupOpen] = useState(false);
	const [filtersApplied, setFiltersApplied] = useState(false);
	const dispatch = useAppDispatch();
	const filterRequestPayload = useAppSelector(
		(state) => state.survey.selectedFilterRequestPayload
	);

	useEffect(() => {
		const includeFilters = JSON.parse(filterRequestPayload.includeFilters);
		const excludeFilters = JSON.parse(filterRequestPayload.excludeFilters);
		setFiltersApplied(
			!_.isEqual(includeFilters, initialFilterValue) ||
				!_.isEqual(excludeFilters, initialFilterValue)
		);
	}, [filterRequestPayload]);

	useEffect(() => {
		handleResetAllFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleResetAllFilter = () => {
		dispatch(resetAppliedFilterData([]));
		dispatch(
			setSelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
			})
		);
		dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyAudienceIncludeClause(''));
		dispatch(setSurveyAudienceExcludeClause(''));
		dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
		setFiltersApplied(false);
	};

	const FilterButton = styled(Button)({
		width: '100px',
		height: '24px',
		borderRadius: '8px',
		backgroundColor: STEPTheme.colors.text,
		color: STEPTheme.colors.white,
		fontFamily: 'JohnsonText-Regular',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '16px',
		align: 'center',
		display: 'flex',
		marginLeft: 'auto',
		'&:hover': {
			backgroundColor: STEPTheme.colors.text,
			textDecoration: 'none !important',
			border: `1px solid ${STEPTheme.colors.text} !important`,
			filter: 'blur(1px)',
		},
	});
	const FilterButtonsBox = styled(Box)({
		display: 'flex',
		gap: '10px',
		marginLeft: 'auto',
		marginBottom: 'auto',
	});

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', flex: 'auto' }}>
				<Box sx={{ width: '80%', float: 'left' }}>
					<SelectedFilters isVertical={true} />
				</Box>
				<FilterButtonsBox>
					{filtersApplied && (
						<Reset
							sx={{ padding: '14px 8px', justifyContent: 'end' }}
							data-testid="result-reset-all-button"
							className="mt-0"
							variant="text"
							onClick={handleResetAllFilter}
						>
							{AudienceFilterLabels.resetAll}
						</Reset>
					)}
					<FilterButton
						variant="outlined"
						color="secondary"
						onClick={() => {
							setFilterPopupOpen(true);
						}}
						disabled={!hasAudienceDataLoaded}
					>
						{commonLabels.filters}
					</FilterButton>
				</FilterButtonsBox>
			</Box>
			<Box>
				{filterPopupOpen && (
					<AudiencePopup
						data-testid="audience-popup"
						closePopup={() => {
							setFilterPopupOpen(false);
						}}
					/>
				)}
			</Box>
		</Box>
	);
};

export default ResultFilter;
