import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/Store';

interface Questionnaire {
	instructions: string;
	checkboxState: Record<string, boolean>;
	questionId: string;
	fileName: Record<number, string>;
	totalOptionsOfDropDownQuestion: Record<string, number>;
	index: number;
	error: Record<string, boolean>;
	helperText: Record<string, string>;
	resetInputValues: boolean;
	resetSorting: boolean;
}

const initialState: Questionnaire = {
	instructions: '',
	checkboxState: {},
	questionId: '',
	fileName: {},
	totalOptionsOfDropDownQuestion: {},
	index: 0,
	error: {},
	helperText: {},
	resetInputValues: false,
	resetSorting: false,
};

const QuestionnaireSurveySlice = createSlice({
	name: 'QuestionnaireSurvey',
	initialState,
	reducers: {
		setInstructions: (state, action: PayloadAction<string>) => {
			state.instructions = action.payload;
		},
		setCheckboxState: (
			state,
			action: PayloadAction<{ questionId: string; value: boolean }>
		) => {
			const { questionId, value } = action.payload;
			state.checkboxState[questionId] = value;
		},
		deleteQuestion: (state, action: PayloadAction<number>) => {
			const index = action.payload;
			delete state.checkboxState[index];
		},
		setPageIndex: (state, action: PayloadAction<number>) => {
			state.index = action.payload;
		},
		setFileName: (
			state,
			action: PayloadAction<{ index: number; name: string }>
		) => {
			const { index, name } = action.payload;
			state.fileName[index] = name;
		},
		setDropDownOptionsCount: (
			state,
			action: PayloadAction<{ questionId: string; length: number }>
		) => {
			const { questionId, length } = action.payload;
			state.totalOptionsOfDropDownQuestion[questionId] = length;
		},
		setError: (
			state,
			action: PayloadAction<{ filterType: string; error: boolean }>
		) => {
			const { filterType, error } = action.payload;
			state.error[filterType] = error;
		},
		setHelperText: (
			state,
			action: PayloadAction<{ filterType: string; helperText: string }>
		) => {
			const { filterType, helperText } = action.payload;
			state.helperText[filterType] = helperText;
		},
		toggleResetInputValues: (state) => {
			state.resetInputValues = !state.resetInputValues;
		},
		/**The reducer method can be used in all the table when
		 * filter reset, reset the sorting */
		resetTableSorting: (state, action: PayloadAction<boolean>) => {
			state.resetSorting = action.payload;
		},
	},
});

export const getCheckBoxState = (questionId: string) => (state: RootState) =>
	state.Questionnaire.checkboxState[questionId] || false;

export const getResetTableSortingState = (state: RootState) =>
	state.Questionnaire.resetSorting;

export const {
	setInstructions,
	setCheckboxState,
	deleteQuestion,
	setPageIndex,
	setFileName,
	setDropDownOptionsCount,
	setError,
	setHelperText,
	toggleResetInputValues,
	resetTableSorting,
} = QuestionnaireSurveySlice.actions;

export default QuestionnaireSurveySlice.reducer;
