import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Box,
	Typography,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import AudienceFilterPopup from '../AudienceFilterPopup';
import { STEPTheme } from '../../utils/Theme';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	updateAudienceFilterListingData,
	appliedFilterData,
	selectedFilterRequestPayloadData,
	selectedAudienceCount,
	setSelectedFilterRequestPayload,
	audienceFilterAPILoadingStatus,
	updateSurveyQuestionnaire,
	surveyQuestionnaireData,
	resetAppliedFilterData,
	setSurveyAudienceCount,
	setSurveyAudienceBlobName,
	setSurveyExAudienceBlobName,
	setActivateApplyFilter,
	setSurveyAudienceIncludeClause,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
	dummySelectedFilterRequestPayloadData,
	getSurveyAudienceIncludeFilterData,
	getSurveyAudienceExcludedFilterData,
	getAudienceFilterData,
} from '../../redux/SurveySlice';
import SelectedFilters from '../SelectedFilters';
import {
	IAppliedFilterArray,
	IUsersDataItems,
	ManageFilterProps,
} from '../../types/CommonTypes';
import { Numbers, QUE_JOURNEY_STEPS } from '../../utils/Enum';
import {
	AudienceFilterLabels,
	initialFilterValue,
	initialFilterLabelJson,
} from '../../utils/SurveyAudience';
import StyledTooltip from '../StyledTooltip';
import { commonLabels } from '../../utils/CommonLabels';
import { peopleLeaderValidation } from '../../utils/CommonMethods';
import FilterAudienceSelectionTable from './FilterAudienceSelectionTable';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
		borderRadius: '8px',
	},
}));
const SelectFilterContainer = styled(Box)({
	width: '80%',
	padding: '20px 40px',
	background: '#fff',
	margin: 'auto',
	marginBottom: '20px',
	borderRadius: '16px',
	boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
	minHeight: '300px',
});

const FilterBoxHeader = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: '20px',
});
const SelectFilterDialogContainer = styled(Box)({
	display: 'flex',
	minHeight: '300px',
	alignItems: 'center',
	justifyContent: 'center',
});
const SelectFilterText = styled(Typography)({
	fontStyle: 'italic',
	color: STEPTheme.colors.darkGray,
});
const TotalAudienceCountText = styled(Typography)({
	color: STEPTheme.colors.darkBlue,
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '10px',
	fontSize: '18px',
	fontWeight: '700',
	lineHeight: '24px',
});

const CaptionText = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.darkGray,
});
const StyledTypography = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '700',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	marginTop: '40px',
	color: STEPTheme.colors.text,
});
const StyledButton = styled(Button)({
	width: '224px',
	height: '32px',
	padding: '8px 16px',
	borderRadius: '8px',
	gap: '4px',
});

const Reset = styled(Button)({
	width: '123px',
	height: '16px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: 400,
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	marginTop: '62px',
	color: STEPTheme.colors.blue,
	textTransform: 'none',
});

const ManageFilters: React.FC<ManageFilterProps> = ({
	onHandleClose,
	onHandleOpen,
	onPageChange,
}) => {
	const [open, setOpen] = useState(false);
	const [includeFilterErrorMessage, setIncludeFilterErrorMessage] = useState<
		string | null
	>(null);
	const [excludeFilterErrorMessage, setExcludeFilterErrorMessage] = useState<
		string | null
	>(null);

	const [paginationState, setPaginationState] = useState({
		pageNumber: Numbers.one,
		pageSize: Numbers.ten,
	});
	const dispatch = useAppDispatch();
	const audienceUserResponse: IUsersDataItems[] =
		useAppSelector(appliedFilterData);
	const filterRequestPayload = useAppSelector(
		selectedFilterRequestPayloadData
	) as IAppliedFilterArray;
	const dummyFilterRequestPayload = useAppSelector(
		dummySelectedFilterRequestPayloadData
	) as IAppliedFilterArray;
	const isApiLoaded = useAppSelector(audienceFilterAPILoadingStatus);
	const selectedSurveyAudienceCount = useAppSelector(selectedAudienceCount);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const surveyAudienceIncludedFilterList = useAppSelector(
		getSurveyAudienceIncludeFilterData
	);
	const surveyAudienceExcludedFilterList = useAppSelector(
		getSurveyAudienceExcludedFilterData
	);
	const surveyAudienceIncludedFilters = Object.entries(
		surveyAudienceIncludedFilterList
	);
	const surveyAudienceExcludedFilters = Object.entries(
		surveyAudienceExcludedFilterList
	);
	const [hasAudienceDataLoaded, setHasAudienceDataLoaded] =
		useState<boolean>(false);

	useEffect(() => {
		if (isApiLoaded !== 'success') {
			setHasAudienceDataLoaded(false);
			isApiLoaded !== 'loading' && dispatch(getAudienceFilterData());
		} else {
			setHasAudienceDataLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isApiLoaded]);

	useEffect(() => {
		dispatch(
			setSelectedFilterRequestPayload({
				...filterRequestPayload,
				pageSize: paginationState.pageSize,
				pageNumber: paginationState.pageNumber,
			})
		);
		//component repeatedly calls setState inside componentWillUpdate or componentDidUpdate
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationState]);

	useEffect(() => {
		if (
			!surveyQuestionnaireInfo?.includeClause?.endsWith('.xlsx') &&
			surveyQuestionnaireInfo?.filterLabelJson !== '{}' &&
			surveyQuestionnaireInfo?.filterLabelJson !== '' &&
			filterRequestPayload?.filterLabelJson !== initialFilterLabelJson &&
			((surveyQuestionnaireInfo?.surveyIncludedAudienceFilter !==
				JSON.stringify(initialFilterValue) &&
				surveyQuestionnaireInfo?.surveyIncludedAudienceFilter !== '') ||
				(surveyQuestionnaireInfo?.surveyExcludedAudienceFilter !==
					JSON.stringify(initialFilterValue) &&
					surveyQuestionnaireInfo?.surveyExcludedAudienceFilter !== ''))
		) {
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					surveyIncludedAudienceFilter: filterRequestPayload.includeFilters,
					surveyExcludedAudienceFilter: filterRequestPayload.excludeFilters,
					currentStep: QUE_JOURNEY_STEPS.STEP_AUDIENCE,
				})
			);
			dispatch(
				updateAudienceFilterListingData({
					...filterRequestPayload,
					questionnaireId:
						surveyQuestionnaireInfo?.surveyFormsDetails?.id?.toString(),
				})
			);
		}
		//a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterRequestPayload]);
	const [disableApplyButton, setDisableApplyButton] = useState(false);
	const [hasIncPeopleLeaderError, setHasIncPeopleLeaderError] = useState(false);
	const [hasExcPeopleLeaderError, setHasExcPeopleLeaderError] = useState(false);
	useEffect(() => {
		if (
			_.isEqual(
				initialFilterValue,
				JSON.parse(dummyFilterRequestPayload.includeFilters)
			) &&
			_.isEqual(
				initialFilterValue,
				JSON.parse(dummyFilterRequestPayload.excludeFilters)
			)
		) {
			setDisableApplyButton(true);
		} else {
			setDisableApplyButton(false);
		}
	}, [dummyFilterRequestPayload]);

	const handleOpen = () => {
		dispatch(setActivateApplyFilter(false));
		setOpen(true);
		onHandleOpen();
	};

	const handleClose = () => {
		setOpen(false);
		onHandleClose();
	};

	const handleAudienceFilterApplied = () => {
		const includedFilters = JSON.parse(
			dummyFilterRequestPayload?.includeFilters
		);
		const excludedFilters = JSON.parse(
			dummyFilterRequestPayload?.excludeFilters
		);
		const hasIncludeFilterError = peopleLeaderValidation(
			includedFilters,
			surveyAudienceIncludedFilters
		).includes(false);
		const hasExcludeFilterError = peopleLeaderValidation(
			excludedFilters,
			surveyAudienceExcludedFilters
		).includes(false);
		setHasIncPeopleLeaderError(hasIncludeFilterError);
		setHasExcPeopleLeaderError(hasExcludeFilterError);
		if (
			!hasIncludeFilterError &&
			!hasExcludeFilterError &&
			!surveyQuestionnaireInfo?.includeClause?.endsWith('.xlsx') &&
			surveyQuestionnaireInfo?.surveyIncludedAudienceFilter !== ''
		) {
			dispatch(setSurveyAudienceBlobName(filterRequestPayload.includeFilters));
			dispatch(
				setSurveyExAudienceBlobName(filterRequestPayload.excludeFilters)
			);
			dispatch(setActivateApplyFilter(true));
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					surveyIncludedAudienceFilter:
						dummyFilterRequestPayload.includeFilters,
					surveyExcludedAudienceFilter:
						dummyFilterRequestPayload.excludeFilters,
					currentStep: QUE_JOURNEY_STEPS.STEP_AUDIENCE,
				})
			);
			dispatch(
				setSelectedFilterRequestPayload({ ...dummyFilterRequestPayload })
			);
			dispatch(
				updateAudienceFilterListingData({
					...dummyFilterRequestPayload,
					pageNumber: Numbers.one,
					questionnaireId:
						surveyQuestionnaireInfo?.surveyFormsDetails?.id?.toString(),
				})
			);
			handleClose();
		}
	};
	const handlePaginationChange = (
		newPaginationState: React.SetStateAction<{
			pageNumber: number;
			pageSize: number;
		}>
	) => {
		setPaginationState(newPaginationState);
		onPageChange('Pagination is set');
	};

	const handleReset = () => {
		dispatch(resetAppliedFilterData([]));
		dispatch(setSurveyAudienceCount(0));
		dispatch(
			setSelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
				pageSize: Numbers.ten,
				pageNumber: Numbers.one,
			})
		);
		dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyAudienceIncludeClause(''));
		dispatch(setSurveyAudienceExcludeClause(''));
		dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
	};

	return (
		<>
			<SelectFilterContainer data-testid="filterContainer">
				<FilterBoxHeader role="heading">
					<Typography variant="h6">{}</Typography>
					<Box>
						{audienceUserResponse?.length > 0 && (
							<Reset
								variant="text"
								className="mt-0 "
								onClick={() => {
									handleReset();
								}}
								data-testid="manage-filter-landing-reset"
							>
								{AudienceFilterLabels.resetAll}
							</Reset>
						)}
						<StyledTooltip
							title={commonLabels.manageFilterTooltip}
							placement="top"
						>
							<Button
								variant="outlined"
								color="secondary"
								component="span"
								onClick={() => handleOpen()}
								disabled={!hasAudienceDataLoaded}
								data-testid="icon-button"
							>
								{AudienceFilterLabels.manageFilters}
							</Button>
						</StyledTooltip>
					</Box>
				</FilterBoxHeader>
				{filterRequestPayload?.includeFilters !== '' &&
				JSON.parse(filterRequestPayload?.includeFilters) !==
					initialFilterValue &&
				filterRequestPayload?.excludeFilters !== '' &&
				JSON.parse(filterRequestPayload?.excludeFilters) !==
					initialFilterValue ? (
					<SelectedFilters isVertical={false} />
				) : (
					<CaptionText>{AudienceFilterLabels.noFiltersSelected}</CaptionText>
				)}
				<StyledTypography>{AudienceFilterLabels.results}</StyledTypography>
				{audienceUserResponse?.length > 0 &&
				surveyQuestionnaireInfo.surveyIncludedAudienceFilter !== '{}' ? (
					<FilterAudienceSelectionTable
						data={audienceUserResponse}
						paginationState={paginationState}
						onPageChange={handlePaginationChange}
						totalCount={
							selectedSurveyAudienceCount ? selectedSurveyAudienceCount : 0
						}
						data-testid="handlePaginationChangeTest"
					/>
				) : (
					<>
						<CaptionText variant="h6" data-testid="noResults">
							{AudienceFilterLabels.noResults}
						</CaptionText>
						<SelectFilterDialogContainer>
							<SelectFilterText variant="h3" data-testid="selectFilter">
								{AudienceFilterLabels.fallbackText}
							</SelectFilterText>
						</SelectFilterDialogContainer>
					</>
				)}
				<TotalAudienceCountText variant="h2">
					{AudienceFilterLabels.totalAudience}: {selectedSurveyAudienceCount}
				</TotalAudienceCountText>
			</SelectFilterContainer>
			<Dialog
				open={open}
				fullWidth={true}
				onClose={() => {
					handleClose();
					onHandleClose();
				}}
				maxWidth="md"
				PaperProps={{
					component: 'form',
					onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
						event.preventDefault();
					},
				}}
				data-testid="dialog"
			>
				<IconButton
					aria-label="close"
					onClick={() => {
						setOpen(false);
						handleClose();
					}}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}
					data-testid="iconButton"
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<AudienceFilterPopup
						hasIncPeopleLeaderError={hasIncPeopleLeaderError}
						hasExcPeopleLeaderError={hasExcPeopleLeaderError}
						includeFilterErrorMessage={includeFilterErrorMessage}
						setIncludeFilterErrorMessage={setIncludeFilterErrorMessage}
						excludeFilterErrorMessage={excludeFilterErrorMessage}
						setExcludeFilterErrorMessage={setExcludeFilterErrorMessage}
					/>
				</DialogContent>
				<DialogActions>
					<BootstrapTooltip
						title={
							disableApplyButton ? AudienceFilterLabels.applyButtonTooltip : ''
						}
						placement="top"
					>
						<span>
							<StyledButton
								variant="contained"
								onClick={handleAudienceFilterApplied}
								data-testid="handleAudienceFilterTest"
								disabled={
									!!includeFilterErrorMessage ||
									!!excludeFilterErrorMessage ||
									disableApplyButton
								}
							>
								{AudienceFilterLabels.apply}
							</StyledButton>
						</span>
					</BootstrapTooltip>
				</DialogActions>
			</Dialog>
		</>
	);
};
export default ManageFilters;
