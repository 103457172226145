import {
	Card,
	CardContent,
	Typography,
	CardActions,
	styled,
	Button,
	Box,
	ThemeProvider,
	BoxProps,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledTooltip from '../StyledTooltip';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { setIsLastScreenEndUser } from '../../redux/SurveySlice';
import { ReactComponent as EndUserLanding } from '../../assets/images/EndUserLanding.svg';
import { commonLabels } from '../../utils/CommonLabels';
import { STEPTheme, stepMuiTheme } from '../../utils/Theme';
import { ReactComponent as ArrowBlack } from '../../assets/images/Arrow-Black.svg';
import { IType } from '../../types/CommonTypes';
import {
	questionnaireData,
	getQuestionnaireData,
	getQuestionnaireDetailsCWAPIStatus,
	getTodoMessage,
} from '../../redux/QuestionnarieUserSlice';
import {
	API_STATUS_CONSTANT,
	BASIC_CONSTANT,
} from '../../constants/StepConstants';
import GenericModalPopup from '../common/GenericModalPopup';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import { addPageViewEventForGA } from '../../utils/CommonMethods';
import { Numbers } from '../../utils/Enum';
import {
	breakPointDown_768,
	breakPointBetween_480_768,
	breakPointBetween_768_1025,
	breakPointBetween_768_1300,
	breakPointDown_339,
	breakPointDown_480,
} from './common/makeStyles';

const StartQuetionaireButton = styled(Button)({
	fontFamily: 'JohnsonDisplay-Regular',
	width: '160px',
	height: '60px',
	color: '#EB1700',
	background: 'white',
	padding: '10px 20px',
	borderRadius: '8px',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: 'bold',
	fontSize: '15px',
	'&:hover': {
		backgroundColor: 'white',
		color: '#EB1700',
	},
	[breakPointBetween_768_1025]: {
		marginTop: '40px',
	},
});
const QuestionnaireName = styled(Box)<BoxProps>({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	color: STEPTheme.colors.white,
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	bottom: '50px',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	[breakPointDown_768]: {
		position: 'relative',
		right: '14.5vw',
		fontSize: '20px',
		lineHeight: '24px',
	},
	[breakPointBetween_480_768]: {
		position: 'relative',
		right: '4.5vw',
		width: '108%',
	},
	[breakPointDown_480]: {
		right: '11.5vw',
		width: '128%',
	},
	[breakPointDown_339]: {
		fontSize: '18px',
		lineHeight: '20px',
	},
	[breakPointBetween_768_1025]: {
		fontSize: '25px',
		lineHeight: '32px',
	},
});
const IconWrapper = styled('div')({
	position: 'relative',
	width: '47px',
	height: '30px',
	resize: 'both',
});
const IconStyle = styled(ArrowBlack)({
	marginRight: '10px',
	cursor: 'pointer',
});
const QuestionnaireNameBox = styled(Box)({
	backgroundColor: STEPTheme.colors.primary,
	padding: '75px 65px 60px 65px',
	overflow: 'hidden',
});
const StyledCard = styled(Card)({
	display: 'block',
	flexDirection: 'column',
	alignItems: 'center',
	marginLeft: 'auto',
	marginRight: 'auto',
	marginBottom: '20%',
	marginTop: '-40px',
	borderRadius: '16px',
	padding: '30px',
	paddingTop: '30px',
	width: '62%',
	height: 'auto',
	justifyContent: 'center',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	[breakPointDown_768]: {
		marginLeft: '9.5%',
		marginBottom: '20%',
		marginTop: '-65px',
		paddingTop: '35px',
		width: '65%',
	},
	[breakPointBetween_480_768]: {
		marginLeft: '13.5%',
	},
	[breakPointBetween_768_1300]: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		width: '70.05vw',
		top: '208px',
		marginLeft: '14.97vw',
		marginRight: '14.97vw',
		padding: '5vh 0vh',
	},
});

const LandingScreenImageContainer = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const LandingScreenCardContent = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
});

const LandingScreen = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id: questionnaireId } = useParams();
	const questionnaireInfo = useAppSelector(questionnaireData);
	const [questionnaireName, setQuestionaaireName] = useState('');
	const [questionaaireDescription, setQuestionaaireDescription] = useState('');
	const [questionnaireType, setQuestionnaireType] = useState('');
	const questionnaireDetailsCWAPIStatus = useAppSelector(
		getQuestionnaireDetailsCWAPIStatus
	);
	const [isGenericModalOpen, setGenericModalOpen] = useState(false);
	const genericModalMsg = useAppSelector(getTodoMessage);
	useEffect(() => {
		const url = new URL(window.location.href);
		const urlArray = url.pathname.split('/');

		addPageViewEventForGA(
			'pageview',
			urlArray.length > Numbers.two
				? `/${urlArray[Numbers.one]}/%`
				: url.pathname,
			questionnaireAPIKeys.EndQuestionaryLandingScreen
		);
	}, []);
	useEffect(() => {
		const endUserReq: IType = {
			id: questionnaireId ? questionnaireId : '',
		};
		dispatch(getQuestionnaireData(endUserReq));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setQuestionaaireName(questionnaireInfo?.data?.questionnaireName);
		setQuestionaaireDescription(
			questionnaireInfo?.data?.questionnaireDescription
		);
		setQuestionnaireType(questionnaireInfo?.data?.questionnaireType);
	}, [questionnaireInfo]);
	useEffect(() => {
		if (
			questionnaireDetailsCWAPIStatus ===
			API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
		) {
			setGenericModalOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireDetailsCWAPIStatus]);
	const goToHome = () => {
		navigate('/');
		navigate(0);
	};
	const handleRedirection = () => {
		if (questionnaireType === BASIC_CONSTANT.SINGLE_PAGE) {
			navigate(`/cwuser/${questionnaireId}`);
		} else {
			navigate(`/end-user/multi-page-questionnaire/${questionnaireId}`);
		}
	};

	return (
		<ThemeProvider theme={stepMuiTheme}>
			<Box data-testid="landing-screen">
				<QuestionnaireNameBox>
					<QuestionnaireName data-testid="questionnaire-name">
						<StyledTooltip
							title={commonLabels.backArrowTooltip}
							placement="top"
						>
							<IconWrapper>
								<IconStyle
									onClick={() => {
										dispatch(setIsLastScreenEndUser(true));
										navigate('/');
									}}
								/>
							</IconWrapper>
						</StyledTooltip>

						{questionnaireName}
					</QuestionnaireName>
				</QuestionnaireNameBox>
				<StyledCard raised={true}>
					<LandingScreenImageContainer>
						<EndUserLanding />
					</LandingScreenImageContainer>
					<LandingScreenCardContent>
						<CardContent>
							<Typography
								sx={{
									overflowWrap: 'break-word',
									wordBreak: 'break-word',
								}}
								variant="body1"
								color="text.secondary"
							>
								{questionaaireDescription}
							</Typography>
						</CardContent>
						<CardActions>
							<StartQuetionaireButton
								variant="contained"
								sx={{ boxShadow: `0px 0px 8px 0px ${STEPTheme.colors.shadow}` }}
								color="primary"
								onClick={handleRedirection}
								data-testid="start-questionnaire-button"
							>
								{commonLabels.startQuestionnaire}
							</StartQuetionaireButton>
						</CardActions>
					</LandingScreenCardContent>
				</StyledCard>
				{isGenericModalOpen && (
					<GenericModalPopup
						openProp={true}
						msgBody={genericModalMsg}
						mainMsg=""
						rightBtnText={'Go To Home'}
						leftBtnText={''}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={goToHome}
						leftBtnHandler={() => {}}
						hideCloseBtn={true}
					/>
				)}
			</Box>
		</ThemeProvider>
	);
};

export default LandingScreen;
