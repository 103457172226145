import React, { useState } from 'react';
import {
	Box,
	Stack,
	Typography,
	styled,
	Button,
	MenuItem,
	InputLabel,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { STEPTheme } from '../../utils/Theme';
import { ReactComponent as DropDownIcon } from '../../assets/images/DropDownIcon.svg';
import { commonLabels } from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';
import {
	keyMetricsAPIStatus,
	keyMetricsSummitAPIStatus,
} from '../../redux/QuestionnaireResultsSlice';
import { useAppSelector } from '../../app/Hooks';
import { determineKeyMetricsTooltip } from '../../utils/QuestionnaireResult';
import KeyMetricsPercentageAvatar from './KeyMetricsPercentageAvatar';
import LoadingIcon from './LoadingIcon';

const StyledMenuItem = styled(MenuItem)({
	fontFamily: '"JohnsonText-Bold"',
	fontSize: '14px',
});

const MetricsContainer = styled(Box)({
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	background: STEPTheme.colors.white,
	display: 'flex',
	width: '30%',
	borderRadius: '8px',
	padding: '20px 10px',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '24px',
});

const TextStack = styled(Stack)({
	alignItems: 'baseline',
	minWidth: '140px',
});

const SecondLineTextWrapper = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	gap: '10px',
});

const DaySelectionContainer = styled(Box)({
	display: 'inline-block',
	padding: 0,
});

const ViewEmployeesButton = styled(Button)({
	right: '7px',
	padding: 0,
	color: 'grey',
	fontSize: '10px',
	lineHeight: '16px',
});

const SelectStyles = {
	textDecoration: 'none',
	padding: 0,
	height: '14px',
	textTransform: 'uppercase',
	fontSize: '14px',
	'& .MuiSelect-select': {
		paddingTop: '8px',
		paddingBottom: '8px',
		fontFamily: '"JohnsonText-Medium"',
		color: STEPTheme.colors.lightRed,
	},
	'& .MuiInputBase-input': {
		fontFamily: '"JohnsonText-Bold"',
		fontSize: '14px',
		fontWeight: 900,
		padding: 0,
	},
	'& .MuiSelect-icon': {
		marginTop: '3px',
	},
};

const LoaderContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	fontFamily: 'JohnsonText-Medium',
	fontSize: '16px',
	fontWeight: Numbers.sevenHundred,
	lineHeight: '20.64px',
	textAlign: 'center',
});
interface ChartSummaryProps {
	percentage: string;
	avatarDiameterInPixel: string;
	avatarColor: string;
	metricsType: string;
	metricsLabelLineOne: string;
	metricsLabelLineTwo?: string;
	triggerSelectedDays: (selectedDay: number, metricsType: string) => void;
	daysNonCompliant: number;
	daysComingDue: number;
	filterViewEmployees: (metricsType: string) => void;
}
export const KeyMetricsItem: React.FC<ChartSummaryProps> = ({
	percentage,
	avatarDiameterInPixel,
	avatarColor,
	metricsType,
	metricsLabelLineOne,
	metricsLabelLineTwo,
	triggerSelectedDays,
	daysNonCompliant,
	daysComingDue,
	filterViewEmployees,
}) => {
	const keyMetricesAPIStatus = useAppSelector(keyMetricsAPIStatus);
	const keyMetricesSummitAPIStatus = useAppSelector(keyMetricsSummitAPIStatus);

	const [selectedDaysNonCompliant, setSelectedDaysNonCompliant] = useState(
		daysNonCompliant ? daysNonCompliant?.toString() : Numbers.zero.toString()
	);
	const [selectedDaysComingDue, setSelectedDaysComingDue] = useState(
		daysComingDue ? daysComingDue?.toString() : Numbers.seven.toString()
	);
	const getSelectedDays = () => {
		if (metricsType === commonLabels.NON_COMPLIANT) {
			return selectedDaysNonCompliant;
		} else {
			return selectedDaysComingDue;
		}
	};

	const handleSelectedDaysChange = (event: SelectChangeEvent) => {
		if (metricsType === commonLabels.NON_COMPLIANT) {
			setSelectedDaysNonCompliant(event.target.value);
		} else {
			setSelectedDaysComingDue(event.target.value);
		}
		triggerSelectedDays(Number(event.target.value), metricsType);
	};

	const getColorOfPrefixText = () => {
		if (
			metricsType === commonLabels.NON_COMPLIANT &&
			selectedDaysNonCompliant === Numbers.zero.toString()
		) {
			return STEPTheme.pieChartColors.pattern13;
		} else if (
			metricsType === commonLabels.RISKOVERDUE &&
			selectedDaysComingDue === Numbers.zero.toString()
		) {
			return STEPTheme.pieChartColors.pattern13;
		} else {
			return STEPTheme.colors.black;
		}
	};
	return (
		<MetricsContainer>
			{keyMetricesAPIStatus === 'loading' ||
			(keyMetricesSummitAPIStatus === 'loading' &&
				metricsType === commonLabels.metricsTypeMappedInSummit) ? (
				<LoaderContainer>
					<LoadingIcon />
					<Box>{commonLabels.dataLoadingTxt}</Box>
				</LoaderContainer>
			) : (
				<KeyMetricsPercentageAvatar
					percentage={percentage}
					tooltipTxt={determineKeyMetricsTooltip(metricsType)}
					diameterInPixel={avatarDiameterInPixel}
					color={avatarColor}
				/>
			)}

			<TextStack>
				<Typography>{metricsLabelLineOne}</Typography>
				<SecondLineTextWrapper>
					<Typography sx={{ color: getColorOfPrefixText() }}>
						{metricsLabelLineTwo}
					</Typography>
					{metricsType !== commonLabels.metricsTypeMappedInSummit && (
						<DaySelectionContainer component="span">
							<InputLabel
								variant="standard"
								htmlFor="uncontrolled-native"
							></InputLabel>
							<Select
								defaultValue={Numbers.seven.toString()}
								IconComponent={DropDownIcon}
								disableUnderline
								value={getSelectedDays()}
								onChange={handleSelectedDaysChange}
								variant="standard"
								MenuProps={{
									PaperProps: {
										style: {
											borderRadius: '12px',
										},
									},
								}}
								sx={SelectStyles}
								data-testid="select-time"
							>
								{metricsType !== commonLabels.RISKOVERDUE && (
									<StyledMenuItem value={Numbers.zero.toString()}>
										{commonLabels.zeroDays}
									</StyledMenuItem>
								)}
								<StyledMenuItem value={Numbers.seven.toString()}>
									{commonLabels.sevenDays}
								</StyledMenuItem>
								<StyledMenuItem value={Numbers.thirty.toString()}>
									{commonLabels.thirtyDays}
								</StyledMenuItem>
								<StyledMenuItem value={Numbers.ninety.toString()}>
									{commonLabels.ninetyDays}
								</StyledMenuItem>
							</Select>
						</DaySelectionContainer>
					)}
				</SecondLineTextWrapper>
				<ViewEmployeesButton
					onClick={() => filterViewEmployees(metricsType)}
					variant="text"
					data-testid="view-employee-button"
				>
					{commonLabels.viewEmployees}
				</ViewEmployeesButton>
			</TextStack>
		</MetricsContainer>
	);
};

export default KeyMetricsItem;
