import React, { useEffect } from 'react';
import {
	Box,
	Checkbox,
	FormControlLabel,
	Radio,
	TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { ISurveyFormsQuestion } from '../../../types/SurveyProps';
import { selectSurveyFormsQuestion } from '../../../redux/SurveySlice';
import { STEPTheme } from '../../../utils/Theme';
import { commonLabels } from '../../../utils/CommonLabels';
import { QUECONSTANT } from '../../../constants/StepConstants';

const MainContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
});

const QuestionnaireContainer = styled(Box)({
	display: 'flex',
	maxWidth: '80%',
	minWidth: '80%',
	backgroundColor: STEPTheme.colors.white,
	flexDirection: 'column',
	marginBottom: '20px',
	boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
	borderRadius: '10px',
	marginTop: '10px',
	gap: '10px',
});

const RightContainer = styled(Box)({
	display: 'flex',
	width: '100%',
	justifyContent: 'flex-end',
});

const LeftContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
});

const QueTypography = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '16px',
	lineHeight: '16px',
	fontWeight: '500',
	marginInline: '30px',
	wordWrap: 'break-word',
	color: '#212121',
});

const QueOptionsTypography = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	marginLeft: '8px',
	fontSize: '14px',
	lineHeight: '16px',
	fontWeight: '500',
	color: '#212121',
});

const QueOptionsConditionTypography = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	marginLeft: '60px',
	fontSize: '14px',
	lineHeight: '16px',
	fontWeight: '500',
	color: '#212121',
});

const QueOptions = styled(Box)({
	display: 'flex',
	alignItems: 'center',
});

const QueOptionsCont = styled(Box)({
	display: 'flex',
	maxHeight: '160px',
	flexDirection: 'column',
	overflowY: 'auto',
});

const QueInstructionCont = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '15px',
	marginTop: '15px',
	wordWrap: 'break-word',
});

const QuestionTypeBox = styled(Box)({
	display: 'flex',
	width: '96px',
	height: '34px',
	borderRadius: '8px',
	borderTopLeftRadius: 0,
	borderBottomRightRadius: 0,
	backgroundColor: STEPTheme.colors.darkGray,
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '10px',
	color: STEPTheme.colors.white,
	flexWrap: 'wrap',
	alignItems: 'center',
	textAlign: 'center',
	paddingLeft: '46px',
	fontWeight: '500',
	lineHeight: '12px',
});

const useStyles = makeStyles({
	radio: {
		'& .MuiSvgIcon-root': {
			width: '1em',
			height: '1em',
		},
		'& .MuiIconButton-root': {
			padding: '8px',
			border: '2px solid black',
			borderRadius: '50%',
			color: 'black',
		},
	},
	optionBox: {
		alignItems: 'center',
		display: 'flex',
	},
});

const PreviewQuestionnaireBox: React.FC<unknown> = () => {
	const classes = useStyles();
	const surveyFormsQuestion = useSelector(selectSurveyFormsQuestion);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<MainContainer data-testid="preview-main-container">
			{surveyFormsQuestion.map(
				(question: ISurveyFormsQuestion, index: number) => (
					<QuestionnaireContainer key={index}>
						<RightContainer>
							<QuestionTypeBox>
								{commonLabels[question.questionType] || question.questionType}
							</QuestionTypeBox>
						</RightContainer>
						<LeftContainer>
							<QueTypography>
								{index + 1}. {question.question}
							</QueTypography>
							<ul style={{ listStyleType: 'none' }}>
								<QueOptionsCont>
									{question.options?.map((option, optionIndex) => (
										<QueOptions key={optionIndex}>
											<li>
												{question.questionType ===
													QUECONSTANT.QUESTION_TYPE_YES_NO && (
													<FormControlLabel
														control={<Radio className={classes.radio} />}
														label={
															<Box className={classes.optionBox}>
																<QueOptionsTypography>
																	{option.options}
																</QueOptionsTypography>
																{option.condition && (
																	<>
																		<QueOptionsConditionTypography>
																			{`${commonLabels.condition}: ${option.condition}`}
																		</QueOptionsConditionTypography>
																	</>
																)}
															</Box>
														}
														disabled
													/>
												)}
												{question.questionType ===
													QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION && (
													<FormControlLabel
														control={<Radio className={classes.radio} />}
														label={
															<Box className={classes.optionBox}>
																<QueOptionsTypography>
																	{option.options}
																</QueOptionsTypography>
																{option.condition && (
																	<>
																		<QueOptionsConditionTypography>
																			{`${commonLabels.condition}: ${option.condition}`}
																		</QueOptionsConditionTypography>
																	</>
																)}
															</Box>
														}
														disabled
													/>
												)}
												{question.questionType ===
													QUECONSTANT.QUESTION_TYPE_DROPDOWN && (
													<FormControlLabel
														control={<Radio className={classes.radio} />}
														label={
															<Box className={classes.optionBox}>
																<QueOptionsTypography>
																	{option.options}
																</QueOptionsTypography>
																{option.condition && (
																	<>
																		<QueOptionsConditionTypography>
																			{`${commonLabels.condition}: ${option.condition}`}
																		</QueOptionsConditionTypography>
																	</>
																)}
															</Box>
														}
														disabled
													/>
												)}
												{question.questionType ===
													QUECONSTANT.QUESTION_TYPE_MULTI_SELECT && (
													<FormControlLabel
														control={<Checkbox className={classes.radio} />}
														label={
															<Box className={classes.optionBox}>
																<QueOptionsTypography>
																	{option.options}
																</QueOptionsTypography>
																{option.condition && (
																	<>
																		<QueOptionsConditionTypography>
																			{`${commonLabels.condition}: ${option.condition}`}
																		</QueOptionsConditionTypography>
																	</>
																)}
															</Box>
														}
														disabled
													/>
												)}
												{question.questionType ===
													QUECONSTANT.QUESTION_TYPE_OPEN && (
													<FormControlLabel
														control={<TextField variant="standard" disabled />}
														label={
															<Box
																sx={{ marginTop: '16px' }}
																className={classes.optionBox}
															>
																<QueOptionsTypography></QueOptionsTypography>
																{option.condition && (
																	<>
																		<QueOptionsConditionTypography>
																			{`${commonLabels.condition}: ${option.condition}`}
																		</QueOptionsConditionTypography>
																	</>
																)}
															</Box>
														}
														disabled
													/>
												)}
											</li>
										</QueOptions>
									))}
									<QueInstructionCont>
										{question.instructions}
									</QueInstructionCont>
								</QueOptionsCont>
							</ul>
						</LeftContainer>
					</QuestionnaireContainer>
				)
			)}
		</MainContainer>
	);
};

export default PreviewQuestionnaireBox;
