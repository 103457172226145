import axios from 'axios';
import {
	InteractionRequiredAuthError,
	PublicClientApplication,
} from '@azure/msal-browser';
import { RESPONSE_CODE } from '../../constants/StepConstants';
import { showRedirectPopUp, showErrorPopUp } from '../../App';
import {
	getErrorMessage,
	setGlobalApiFailure,
} from '../../middleware/ErrorHandler';
import { getAuthDetails } from './authToken';
import { loginRequest, msalConfig } from './AuthConfig';

/* axiosIntercept
 * Set Base URL globaly
 * Set Header globaly
 */
const axiosIntercept = axios.create({
	baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
	headers: {
		'Content-Type': 'application/json',
	},
});

const getToken = async () => {
	const instance = new PublicClientApplication(msalConfig);
	// Initialize the instance
	await instance.initialize();
	let account = instance.getAllAccounts()[0];
	const config = {
		scopes: loginRequest.scopes,
		authority: msalConfig.auth.authority,
		account: account,
	};
	const redRequest = {
		scopes: loginRequest.scopes,
		loginHint: account?.username,
	};
	return instance
		.acquireTokenSilent(config)
		.then(
			(res) => {
				return res;
			},
			(err) => {
				if (err instanceof InteractionRequiredAuthError) {
					return instance.acquireTokenRedirect(redRequest);
				}
			}
		)
		.catch((error) => {
			if (error instanceof InteractionRequiredAuthError) {
				return instance.acquireTokenRedirect(redRequest);
			}
		});
};

axiosIntercept.interceptors.request.use(
	(config) => {
		const token = getAuthDetails();
		if (config?.url === 'msal') {
			config.url = `${process.env.REACT_APP_MSAL_ENDPOINT}`;
		} else if (!config?.url?.toLocaleLowerCase()?.includes('static')) {
			config.url = config.baseURL + config.url;
		} else {
			/* empty */
		}

		/// Setting token in header
		if (token?.idToken) {
			// request for silent token before calling the API's
			return getToken()
				.then(() => {
					const newToken = getAuthDetails();
					return {
						...config,
						headers: {
							...(newToken !== null && {
								Authorization: `Bearer ${newToken.idToken}`,
							}),
							...config.headers,
						},
					};
				})
				.catch(() => {
					// could not get a new token- use old token; response will handle if token expired
					return {
						...config,
						headers: {
							...(token !== null && {
								Authorization: `Bearer ${token.idToken}`,
							}),
							...config.headers,
						},
					};
				});
		} else {
			// token was not found- session left idle
			showRedirectPopUp();
		}
	},
	(error) => Promise.reject(error)
);

axiosIntercept.interceptors.response.use(
	(response) => {
		setGlobalApiFailure(false);
		return response;
	},
	(error) => {
		const status = error?.response?.status;
		const customCode = error?.response?.data?.code;
		const errorMessage = getErrorMessage(
			status,
			customCode,
			error.response.data.message
		);

		// Check if the error handling should be skipped
		const skipGlobalErrorHandling = error.config.skipGlobalErrorHandling;
		if (
			status === RESPONSE_CODE.HTTP_STATUS_UNAUTHORIZED ||
			customCode?.toLowerCase()?.includes('token expired')
		) {
			showRedirectPopUp();
			setGlobalApiFailure(true);
			return Promise.reject(error);
		}
		if (!skipGlobalErrorHandling && errorMessage !== '') {
			// Handling the other HTTP Errors
			showErrorPopUp(errorMessage);
			setGlobalApiFailure(true);
		}
		return Promise.reject(error);
	}
);

export default axiosIntercept;
