import { makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { Box, Button, Radio } from '@mui/material';

export const UseStyles = makeStyles({
	submitHide: {
		display: 'none',
	},
	titleInput: {
		flex: '1 0 33%',
	},
	errorMsg: {
		flexBasis: '100%',
		margin: '0',
		paddingLeft: '15px',
	},
	errorOptionMsg: {
		margin: '0',
	},
	formControlOuter: {
		marginTop: '0',
		width: '100%',
		display: 'flex',
		'&.MuiFormControl-root': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
	formControlInputCustom: {
		'&.MuiFormControl-root': {
			width: '80%',
		},
	},
	formControlInputBox: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControlInputIns: {
		'&.MuiFormControl-root': {
			width: '100%',
			marginLeft: '15px',
		},
	},
	formControlSelectCustom: {
		width: '34%',
	},
	formControlMultiOuter: {
		marginTop: '0',
		width: '100%',
		display: 'flex',
		'&.MuiFormControl-root': {
			flexDirection: 'row',
			alignItems: 'center',
		},
	},
});

export const QuestionBox = styled(Box)({
	display: 'flex',
	flex: '2 1 auto',
	padding: '10px 10px 10px 20px',
});
export const QuestionTitle = styled(Box)({
	display: 'flex',
	marginTop: '0px',
	alignItems: 'end',
	height: 'auto',
	flexWrap: 'wrap',
	fontWeight: '400',
	fontSize: '16px',
	lineHeight: '24px',
	fontFamily: '"JohnsonText-Regular"',
});

export const RemoveOptionBox = styled(Box)({
	padding: '4% 2% 0 0',
	height: '11px',
	cursor: 'pointer',
	display: 'flex',
});
export const QuestionType = styled(Box)({
	fontFamily: '"JohnsonText-Medium"',
	fontSize: '14px',
	lineHeight: '12px',
	fontWeight: '500',
	paddingLeft: '15px',
	display: 'flex',
	justifyContent: 'space-between',
});
export const QuestionOptions = styled(Box)({
	paddingLeft: '15px',
});
export const QuestionNumber = styled(Box)({
	paddingBottom: '5px',
	paddingRight: '5px',
	flex: '0 1 0',
});
export const OptionRadio = styled(Radio)({
	paddingBottom: '0',
	paddingRight: '3px',
	paddingLeft: '0',
});
export const OptionAddButton = styled(Button)({
	marginTop: '10px',
	marginLeft: '15px',
});
export const slotProps = {
	select: {
		MenuProps: {
			PaperProps: {
				sx: {
					borderRadius: '16px !important',
					width: '55px',
				},
			},
			anchorOrigin: {
				vertical: 'top' as const,
				horizontal: 'center' as const,
			},
			transformOrigin: {
				vertical: 'bottom' as const,
				horizontal: 'center' as const,
			},
		},
	},
};
export const menuPropsStyles = {
	MenuProps: {
		PaperProps: {
			sx: {
				marginTop: '4px',
			},
		},
	},
};
