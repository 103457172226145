import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TabPanelProps } from '../../../types/CommonTypes';
import { IMultipleOptionAnswersComponent } from '../../../types/ResultsType';
import BarChart from '../../questionnaire-results/BarChart';
import ChartSummary from '../../questionnaire-results/ChartSummary';
import CustomInfoChart from '../../common/CustomInfoChart';
import { Numbers } from '../../../utils/Enum';
import { commonLabels } from '../../../utils/CommonLabels';
import { isDataAvailable } from '../../../utils/Helpers';

const MultipleOptionAnswersComponent: React.FC<
	IMultipleOptionAnswersComponent
> = ({
	questionJSONString,
	activeTabValue,
	chartSummary,
	graphData,
	yMax,
	dataDisplayType,
}) => {
	const MultiChartSummary = styled(Box)({
		'.MuiStack-root .MuiBox-root': {
			marginBottom: '4px',
		},
	});
	const windowWidth = window.innerWidth;
	const minBarChartWidth = Numbers.sevenSixty;
	const largerScreenWidth = Numbers.fourteenHundredForty;
	const widthMultiplicationFactor = Numbers.zeroPointSix;

	/**
	 * @description Function to get BarChart width based on window size
	 * @param newWindowWidth
	 * @returns number
	 */

	// Function to get BarChart width based on window size
	const getBarChartWidth = (newWindowWidth: number) => {
		let barChartContainerWidth = minBarChartWidth;
		if (newWindowWidth >= largerScreenWidth) {
			barChartContainerWidth = newWindowWidth * widthMultiplicationFactor;
		}
		return barChartContainerWidth;
	};

	const defaultBarChartWidth = getBarChartWidth(windowWidth);
	const [barChartWidth, setBarChartWidth] = useState(defaultBarChartWidth);

	const CustomTabPanel = (props: TabPanelProps) => {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box>{children}</Box>}
			</div>
		);
	};

	// Event Listener to update bar chart's width dynamically
	window.addEventListener('resize', () => {
		const newWindowWidth = window.innerWidth;
		const newBarChartWidth = getBarChartWidth(newWindowWidth);

		if (newBarChartWidth) {
			setBarChartWidth(newBarChartWidth);
		}
	});

	// Check if all values in chartSummary are 0
	const dataAvailable = isDataAvailable(chartSummary, Numbers.zero);

	return (
		<CustomTabPanel value={activeTabValue} index={activeTabValue}>
			{dataAvailable ? (
				<Box
					sx={{
						padding: '20px 0',
						flexDirection: 'column',
					}}
				>
					<Typography
						variant="h2"
						sx={{
							fontFamily: '"JohnsonText-Regular"',
							fontSize: '14px',
							fontWeight: '400',
							lineHeight: '16px',
							pl: '15px',
						}}
					>
						{`Q${Number(activeTabValue + 1)}. ${questionJSONString?.question}`}
					</Typography>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Box
							id="bar-chart-container"
							sx={{ flex: '0 0 65%' }}
							data-testid="bar-chart"
						>
							<BarChart
								data={graphData}
								width={barChartWidth}
								height={Numbers.twoFifty}
								chartType={dataDisplayType}
								yMax={yMax}
								id="response-bar-chart"
							/>
						</Box>
						<MultiChartSummary
							sx={{
								flex: '0 0 30%',
								minHeight: '240px',
								margin: '15px',
							}}
						>
							<ChartSummary
								className={'flex-col items-start justify-flex gap-0'}
								data={chartSummary}
								chartType={'NA'}
							/>
						</MultiChartSummary>
					</Box>
				</Box>
			) : (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '50vh',
					}}
				>
					<CustomInfoChart
						type="info"
						titleText={commonLabels.noDataFound}
						bodyText={commonLabels.noDataFoundForQuestion}
					/>
				</Box>
			)}
		</CustomTabPanel>
	);
};

const MultipleOptionAnswers = React.memo(MultipleOptionAnswersComponent);
export default MultipleOptionAnswers;
