export const questionnaireAPIKeys: { [key: string]: string } = {
	reportTypeKeyMetrics: 'keyMetrics',
	reportTypeMain: 'result',
	reportSubTypeRegionalGraph: 'regionalGraph',
	reportSubTypeGroupGraph: 'groupGraph',
	reportSubTypeResponseStatus: 'employeesResponseStatus',
	reportSubTypeSummitGroupStatus: 'employeesSummitGroupStatus',
	reportSubTypeDefaulterDetails: 'employeesDefaultersDetails',
	responded: 'responded',
	notResponded: 'not responded',
	defaulter: 'defaulter',
	legal_entity: 'legal_entity',
	manager_wwid: 'manager_wwid',
	work_region: 'work_region',
	work_country: 'work_country',
	job_function: 'job_function',
	job_subfunction: 'job_subfunction',
	people_leader: 'people_leader',
	asc: 'asc',
	desc: 'desc',
	HomeScreenAdmin: 'Home Screen - Admin',
	HomeScreenEndUser: 'Home Screen - End User',
	CreateQuestionnaireDetails: 'Create Questionnaire - DETAILS',
	CreateQuestionnaireAudiance: 'Create Questionnaire - AUDIENCE',
	CreateQuestionnaireQuestions: 'Create Questionnaire - QUESTIONS',
	CreateQuestionnairePreview: 'Create Questionnaire - PREVIEW',
	CreateQuestionnaireNotification: 'Create Questionnaire - NOTIFICATION',
	CreateQuestionnairePublish: 'Create Questionnaire - PUBLISH',
	CreateQuestionnaire: 'Create Questionnaire',
	MappingScreen: 'Mapping Screen',
	ResultScreenListMode: 'Result Screen - List Mode',
	ResultScreenGraphMode: 'Result Screen - Graph Mode',
	EndQuestionaryLandingScreen: 'End Questionnaire landing screen',
	SinglePageQuestionnaire: 'Single-page Questionnaire',
	MultiPageQuestionnaire: 'Multi-page Questionnaire',
	NoAccessScreen: 'No Access Screen',
};
