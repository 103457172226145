import React, { useEffect, SetStateAction, Dispatch } from 'react';
import { orderBy } from 'lodash';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Paper,
	Grid,
	DialogTitle,
	Dialog,
	DialogContent,
	tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavigateFunction, useNavigate } from 'react-router';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { tableHead } from '../../utils/Filter';
import { PageRows, QUE_JOURNEY_STEPS, Numbers } from '../../utils/Enum';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import { ISurveyListingDataItem } from '../../types/CommonTypes';
import { ReactComponent as ArrowCircleAction } from '../../assets/images/Arrow_Circle_Action.svg';
import { ReactComponent as CopyLogo } from '../../assets/images/Copy-logo.svg';
import { ReactComponent as CopyOffLogo } from '../../assets/images/Copy-logo-off.svg';
import { ReactComponent as MailLogo } from '../../assets/images/Mail-logo.svg';
import { ReactComponent as MailOffLogo } from '../../assets/images/Mail-logo-off.svg';
import { ReactComponent as EditLogo } from '../../assets/images/Edit-logo.svg';
import { ReactComponent as EditOffLogo } from '../../assets/images/Edit-logo-off.svg';
import { ReactComponent as ResultsLogo } from '../../assets/images/Result-logo.svg';
import { ReactComponent as ResultsOffLogo } from '../../assets/images/Result-logo-off.svg';
import { ReactComponent as FilterLogo } from '../../assets/images/filter-funnel-02.svg';
import { ReactComponent as DeactivateLogo } from '../../assets/images/Deactivate-logo.svg';
import { ReactComponent as DeleteLogo } from '../../assets/images/Delete.svg';
import { ReactComponent as ActiveFilterLogo } from '../../assets/images/Enablefilter-funnel.svg';
import { ReactComponent as MappingLogo } from '../../assets/images/Mapping.svg';
import { ReactComponent as PreviewLogo } from '../../assets/images/Preview-logo.svg';
import {
	getSurveyData,
	pageIndex,
	surveyModifiedListingData,
	setModifiedSurveyResponse,
	setPageIndex,
	setRowsPerPageValue,
	configurationData,
	questionnaireDataAPILoadingStatus,
	setQuestionnaireDataStatus,
	getQuestionnaireByIdData,
	questionnaireDataByIdAPILoadingStatus,
	setQuestionnaireDataByIdStatus,
	setQuestionnaireStatus,
	updateSurveyQuestionnaire,
	updateUploadedAudienceListingData,
	surveyQuestionnaireData,
	initialState,
	getQuestionnaireDeactivatedData,
	questionnaireDeactivatedStatus,
	getFilterDate,
	setFilterDate,
	CopyQuestionnaireDetail,
	questionnaireCopyDataAPILoadingStatus,
	setQuestionnaireCopyDataStatus,
	setQuestionnaireId,
	questionnaireId,
	deleteDraftQuestionnaireStatus,
	getDeleteDraftQuestionnaireData,
} from '../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import FilterCalender from '../FilterCalender';
import { messages } from '../../utils/Messages';
import FallbackUI from '../common/FallbackUi';
import Loader from '../../components/Loader';
import {
	API_STATUS_CONSTANT,
	SURVEY_STATUS,
} from '../../constants/StepConstants';
import { AppDispatch } from '../../app/Store';
import { URL_CONSTANT } from '../../utils/PathConstants';
import GenericModalPopup from '../common/GenericModalPopup';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import { isCurrentUser } from '../../utils/Helpers';
import StyledTooltip from '../StyledTooltip';
import { STEPTheme } from '../../utils/Theme';
import {
	getListViewResultsRecords,
	resultListViewRecord,
} from '../../redux/QuestionnaireResultsSlice';
import {
	IEmployeeDetailsCollectionData,
	IListViewReq,
} from '../../types/QuestionnaireResultsType';
import { initialFilterValue } from '../../utils/SurveyAudience';
import { globalApiFailure } from '../../middleware/ErrorHandler';
import { slotProps } from '../questions/common/QuestionStyles';
import {
	getResetTableSortingState,
	resetTableSorting,
} from '../../redux/QuestionnaireSurveySlice';
import TableHeader from './common/TableHeader';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: STEPTheme.colors.background,
		color: STEPTheme.colors.black,
	},
	border: 'none',
}));

const StyledTableBody = styled(TableBody)(() => ({
	position: 'relative',
	borderRadius: '16px',
	'&:before': {
		content: '" "',
		position: 'relative',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		background: 'transparent',
		zIndex: Numbers.one,
		borderRadius: '16px',
	},
	'&:after': {
		content: '" "',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		zIndex: Numbers.zero,
		borderRadius: '16px',
		background: 'transparent',
		boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	},
}));

const checkAPICompletion = (
	APICompletionStatus: string,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	dispatch: AppDispatch
) => {
	if (APICompletionStatus === API_STATUS_CONSTANT.FAILED) {
		setLoaderOpen(false);
		dispatch(setQuestionnaireDataStatus(API_STATUS_CONSTANT.IDLE));
	} else if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
		setLoaderOpen(false);
	} else {
		// No code- added for SONAR
	}
};

const checkRow = (
	row: ISurveyListingDataItem,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	surveyAction = 'edit'
) => {
	if (row.id) {
		dispatch(
			getQuestionnaireByIdData({
				id: row.id,
			})
		);
		if (surveyAction === 'edit') {
			dispatch(
				updateUploadedAudienceListingData({
					blobCache: '',
					questionnaireId: row.id,
					pageSize: 10,
					pageNumber: 1,
				})
			);
		} else {
			// No code- added for SONAR
		}
	}
	setLoaderOpen(true);
};
const copyRow = (
	row: ISurveyListingDataItem,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>
) => {
	if (row.id) {
		dispatch(
			CopyQuestionnaireDetail({
				id: row.id,
			})
		);
	}
	setLoaderOpen(true);
};

const checkRowStatus = (row: ISurveyListingDataItem) => {
	return (
		row.status === SURVEY_STATUS.PUBLISHED ||
		row.status === SURVEY_STATUS.ACTIVE
	);
};

const checkRowCompletedStatus = (row: ISurveyListingDataItem) => {
	return (
		row.status === SURVEY_STATUS.COMPLETED ||
		row.status === SURVEY_STATUS.ACTIVE
	);
};

const checkQuestionnaireStatus = (
	QuestionnaireAPIStatus: string,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	navigate: NavigateFunction,
	status: string,
	surveyAction = 'edit'
) => {
	if (QuestionnaireAPIStatus === API_STATUS_CONSTANT.SUCCESS) {
		setLoaderOpen(false);
		if (surveyAction === 'edit') {
			navigate(URL_CONSTANT.EDIT_SURVEY);
		} else if (surveyAction === 'preview') {
			navigate(`${URL_CONSTANT.EDIT_SURVEY}/4`);
		} else {
			// No code- added for SONAR
		}

		dispatch(setQuestionnaireStatus(status));
		dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.IDLE));
	}
	if (QuestionnaireAPIStatus === API_STATUS_CONSTANT.FAILED) {
		dispatch(setQuestionnaireDataByIdStatus(API_STATUS_CONSTANT.IDLE));
		setLoaderOpen(false);
	}
};
const checkQuestionnaireCopyStatus = (
	copyStatus: string,
	dispatch: AppDispatch,
	setLoaderOpen: Dispatch<SetStateAction<boolean>>
) => {
	if (copyStatus === API_STATUS_CONSTANT.SUCCESS) {
		setLoaderOpen(false);
		dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.IDLE));
	}
	if (copyStatus === API_STATUS_CONSTANT.FAILED) {
		dispatch(setQuestionnaireCopyDataStatus(API_STATUS_CONSTANT.IDLE));
		setLoaderOpen(false);
	}
};

const SurveyTable: React.FC<{ type: string }> = (props: { type: string }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const surveyTablePageIndex: number = useAppSelector(pageIndex);
	const resetSortingState: boolean = useAppSelector(getResetTableSortingState);
	const deactivatedStatus: string = useAppSelector(
		questionnaireDeactivatedStatus
	);
	const deleteDraftStatus: string = useAppSelector(
		deleteDraftQuestionnaireStatus
	);
	const selectedQuestionnaireId: string = useAppSelector(questionnaireId);
	const surveyListDataArray: ISurveyListingDataItem[] = useAppSelector(
		surveyModifiedListingData
	);
	const configs = useAppSelector(configurationData);
	const date = useAppSelector(getFilterDate);
	const surveyListCount = surveyListDataArray.length;
	const isAdmin = configs?.rolePermissions?.isAdmin;
	const isLMSAdmin = configs?.rolePermissions?.isLMSAdmin;
	const rowPage = configs?.rows_per_page?.map((i) => Number(i));
	const [page, setPage] = React.useState(surveyTablePageIndex);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		rowPage ? rowPage[0] : PageRows.firstOption
	);
	const [dateCreatedOpen, setDateCreatedOpen] = React.useState(false);
	const [startDateOpen, setStartDateOpen] = React.useState(false);
	const [surveyNameSortObj, setSurveyNameSortObj] = React.useState(' ');
	const [surveyCategorySortObj, setSurveyCategorySortObj] = React.useState(' ');
	const [surveyIdSortObj, setSurveyIdSortObj] = React.useState(' ');
	const [createdByObj, setCreatedByObj] = React.useState(' ');
	const [statusObj, setStatusObj] = React.useState(' ');
	const [dateCreatedObj, setDateCreatedObj] = React.useState(' ');
	const [startDateObj, setStartDateObj] = React.useState(' ');
	const [showLoader, setShowLoader] = React.useState(false);
	const [status, setStatus] = React.useState('');
	const [deactivePopupOpen, setDeactivePopupOpen] = React.useState(false);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const [leftBtn, setLeftBtn] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const [deleteWarningMessage, setDeleteWarningMessage] = React.useState('');
	const [warningPopupOpen, setWarningPopupOpen] = React.useState(false);
	const [deleteWarningPopupOpen, setDeleteWarningPopupOpen] =
		React.useState(false);
	const [deleteConfirmationPopupOpen, setDeleteConfirmationPopupOpen] =
		React.useState(false);
	const [copyPopupOpen, setCopyPopupOpen] = React.useState(false);
	const [copyErrorPopupOpen, setCopyErrorPopupOpen] = React.useState(false);
	// will be used when mapping will be active
	/* eslint-disable @typescript-eslint/no-unused-vars */
	const [surveyAction, setSurveyAction] = React.useState('');

	const [showErrorPopup, setShowErrorPopup] = React.useState(false);
	const [isLoadingData, setIsLoadingData] = React.useState(false);
	const listViewData: IEmployeeDetailsCollectionData =
		useAppSelector(resultListViewRecord);
	const [selectQuestionnaireId, setSelectQuestionnaireId] = React.useState<
		string | undefined
	>(undefined);
	const { t } = useTranslation();

	const createRequestPayload = () => {
		const listRequestFilterPayload: IListViewReq = {
			includeFilter: JSON.stringify({ initialFilterValue }),
			excludeFilter: JSON.stringify({ initialFilterValue }),
			days: Numbers.one,
			pageNumber: Numbers.one,
			pageSize: Numbers.ten,
			metricName: '',
			metricSubType: t('commonLabels.RESPONDED'),
		};
		return listRequestFilterPayload;
	};

	useEffect(() => {
		if (isLoadingData) {
			if (globalApiFailure) {
				// If any API has failed, not showing the no response popup
				setShowErrorPopup(false);
			} else {
				if (listViewData.totalCount > 0) {
					navigate(`/questionnaire-results/${selectQuestionnaireId}`);
				} else {
					setShowErrorPopup(true);
				}
			}
			setIsLoadingData(false);
			setShowLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listViewData, selectedQuestionnaireId]);

	/**This Effect runs when there is reset required for sorting in table */
	useEffect(() => {
		if (resetSortingState) {
			clearAllSorting();
			dispatch(resetTableSorting(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetSortingState]);

	const handleEditSurvey = (row: ISurveyListingDataItem, type = 'edit') => {
		setSurveyAction(type);
		setStatus(row.status);
		checkRow(row, dispatch, setShowLoader);
	};

	// will be used when mapping will be active
	const handleMappingOpen = (row: ISurveyListingDataItem) => {
		if (row?.id !== undefined) {
			navigate(`/mapping/${row.id}`);
		} else {
			return;
		}
	};

	const handleCopy = (row: ISurveyListingDataItem) => {
		copyRow(row, dispatch, setShowLoader);
	};

	useEffect(() => {
		dispatch(
			updateSurveyQuestionnaire({
				...surveyQuestionnaireInfo,
				surveyFormsDetails:
					initialState.surveyQuestionnaireData.surveyFormsDetails,
				surveyFormsAudience:
					initialState.surveyQuestionnaireData.surveyFormsAudience,
				surveyFormsQuestion:
					initialState.surveyQuestionnaireData.surveyFormsQuestion,
				notificationConfig:
					initialState.surveyQuestionnaireData.notificationConfig,
				currentStep: QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS,
			})
		);
		dispatch(
			setFilterDate({
				startDate: '',
				endDate: '',
				createdAtStartDate: '',
				createdAtEndDate: '',
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setShowLoader(true);
		const surveyListingReq = {
			type: props.type,
		};
		dispatch(getSurveyData(surveyListingReq));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDateCreatedOpen(false);
		setStartDateOpen(false);
		setPage(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyListDataArray]);

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		dispatch(setPageIndex(newPage));
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		dispatch(setRowsPerPageValue(rowsPerPage));
		dispatch(setPageIndex(0));
	};

	const handleShowQuestionnaireResults = (id: string | undefined) => {
		if (id !== undefined) {
			const payload = {
				requestFilterPayload: createRequestPayload(),
				questionniareId: id,
			};
			setSelectQuestionnaireId(id);
			setIsLoadingData(true);
			setShowLoader(true);
			dispatch(getListViewResultsRecords(payload));
		}
	};

	const APICompletionStatus = useAppSelector(questionnaireDataAPILoadingStatus);
	useEffect(() => {
		checkAPICompletion(APICompletionStatus, setShowLoader, dispatch);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	const QuestionnaireAPIStatus = useAppSelector(
		questionnaireDataByIdAPILoadingStatus
	);
	const copyStatus = useAppSelector(questionnaireCopyDataAPILoadingStatus);
	useEffect(() => {
		checkQuestionnaireStatus(
			QuestionnaireAPIStatus,
			dispatch,
			setShowLoader,
			navigate,
			status,
			surveyAction
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [QuestionnaireAPIStatus]);

	useEffect(() => {
		checkQuestionnaireCopyStatus(copyStatus, dispatch, setShowLoader);
		updateCopyStatus(copyStatus);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [copyStatus]);
	const HeadText = styled('div')({
		width: 'auto',
		minHeight: '22px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		letterSpacing: '1px',
		textAlign: 'left',
		display: 'flex',
		cursor: 'pointer',
		svg: {
			cursor: 'pointer',
			width: '22px',
			height: '18px',
		},
		'.up-arrow': {
			svg: {
				transform: 'rotate(180deg)',
			},
		},
	});

	const StyledAuthorCell = styled(TableCell)({
		whiteSpace: 'nowrap',
	});

	const StyledDueDateCell = styled(TableCell)({
		whiteSpace: 'nowrap',
	});

	const handleClose = () => {
		setDateCreatedOpen(false);
		setStartDateOpen(false);
		setDeactivePopupOpen(false);
		setWarningPopupOpen(false);
		setDeleteWarningPopupOpen(false);
		setDeleteConfirmationPopupOpen(false);
	};

	const okMethod = () => {
		setCopyPopupOpen(false);
		setCopyErrorPopupOpen(false);
		navigate('/');
		navigate(0);
	};
	const customSort = (
		firstItem: ISurveyListingDataItem,
		secondItem: ISurveyListingDataItem
	): number => {
		const firstItemParts = firstItem.questionnaireId.split('_');
		const secondItemParts = secondItem.questionnaireId.split('_');
		if (firstItemParts[0] < secondItemParts[0]) return -1;
		if (firstItemParts[0] > secondItemParts[0]) return 1;
		if (firstItemParts[1] < secondItemParts[1]) return -1;
		if (firstItemParts[1] > secondItemParts[1]) return 1;
		const firstItemNumber = parseInt(firstItemParts[Numbers.two], 10);
		const secondItemNumber = parseInt(secondItemParts[Numbers.two], 10);
		return firstItemNumber - secondItemNumber;
	};

	const handleClickOpen = (row: ISurveyListingDataItem) => {
		setWarningPopupOpen(true);
		setDeactivePopupOpen(false);
		setLeftBtn(true);
		setMessage(
			`${t('commonLabels.deactivatedWarningMessage')} ${row.questionnaireName} Questionnaire?`
		);
		dispatch(setQuestionnaireId(row?.id as string));
	};

	const handleDeleteDraftConfirmationPopup = (row: ISurveyListingDataItem) => {
		setDeleteWarningPopupOpen(true);
		setDeleteConfirmationPopupOpen(false);
		setLeftBtn(true);
		setDeleteWarningMessage(
			`${t('commonLabels.deleteWarningMessage')} ${row.questionnaireName} ?`
		);
		dispatch(setQuestionnaireId(row?.id as string));
	};

	const handleDeleteDraft = (id: string) => {
		setDeleteWarningPopupOpen(false);
		const surveyDeleteReq = {
			id: id,
		};
		dispatch(getDeleteDraftQuestionnaireData(surveyDeleteReq));
	};

	const handleDeactiveSurvey = (id: string) => {
		setWarningPopupOpen(false);
		const surveyDeactiveReq = {
			id: id,
		};
		dispatch(getQuestionnaireDeactivatedData(surveyDeactiveReq));
	};

	useEffect(() => {
		updateDeactivatePopupVisibility(deactivatedStatus);
	}, [deactivatedStatus]);

	const updateDeactivatePopupVisibility = (currentStatus: string) => {
		if (
			currentStatus === API_STATUS_CONSTANT.SUCCESS ||
			currentStatus === API_STATUS_CONSTANT.FAILED
		) {
			setShowLoader(false);
			setDeactivePopupOpen(true);
		} else {
			setShowLoader(true);
			setDeactivePopupOpen(false);
		}
	};

	useEffect(() => {
		updateDeleteDraftPopupVisibility(deleteDraftStatus);
	}, [deleteDraftStatus]);

	const updateDeleteDraftPopupVisibility = (currentStatus: string) => {
		if (
			currentStatus === API_STATUS_CONSTANT.SUCCESS ||
			currentStatus === API_STATUS_CONSTANT.FAILED
		) {
			setShowLoader(false);
			setDeleteConfirmationPopupOpen(true);
		} else {
			setShowLoader(true);
			setDeleteConfirmationPopupOpen(false);
		}
	};

	const handleRefresh = () => {
		navigate('/');
		navigate(0);
	};

	const updateCopyStatus = (currentCopyStatus: string) => {
		if (currentCopyStatus === API_STATUS_CONSTANT.LOADING) {
			setShowLoader(true);
			setCopyPopupOpen(false);
		} else if (currentCopyStatus === API_STATUS_CONSTANT.SUCCESS) {
			setShowLoader(false);
			setCopyPopupOpen(true);
		} else if (currentCopyStatus === API_STATUS_CONSTANT.FAILED) {
			setShowLoader(false);
			setCopyErrorPopupOpen(true);
		} else {
			setShowLoader(false);
		}
	};

	const sortOperation = (sortField: string, sortOrder: string) => {
		dispatch(
			setModifiedSurveyResponse(
				orderBy(surveyListDataArray, [sortField], [sortOrder as 'asc'])
			)
		);
	};

	const clearAllSorting = () => {
		setSurveyNameSortObj(' ');
		setSurveyCategorySortObj(' ');
		setSurveyIdSortObj(' ');
		setCreatedByObj(' ');
		setStatusObj(' ');
		setDateCreatedObj(' ');
		setStartDateObj(' ');
	};

	const showDeactivatePopup = (msg: string) => {
		if (
			deactivatedStatus === API_STATUS_CONSTANT.SUCCESS ||
			deactivatedStatus === API_STATUS_CONSTANT.FAILED
		) {
			return (
				<GenericModalPopup
					openProp={deactivePopupOpen}
					msgBody={msg}
					mainMsg=""
					rightBtnText={t('commonLabels.ok')}
					leftBtnText={t('commonLabels.cancel')}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleRefresh}
					leftBtnHandler={handleClose}
				/>
			);
		}
	};

	const showDeleteDraftResponsePopup = (msg: string) => {
		if (
			deleteDraftStatus === API_STATUS_CONSTANT.SUCCESS ||
			deleteDraftStatus === API_STATUS_CONSTANT.FAILED
		) {
			return (
				<GenericModalPopup
					openProp={deleteConfirmationPopupOpen}
					msgBody={msg}
					mainMsg=""
					rightBtnText={t('commonLabels.ok')}
					leftBtnText={t('commonLabels.no')}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleRefresh}
					leftBtnHandler={handleClose}
				/>
			);
		}
	};

	const showCopyPopup = (msg: string) => {
		return (
			<GenericModalPopup
				data-testid="copy-popup"
				openProp={true}
				msgBody={msg}
				mainMsg=""
				rightBtnText={t('commonLabels.ok')}
				leftBtnText={t('commonLabels.no')}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={okMethod}
				leftBtnHandler={okMethod}
			/>
		);
	};

	const prepareDuration = (row: { duration: string; dueDate: string }) => {
		return (
			<>
				{Number(row?.duration) > 0
					? `${row?.duration} ${t('commonLabels.days')}`
					: row.dueDate}
			</>
		);
	};

	const editAction = (row: ISurveyListingDataItem) => {
		{
			if (isAdmin) {
				return isAdmin &&
					isCurrentUser(row?.createdBy) &&
					row.status === 'In Draft' ? (
					<>
						<span
							onClick={() => handleEditSurvey(row)}
							data-testid="edit-enabled-logo"
						>
							<EditLogo />
							{t('commonLabels.edit')}
						</span>
						<br />
					</>
				) : (
					<>
						<span className="disabled-action" data-testid="edit-disabled-logo">
							<EditOffLogo />
							{t('commonLabels.edit')}
						</span>
						<br />
					</>
				);
			}
			return <></>;
		}
	};

	const deleteAction = (row: ISurveyListingDataItem) => {
		{
			if (isAdmin) {
				if (
					isCurrentUser(row?.createdBy) &&
					row.status === SURVEY_STATUS.IN_DRAFT
				) {
					return (
						<>
							<span onClick={() => handleDeleteDraftConfirmationPopup(row)}>
								<DeleteLogo />
								{t('commonLabels.delete')}
							</span>
							<br />
						</>
					);
				}
			} else {
				return <></>;
			}
		}
	};

	const previewAction = (row: ISurveyListingDataItem) => {
		if (isAdmin) {
			return (
				<div>
					{(checkRowStatus(row) || row.status === SURVEY_STATUS.COMPLETED) && (
						<>
							<span
								data-testid="preview-logo"
								onClick={() => handleEditSurvey(row, 'preview')}
							>
								<PreviewLogo />
								{t('commonLabels.preview')}
							</span>
							<br />
						</>
					)}
				</div>
			);
		}
		return <></>;
	};

	const copyAction = (row: ISurveyListingDataItem) => {
		if (checkRowStatus(row) || row.status === SURVEY_STATUS.COMPLETED) {
			return (
				<div>
					<span data-testid="copy-logo" onClick={() => handleCopy(row)}>
						<CopyLogo />
						{t('commonLabels.copy')}
					</span>
					<br />
				</div>
			);
		}
		return (
			<div>
				<span className="disabled-action">
					<CopyOffLogo />
					{t('commonLabels.copy')}
				</span>
				<br />
			</div>
		);
	};

	const notificationAction = (row: ISurveyListingDataItem) => {
		if (isAdmin) {
			if (
				isCurrentUser(row?.createdBy) &&
				(checkRowStatus(row) || row.status === SURVEY_STATUS.COMPLETED)
			) {
				return (
					<div>
						<span onClick={() => handleEditSurvey(row)}>
							<MailLogo />
							{t('commonLabels.notification')}
						</span>
						<br />
					</div>
				);
			} else {
				return (
					<div>
						<span className="disabled-action">
							<MailOffLogo />
							{t('commonLabels.notification')}
						</span>
						<br />
					</div>
				);
			}
		}
		return <></>;
	};

	const resultsAction = (row: ISurveyListingDataItem) => {
		return checkRowCompletedStatus(row) ? (
			<>
				<span
					data-testid="results-logo"
					onClick={() => handleShowQuestionnaireResults(row.id)}
				>
					<ResultsLogo />
					{t('commonLabels.results')}
				</span>
				<br />
			</>
		) : (
			<>
				<span className="disabled-action" data-testid="disabled-result">
					<ResultsOffLogo />
					{t('commonLabels.results')}
				</span>
				<br />
			</>
		);
	};

	const deactivateAction = (row: ISurveyListingDataItem) => {
		return isAdmin ? (
			<div>
				{isCurrentUser(row?.createdBy) &&
					(checkRowStatus(row) ||
						row.status === SURVEY_STATUS.ACTIVE ||
						row.status === SURVEY_STATUS.PUBLISHED) && (
						<>
							<span onClick={() => handleClickOpen(row)}>
								<DeactivateLogo />
								{t('commonLabels.deactivate')}
							</span>
							<br />
						</>
					)}
			</div>
		) : (
			<></>
		);
	};

	const mappingAction = (row: ISurveyListingDataItem) => {
		return (isAdmin || isLMSAdmin) && checkRowStatus(row) ? (
			<div>
				{
					<span onClick={() => handleMappingOpen(row)}>
						<MappingLogo />
						{t('commonLabels.mapping')}
					</span>
				}
			</div>
		) : (
			<></>
		);
	};

	const prepareActionTooltip = (row: ISurveyListingDataItem) => {
		return (
			<StyledTooltip
				title={
					<div data-testid="edit">
						{editAction(row)}
						{deleteAction(row)}
						{previewAction(row)}
						{copyAction(row)}
						{notificationAction(row)}
						{resultsAction(row)}
						{deactivateAction(row)}
						{mappingAction(row)}
					</div>
				}
				placement="right-start"
			>
				<ArrowCircleAction data-testid="actionButton" />
			</StyledTooltip>
		);
	};

	const sortDescending = (sortKey: string) => {
		switch (sortKey) {
			case 'name':
				setSurveyNameSortObj(' ');
				break;
			case 'category':
				setSurveyCategorySortObj(' ');
				break;
			case 'id':
				setSurveyIdSortObj(' ');
				break;
			case 'created':
				setCreatedByObj(' ');
				break;
			case 'dateCreated':
				setDateCreatedObj(' ');
				break;
			case 'startDate':
				setStartDateObj(' ');
				break;
			case 'status':
				setStatusObj(' ');
				break;
			default:
				break;
		}
		// Reset to initial state sorted by DateCreated
		dispatch(
			setModifiedSurveyResponse(
				orderBy(surveyListDataArray, ['createdAt'], ['desc'])
			)
		);
	};

	const sortAscending = (sortKey: string) => {
		switch (sortKey) {
			case 'name':
				setSurveyNameSortObj(questionnaireAPIKeys.desc);
				sortOperation('questionnaireName', questionnaireAPIKeys.desc);
				break;
			case 'category':
				setSurveyCategorySortObj(questionnaireAPIKeys.desc);
				sortOperation('location', questionnaireAPIKeys.desc);
				break;
			case 'id':
				setSurveyIdSortObj(questionnaireAPIKeys.desc);
				dispatch(
					setModifiedSurveyResponse(
						[...surveyListDataArray].sort(customSort).reverse()
					)
				);
				break;
			case 'created':
				setCreatedByObj(questionnaireAPIKeys.desc);
				sortOperation('questionnaireAuthor', questionnaireAPIKeys.desc);
				break;
			case 'dateCreated':
				setDateCreatedObj(questionnaireAPIKeys.desc);
				sortOperation('createdAt', questionnaireAPIKeys.desc);
				break;
			case 'startDate':
				setStartDateObj(questionnaireAPIKeys.desc);
				sortOperation('startDate', questionnaireAPIKeys.desc);
				break;
			case 'status':
				setStatusObj(questionnaireAPIKeys.desc);
				sortOperation('status', questionnaireAPIKeys.desc);
				break;
			default:
				break;
		}
	};

	const clearSort = (sortKey: string) => {
		switch (sortKey) {
			case 'name':
				clearAllSorting();
				setSurveyNameSortObj(questionnaireAPIKeys.asc);
				sortOperation('questionnaireName', questionnaireAPIKeys.asc);
				break;
			case 'category':
				clearAllSorting();
				setSurveyCategorySortObj(questionnaireAPIKeys.asc);
				sortOperation('location', questionnaireAPIKeys.asc);
				break;
			case 'id':
				clearAllSorting();
				setSurveyIdSortObj(questionnaireAPIKeys.asc);
				dispatch(
					setModifiedSurveyResponse([...surveyListDataArray].sort(customSort))
				);
				break;
			case 'created':
				clearAllSorting();
				setCreatedByObj(questionnaireAPIKeys.asc);
				sortOperation('questionnaireAuthor', questionnaireAPIKeys.asc);
				break;
			case 'dateCreated':
				clearAllSorting();
				setDateCreatedObj(questionnaireAPIKeys.asc);
				sortOperation('createdAt', questionnaireAPIKeys.asc);
				break;
			case 'startDate':
				clearAllSorting();
				setStartDateObj(questionnaireAPIKeys.asc);
				sortOperation('startDate', questionnaireAPIKeys.asc);
				break;
			case 'status':
				clearAllSorting();
				setStatusObj(questionnaireAPIKeys.asc);
				sortOperation('status', questionnaireAPIKeys.asc);
				break;
			default:
				break;
		}
	};

	const handleSort = (sortKey: string, sortObject: string) => {
		if (sortObject === questionnaireAPIKeys.desc) {
			sortDescending(sortKey);
		} else if (sortObject === questionnaireAPIKeys.asc) {
			sortAscending(sortKey);
		} else {
			clearSort(sortKey);
		}
	};

	return surveyListCount > 0 ? (
		<>
			<Loader isLoading={showLoader} />
			<TableContainer
				component={Paper}
				elevation={0}
				sx={{
					borderRadius: '12px',
					height: '420px',
				}}
			>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<StyledTableCell>
								<TableHeader
									label={t('commonLabels.QUESTIONNAIRE_NAME')}
									sortKey={'name'}
									sortObj={surveyNameSortObj}
									onClick={handleSort}
								/>
							</StyledTableCell>

							<StyledTableCell>
								<TableHeader
									label={t('commonLabels.QUESTIONNAIRE_CATEGORY')}
									sortObj={surveyCategorySortObj}
									sortKey={'category'}
									width="180px"
									onClick={handleSort}
								/>
							</StyledTableCell>
							<StyledTableCell>
								<TableHeader
									label={t('commonLabels.QUESTIONNAIRE_ID')}
									sortObj={surveyIdSortObj}
									sortKey={'id'}
									onClick={handleSort}
								/>
							</StyledTableCell>
							<StyledTableCell>
								<TableHeader
									label={t('commonLabels.CREATE_BY')}
									sortObj={createdByObj}
									sortKey={'created'}
									onClick={handleSort}
								/>
							</StyledTableCell>
							<StyledTableCell>
								<Grid item display="flex">
									<TableHeader
										label={t('commonLabels.CREATED_DATE')}
										sortObj={dateCreatedObj}
										sortKey={'dateCreated'}
										onClick={handleSort}
									/>
									{!dateCreatedOpen &&
									date.createdAtStartDate === '' &&
									date.createdAtEndDate === '' ? (
										<FilterLogo
											data-testid="dateCreatedFilter"
											className="cursor-pointer"
											onClick={() => setDateCreatedOpen(true)}
										/>
									) : (
										<ActiveFilterLogo
											data-testid="dateCreatedFilter"
											className="cursor-pointer"
											onClick={() => setDateCreatedOpen(true)}
										/>
									)}
								</Grid>
							</StyledTableCell>
							<Dialog
								open={dateCreatedOpen}
								onClose={() => setDateCreatedOpen(false)}
								style={{ marginTop: '150px' }}
							>
								<DialogTitle variant="h5">Select Created Date</DialogTitle>
								<IconButton
									data-testid="closeCreatedDate"
									aria-label="close"
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
									}}
								>
									<CloseIcon />
								</IconButton>
								<DialogContent>
									<FilterCalender type={'createdAt'} />
								</DialogContent>
							</Dialog>
							<StyledTooltip
								title={tableHead.tooltips.START_DATE}
								placement="top"
							>
								<StyledTableCell>
									<Grid item display="flex">
										<TableHeader
											label={t('commonLabels.START_DATE')}
											sortObj={startDateObj}
											sortKey={'startDate'}
											onClick={handleSort}
										/>

										{!startDateOpen &&
										date.startDate === '' &&
										date.endDate === '' ? (
											<FilterLogo
												data-testid="startDateFilter"
												className="cursor-pointer"
												onClick={() => setStartDateOpen(true)}
											/>
										) : (
											<ActiveFilterLogo
												data-testid="startDateFilter"
												className="cursor-pointer"
												onClick={() => setStartDateOpen(true)}
											/>
										)}
									</Grid>
								</StyledTableCell>
							</StyledTooltip>
							<Dialog
								open={startDateOpen}
								onClose={() => setStartDateOpen(false)}
								style={{ marginTop: '150px' }}
							>
								<DialogTitle variant="h5">Select Start Date</DialogTitle>
								<IconButton
									data-testid="startDateClose"
									aria-label="close"
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: 8,
										top: 8,
									}}
								>
									<CloseIcon />
								</IconButton>
								<DialogContent>
									<FilterCalender type={'startDate'} />
								</DialogContent>
							</Dialog>
							<StyledTooltip
								title={tableHead.tooltips.DUE_DATE}
								placement="top"
							>
								<StyledTableCell>
									<HeadText>{t('commonLabels.END_DATE')}</HeadText>
								</StyledTableCell>
							</StyledTooltip>
							<StyledTooltip title={tableHead.tooltips.STATUS} placement="top">
								<StyledTableCell>
									<TableHeader
										label={t('commonLabels.STATUS')}
										sortKey={'status'}
										sortObj={statusObj}
										onClick={handleSort}
									/>
								</StyledTableCell>
							</StyledTooltip>
							<StyledTableCell>
								<HeadText> {t('commonLabels.ACTION')}</HeadText>
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<StyledTableBody>
						{surveyListDataArray
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row: ISurveyListingDataItem) => (
								<TableRow
									key={row.questionnaireId}
									hover
									tabIndex={-1}
									sx={{ background: STEPTheme.colors.white }}
								>
									<TableCell className="break-all" component="th" scope="row">
										{row.questionnaireName}
									</TableCell>
									<TableCell>{row.location}</TableCell>
									<TableCell>{row.questionnaireId}</TableCell>
									<StyledAuthorCell>{row.questionnaireAuthor}</StyledAuthorCell>
									<TableCell>{row.createdAt}</TableCell>
									<TableCell>{row.startDate}</TableCell>
									<StyledDueDateCell>{prepareDuration(row)}</StyledDueDateCell>
									<TableCell>{row.status}</TableCell>
									<TableCell sx={{ textAlign: 'center' }}>
										{prepareActionTooltip(row)}
									</TableCell>
								</TableRow>
							))}
					</StyledTableBody>
				</Table>
			</TableContainer>
			{deactivatedStatus === 'success'
				? showDeactivatePopup(t('commonLabels.deactivatedText'))
				: showDeactivatePopup(t('commonLabels.errorMsg'))}
			{deleteDraftStatus === 'success'
				? showDeleteDraftResponsePopup(t('commonLabels.deleteDraftSuccessText'))
				: showDeleteDraftResponsePopup(t('commonLabels.error'))}

			{copyPopupOpen && showCopyPopup(t('commonLabels.copyText'))}
			{copyErrorPopupOpen && showCopyPopup(t('commonLabels.errorMsg'))}

			<GenericConfirmationPopup
				open={warningPopupOpen}
				msgBody={t('commonLabels.deactivateNoteMessage')}
				title={message}
				rightBtnText={t('commonLabels.yes')}
				leftBtnText={t('commonLabels.cancel')}
				isRightBtnVisible={true}
				isLeftBtnVisible={leftBtn}
				rightBtnHandler={() => handleDeactiveSurvey(selectedQuestionnaireId)}
				leftBtnHandler={handleClose}
			/>
			<GenericConfirmationPopup
				open={deleteWarningPopupOpen}
				msgBody={t('commonLabels.deleteNoteMessage')}
				title={deleteWarningMessage}
				rightBtnText={t('commonLabels.yes')}
				leftBtnText={t('commonLabels.no')}
				isRightBtnVisible={true}
				isLeftBtnVisible={leftBtn}
				rightBtnHandler={() => handleDeleteDraft(selectedQuestionnaireId)}
				leftBtnHandler={handleClose}
			/>
			<GenericConfirmationPopup
				open={showErrorPopup}
				title={t('commonLabels.noQuestionnaireResposeTxt')}
				msgBody={t('commonLabels.responseErrorTxt')}
				rightBtnText={t('commonLabels.ok')}
				leftBtnText={t('commonLabels.no')}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={() => setShowErrorPopup(false)}
				leftBtnHandler={() => setShowErrorPopup(false)}
			/>

			<Grid container sx={{ justifyContent: 'flex-end' }}>
				<StyledTooltip
					title={tableHead.tooltips.ROWS_PER_PAGE}
					placement="left"
				>
					<TablePagination
						data-testid="paginationWrapper"
						rowsPerPageOptions={rowPage}
						component="div"
						count={surveyListCount}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						slotProps={slotProps}
					/>
				</StyledTooltip>
			</Grid>
		</>
	) : (
		<>
			<Loader isLoading={showLoader} />
			<FallbackUI fallbackText={messages.common.NOT_QUESTIONNAIRE_FOUND} />
		</>
	);
};
export default SurveyTable;
