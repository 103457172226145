import {
	Box,
	BoxProps,
	Card,
	CardContent,
	Typography,
	styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { setIsLastScreenEndUser } from '../../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import { ReactComponent as ArrowBlack } from '../../../assets/images/Arrow-Black.svg';
import { STEPTheme } from '../../../utils/Theme';
import { commonLabels } from '../../../utils/CommonLabels';
import { URL_CONSTANT } from '../../../utils/PathConstants';
import { IType } from '../../../types/CommonTypes';
import {
	questionnaireData,
	getQuestionnaireData,
} from '../../../redux/QuestionnarieUserSlice';
import {
	breakPointDown_768,
	breakPointBetween_480_768,
	breakPointDown_480,
	breakPointDown_339,
	breakPointBetween_768_1025,
} from '../common/makeStyles';
import HTMLRenderer from './HtmlRenderer';

const QuestionnaireName = styled(Box)<BoxProps>({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	color: STEPTheme.colors.white,
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	bottom: '50px',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	[breakPointDown_768]: {
		position: 'relative',
		right: '14.5vw',
		fontSize: '20px',
		lineHeight: '24px',
	},
	[breakPointBetween_480_768]: {
		position: 'relative',
		right: '4.5vw',
		width: '108%',
	},
	[breakPointDown_480]: {
		right: '11.5vw',
		width: '128%',
	},
	[breakPointDown_339]: {
		fontSize: '18px',
		lineHeight: '20px',
	},
	[breakPointBetween_768_1025]: {
		fontSize: '25px',
		lineHeight: '32px',
	},
});

const IconWrapper = styled('div')({
	position: 'relative',
	width: '47px',
	height: '30px',
	resize: 'both',
});

const IconStyle = styled(ArrowBlack)({
	marginRight: '10px',
	cursor: 'pointer',
});

const QuestionnaireNameBox = styled(Box)({
	backgroundColor: STEPTheme.colors.primary,
	padding: '75px 65px 60px 65px',
	overflow: 'hidden',
});

const StyledCard = styled(Card)({
	position: 'relative',
	width: '80%',
	padding: '2.33%',
	marginLeft: '8%',
	marginBottom: '2%',
	marginTop: '-30px',
	borderRadius: '16px',
	display: 'flex',
	flexDirection: 'column',
	paddingTop: '30px',
	height: 'auto',
	justifyContent: 'center',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
});

const StyledCardContent = styled(CardContent)({
	'&.MuiCardContent-root': {
		padding: '0px',
		paddingBottom: '24px',
	},
	[breakPointBetween_480_768]: {
		'&.MuiCardContent-root': {
			paddingTop: '0px',
		},
	},
	[breakPointDown_480]: {
		'&.MuiCardContent-root': {
			paddingLeft: '15px',
			paddingRight: '10px',
		},
	},
});

const StyledTypographyTitle = styled(Typography)({
	textAlign: 'left',
});

const StyledTypography = styled(Typography)({
	lineHeight: '20px',
	textAlign: 'left',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
});

const Instruction: React.FC<unknown> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id: questionnaireId } = useParams();
	const QuestionnaireInfo = useAppSelector(questionnaireData);
	const [questionnaireName, setQuestionaaireName] = useState('');
	const [instructionDetails, setInstructionDetails] = useState('');
	const [isRespondedByWWID, setIsRespondedByWWID] = useState<string>('False');
	const [isQuestionnaireInfoReceived, setQuestionnaireInfoReceived] =
		useState<boolean>(false);
	useEffect(() => {
		if (!isQuestionnaireInfoReceived) {
			const endUserReq: IType = {
				id: questionnaireId ? questionnaireId : '',
			};
			dispatch(getQuestionnaireData(endUserReq));
			setQuestionnaireInfoReceived(true);
		}
	}, [dispatch, questionnaireId, isQuestionnaireInfoReceived]);

	useEffect(() => {
		setIsRespondedByWWID(QuestionnaireInfo?.data?.isResponsedByWWID);
		setQuestionaaireName(QuestionnaireInfo?.data?.questionnaireName);
		setInstructionDetails(QuestionnaireInfo?.data?.questionnaireInstructions);
	}, [QuestionnaireInfo]);

	const handleBack = () => {
		if (isRespondedByWWID && isRespondedByWWID !== 'False') {
			dispatch(setIsLastScreenEndUser(true));
			navigate(`/`);
		} else {
			navigate(`${URL_CONSTANT.END_USER}/${questionnaireId}`);
		}
	};
	return (
		<Box>
			<QuestionnaireNameBox>
				<QuestionnaireName>
					<IconWrapper>
						<IconStyle onClick={handleBack} />
					</IconWrapper>
					{questionnaireName}
				</QuestionnaireName>
			</QuestionnaireNameBox>
			<StyledCard raised={true}>
				<StyledCardContent>
					<StyledTypographyTitle variant="h1">
						{commonLabels.instructions}
					</StyledTypographyTitle>
					<StyledTypography variant="subtitle1">
						<HTMLRenderer htmlString={instructionDetails} />
					</StyledTypography>
				</StyledCardContent>
			</StyledCard>
		</Box>
	);
};
export default Instruction;
