import React from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { STEPTheme } from '../../utils/Theme';
import { commonLabels } from '../../utils/CommonLabels';
import StyledTooltip from '../StyledTooltip';
import QuestionAddInstructionPop from './QuestionAddInstructionPop';

const useStyles = makeStyles({
	pageTitle: {
		marginBottom: '25px',
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
	},
});
const InstructionButton = styled(Button)({
	width: 'auto',
	height: '32px',
	marginLeft: '10px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.text,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid black`,
	position: 'absolute',
	right: '0',
	top: '0',
	'&:hover': {
		backgroundColor: STEPTheme.colors.text,
		borderColor: STEPTheme.colors.text,
	},
});

const StyledTitleTypography = styled(Box)`
	margin-bottom: 2px;
`;
const QuestionHeader: React.FC<unknown> = () => {
	const classes = useStyles();
	const [popupOpenIns, setPopupOpenIns] = React.useState(false);

	const handleClickOpen = () => {
		setPopupOpenIns(true);
	};

	const handleCloseInsPopup = () => {
		setPopupOpenIns(false);
	};

	return (
		<Box data-testid="question-header">
			<Box className={classes.pageTitle}>
				<StyledTooltip
					title={commonLabels.addQuestionnaireInstructionTooltip}
					placement="top"
				>
					<InstructionButton
						data-testid="instruction-button"
						onClick={handleClickOpen}
					>
						{commonLabels.addQueIns}
					</InstructionButton>
				</StyledTooltip>
				<StyledTitleTypography>
					<Typography variant="h1">{commonLabels.addQuestions}</Typography>
				</StyledTitleTypography>
			</Box>
			<QuestionAddInstructionPop
				open={popupOpenIns}
				onClose={handleCloseInsPopup}
			/>
		</Box>
	);
};

export default QuestionHeader;
