import React from 'react';
import { Stack, Button, styled } from '@mui/material';
import { ButtonsGroupProps } from '../../types/ResultsType';

const ButtonsContainer = styled(Stack)({
	padding: '0px 5px',
	borderRadius: '25px',
	display: 'inline-block',
	margin: '5px',
	background: '#F1EFED',
});

const ButtonsGroup: React.FC<ButtonsGroupProps> = ({
	buttonsList,
	onButtonSelect,
	selectedButton,
}) => {
	const handleButtonClick = (buttonName: string) => {
		onButtonSelect(buttonName);
	};

	return (
		<ButtonsContainer direction="row" spacing={2}>
			{buttonsList.map((buttonName, index) => (
				<Button
					key={index}
					onClick={() => handleButtonClick(buttonName)}
					variant={selectedButton === buttonName ? 'contained' : 'text'}
					color={selectedButton === buttonName ? 'primary' : 'secondary'}
					sx={{ borderRadius: '25px', marginLeft: '0px !important' }}
				>
					{buttonName}
				</Button>
			))}
		</ButtonsContainer>
	);
};

export default ButtonsGroup;
