import { TableType } from '../types/TableTypes';
// filter pane labels
export const filter = {
	label: {
		QUESTIONNAIRE_NAME: 'Questionnaire Name/ID',
		QUESTIONNAIRE_CATEGORY: 'Questionnaire Owning Location',
		CREATE_BY: 'Created By',
		STATUS: 'Status',
		SELECT: 'Select',
		NONE: 'None',
		ENTER_TEXT: 'Enter text',
		APPLY: 'Apply',
		APPLY_FILTER: 'Apply Filter',
		RESET: 'Reset',
		RESET_FILTER: 'Reset Filter',
		Total_Surveys: 'Total Items/Surveys:',
		RESPONSE: 'Response Status',
		TOTAL_NUMBER_QUESTIONNAIRE_RECORDS: 'Total Number of Questionnaire records',
	},
};

export const APIKeys = {
	status: 'status',
	questionnaireName: 'questionnaireName',
	questionnaireId: 'questionnaireId',
	startDate: 'startDate',
	dueDate: 'dueDate',
	questionnaireCode: 'questionnaireCode',
	response: 'response',
};
export const tableHead = {
	tooltips: {
		START_DATE:
			'First date when Questionnaire available for user(s) to respond.',
		DUE_DATE: 'Due date for the questionnaire',
		STATUS:
			'• In Draft: Draft is saved not published\n• Published: Final version published but not available for users to respond.\n• Active: Available for users to respond\n• Completed: De-activated & no longer available for users to respond',
		ROWS_PER_PAGE:
			'•Choose from dropdown options 10,15,20,25,50,100.\n•Click to scroll between pages .',
	},
};

export const surveyCategory = [
	{
		value: 'STEP',
		label: 'STEP',
	},
	{
		value: 'RPK',
		label: 'RPK',
	},
];
export const status = ['InDraft', 'Completed', 'Published', 'Active'];

export const rows: readonly TableType[] = [
	{
		Survay_Name: 'Survay Name',
		Survay_Category: 'STEP',
		Survay_ID: 1234,
		Created_By: 'Abcd',
		Date_Created: 'Date Created',
		Start_Date: 'Start Date',
		End_Date: 'Due Date',
		Status: ' In Draft',
	},
];
export const surveyFormDropDown = {
	label: {
		QUESTIONNAIRE_NAME: 'Questionnaire Name',
		QUESTIONNAIRE_DESCRIPTION: 'Questionnaire Description',
		QUESTIONNAIRE_CATEGORY: ' Questionnaire Owning Location',
		QUESTIONNAIRE_TYPE: 'Questionnaire Type',
		QUESTIONNAIRE_DUE_DATE: 'Questionnaire Due Date',
		QUESTIONNAIRE_START_DATE: 'Questionnaire Start Date',
		COMPLETE_SERVEY_FOR: 'Completing Questionnaire For',
		SELECT: 'Select',
		ENTER_TEXT: 'Enter text',
		APPLY: 'Apply',
		RESET: 'Reset',
		SET_DATE: 'Set Date',
		SET_DURTION: 'Set Duration',
	},
};

export enum manageAudienceFilters {
	people_leader = 'People Leader',
	worker_type = 'Worker Type',
	work_region = 'Work Region',
	work_country = 'Work Country',
	sector = 'Sector',
	mrc_code = 'MRC Code',
	legal_entity = 'Legal Entity',
	job_function = 'Job Function',
	job_subfunction = 'Job Sub Function',
	job_group = 'Job Group',
	employee_function = 'Employee Function',
	manager_wwid = 'Leader WWID/ Name',
	wwid = 'Individual WWID',
}
