import React, { useState, useRef, useEffect } from 'react';
import * as XLSX from 'xlsx'; //NOSONAR
import { Button, Box, Typography, styled } from '@mui/material';
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/uploadIcon.svg';
import { commonLabels } from '../../utils/CommonLabels';
import { isFileTypeValidForExcel } from '../../utils/CommonMethods';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	setSurveyAudienceCount,
	surveyQuestionnaireData,
	updateSurveyQuestionnaire,
	updateAudienceCollectionFilterListingData,
	updateUploadedAudienceListingData,
	resetAppliedFilterData,
	verifiedAudienceWWIDs,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setValidWWIDsResponse,
	invalidAudienceWWIDs,
	setInvalidWWIDsResponse,
} from '../../redux/SurveySlice';
import { ReactComponent as InvalidUserIdIcon } from '../../assets/images/invalid-userIds.svg';
import { QUE_JOURNEY_STEPS, Numbers } from '../../utils/Enum';
import AudienceSelectionTable from './AudienceSelectionTable';
import GenericConfirmationPopup from './GenericConfirmationPopup';

const UploadExcelContainer = styled(Box)({
	width: '80%',
	padding: '20px 40px',
	background: '#fff',
	margin: 'auto',
	marginBottom: '20px',
	borderRadius: '16px',
	boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
});

const UploadBtnContainer = styled(Box)({
	display: 'inline-flex',
	alignItems: 'center',
	gap: '20px',
});
const UploadBoxHeader = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	mb: '20px',
});
const UploadExcelDialogContainer = styled(Box)({
	display: 'flex',
	minHeight: '300px',
	alignItems: 'center',
	justifyContent: 'center',
});
const UploadFromExcelText = styled(Typography)({
	fontStyle: 'italic',
	color: '#81766F',
});
const TotalAudienceCountText = styled(Typography)({
	color: '#004685',
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: '10px',
	fontSize: '18px',
	fontWeight: '700',
	lineHeight: '24px',
});
interface ExcelUploadTableProps {
	onFileUpload: () => void;
	onReset: () => void;
}

const UploadExcel: React.FC<ExcelUploadTableProps> = ({
	onFileUpload,
	onReset,
}) => {
	//Data is inconsistent so disabling here
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [data, setData] = useState<any[][]>([]); //NOSONAR
	const [selectedAudienceWWIDs, setSelectedAudienceWWIDs] = useState<number[]>(
		[]
	);
	const [showInvalidUserPopup, setShowInvalidUserPopup] = useState(false);
	const [fileName, setFileName] = useState<string>('');
	const excelData: Array<[number, string, string]> = [];
	const uploadedWWIDs: number[] = [];
	const excelInputRef = useRef<HTMLInputElement>(null);
	const MAX_AUDIENCE_COLLECTION_LENGTH = 100000;

	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const validWWIDS = useAppSelector(verifiedAudienceWWIDs);
	const invalidWWIDS = useAppSelector(invalidAudienceWWIDs);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (surveyQuestionnaireInfo?.includeClause.endsWith('.xlsx') && !fileName) {
			dispatch(
				updateUploadedAudienceListingData({
					blobCache: '',
					questionnaireId: surveyQuestionnaireInfo?.surveyFormsDetails?.id,
					pageSize: MAX_AUDIENCE_COLLECTION_LENGTH,
					pageNumber: 1,
				})
			);
		}
	}, [
		dispatch,
		fileName,
		surveyQuestionnaireInfo?.includeClause,
		surveyQuestionnaireInfo?.surveyFormsDetails?.id,
	]);

	useEffect(() => {
		if (invalidWWIDS.length > 0) {
			setShowInvalidUserPopup(true);
		} else {
			setShowInvalidUserPopup(false);
		}
	}, [invalidWWIDS]);

	useEffect(() => {
		if (selectedAudienceWWIDs.length === 0) {
			dispatch(
				updateSurveyQuestionnaire({
					...surveyQuestionnaireInfo,
					currentStep: QUE_JOURNEY_STEPS.STEP_AUDIENCE,
					surveyIncludedAudienceFilter: '',
					surveyExcludedAudienceFilter: '',
				})
			);
		}
		//disabling here because component repeatedly calls setState inside componentWillUpdate or componentDidUpdate
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAudienceWWIDs]);

	useEffect(() => {
		if (validWWIDS.length > 0) {
			const formattedData = validWWIDS.map(({ wwid, fullName, workEmail }) => [
				wwid,
				fullName,
				workEmail,
			]);
			setData(formattedData);
		} else {
			setData([]);
		}
	}, [validWWIDS]);

	useEffect(() => {
		if (selectedAudienceWWIDs.length > 0) {
			const collections = selectedAudienceWWIDs.map((collection) =>
				collection.toString()
			);
			dispatch(
				updateAudienceCollectionFilterListingData({ wwids: collections })
			);
		}
	}, [dispatch, selectedAudienceWWIDs]);

	const audienceDataValidator = (row: [number, string, string]) => {
		if (isNaN(row[0])) {
			return false;
		}
		return true;
	};

	const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if (file && isFileTypeValidForExcel(file)) {
			dispatch(resetAppliedFilterData([]));
			dispatch(setSurveyAudienceCount(0));
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: '',
					excludeFilters: '',
					includeClause: '',
					excludeClause: '',
					filterLabelJson: '',
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
			const reader = new FileReader();

			reader.onload = (e: ProgressEvent<FileReader>) => {
				const workbook = XLSX.read(e.target?.result, { type: 'binary' });

				const worksheet = workbook.Sheets[workbook.SheetNames[0]];
				const jsonData: Array<[number, string, string]> =
					XLSX.utils.sheet_to_json(worksheet, {
						header: 1,
					});
				jsonData.forEach((res) => {
					if (audienceDataValidator(res)) {
						excelData.push(res);
						uploadedWWIDs.push(res[0]);
					}
				});
				setData(excelData);
				dispatch(setSurveyAudienceCount(excelData.length));
				setSelectedAudienceWWIDs(uploadedWWIDs);
			};

			if (invalidWWIDS.length > 0) {
				setShowInvalidUserPopup(true);
			}

			reader.readAsBinaryString(file);
			setFileName(file.name);

			if (excelInputRef.current) {
				excelInputRef.current.value = '';
			}

			onFileUpload();
		} else {
			alert('Please enter a correct file format');
		}
	};

	const handleCancelPopup = () => {
		setShowInvalidUserPopup(false);
		handleClearFile();
	};

	const handleClearFile = () => {
		setData([]);
		dispatch(resetAppliedFilterData([]));
		dispatch(setSurveyAudienceCount(0));
		setSelectedAudienceWWIDs([]);
		setSurveyAudienceBlobName('');
		setValidWWIDsResponse([]);
		dispatch(setInvalidWWIDsResponse([]));
		setShowInvalidUserPopup(false);

		setFileName('');
		if (excelInputRef.current) {
			excelInputRef.current.value = '';
		}
		onReset();
	};

	const handleExportTemplate = () => {
		const excelDataArray = [['WWID', 'Name', 'Email ID']];
		// Create a new Excel workbook
		const workbook = XLSX.utils.book_new();
		// Add the data to a new worksheet
		const worksheet1 = XLSX.utils.aoa_to_sheet(excelDataArray);
		XLSX.utils.book_append_sheet(workbook, worksheet1, 'Sheet1');
		// Generate a binary Excel file and trigger a download
		const excelFile = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'binary',
		});
		const blob = new Blob([s2ab(excelFile)], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = `Audience_Selection_Template.xlsx`;
		downloadLink.click();
	};

	// Function to convert string to ArrayBuffer
	function s2ab(s: string) {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < s.length; i++) {
			view[i] = s.charCodeAt(i) & Numbers.zeroxff;
		}
		return buf;
	}

	return (
		<>
			<UploadExcelContainer>
				<UploadBoxHeader>
					<Box>
						<Typography variant="h6">{commonLabels.results}</Typography>
						{fileName && data.length === 0 && (
							<Typography variant="body2">{commonLabels.noResults}</Typography>
						)}
					</Box>
					<Box>
						<Button
							variant="outlined"
							color="secondary"
							component="span"
							startIcon={<DownloadIcon />}
							onClick={handleExportTemplate}
							sx={{ mr: '10px' }}
						>
							{commonLabels.downloadTemplate}
						</Button>
						<UploadBtnContainer>
							{data.length > 0 && (
								<Box
									sx={{
										display: 'inline-flex',
										alignItems: 'center',
										marginLeft: '10px',
									}}
								>
									<Button
										onClick={handleClearFile}
										sx={{
											color: '#1C75BC',
											fontFamily: '"JohnsonText-Regular"',
											fontSize: '14px',
											lineHeight: '16px',
											textTransform: 'capitalize',
										}}
									>
										{commonLabels.resetAll}
									</Button>
								</Box>
							)}
							<input
								type="file"
								accept=".xlsx, .xls"
								onChange={handleFileUpload}
								ref={excelInputRef}
								style={{ display: 'none' }}
								id="upload-input"
							/>
							<label htmlFor="upload-input">
								<Button
									variant="outlined"
									color="secondary"
									component="span"
									startIcon={<UploadIcon />}
								>
									{commonLabels.uploadFromExcel}
								</Button>
							</label>
						</UploadBtnContainer>
					</Box>
				</UploadBoxHeader>

				{data.length > 0 ? (
					<AudienceSelectionTable data={data} />
				) : (
					<UploadExcelDialogContainer>
						<UploadFromExcelText variant="h3">
							{commonLabels.fallbackText}
						</UploadFromExcelText>
					</UploadExcelDialogContainer>
				)}
				<TotalAudienceCountText variant="h2">
					{commonLabels.totalAudience}: {data?.length}
				</TotalAudienceCountText>
			</UploadExcelContainer>
			<GenericConfirmationPopup
				open={showInvalidUserPopup}
				msgBody={`${invalidWWIDS.length} ${commonLabels.invalidUserIdMesage}`}
				msgNote={commonLabels.invalidUSerIdConfirmationMessage}
				title={commonLabels.invalidUsersIDs}
				rightBtnText={commonLabels.continue}
				leftBtnText={commonLabels.cancel}
				isRightBtnVisible={true}
				isLeftBtnVisible={true}
				rightBtnHandler={() => setShowInvalidUserPopup(false)}
				leftBtnHandler={handleCancelPopup}
				data-testid="invalid-user-popup"
				icon={<InvalidUserIdIcon />}
				titleSx={{
					fontSize: '31px',
					lineHeight: '32px',
					fontfamily: 'JohnsonDisplay-Regular',
					fontWeight: 700,
				}}
				msgBodySx={{
					fontSize: '16px',
					lineHeight: '16px',
					fontFamily: 'JohnsonText-Regular',
					fontWeight: 400,
				}}
				msgNoteSx={{
					fontSize: '16px',
					lineHeight: '16px',
					marginTop: '10px',
					fontFamily: 'JohnsonText-Regular',
					fontWeight: 400,
				}}
			/>
		</>
	);
};

export default UploadExcel;
