import { Draggable } from 'react-beautiful-dnd';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import { Box } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React from 'react';
import { Theme } from '@material-ui/core/styles';
import { STEPTheme } from '../../utils/Theme';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { useAppSelector } from '../../app/Hooks';
import { selectSurveyFormsQuestion } from '../../redux/SurveySlice';
import QuestionForm from './QuestionForm';

interface StyleProps {
	questionButtonLabel?: string;
}

const useStyles = makeStyles<Theme, StyleProps>({
	draggableHandleIcon: {
		color: STEPTheme.colors.white,
		width: '0.7em !important',
	},
	draggableHandle: {
		height: '100%',
		width: '15px',
		background: (props) => {
			switch (props.questionButtonLabel) {
				case 'Edit':
					return STEPTheme.colors.linkGray;
				case 'Save':
					return STEPTheme.colors.middlGray;
				default:
					return STEPTheme.colors.middlGray;
			}
		},
		borderRadius: '8px 0 0 8px',
		position: 'absolute',
		left: '0',
		alignItems: 'center',
		display: 'flex',
	},
	draggableArea: {
		boxShadow: 'none',
		border: 'none',
	},
	draggingListItem: {
		background: STEPTheme.colors.white,
		borderRadius: '10px',
	},
	dropListItem: {
		boxShadow: `0px 0px 8px 0px ${STEPTheme.colors.shadowGray}`,
		marginBottom: '20px',
		borderRadius: '8px',
		minHeight: '100px',
		background: STEPTheme.colors.white,
	},
	fullWidth: {
		width: '100%',
	},
});

export type QuestionListItemProps = {
	item: ISurveyFormsQuestion;
	index: number;
	deleteQue: (index: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion) => void;
	onQueInputChange: (
		inputValue: string,
		questionId: string,
		questionObj?: ISurveyFormsQuestion
	) => void;
};

const QuestionListItem = ({
	item,
	index,
	deleteQue,
	onQueClone,
	onQueSave,
	onQueInputChange,
}: QuestionListItemProps) => {
	const surveyFormsQuestion = useAppSelector(selectSurveyFormsQuestion);
	const questionId = item?.questionId;
	const selectCurrentQuestionIsValid = () =>
		surveyFormsQuestion.find((question) => question.questionId === questionId)
			?.questionButtonLabel;

	const currentQuestionValid = selectCurrentQuestionIsValid();
	const classes = useStyles({ questionButtonLabel: currentQuestionValid });
	const onDeleteQuestion = () => {
		deleteQue(item.questionId);
	};
	const handlerSaveQuestion = (itemQuestion: ISurveyFormsQuestion) => {
		onQueSave(itemQuestion);
	};
	const handleQueClone = (data: ISurveyFormsQuestion) => {
		onQueClone(data);
	};
	return (
		<Draggable
			draggableId={item.questionId}
			index={index}
			data-testid="question-list-item"
		>
			{(provided, snapshot) => (
				<ListItem
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className={
						snapshot.isDragging
							? classes.draggingListItem
							: classes.dropListItem
					}
					data-testid="question-list-item"
				>
					<Box className={classes.draggableHandle}>
						<DragIndicatorIcon className={classes.draggableHandleIcon} />
					</Box>
					<Box className={classes.fullWidth}>
						<QuestionForm
							onQueSave={handlerSaveQuestion}
							onQueClone={handleQueClone}
							onDeleteQue={onDeleteQuestion}
							onQueInputChange={onQueInputChange}
							index={index}
							item={item}
							questionType={item.questionType}
						/>
					</Box>
				</ListItem>
			)}
		</Draggable>
	);
};

export default QuestionListItem;
