import {
	AnyAction,
	createAsyncThunk,
	createSlice,
	PayloadAction,
	ThunkDispatch,
} from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../app/Store';
import {
	DownloadResults,
	FetchListViewResults,
	GetParameterList,
	GetRegionalData,
	GetResponseGraphData,
	GetResultKeyMetrices,
	GetGroupData,
	ReportDownload,
	SummitReportRequest,
	keyMetricBatchDownload,
} from '../middleware/QuestionnaireResultsService';
import {
	IQuestionnaireResultsData,
	IQuestionnaireResultsSummaryCollection,
	ISectorSummaryCollection,
	IOptionalScopeSummaryByRegionCollection,
	ICompletionSummaryCollection,
	IRegionalSummaryCollection,
	IOptionalScopeSummaryCollection,
	IRegionGroupReq,
	IResponseGraphReq,
	IResponseGraphResponse,
	IListViewReq,
	ResultsKeyMetricesRequest,
	IGroupDataReq,
	StatusType,
	IDownloadReq,
	ISUMMITEdgeFormRequestPayload,
	IEmployeeDetailsCollectionData,
} from '../types/QuestionnaireResultsType';
import { API_STATUS_CONSTANT, RESPONSE_CODE } from '../constants/StepConstants';
import { commonLabels } from '../utils/CommonLabels';
import { Numbers } from '../utils/Enum';
const initialOptionalScopeSummaryByRegionCollectionState: IOptionalScopeSummaryByRegionCollection =
	{
		inScopeInValue: Numbers.zero,
		notInScopeInValue: Numbers.zero,
		inScopeInPercentage: Numbers.zero,
		notInScopeInPercentage: Numbers.zero,
		notAssessedInValue: Numbers.zero,
		notAssessedInPercentage: Numbers.zero,
		totalCount: Numbers.zero,
	};

const initialCompletionSummaryCollectionState: ICompletionSummaryCollection = {
	totalCount: Numbers.zero,
	respondedInNumber: Numbers.zero,
	notRespondedInNumber: Numbers.zero,
	respondedInPercentage: Numbers.zero,
	notRespondedInPercentage: Numbers.zero,
};
const initialRegionalSummaryCollectionState: IRegionalSummaryCollection = {
	asia_pacific: initialCompletionSummaryCollectionState,
	emea: initialCompletionSummaryCollectionState,
	latin_america: initialCompletionSummaryCollectionState,
	north_america: initialCompletionSummaryCollectionState,
};
const initialOptionalScopeSummaryCollectionState: IOptionalScopeSummaryCollection =
	{
		asia_pacific: initialOptionalScopeSummaryByRegionCollectionState,
		emea: initialOptionalScopeSummaryByRegionCollectionState,
		latin_america: initialOptionalScopeSummaryByRegionCollectionState,
		north_america: initialOptionalScopeSummaryByRegionCollectionState,
	};

const initialSummaryCollectionState: ISectorSummaryCollection = {
	overallCompletionSummary: initialCompletionSummaryCollectionState,
	regionalCompletionSummary: initialRegionalSummaryCollectionState,
	optionalScopeSummaryByRegion: initialOptionalScopeSummaryCollectionState,
};

const initialQuestionnaireResultsSummaryCollectionState: IQuestionnaireResultsSummaryCollection =
	{
		all: initialSummaryCollectionState,
		medical_devices: initialSummaryCollectionState,
		pharmaceuticals: initialSummaryCollectionState,
		corporate: initialSummaryCollectionState,
		consumer: initialSummaryCollectionState,
	};
const initialResponseGraphData: IResponseGraphResponse = {
	questionnaireName: '',
	lastRefreshedDate: '',
	answersData: [],
};

const initialState: IQuestionnaireResultsData = {
	questionnaireName: '',
	questionnaireCode: '',
	summaryCollection: initialQuestionnaireResultsSummaryCollectionState,
	lastRefreshedDate: '',
	totalCount: Numbers.zero,
	resultAPICompletionStatus: 'idle',
	downloadResultsData: null,
	downloadResultsApiStatus: 'idle',
	responseGraphApiStatus: 'idle',
	summitReportApiStatus: 'idle',
	keyMetricsBatchReportApiStatus: 'idle',
	targetAudience: '',
	resultListViewRecord: {
		totalCount: Numbers.zero,
		employeeDetailsCollection: [],
	} as unknown as IEmployeeDetailsCollectionData,
	parameterList: [],
	parameterListAPICompletionStatus: 'idle',
	resultsKeyMetricesData: {},
	resultsKeyMetricesSummitData: {},
	resultsKeyMetricesSummitAPICompletionStatus: 'idle',
	resultsKeyMetricesAPICompletionStatus: 'idle',
	regionalAPICompletionStatus: 'idle',
	regionData: [],
	dataDisplayType: commonLabels.dataDisplayTypePercentage,
	responseGraphData: initialResponseGraphData,
	currentQuestion: Numbers.zero,
	groupGraphData: [],
	groupGraphDataAPICompletionStatus: 'idle',
	totalEmployeeCountRegional: Numbers.zero,
	totalEmployeeCountGroup: Numbers.zero,
	pageIndex: Numbers.zero,
	pageSize: Numbers.ten,
};

// updates parameter list fetch failed
const updateParamterListAPIFailed = createAsyncThunk(
	'updateParamterListAPIFailed',
	async (_, { dispatch }) => {
		dispatch(setParameterList([]));
		dispatch(setParameterListApiStatus('failed'));
	}
);

// updates group graph data fetch failed
const updateGroupGraphAPIStatus = createAsyncThunk(
	'updateGroupGraphAPIStatus',
	async (status: StatusType, { dispatch }) => {
		dispatch(setGroupGraphData([]));
		dispatch(setGroupGraphDataApiStatus(status));
	}
);

// updates regional graph data fetch failed
const updateRegionalGraphAPIStatus = createAsyncThunk(
	'updateRegionalGraphAPIStatus',
	async (status: StatusType, { dispatch }) => {
		dispatch(setRegionalData([]));
		dispatch(setRegionalDataApiStatus(status));
	}
);

export const getListViewResultsRecords = createAsyncThunk(
	'FetchListView',
	async (
		listViewPayload: {
			requestFilterPayload: IListViewReq;
			questionniareId: string;
		},
		{ dispatch }
	) => {
		const listViewArray = FetchListViewResults(
			listViewPayload.questionniareId,
			listViewPayload.requestFilterPayload
		);
		listViewArray
			.then((responseListViewArray: AxiosResponse) => {
				if (
					(responseListViewArray?.status === RESPONSE_CODE.HTTP_STATUS_OK ||
						responseListViewArray?.status ===
							RESPONSE_CODE.HTTP_STATUS_NO_CONTENT) &&
					responseListViewArray?.data
				) {
					setListViewResultsRecords(dispatch, API_STATUS_CONSTANT.SUCCESS, {
						totalCount:
							Number(
								responseListViewArray?.data?.data?.metricNameValues
									?.impactedEmployees ?? Numbers.zero
							) ?? responseListViewArray?.data?.data?.employeeDetails?.length,
						employeeDetailsCollection:
							responseListViewArray?.data?.data?.employeeDetails,
					});
				} else {
					setListViewResultsRecords(dispatch, API_STATUS_CONSTANT.FAILED, {
						totalCount: Numbers.zero,
						employeeDetailsCollection: [],
					});
				}
			})
			.catch(() => {
				setListViewResultsRecords(dispatch, API_STATUS_CONSTANT.FAILED, {
					totalCount: Numbers.zero,
					employeeDetailsCollection: [],
				});
			});
	}
);
/**
 * Method to reset the Employees data if API fails And this method sets the API loading status as False
 */
const setListViewResultsRecords = (
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
	APIStatus: string,
	data: IEmployeeDetailsCollectionData
) => {
	dispatch(setModifiedListResultResponse(data));
	dispatch(setResultAPICompletionStatus(APIStatus));
};

export const downloadResults = createAsyncThunk(
	'questionnaireResults/downloadResults',
	async (id: string, { dispatch }) => {
		DownloadResults(id)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setDownloadResultsData(response?.data));
					dispatch(setDownloadResultsApiStatus('success'));
				} else {
					dispatch(setDownloadResultsApiStatus('failed'));
				}
			})
			.catch(() => {
				dispatch(setDownloadResultsApiStatus('failed'));
			});
	}
);

export const reportDownload = createAsyncThunk(
	'questionnaireResults/ReportDownload',
	async (
		reportDownloadPayload: {
			questionnaireId: string;
			downloadReqPayload: IDownloadReq;
		},
		{ dispatch }
	) => {
		ReportDownload(
			reportDownloadPayload.questionnaireId,
			reportDownloadPayload.downloadReqPayload
		)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setDownloadResultsData(response?.data));
					dispatch(setDownloadResultsApiStatus('success'));
				} else if (response?.status === RESPONSE_CODE.HTTP_STATUS_BAD_REQUEST) {
					dispatch(
						setDownloadResultsApiStatus(API_STATUS_CONSTANT.ERROR_BAD_REQUEST)
					);
				} else {
					dispatch(setDownloadResultsApiStatus('failed'));
				}
			})
			.catch((error) => {
				if (error?.response?.status === RESPONSE_CODE.HTTP_STATUS_BAD_REQUEST) {
					dispatch(
						setDownloadResultsApiStatus(API_STATUS_CONSTANT.ERROR_BAD_REQUEST)
					);
				} else {
					dispatch(setDownloadResultsApiStatus('failed'));
				}
			});
	}
);

export const keyMetricsBatchReportRequest = createAsyncThunk(
	'Survey/keyMetricsBatchReportRequest',
	async (
		keyMetricsBatchReportDownloadPayload: {
			questionnaireId: string;
			downloadReqPayload: IDownloadReq;
		},
		{ dispatch }
	) => {
		keyMetricBatchDownload(
			keyMetricsBatchReportDownloadPayload.questionnaireId,
			keyMetricsBatchReportDownloadPayload.downloadReqPayload
		)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setKeyMetricsBatchReportRequestResponse('success'));
				} else {
					dispatch(setKeyMetricsBatchReportRequestResponse('failed'));
				}
			})
			.catch(() => {
				dispatch(setKeyMetricsBatchReportRequestResponse('failed'));
			});
	}
);

export const fetchParameterList = createAsyncThunk(
	'questionnaireResults/fetchParameterList',
	async (_, { dispatch }) => {
		GetParameterList()
			.then((response) => {
				if (response?.data?.data?.parameters) {
					dispatch(setParameterList(response.data.data.parameters));
					dispatch(setParameterListApiStatus('success'));
				} else {
					dispatch(updateParamterListAPIFailed());
				}
			})
			.catch(() => {
				dispatch(updateParamterListAPIFailed());
			});
	}
);

export const fetchGroupGraphData = createAsyncThunk(
	'questionnaireResults/fetchGroupGraphData',
	async (
		fetchGroupDataReq: {
			questionnaireId: string;
			actionPayload: IGroupDataReq;
		},
		{ dispatch }
	) => {
		dispatch(setGroupGraphDataApiStatus('idle'));
		GetGroupData(fetchGroupDataReq)
			.then((response) => {
				if (response?.data?.data?.parameters) {
					dispatch(setGroupGraphData(response.data.data.parameters));
					dispatch(setGroupGraphDataApiStatus('success'));
				} else {
					dispatch(updateGroupGraphAPIStatus('success'));
				}
				if (response?.data?.data?.overallEmployeesCount) {
					dispatch(
						setTotalEmployeeCountGroup(response.data.data.overallEmployeesCount)
					);
				} else {
					dispatch(setTotalEmployeeCountGroup(Numbers.zero));
				}
			})
			.catch(() => {
				dispatch(updateGroupGraphAPIStatus('failed'));
				dispatch(setTotalEmployeeCountGroup(Numbers.zero));
			});
	}
);

/**
 * To get the key metrices in the Result screen
 * It will also get the data for pie-chart of Response-status, Summit group status & Defaulters sections
 */
export const fetchResultKeyMetrices = createAsyncThunk(
	'questionnaireResults/fetchResultKeyMetrices',
	async (
		fetchResultKeyMetricesReq: {
			questionnaireId: string;
			actionPayload: ResultsKeyMetricesRequest;
		},
		{ dispatch }
	) => {
		dispatch(setResultsKeyMetricesAPICompletionStatus('loading'));
		dispatch(setResultsKeyMetricesSummitAPICompletionStatus('loading'));
		GetResultKeyMetrices(fetchResultKeyMetricesReq)
			.then((response: AxiosResponse) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setResultsKeyMetrices(response?.data?.data));
					dispatch(
						setResultsKeyMetricesAPICompletionStatus(
							API_STATUS_CONSTANT?.SUCCESS as IQuestionnaireResultsData['resultsKeyMetricesAPICompletionStatus']
						)
					);
					// call the API for Summit group status
					dispatch(fetchResultKeyMetricesSummit(fetchResultKeyMetricesReq));
				} else {
					resultKeyMetricesFailed(dispatch);
				}
			})
			.catch(() => {
				resultKeyMetricesFailed(dispatch);
			});
	}
);

export const fetchResultKeyMetricesSummit = createAsyncThunk(
	'questionnaireResults/fetchResultKeyMetricesSummit',
	async (
		fetchResultKeyMetricesReq: {
			questionnaireId: string;
			actionPayload: ResultsKeyMetricesRequest;
		},
		{ dispatch }
	) => {
		dispatch(setResultsKeyMetricesSummitAPICompletionStatus('loading'));
		fetchResultKeyMetricesReq.actionPayload['metricName'] =
			'employeessummitgroupstatus';
		GetResultKeyMetrices(fetchResultKeyMetricesReq)
			.then((response: AxiosResponse) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setResultsKeyMetricesSummit(response?.data?.data));
					dispatch(
						setResultsKeyMetricesSummitAPICompletionStatus(
							API_STATUS_CONSTANT?.SUCCESS as IQuestionnaireResultsData['resultsKeyMetricesSummitAPICompletionStatus']
						)
					);
				} else {
					resultKeyMetricesSummitFailed(dispatch);
				}
			})
			.catch(() => {
				resultKeyMetricesSummitFailed(dispatch);
			});
	}
);

/**
 * Method to reset the keymetrics data if API fails And this method sets the API loading status as False
 */
const resultKeyMetricesFailed = (
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
	dispatch(setResultsKeyMetrices({}));
	dispatch(
		setResultsKeyMetricesAPICompletionStatus(
			API_STATUS_CONSTANT?.FAILED as IQuestionnaireResultsData['resultsKeyMetricesAPICompletionStatus']
		)
	);
	// If keyMetrices failed summit API fill not be called
	dispatch(setResultsKeyMetricesSummit({}));
	dispatch(
		setResultsKeyMetricesSummitAPICompletionStatus(
			API_STATUS_CONSTANT?.FAILED as IQuestionnaireResultsData['resultsKeyMetricesSummitAPICompletionStatus']
		)
	);
};

/**
 * Method to reset the keymetrics data if API fails And this method sets the API loading status as False
 */
const resultKeyMetricesSummitFailed = (
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
	dispatch(setResultsKeyMetricesSummit({}));
	dispatch(
		setResultsKeyMetricesSummitAPICompletionStatus(
			API_STATUS_CONSTANT?.FAILED as IQuestionnaireResultsData['resultsKeyMetricesSummitAPICompletionStatus']
		)
	);
};

export const fetchRegionalGraphData = createAsyncThunk(
	'questionnaireResults/fetchRegionGraphData',
	async (regionGroup: IRegionGroupReq, { dispatch }) => {
		dispatch(setRegionalDataApiStatus('idle'));
		GetRegionalData(regionGroup)
			.then((response) => {
				if (response?.data?.data?.overallEmployeesCount) {
					dispatch(
						setTotalEmployeeCountRegional(
							response.data.data.overallEmployeesCount
						)
					);
				} else {
					dispatch(setTotalEmployeeCountRegional(Numbers.zero));
				}
				if (response?.data?.data?.regions) {
					dispatch(setRegionalData(response.data.data.regions));
					dispatch(setRegionalDataApiStatus('success'));
				} else {
					dispatch(updateRegionalGraphAPIStatus('success'));
				}
			})
			.catch(() => {
				dispatch(updateRegionalGraphAPIStatus('failed'));
			});
	}
);

export const fetchResponseGraphData = createAsyncThunk(
	'questionnaireResults/fetchResponseGraphData',
	async (ResponseGraphReq: IResponseGraphReq, { dispatch }) => {
		GetResponseGraphData(ResponseGraphReq)
			.then((response) => {
				if (
					response?.data?.data &&
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK
				) {
					dispatch(setResponseGraphApiStatus('success'));
					dispatch(setResponseGraphData(response.data.data));
					response.data.data?.lastRefreshedDate &&
						dispatch(
							setLastRefreshedDate(response.data.data?.lastRefreshedDate)
						);
				} else if (response?.status === RESPONSE_CODE.HTTP_STATUS_NO_CONTENT) {
					dispatch(setResponseGraphData(initialResponseGraphData));
					dispatch(setResponseGraphApiStatus('success'));
				} else {
					responseGraphFailureHandler(dispatch);
				}
			})
			.catch(() => {
				responseGraphFailureHandler(dispatch);
			});
	}
);
const responseGraphFailureHandler = (
	dispatch: ThunkDispatch<unknown, unknown, AnyAction>
) => {
	dispatch(setResponseGraphData(initialResponseGraphData));
	dispatch(setResponseGraphApiStatus('failed'));
};

export const summitEdgeFormReportRequest = createAsyncThunk(
	'Survey/summitEdgeFormReportRequest',
	async (
		summitEdgeFormRequestPayload: ISUMMITEdgeFormRequestPayload,
		{ dispatch }
	) => {
		SummitReportRequest(
			summitEdgeFormRequestPayload.questionnaireId,
			summitEdgeFormRequestPayload.requestPayload,
			summitEdgeFormRequestPayload?.groupId
		)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(setSummitReportApiStatus('success'));
				} else if (response?.status === RESPONSE_CODE.HTTP_STATUS_NO_CONTENT) {
					dispatch(setSummitReportApiStatus(API_STATUS_CONSTANT.NO_CONTENT));
				} else {
					dispatch(setSummitReportApiStatus('failed'));
				}
			})
			.catch(() => {
				dispatch(setSummitReportApiStatus('failed'));
			});
	}
);

export const questionnaireResultsSlice = createSlice({
	name: 'questionnaireResults',
	initialState,
	reducers: {
		setDownloadResultsData: (state, action: PayloadAction<unknown>) => {
			state.downloadResultsData = action.payload;
		},
		setDownloadResultsApiStatus: (state, action: PayloadAction<string>) => {
			state.downloadResultsApiStatus = action.payload;
		},
		setResultAPICompletionStatus: (state, action) => {
			state.resultAPICompletionStatus = action.payload;
		},
		setModifiedListResultResponse: (
			state,
			action: PayloadAction<IEmployeeDetailsCollectionData>
		) => {
			state.resultListViewRecord = action.payload;
		},
		setParameterList: (state, action) => {
			state.parameterList = action.payload;
		},
		setParameterListApiStatus: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['parameterListAPICompletionStatus']
			>
		) => {
			state.parameterListAPICompletionStatus = action.payload;
		},
		setResultsKeyMetrices: (
			state,
			action: PayloadAction<IQuestionnaireResultsData['resultsKeyMetricesData']>
		) => {
			state.resultsKeyMetricesData = action.payload;
		},
		setResultsKeyMetricesAPICompletionStatus: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['resultsKeyMetricesAPICompletionStatus']
			>
		) => {
			state.resultsKeyMetricesAPICompletionStatus = action.payload;
		},
		setResultsKeyMetricesSummit: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['resultsKeyMetricesSummitData']
			>
		) => {
			state.resultsKeyMetricesSummitData = action.payload;
		},
		setResultsKeyMetricesSummitAPICompletionStatus: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['resultsKeyMetricesSummitAPICompletionStatus']
			>
		) => {
			state.resultsKeyMetricesSummitAPICompletionStatus = action.payload;
		},
		setRegionalData: (state, action) => {
			state.regionData = action.payload;
		},
		setLastRefreshedDate: (state, action) => {
			state.lastRefreshedDate = action.payload;
		},
		setDataDisplayTypeAction: (state, action) => {
			state.dataDisplayType = action.payload;
		},
		setResponseGraphData: (state, action) => {
			state.responseGraphData = action.payload;
		},
		setResponseGraphApiStatus: (state, action) => {
			state.responseGraphApiStatus = action.payload;
		},
		setCurrentQuestion: (state, action) => {
			state.currentQuestion = action.payload;
		},
		setSummitReportApiStatus: (state, action) => {
			state.summitReportApiStatus = action.payload;
		},
		setKeyMetricsBatchReportRequestResponse: (state, action) => {
			state.keyMetricsBatchReportApiStatus = action.payload;
		},
		setRegionalDataApiStatus: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['regionalAPICompletionStatus']
			>
		) => {
			state.regionalAPICompletionStatus = action.payload;
		},
		setTotalEmployeeCountRegional: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['totalEmployeeCountRegional']
			>
		) => {
			state.totalEmployeeCountRegional = action.payload;
		},

		setGroupGraphData: (state, action) => {
			state.groupGraphData = action.payload;
		},
		setGroupGraphDataApiStatus: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['groupGraphDataAPICompletionStatus']
			>
		) => {
			state.groupGraphDataAPICompletionStatus = action.payload;
		},
		setTotalEmployeeCountGroup: (
			state,
			action: PayloadAction<
				IQuestionnaireResultsData['totalEmployeeCountGroup']
			>
		) => {
			state.totalEmployeeCountGroup = action.payload;
		},
		setPageIndex: (state, action: PayloadAction<number>) => {
			state.pageIndex = action.payload;
		},
		setRowsPerPageValue: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchResponseGraphData.pending, (state) => {
				state.responseGraphApiStatus = 'loading';
			})
			.addCase(fetchResponseGraphData.rejected, (state) => {
				state.responseGraphApiStatus = 'failed';
			})
			.addCase(fetchResultKeyMetrices.pending, (state) => {
				state.resultsKeyMetricesAPICompletionStatus = 'loading';
			});
	},
});

export const resultListViewRecord = (state: RootState) =>
	state.questionnaireResults.resultListViewRecord;
export const questionnaireResultsData = (state: RootState) =>
	state.questionnaireResults;
export const questionnaireResultsLastRefresh = (state: RootState) =>
	state.questionnaireResults.lastRefreshedDate;
export const downloadResultsData = (state: RootState) =>
	state.questionnaireResults.downloadResultsData;
export const downloadResultsApiStatus = (state: RootState) =>
	state.questionnaireResults.downloadResultsApiStatus;
export const parameterList = (state: RootState) =>
	state.questionnaireResults.parameterList;
export const parameterListAPIStatus = (state: RootState) =>
	state.questionnaireResults.parameterListAPICompletionStatus;
export const regionalData = (state: RootState) =>
	state.questionnaireResults.regionData;
export const fetchDataDisplayType = (state: RootState) =>
	state.questionnaireResults.dataDisplayType;
export const responseGraphData = (state: RootState) =>
	state.questionnaireResults.responseGraphData;
export const fetchResponseGraphApiStatus = (state: RootState) =>
	state.questionnaireResults.responseGraphApiStatus;
export const currentQuestion = (state: RootState) =>
	state.questionnaireResults.currentQuestion;
export const regionalDataApiStatus = (state: RootState) =>
	state.questionnaireResults.regionalAPICompletionStatus;
export const totalEmployeeCountRegional = (state: RootState) =>
	state.questionnaireResults.totalEmployeeCountRegional;
export const keyMetricsAPIStatus = (state: RootState) =>
	state.questionnaireResults.resultsKeyMetricesAPICompletionStatus;
export const keyMetricsData = (state: RootState) =>
	state.questionnaireResults.resultsKeyMetricesData;
export const keyMetricsSummitAPIStatus = (state: RootState) =>
	state.questionnaireResults.resultsKeyMetricesSummitAPICompletionStatus;
export const keyMetricsSummitData = (state: RootState) =>
	state.questionnaireResults.resultsKeyMetricesSummitData;
export const groupGraphData = (state: RootState) =>
	state.questionnaireResults.groupGraphData;
export const groupGraphDataAPIStatus = (state: RootState) =>
	state.questionnaireResults.groupGraphDataAPICompletionStatus;
export const keyMetricsReportAPIStatus = (state: RootState) =>
	state.questionnaireResults.keyMetricsBatchReportApiStatus;
export const totalEmployeeCountGroup = (state: RootState) =>
	state.questionnaireResults.totalEmployeeCountGroup;
export const listViewResultAPICompletionStatus = (state: RootState) =>
	state.questionnaireResults.resultAPICompletionStatus;
export const summitReportApiCompletionStatus = (state: RootState) =>
	state.questionnaireResults.summitReportApiStatus;
export const pageIndex = (state: RootState) =>
	state.questionnaireResults.pageIndex;
export const pageSize = (state: RootState) =>
	state.questionnaireResults.pageSize;

export const {
	setResultAPICompletionStatus,
	setDownloadResultsData,
	setDownloadResultsApiStatus,
	setModifiedListResultResponse,
	setParameterList,
	setParameterListApiStatus,
	setResultsKeyMetrices,
	setResultsKeyMetricesAPICompletionStatus,
	setResultsKeyMetricesSummit,
	setResultsKeyMetricesSummitAPICompletionStatus,
	setRegionalData,
	setLastRefreshedDate,
	setDataDisplayTypeAction,
	setResponseGraphData,
	setResponseGraphApiStatus,
	setCurrentQuestion,
	setRegionalDataApiStatus,
	setTotalEmployeeCountRegional,
	setGroupGraphData,
	setGroupGraphDataApiStatus,
	setTotalEmployeeCountGroup,
	setSummitReportApiStatus,
	setKeyMetricsBatchReportRequestResponse,
	setPageIndex,
	setRowsPerPageValue,
} = questionnaireResultsSlice.actions;
export default questionnaireResultsSlice.reducer;
