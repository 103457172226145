import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
	Box,
	Typography,
	ToggleButtonGroup,
	ToggleButton,
	Button,
} from '@mui/material';
import { startCase } from 'lodash';
import { STEPTheme } from '../../utils/Theme';
import { ReactComponent as GraphView } from '../../assets/images/Graph-view.svg';
import { ReactComponent as ListView } from '../../assets/images/List-view.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg';
import ButtonsGroup from '../../components/questionnaire-results/ButtonsGroup';
import KeyMetrics from '../../components/questionnaire-results/KeyMetrics';
import { Numbers } from '../../utils/Enum';
import {
	getFormattedCurrentDateTime,
	getTimeInLastRefreshFormat,
} from '../../utils/DateFormatting';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	downloadResultsApiStatus,
	downloadResultsData,
	fetchGroupGraphData,
	fetchParameterList,
	fetchRegionalGraphData,
	fetchResultKeyMetrices,
	keyMetricsData,
	keyMetricsAPIStatus,
	parameterList,
	parameterListAPIStatus,
	regionalData,
	setDownloadResultsApiStatus,
	setDownloadResultsData,
	setDataDisplayTypeAction,
	groupGraphData,
	totalEmployeeCountRegional,
	reportDownload,
	totalEmployeeCountGroup,
	summitEdgeFormReportRequest,
	setSummitReportApiStatus,
	summitReportApiCompletionStatus,
	keyMetricsSummitData,
	fetchResultKeyMetricesSummit,
	setGroupGraphData,
	setGroupGraphDataApiStatus,
	setTotalEmployeeCountGroup,
	keyMetricsBatchReportRequest,
	setKeyMetricsBatchReportRequestResponse,
	keyMetricsReportAPIStatus,
} from '../../redux/QuestionnaireResultsSlice';
import { commonLabels } from '../../utils/CommonLabels';
import {
	getGroupGraphData,
	getStatusData,
} from '../../utils/QuestionnaireResult';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import GenericModalPopup from '../../components/common/GenericModalPopup';
import GenericConfirmationPopup from '../../components/common/GenericConfirmationPopup';
import {
	audienceFilterAPILoadingStatus,
	batchReportRequest,
	batchReportRequestResponse,
	getAudienceFilterData,
	selectedFilterRequestPayloadData,
	setBatchReportRequestResponse,
	surveyAllListingData,
} from '../../redux/SurveySlice';
import BarChartCard from '../../components/common/BarChartCard';
import Main from '../../components/QuestionnaireSpecificResultsResponse/Main';
import DonutChart from '../../components/common/DonutChart';
import {
	getGroupsList,
	groupsList,
	hasGroupListUpdated,
} from '../../redux/MappingSlice';
import {
	addPageViewEventForGA,
	getGroupDropDownList,
	getParameterDropDownList,
	prepareRegionalGraphData,
} from '../../utils/CommonMethods';
import StyledTooltip from '../../components/StyledTooltip';
import {
	IAudienceListingRequest,
	IGraphData,
	ISurveyListingDataItem,
} from '../../types/CommonTypes';
import { initialFilterValue } from '../../utils/SurveyAudience';
import KeyMetricsError from '../../components/questionnaire-results/KeyMetricsError';
import { IDownloadReq } from '../../types/QuestionnaireResultsType';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import ListTableView from './views/ListTableView';
import ResultFilter from './views/ResultFilter';

const ToggleButtonStyled = styled(ToggleButton)({
	'&.Mui-selected': {
		backgroundColor: 'white',
		'.toggle-selected-view .path-bg': {
			fill: 'white',
		},
		'.toggle-selected-view .path-stroke': {
			stroke: '#EB1700',
		},
	},
	padding: '0',
});

const GraphContainers = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	height: 'auto',
	justifyContent: 'space-between',
});

const QuestionnaireResultContainer = styled(Box)({
	background: '#F9F8F7',
	minHeight: '500px',
	padding: '40px 100px',
	maxWidth: '100%',
});

const ResultHeadingContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	alignItems: 'baseline',
	marginBottom: '20px',
});

const HeadingTextContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'baseline',
	gap: '20px',
	width: '50%',
});

const GeneralResultHeadingContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	alignItems: 'center',
	marginBottom: '10px',
});

const ResultActionRightContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	rowGap: '5px',
	flexWrap: 'wrap',
});

const GeneralResultsContainer = styled(Box)({
	display: 'flex',
	width: '100%',
	gap: '3%',
	marginBottom: '20px',
	flexDirection: 'column',
});

const StyledMsgBody = styled('div')({
	fontWeight: '500',
	fontSize: '25px',
	fontFamily: '"JohnsonDisplay-Bold"',
	lineHeight: '32px',
});

const QuestionnaireResult: React.FC<unknown> = () => {
	const groupListFetched = useAppSelector(groupsList);
	const isApiLoaded = useAppSelector(audienceFilterAPILoadingStatus);
	const surveyLegacyListData: ISurveyListingDataItem[] =
		useAppSelector(surveyAllListingData);
	const parameterListFetched = useAppSelector(parameterList);
	const apiStatusParameterList = useAppSelector(parameterListAPIStatus);
	const groupData = useAppSelector(groupGraphData);
	const overallEmployeeCountGroup = useAppSelector(totalEmployeeCountGroup);
	const [completedByGroupData, setCompletedByGroupData] = useState<
		IGraphData[]
	>([]);
	const [groupYMax, setGroupYMax] = useState(Numbers.hundred);
	const overallEmployeeCountRegional = useAppSelector(
		totalEmployeeCountRegional
	);
	const groupListDropDownOptions = getGroupDropDownList(groupListFetched);
	const groupListUpdated = useAppSelector(hasGroupListUpdated);
	const parameterListDropDownOptions =
		getParameterDropDownList(parameterListFetched);
	const [showBatchPopup, setShowBatchPopup] = React.useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const [batchPopupMsg, setBatchPopupMsg] = React.useState<string>(
		commonLabels.downloadInProgress
	);
	const [showSummitPopup, setShowSummitPopup] = React.useState(false);
	const [summitPopupMsg, setSummitPopupMsg] = React.useState<string>(
		commonLabels.downloadInProgress
	);
	const [showMaxLimitWarning, setShowMaxLimitWarning] = React.useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [disabledCompleteDownload, setDisabledCompleteDownload] =
		React.useState(false);
	const [dataDisplayType, setDataDisplayType] = useState(
		commonLabels.dataDisplayTypePercentage
	);
	const [viewToggleButton, setViewToggleButton] = useState('graphView');
	const [preFilterValue, setPreFilterViewValue] = useState('');
	const dataDisplayTypeBtnList = [
		commonLabels.dataDisplayTypePercentage,
		commonLabels.dataDisplayTypeValues,
	];
	const batchReportRequestResponseStatus = useAppSelector(
		batchReportRequestResponse
	);
	const keyMetricsReportResponseStatus = useAppSelector(
		keyMetricsReportAPIStatus
	);
	const summitReportApiStatus = useAppSelector(summitReportApiCompletionStatus);
	const downloadResultsApiCurrentStatus = useAppSelector(
		downloadResultsApiStatus
	);
	const downloadResultsFileData = useAppSelector(downloadResultsData);
	const regionDataFetched = useAppSelector(regionalData);
	const [regionData, setRegionData] = useState<IGraphData[]>([]);
	const [regionalYMax, setRegionalYMax] = useState(Numbers.hundred);
	const dispatch = useAppDispatch();
	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	const matchedItem = surveyLegacyListData.find(
		(item) => item.id === questionnaireID
	);
	const matchedQuestionnaireID = matchedItem
		? matchedItem.questionnaireId
		: null;
	const [chipData, setChipData] = useState([
		{
			text: commonLabels.respondedTxt,
			selected: true,
		},
		{
			text: commonLabels.notRespondedTxt,
			selected: false,
		},
		{
			text: commonLabels.notCompliant,
			selected: false,
		},
	]);
	const [groupSelected, setGroupSelected] = useState<string[]>([]);
	const [selectedGroupId, setSelectedGroupId] = useState<string[]>([]);
	const [parameterSelected, setParameterSelected] = useState('');
	const [hasAudienceDataLoaded, setHasAudienceDataLoaded] =
		useState<boolean>(false);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	const includedFilters = filterRequestPayload.includeFilters
		? JSON.parse(filterRequestPayload.includeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const excludedFilters = filterRequestPayload.excludeFilters
		? JSON.parse(filterRequestPayload.excludeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const includedFilterStr = JSON.stringify(includedFilters);
	const excludedFiltersStr = JSON.stringify(excludedFilters);
	const keyMetricesData = useAppSelector(keyMetricsData);
	const keyMetricesSummitData = useAppSelector(keyMetricsSummitData);
	const keyMetricesAPIStatus = useAppSelector(keyMetricsAPIStatus);
	const [selectedNonCompliantDay, setSelectedNonCompliantDay] = useState(
		Numbers.zero
	);
	const [selectedComingDueDay, setSelectedComingDueDay] = useState(
		Numbers.seven
	);
	const [currentReportType, setCurrentReportType] = useState<
		string | undefined
	>(undefined);
	const [currentMetricsSubType, setCurrentMetricsSubType] = useState<
		string | undefined
	>(undefined);
	const defaultReportPayload: IDownloadReq = {
		reportType: questionnaireAPIKeys.reportTypeMain,
		reportSubType: null,
		regionalSubType: null,
		groupName: null,
		groupParameter: null,
		includeFilter: '',
		excludeFilter: '',
	};
	const [reportPayload, setReportPayload] =
		useState<IDownloadReq>(defaultReportPayload);
	useEffect(() => {
		if (isApiLoaded !== 'success') {
			setHasAudienceDataLoaded(false);
			isApiLoaded !== 'loading' && dispatch(getAudienceFilterData());
		} else {
			setHasAudienceDataLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isApiLoaded]);

	// use effect to fetch group graph data
	useEffect(() => {
		if (
			apiStatusParameterList === 'success' &&
			parameterListFetched?.length > Numbers.zero
		) {
			setParameterSelected(parameterListFetched[Numbers.zero]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiStatusParameterList]);

	// fetch latest group graph data if any one- group/parameter changes
	useEffect(() => {
		viewToggleButton === 'graphView' &&
			groupListUpdated &&
			getGroupWiseGraphData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		viewToggleButton,
		groupSelected,
		parameterSelected,
		includedFilterStr,
		excludedFiltersStr,
		groupListFetched,
		groupListUpdated,
	]);

	useEffect(() => {
		dispatch(
			getGroupsList({
				questionnaireId: `${questionnaireID}`,
			})
		);
		dispatch(fetchParameterList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireID]);

	useEffect(() => {
		const url = new URL(window.location.href);
		const urlArray = url.pathname.split('/');

		addPageViewEventForGA(
			'pageview',
			urlArray.length > Numbers.two
				? `/${urlArray[Numbers.one]}/%`
				: url.pathname,
			viewToggleButton === 'graphView'
				? questionnaireAPIKeys.ResultScreenGraphMode
				: questionnaireAPIKeys.ResultScreenListMode
		);
	}, [viewToggleButton]);

	useEffect(() => {
		if (questionnaireID && viewToggleButton === 'graphView') {
			dispatch(
				fetchResultKeyMetrices({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						daysNonCompliant: selectedNonCompliantDay,
						daysComingDue: selectedComingDueDay,
						metricName: '',
						metricSubType: '',
					},
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedNonCompliantDay,
		selectedComingDueDay,
		questionnaireID,
		viewToggleButton,
		includedFilterStr,
		excludedFiltersStr,
	]);

	useEffect(() => {
		getRegionGraphData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireID, includedFilterStr, excludedFiltersStr]);

	const refresh = (refreshOnlySummit = false) => {
		// Synthetic event if not a boolean as the variable is passed down from mutiple components
		if (typeof refreshOnlySummit !== 'object') {
			dispatch(
				fetchResultKeyMetricesSummit({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						daysNonCompliant: selectedNonCompliantDay,
						daysComingDue: selectedComingDueDay,
						metricName: '',
						metricSubType: '',
					},
				})
			);
		} else {
			dispatch(
				fetchResultKeyMetrices({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						daysNonCompliant: selectedNonCompliantDay,
						daysComingDue: selectedComingDueDay,
						metricName: '',
						metricSubType: '',
					},
				})
			);
		}
	};

	useEffect(() => {
		setShowBatchPopup(false);
		if (
			batchReportRequestResponseStatus === API_STATUS_CONSTANT.SUCCESS ||
			keyMetricsReportResponseStatus === API_STATUS_CONSTANT.SUCCESS
		) {
			setBatchPopupMsg(commonLabels.audienceMaxRowsSuccessMessage);
			setShowBatchPopup(true);
		} else if (
			batchReportRequestResponseStatus === API_STATUS_CONSTANT.FAILED ||
			keyMetricsReportResponseStatus === API_STATUS_CONSTANT.FAILED
		) {
			setBatchPopupMsg(commonLabels.networkError);
			setShowBatchPopup(false);
		} else {
			setShowBatchPopup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [batchReportRequestResponseStatus, keyMetricsReportResponseStatus]);

	useEffect(() => {
		setShowSummitPopup(false);
		if (summitReportApiStatus === API_STATUS_CONSTANT.SUCCESS) {
			setSummitPopupMsg(commonLabels.summitSuccessPopupMsg);
			setShowSummitPopup(true);
		} else if (summitReportApiStatus === API_STATUS_CONSTANT.NO_CONTENT) {
			setSummitPopupMsg(commonLabels.summitNoContentPopupMsg);
			setShowSummitPopup(true);
		} else if (summitReportApiStatus === API_STATUS_CONSTANT.FAILED) {
			setSummitPopupMsg(commonLabels.networkError);
			setShowSummitPopup(false);
		} else {
			setShowSummitPopup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summitReportApiStatus]);

	// use effect to build data of regional graph when data filter changes
	useEffect(() => {
		const regionaDataInfo = prepareRegionalGraphData(
			regionDataFetched,
			dataDisplayType,
			overallEmployeeCountRegional
		);
		setRegionData(regionaDataInfo[Numbers.zero].preppedRegionalData);
		setRegionalYMax(regionaDataInfo[Numbers.zero].maxCount);
	}, [regionDataFetched, dataDisplayType, overallEmployeeCountRegional]);

	// use effect to build data of group-wise graph when data filter changes
	useEffect(() => {
		const groupDataInfo = getGroupGraphData(
			groupData,
			dataDisplayType,
			overallEmployeeCountGroup
		);
		setCompletedByGroupData(groupDataInfo[Numbers.zero].processedGroupData);
		setGroupYMax(groupDataInfo[Numbers.zero].maxCount);
	}, [groupData, dataDisplayType, overallEmployeeCountGroup]);

	const chipChangeHandler = (chipSelected: string) => {
		// set all selected to false
		const updatedChipData = chipData.map(
			(chip: { text: string; selected: boolean }) => {
				chip.selected = false;
				if (chip.text === chipSelected) {
					chip.selected = true;
				}
				return chip;
			}
		);
		getRegionGraphData();
		setChipData(updatedChipData);
	};
	const parameterSelectHandler = (parameter: string) => {
		setParameterSelected(parameter);
	};

	const groupSelectHandler = (groupNameSelected: string) => {
		if (groupNameSelected === '') {
			setGroupSelected([]);
			setSelectedGroupId([]);
		} else if (groupNameSelected === Numbers.minusOne.toString()) {
			const allGroupSelected: string[] = [];
			const allGroupSelectedIds: string[] = [];
			groupListDropDownOptions?.map((item) => {
				allGroupSelected.push(item.value);
				const selectedGroupOption = groupListFetched.find(
					(group) => group.groupName === item.value
				);
				allGroupSelectedIds.push(selectedGroupOption?.groupId ?? '');
			});
			setGroupSelected(allGroupSelected);
			setSelectedGroupId(allGroupSelectedIds);
		} else {
			const currentIndex = groupSelected?.indexOf(groupNameSelected);
			const selectedGroupOption = groupListFetched.find(
				(group) => group.groupName === groupNameSelected
			);
			if (currentIndex > -1) {
				setGroupSelected((prevState) =>
					prevState.filter((prevItem) => prevItem !== groupNameSelected)
				);
				setSelectedGroupId((prevState) =>
					prevState.filter(
						(prevItem) => prevItem !== selectedGroupOption?.groupId
					)
				);
			} else {
				setGroupSelected((values) => [...values, groupNameSelected]);
				selectedGroupOption &&
					setSelectedGroupId((values) => [
						...values,
						selectedGroupOption.groupId,
					]);
			}
		}
	};

	// Using data received from getOptionalResultsData function optionalBarChartData is prepared
	// Updates the currently selected sector
	const handleDataDisplayChange = (buttonName: string) => {
		setDataDisplayType(buttonName);
		dispatch(setDataDisplayTypeAction(buttonName));
	};

	useEffect(() => {
		if (
			downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.SUCCESS &&
			downloadResultsFileData
		) {
			const fileName = getFileName(currentReportType, currentMetricsSubType);
			handleExportFile(downloadResultsFileData as Blob, fileName);
			dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
			setDisabledCompleteDownload(false);
		}
		if (downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.FAILED) {
			setDisabledCompleteDownload(false);
			dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
		}
		if (
			downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.ERROR_BAD_REQUEST
		) {
			setShowMaxLimitWarning(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		downloadResultsApiCurrentStatus,
		downloadResultsFileData,
		currentReportType,
		currentMetricsSubType,
	]);

	const getFileName = (reportType?: string, metricsSubType?: string) => {
		const formattedDate = getFormattedCurrentDateTime();
		let fileName = '';

		if (reportType === questionnaireAPIKeys.reportTypeMain) {
			fileName = `${commonLabels.overallDownloadReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
		} else {
			switch (metricsSubType) {
				case questionnaireAPIKeys.reportSubTypeResponseStatus:
					fileName = `${commonLabels.completedResponseStatusReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeSummitGroupStatus:
					fileName = `${commonLabels.summitGroupStatusReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeDefaulterDetails:
					fileName = `${commonLabels.nonCompliantStatusReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeRegionalGraph:
					fileName = `${commonLabels.statusByRegionReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeGroupGraph:
					fileName = `${commonLabels.userGroupDistributionReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
					break;
				default:
					fileName = `${commonLabels.overallDownloadReport}_${matchedQuestionnaireID}_${formattedDate}.csv`;
					break;
			}
		}

		return fileName;
	};

	const handleExportFile = (excelData: Blob, fileName: string) => {
		const blob = new Blob([excelData], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = fileName;
		downloadLink.click();
		dispatch(setDownloadResultsData(null));
	};

	const handleCloseToast = () => {
		setShowBatchPopup(false);
		dispatch(setBatchReportRequestResponse('idle'));
		dispatch(setKeyMetricsBatchReportRequestResponse('idle'));
	};
	const handleCloseSummitPopup = () => {
		setShowSummitPopup(false);
		dispatch(setSummitReportApiStatus('idle'));
	};
	const handleClosePopup = () => {
		setShowPopup(false);
	};
	const handleCloseMaxDataWarningPopup = () => {
		setShowMaxLimitWarning(false);
		setDisabledCompleteDownload(false);
		dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
	};
	const handleMaxDataExport = () => {
		setShowMaxLimitWarning(false);
		dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
		if (questionnaireID) {
			if (currentReportType === questionnaireAPIKeys.reportTypeMain) {
				const maxDataExportPayload = {
					id: `${questionnaireID}`,
					reporttype: commonLabels.resultExport,
				};
				setBatchPopupMsg(commonLabels.downloadInProgress);
				setShowBatchPopup(true);
				dispatch(batchReportRequest(maxDataExportPayload));
			} else {
				setBatchPopupMsg(commonLabels.downloadInProgress);
				setShowBatchPopup(true);
				dispatch(
					keyMetricsBatchReportRequest({
						questionnaireId: `${questionnaireID}`,
						downloadReqPayload: reportPayload,
					})
				);
			}
		} else {
			setDisabledCompleteDownload(false);
			setBatchPopupMsg(commonLabels.networkError);
			setShowBatchPopup(true);
		}
	};
	const handleView = (event: React.MouseEvent<HTMLElement>) => {
		setPreFilterViewValue('');
		setViewToggleButton((event.currentTarget as HTMLInputElement).value);
	};
	const triggerSelectedDaysHandler = (
		selectedDayAs: number,
		metricsType: string
	) => {
		if (metricsType === commonLabels.RISKOVERDUE) {
			setSelectedComingDueDay(selectedDayAs);
		} else {
			setSelectedNonCompliantDay(selectedDayAs);
		}
	};

	const filterViewEmployees = (metricsType: string) => {
		setPreFilterViewValue(metricsType);
		setViewToggleButton('tableView');
	};

	const getRegionGraphData = () => {
		let selectedChip = chipData?.find((chip) => chip.selected)?.text;
		if (selectedChip === commonLabels.notCompliant) {
			selectedChip = commonLabels.defaulterTxt;
		}
		if (selectedChip) {
			dispatch(
				fetchRegionalGraphData({
					questionnaireId: `${questionnaireID}`,
					includeFilter: JSON.stringify({ ...includedFilters }),
					excludeFilter: JSON.stringify({ ...excludedFilters }),
					key: selectedChip,
				})
			);
		}
	};

	const getGroupSelectedIds = () => {
		const groupSelectedIds: string[] = [];
		groupSelected.map((grpSelctd: string) => {
			const groupId = groupListFetched.find((group) => {
				return grpSelctd === group.groupName;
			})?.groupId;
			groupId && groupSelectedIds.push(groupId);
		});
		return groupSelectedIds;
	};

	const getGroupWiseGraphData = () => {
		if (
			groupSelected.length > Numbers.zero &&
			parameterSelected &&
			groupListFetched.length > Numbers.zero
		) {
			dispatch(
				fetchGroupGraphData({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						parameter: parameterSelected,
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						groupId: getGroupSelectedIds(),
					},
				})
			);
		} else {
			dispatch(setGroupGraphData([]));
			dispatch(setGroupGraphDataApiStatus('success'));
			dispatch(setTotalEmployeeCountGroup(Numbers.zero));
		}
	};

	// handles refresh of graph requested
	const handleGraphRefresh = (type: string) => {
		switch (type) {
			case 'group':
				getGroupWiseGraphData();
				break;
			case 'region':
				getRegionGraphData();
				break;
			default:
				break;
		}
	};

	const handleReportDownload = (
		reportType?: string,
		metricsSubType?: string
	) => {
		setCurrentReportType(reportType);
		setCurrentMetricsSubType(metricsSubType);
		const reportDownloadPayload: IDownloadReq = {
			reportType: questionnaireAPIKeys.reportTypeMain,
			reportSubType: null,
			regionalSubType: null,
			groupName: null,
			groupParameter: null,
			includeFilter: '',
			excludeFilter: '',
		};
		if (reportType === questionnaireAPIKeys.reportTypeMain) {
			if (viewToggleButton === commonLabels.graphView) {
				reportDownloadPayload.reportType = questionnaireAPIKeys.reportTypeMain;
			}
		} else {
			reportDownloadPayload.reportType =
				questionnaireAPIKeys.reportTypeKeyMetrics;
			reportDownloadPayload.includeFilter = JSON.stringify({
				...includedFilters,
			});
			reportDownloadPayload.excludeFilter = JSON.stringify({
				...excludedFilters,
			});
		}
		if (metricsSubType) {
			reportDownloadPayload.reportSubType = metricsSubType;
		}
		if (metricsSubType === questionnaireAPIKeys.reportSubTypeRegionalGraph) {
			const selectedChip = chipData.find((chip) => chip.selected === true);
			const selectedChipKey = selectedChip ? selectedChip.text : null;

			if (selectedChipKey === commonLabels.DEFAULTER) {
				reportDownloadPayload.regionalSubType = questionnaireAPIKeys.defaulter;
			} else if (selectedChipKey === commonLabels.NOTRESPONDED) {
				reportDownloadPayload.regionalSubType =
					questionnaireAPIKeys.notResponded;
			} else if (selectedChipKey === commonLabels.RESPONDED) {
				reportDownloadPayload.regionalSubType = questionnaireAPIKeys.responded;
			} else {
				reportDownloadPayload.regionalSubType = null;
			}
		} else if (
			metricsSubType === questionnaireAPIKeys.reportSubTypeGroupGraph
		) {
			const selectedGroupName = groupSelected.toString();
			const selectedGroupParameter = parameterSelected;
			reportDownloadPayload.groupId =
				selectedGroupId && selectedGroupId.length > Numbers.zero
					? selectedGroupId
					: [];
			reportDownloadPayload.groupName = selectedGroupName;
			reportDownloadPayload.groupParameter = selectedGroupParameter;
		} else {
			reportDownloadPayload.regionalSubType = null;
			reportDownloadPayload.groupName = null;
			reportDownloadPayload.groupParameter = null;
		}

		setReportPayload(reportDownloadPayload);

		dispatch(
			reportDownload({
				questionnaireId: `${questionnaireID}`,
				downloadReqPayload: reportDownloadPayload,
			})
		);
	};

	const handleGlobalDownload = () => {
		handleReportDownload(questionnaireAPIKeys.reportTypeMain);
	};

	const handleSummitDownload = () => {
		const summitEdgeFormRequestPayload = {
			questionnaireId: `${questionnaireID}`,
			requestPayload: {
				includeFilter: JSON.stringify({
					...includedFilters,
				}),
				excludeFilter: JSON.stringify({
					...excludedFilters,
				}),
			},
			groupId: [],
		};

		dispatch(summitEdgeFormReportRequest(summitEdgeFormRequestPayload));
	};

	const getPreSelectedDays = (preSelectedMetric: string) => {
		if (preSelectedMetric === commonLabels.RISKOVERDUE) {
			return selectedComingDueDay;
		} else if (preSelectedMetric === commonLabels.NON_COMPLIANT) {
			return selectedNonCompliantDay;
		} else {
			return Numbers.one;
		}
	};

	return (
		<>
			<QuestionnaireResultContainer>
				<GenericConfirmationPopup
					open={showBatchPopup}
					msgBody={<StyledMsgBody>{batchPopupMsg}</StyledMsgBody>}
					title={''}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleCloseToast}
					leftBtnHandler={handleCloseToast}
				/>
				<GenericConfirmationPopup
					open={showSummitPopup}
					msgBody={<StyledMsgBody>{summitPopupMsg}</StyledMsgBody>}
					title={''}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleCloseSummitPopup}
					leftBtnHandler={handleCloseSummitPopup}
				/>
				<ResultHeadingContainer>
					<HeadingTextContainer>
						<Box>
							{keyMetricesData?.questionnaireName && (
								<Typography variant="h1">
									{`${commonLabels.showingResultsFor} ${keyMetricesData?.questionnaireName}`}
								</Typography>
							)}
							{keyMetricesData?.lastRefreshedDate && (
								<Typography variant="body2" fontSize="14px" lineHeight="18px">
									{`${commonLabels.lastRefreshTxt} ${
										keyMetricesData.lastRefreshedDate &&
										getTimeInLastRefreshFormat(
											keyMetricesData?.lastRefreshedDate
										)
									}`}
								</Typography>
							)}
						</Box>
					</HeadingTextContainer>
					<ResultActionRightContainer>
						{viewToggleButton === 'graphView' && (
							<ButtonsGroup
								onButtonSelect={handleDataDisplayChange}
								buttonsList={dataDisplayTypeBtnList}
								selectedButton={dataDisplayType}
							/>
						)}
						<Box>
							<StyledTooltip
								title={commonLabels.downloadButtonTooltip}
								placement="top"
							>
								<Button
									variant="outlined"
									color="secondary"
									component="span"
									startIcon={<DownloadIcon />}
									onClick={handleSummitDownload}
									sx={{
										mr: '10px',
										mt: '2px',
										minWidth: '124px',
										minHeight: '32px',
										background: STEPTheme.colors.background,
									}}
								>
									{commonLabels.summitDownload}
								</Button>
							</StyledTooltip>
							<StyledTooltip
								title={commonLabels.downloadButtonTooltip}
								placement="top"
							>
								<Button
									variant="outlined"
									color="secondary"
									component="span"
									startIcon={<DownloadIcon />}
									onClick={handleGlobalDownload}
									sx={{
										mr: '10px',
										mt: '2px',
										minWidth: '124px',
										minHeight: '32px',
										background: STEPTheme.colors.background,
									}}
								>
									{commonLabels.download}
								</Button>
							</StyledTooltip>
						</Box>
						<ToggleButtonGroup
							value={viewToggleButton}
							exclusive
							onChange={handleView}
						>
							<StyledTooltip
								title={commonLabels.listViewButtonLabel}
								placement="top"
							>
								<ToggleButtonStyled value="tableView">
									<ListView className="toggle-selected-view" />
								</ToggleButtonStyled>
							</StyledTooltip>
							<StyledTooltip
								title={commonLabels.graphViewButtonLabel}
								placement="top"
							>
								<ToggleButtonStyled
									value="graphView"
									sx={{
										marginLeft: '0px',
									}}
								>
									<GraphView className="toggle-selected-view" />
								</ToggleButtonStyled>
							</StyledTooltip>
						</ToggleButtonGroup>
					</ResultActionRightContainer>
				</ResultHeadingContainer>
				<GeneralResultHeadingContainer>
					{viewToggleButton === 'tableView' ? (
						<ListTableView
							preFilterValue={preFilterValue}
							preSelectedDays={getPreSelectedDays(preFilterValue)}
						/>
					) : (
						<Fragment>
							<ResultFilter hasAudienceDataLoaded={hasAudienceDataLoaded} />
							{keyMetricesAPIStatus === API_STATUS_CONSTANT.FAILED ? (
								<KeyMetricsError
									triggerSelectedDays={triggerSelectedDaysHandler}
									selectedDay={selectedNonCompliantDay}
									refreshFn={refresh}
								/>
							) : (
								<KeyMetrics
									type={dataDisplayType}
									employeesAtRiskOfOverdue={
										keyMetricesData?.metrics?.employeesAtRiskOfOverdue ?? {}
									}
									employeesOverdue={
										keyMetricesData?.metrics?.employeesOverdue ?? {}
									}
									employeesSummitMapped={
										keyMetricesSummitData?.metrics
											?.employeesSummitGroupStatus ?? {}
									}
									triggerSelectedDays={triggerSelectedDaysHandler}
									daysNonCompliant={selectedNonCompliantDay}
									daysComingDue={selectedComingDueDay}
									refreshFn={(isSummitRefresh) => refresh(isSummitRefresh)}
									filterViewEmployees={filterViewEmployees}
								/>
							)}
							<GeneralResultsContainer>
								<GraphContainers
									sx={{
										flexDirection: 'row',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											height: '303px',
											justifyContent: 'space-between',
											alignItems: 'stretch',
											boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
											borderRadius: '12px',
											background: '#fff',
											width: '45.5%',
											padding: '0px 20px',
										}}
									>
										<DonutChart
											reportSubTypeKey={
												questionnaireAPIKeys.reportSubTypeResponseStatus
											}
											downloadHandler={handleReportDownload}
											data={[
												{
													label: commonLabels.respondedTxt,
													value: getStatusData(
														commonLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														''
													),
													color: STEPTheme.colors.lightGreen,
												},
												{
													label: commonLabels.notRespondedTxt,
													value: getStatusData(
														commonLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											id={'donut-chart-svg'}
											width={Numbers.oneFifty}
											height={Numbers.oneFifty}
											summaryData={[
												{
													label: commonLabels.completeTxt,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														''
													),
													color: STEPTheme.colors.lightGreen,
												},
												{
													label: commonLabels.incompleteTxt,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											centerText={`${commonLabels.completeTxt}|${getStatusData(
												dataDisplayType,
												keyMetricesData?.metrics?.employeesResponseStatus ?? {},
												''
											)}${dataDisplayType === 'Percentage' ? '%' : ''}`}
											heading={commonLabels.completed}
											chartType={dataDisplayType}
											summaryPosition="below"
											refresh={refresh}
											showAdditionalInfo={true}
										/>
									</Box>
									<Box
										sx={{
											display: 'flex',
											height: '303px',
											justifyContent: 'space-between',
											boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
											borderRadius: '12px',
											background: '#fff',
											width: '45.5%',
											padding: '0px 20px',
											alignItems: 'stretch',
										}}
									>
										<DonutChart
											reportSubTypeKey={
												questionnaireAPIKeys.reportSubTypeDefaulterDetails
											}
											downloadHandler={handleReportDownload}
											data={[
												{
													label: commonLabels.respondedAfterDueDate,
													value: getStatusData(
														commonLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														''
													),
													color: STEPTheme.colors.amber,
												},
												{
													label: commonLabels.notRespondedYet,
													value: getStatusData(
														commonLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											id={'donut-chart-svg-3'}
											width={Numbers.oneFifty}
											height={Numbers.oneFifty}
											summaryData={[
												{
													label: commonLabels.respondedAfterDueDate,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														''
													),
													color: STEPTheme.colors.amber,
												},
												{
													label: commonLabels.notRespondedYet,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											centerText={`${commonLabels.respondedAfterDueDate}|${getStatusData(
												dataDisplayType,
												keyMetricesData?.metrics?.employeesDefaultersDetails ??
													{},
												''
											)}${dataDisplayType === 'Percentage' ? '%' : ''}`}
											heading={commonLabels.notCompliant}
											chartType={dataDisplayType}
											summaryPosition="below"
											refresh={refresh}
											showAdditionalInfo={true}
										/>
									</Box>
								</GraphContainers>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										paddingTop: '30px',
									}}
								>
									<BarChartCard
										cardKey={questionnaireAPIKeys.reportSubTypeRegionalGraph}
										downloadHandler={handleReportDownload}
										cardName={startCase(commonLabels.completedByRegionTxt)}
										graphData={regionData}
										isChipToShow={true}
										chipData={chipData}
										handleChipSelect={(chipSelected) => {
											chipChangeHandler(chipSelected);
										}}
										colors={[
											STEPTheme.colors.lightBlue,
											STEPTheme.colors.latinBlue,
										]}
										legends={[
											{
												key: commonLabels.contingentWorkerTxt,
												color: STEPTheme.colors.lightBlue,
												label: commonLabels.contingentWorkerTxt,
											},
											{
												key: commonLabels.employeeTxt,
												color: STEPTheme.colors.latinBlue,
												label: commonLabels.employeeTxt,
											},
										]}
										chartType={dataDisplayType}
										isParameterToShow={false}
										isGroupToShow={false}
										graphId={'region-graph-id'}
										height={174}
										yMax={regionalYMax}
										refreshHandler={() => handleGraphRefresh('region')}
									/>
									{groupListUpdated && (
										<BarChartCard
											cardKey={questionnaireAPIKeys.reportSubTypeGroupGraph}
											downloadHandler={handleReportDownload}
											cardName={startCase(commonLabels.groupWiseTxt)}
											graphData={completedByGroupData}
											isChipToShow={false}
											colors={[STEPTheme.colors.purple]}
											legends={[
												{
													key: 'users',
													color: STEPTheme.colors.purple,
													label: commonLabels.totalNoOfUsersTxt,
												},
											]}
											chartType={dataDisplayType}
											isParameterToShow={true}
											isGroupToShow={true}
											graphId={'group-graph-id'}
											height={200}
											yMax={groupYMax}
											groupList={groupListDropDownOptions}
											paramList={parameterListDropDownOptions}
											handleParameterSelect={(e) =>
												parameterSelectHandler(e.target.value)
											}
											handleGroupSelect={(grpName: string) =>
												groupSelectHandler(grpName)
											}
											groupSelected={groupSelected}
											parameterSelected={parameterSelected}
											refreshHandler={() => handleGraphRefresh('group')}
										/>
									)}
								</Box>
							</GeneralResultsContainer>
							<Main />
						</Fragment>
					)}
				</GeneralResultHeadingContainer>
			</QuestionnaireResultContainer>
			{viewToggleButton === 'graphView' && (
				<Fragment>
					<GenericModalPopup
						openProp={showPopup}
						msgBody={commonLabels.reportDownloadErrorTitle}
						mainMsg=""
						rightBtnText={commonLabels.ok}
						leftBtnText={commonLabels.no}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={handleClosePopup}
						leftBtnHandler={handleClosePopup}
					/>
					<GenericConfirmationPopup
						open={showMaxLimitWarning}
						msgBody={
							<StyledMsgBody>
								{commonLabels.audienceMaxRowsMessage}
							</StyledMsgBody>
						}
						title={commonLabels.audienceMaxRowsTitle}
						rightBtnText={commonLabels.ok}
						leftBtnText={commonLabels.no}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={handleMaxDataExport}
						leftBtnHandler={handleCloseMaxDataWarningPopup}
					/>
				</Fragment>
			)}
		</>
	);
};

export default QuestionnaireResult;
