import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import SurveyHorizontalStepper from '../../components/SurveyHorizontalStepper';
import { ReactComponent as LeftArrowIcon } from '../../assets/images/LeftArrow.svg';
import { useAppSelector } from '../../app/Hooks';
import {
	questionnaireStatus,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { Numbers, QUE_JOURNEY_STEPS } from '../../utils/Enum';
import { SURVEY_STATUS } from '../../constants/StepConstants';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import { addPageViewEventForGA } from '../../utils/CommonMethods';
import CreateSurveyPreview from './CreateSurveyPreview';
import CreateSurveyNotifications from './CreateSurveyNotifications';
import CreateSurveyDetails from './CreateSurveyDetails';
import CreateSurveyAudiences from './CreateSurveyAudiences';
import CreateSurveyQuestions from './CreateSurveyQuestions';

const useStyles = makeStyles({
	disbledNav: {
		opacity: '0.2',
		pointerEvents: 'none',
	},
});
const StyledPrevButton = styled(IconButton)({
	'&:hover': {
		backgroundColor: 'transparent',
	},
	position: 'absolute',
	top: '104px',
	left: '70px',
});

const StyledLeftArrowIcon = styled(LeftArrowIcon)({
	'&:hover': {
		cursor: 'pointer',
	},
});

const CreateSurvey: React.FC = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { landingStep } = useParams();
	const questionnaireDetailsStep = QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS;
	const createSurveyAudiencesStep = QUE_JOURNEY_STEPS.STEP_AUDIENCE;
	const createSurveyQuestionsStep = QUE_JOURNEY_STEPS.STEP_QUESTIONS;
	const createSurveyPreviewStep = QUE_JOURNEY_STEPS.STEP_PREVIEW;
	const createSurveyNotificationsStep = QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS;
	const [activeStep, setActiveStep] = useState(
		landingStep ? Number(landingStep) : questionnaireDetailsStep
	);
	const [activeMaxStep, setActiveMaxStep] = useState(
		landingStep ? Number(landingStep) : questionnaireDetailsStep
	);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const questionnaireStatusInfo = useAppSelector(questionnaireStatus);
	const [isStatusActive, setIsStatusActive] = useState(false);

	useEffect(() => {
		setActiveMaxStep(Math.max(activeStep, activeMaxStep));
	}, [activeStep, activeMaxStep]);

	useEffect(() => {
		if (
			questionnaireStatusInfo === SURVEY_STATUS.ACTIVE ||
			questionnaireStatusInfo === SURVEY_STATUS.PUBLISHED ||
			questionnaireStatusInfo === SURVEY_STATUS.COMPLETED
		) {
			setIsStatusActive(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireStatusInfo]);
	useEffect(() => {
		if (
			surveyQuestionnaireInfo?.currentStep &&
			surveyQuestionnaireInfo?.currentStep !==
				QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS &&
			!landingStep
		) {
			setActiveStep(surveyQuestionnaireInfo.currentStep);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * @method
	 * @summary - Move forwards to the next step for the create-questionnaire form
	 * @param - Empty
	 * @returns void
	 */
	const handleNext = (): void => {
		setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
	};

	useEffect(() => {
		const url = new URL(window.location.href);
		const urlArray = url.pathname.split('/');
		let header;
		if (activeStep === Numbers.one) {
			header = questionnaireAPIKeys.CreateQuestionnaireDetails;
		} else if (activeStep === Numbers.two) {
			header = questionnaireAPIKeys.CreateQuestionnaireAudiance;
		} else if (activeStep === Numbers.three) {
			header = questionnaireAPIKeys.CreateQuestionnaireQuestions;
		} else if (activeStep === Numbers.four || urlArray[Numbers.two] === '4') {
			header = questionnaireAPIKeys.CreateQuestionnairePreview;
		} else if (activeStep === Numbers.five) {
			header = questionnaireAPIKeys.CreateQuestionnaireNotification;
		} else if (activeStep === Numbers.six) {
			header = questionnaireAPIKeys.CreateQuestionnairePublish;
		} else if (activeStep === Numbers.seven) {
			header = questionnaireAPIKeys.CreateQuestionnaireAudiance;
		} else {
			header = questionnaireAPIKeys.CreateQuestionnaire;
		}

		addPageViewEventForGA(
			'pageview',
			urlArray.length > Numbers.two
				? `/${urlArray[Numbers.one]}/%`
				: url.pathname,
			header
		);
	}, [activeStep]);

	const handleBack = (): void => {
		if (!landingStep && activeStep > 1) {
			setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
		} else {
			navigate('/');
			navigate(0);
		}
	};

	const backToDetailsPage = (): void => {
		if (activeStep === createSurveyQuestionsStep) {
			setActiveStep(questionnaireDetailsStep);
		}
	};

	return (
		<div data-testid="CreateSurvey">
			<StyledPrevButton
				aria-label="previous"
				data-testid="previous-btn"
				className={!landingStep && isStatusActive ? classes.disbledNav : ''}
				onClick={handleBack}
			>
				<StyledLeftArrowIcon data-testid="LeftArrowIcon" />
			</StyledPrevButton>
			{!landingStep && (
				<SurveyHorizontalStepper
					data-testid="SurveyHorizontalStepper"
					activeStep={activeStep}
					setActiveStep={(outerActiveStep) =>
						((innerActiveStep: number): void => {
							setActiveStep(innerActiveStep);
						})(outerActiveStep)
					}
					activeMaxStep={activeMaxStep}
				/>
			)}
			{activeStep === questionnaireDetailsStep && (
				<CreateSurveyDetails
					data-testid="CreateSurveyDetails"
					handleNext={handleNext}
				/>
			)}
			{activeStep === createSurveyAudiencesStep && (
				<CreateSurveyAudiences
					data-testid="CreateSurveyAudiences"
					handleNext={handleNext}
				/>
			)}
			{activeStep === createSurveyQuestionsStep && (
				<CreateSurveyQuestions
					data-testid="CreateSurveyQuestions"
					handleNext={handleNext}
					handleBack={handleBack}
					backToDetailsPage={backToDetailsPage}
					activeStep={QUE_JOURNEY_STEPS.STEP_QUESTIONS}
				/>
			)}
			{activeStep === createSurveyPreviewStep && (
				<CreateSurveyPreview
					data-testid="CreateSurveyPreview"
					handleNext={handleNext}
				/>
			)}
			{activeStep === createSurveyNotificationsStep && (
				<CreateSurveyNotifications
					data-testid="CreateSurveyNotifications"
					handleNext={handleNext}
				/>
			)}
		</div>
	);
};

export default CreateSurvey;
