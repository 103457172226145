/* eslint-disable react/prop-types */
import React, { useState, ReactNode, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import Step from '@mui/material/Step';
import StepIcon from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, {
	stepConnectorClasses,
} from '@mui/material/StepConnector';
import { makeStyles } from '@material-ui/core';
import { StyledStepProps } from '../types/CommonTypes';
import { STEPTheme } from '../utils/Theme';
import { questionnaireStatus } from '../redux/SurveySlice';
import { useAppSelector } from '../app/Hooks';
import { SURVEY_STATUS } from '../constants/StepConstants';
import { Numbers } from '../utils/Enum';
import { getSTEPLabels } from '../utils/CommonMethods';
const useStyles = makeStyles({
	activePublishedStatus: {
		'& .MuiSvgIcon-root': {
			color: `${STEPTheme.colors.black} !important`,
			pointerEvents: 'none',
			opacity: '0.3',
		},
		'& .MuiStepLabel-label': {
			color: `${STEPTheme.colors.disabledText} !important`,
			fontWeight: '400 !important',
		},
	},
	activePublishedLine: {
		'& .MuiStepConnector-line': {
			borderColor: `${STEPTheme.colors.lightGray} !important`,
		},
	},
});
const StyledStepperContainer = styled(Box)({
	width: '70%',
	margin: 'auto',
	paddingTop: '50px',
	paddingBottom: '20px',
});

const StyledStep = styled((props: StyledStepProps) => <Step {...props} />)({
	'& .MuiStepLabel-root .Mui-completed .Mui-active': {
		color: STEPTheme.colors.green,
	},
	'& .MuiStepLabel-root  .Mui-disabled': {
		color: STEPTheme.colors.disabledText,
	},
	'& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
		color: STEPTheme.colors.green,
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: '700',
	},
	'& .MuiStepLabel-root .Mui-active .MuiStepIcon-root ': {
		border: `2px solid ${STEPTheme.colors.green}`,
		color: STEPTheme.colors.white,
		borderRadius: '50%',
		fontColor: STEPTheme.colors.green,
		width: '20px',
		height: '20px',
	},
	'& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
		color: STEPTheme.colors.green,
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: '700',
	},
	'& .MuiStepLabel-label .Mui-disabled.MuiStepLabel-alternativeLabel': {
		color: STEPTheme.colors.disabledText,
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: '700',
	},
	'& .MuiStepLabel-root .Mui-completed .Mui-active .MuiStepIcon-text': {
		fill: 'white',
	},
	'& .MuiStepLabel-root  .Mui-active .MuiStepIcon-text': {
		fill: STEPTheme.colors.green,
		fontSize: '14px',
	},
	'& .MuiStepConnector-line .MuiStepConnector-lineHorizontal .MuiStepConnector-line':
		{
			color: STEPTheme.colors.green,
		},
	'& .MuiStepLabel-alternativeLabel .MuiStepLabel-label': {
		marginTop: '-50px',
	},
});

const StyledStepConnector = styled(StepConnector)(() => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: '10',
		left: 'calc(-50% + 16px)',
		right: 'calc(50% + 16px)',
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: STEPTheme.colors.green,
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			borderColor: STEPTheme.colors.green,
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		borderColor: STEPTheme.colors.lightGray,
		borderTopWidth: '3px',
		borderRadius: 1,
	},
}));

const StepperSx = {
	'& .MuiStepConnector-root': {
		left: 'calc(-50% + 40px)',
		right: 'calc(50% + 40px)',
	},
	'& .MuiStepConnector-line': {
		marginTop: '16px',
	},
};

interface SurveyHorizontalStepperProps {
	activeStep: number;
	setActiveStep: (activeStep: number) => void;
	activeMaxStep?: number;
}
const SurveyHorizontalStepper: React.FC<SurveyHorizontalStepperProps> = ({
	activeStep,
	setActiveStep,
}) => {
	const classes = useStyles();
	const [isStatusActive, setIsStatusActive] = useState(false);
	const questionnaireStatusInfo = useAppSelector(questionnaireStatus);
	const stepsLabels = getSTEPLabels();
	useEffect(() => {
		if (
			questionnaireStatusInfo === SURVEY_STATUS.ACTIVE ||
			questionnaireStatusInfo === SURVEY_STATUS.PUBLISHED ||
			questionnaireStatusInfo === SURVEY_STATUS.COMPLETED
		) {
			setIsStatusActive(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireStatusInfo]);
	return (
		<StyledStepperContainer>
			<Stepper
				className={isStatusActive ? classes.activePublishedLine : ''}
				activeStep={activeStep - 1}
				alternativeLabel
				sx={{ StepperSx }}
				connector={<StyledStepConnector />}
			>
				{stepsLabels.map((label, index) => (
					<StyledStep key={label}>
						<StepLabel
							className={
								isStatusActive && index < Numbers.four
									? classes.activePublishedStatus
									: ''
							}
							StepIconComponent={(props: {
								icon: ReactNode;
								active?: boolean;
								completed?: boolean;
							}) => (
								<StepIcon
									className={`${index < activeStep - 1 ? 'cursor-pointer' : ''}`}
									{...props}
									{...(index < activeStep - 1
										? {
												onClick: () => {
													setActiveStep(index + Numbers.one);
												},
											}
										: {})}
									icon={props.icon}
									active={props.active || props.completed}
									completed={false}
								/>
							)}
						>
							{label}
						</StepLabel>
					</StyledStep>
				))}
			</Stepper>
		</StyledStepperContainer>
	);
};

export default SurveyHorizontalStepper;
