import {
	Box,
	Checkbox,
	Typography,
	Divider,
	TextField,
	FormControl,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	FormControlLabel,
	Autocomplete,
	FilledTextFieldProps,
	OutlinedTextFieldProps,
	StandardTextFieldProps,
	TextFieldVariants,
	Button,
	InputBase,
} from '@mui/material';
import styled from '@emotion/styled';
import { useForm, Controller } from 'react-hook-form';
import { Fragment, useEffect, useRef } from 'react';
import React from 'react';
import _ from 'lodash';
import { JSX } from 'react/jsx-runtime';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { commonLabels } from '../../utils/CommonLabels';
import { ISurveyFormsQuestion, SurveyOptions } from '../../types/SurveyProps';
import { STEPTheme } from '../../utils/Theme';
import {
	MAX_LENGTH_MAPPING_GROUP_NAME,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { Numbers } from '../../utils/Enum';
import {
	FormState,
	IGroup,
	IGroupQuestions,
	IGroupSaveReq,
} from '../../types/MappingTypes';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	clearGroupName,
	getSummitSurveyList,
	groupsList,
	isGroupNameCleared,
	updateSaveGroupReq,
} from '../../redux/MappingSlice';
import {
	configurationData,
	getSurveyStartDate,
	selectedFilterRequestPayloadData,
	setSelectedFilterRequestPayload,
} from '../../redux/SurveySlice';
import StyledTooltip from '../StyledTooltip';
import {
	initialFilterLabelJson,
	initialFilterValue,
} from '../../utils/SurveyAudience';
import { getFormattedDate } from '../../utils/DateFormatting';
import {
	replaceSpecialCharacter,
	sortedQuestionList,
} from '../../utils/Helpers';
import { TextBlueButton } from './common/TextButtonBlue';
import { Styles } from './common/Styles';
import MappingGroupCriteria from './MappingGroupCriteria';

const StyledCriteriaLabel = styled(Typography)({
	fontFamily: 'JohnsonText-Medium',
	fontSize: '16px',
	fontWeight: '500',
	lineHeight: '16px',
	gap: '4px',
	padding: '2vh 0',
});
const StyledDateCriteriaLabel = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	gap: '4px',
	padding: '1vh 0',
});

const StyledQuestionsText = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: 400,
	lineHeight: '16px',
	textAlign: 'left',
	paddingLeft: '6px',
});
const StyledGroupNameInput = styled(Autocomplete)({
	fontFamily: 'JohnsonText-Regular',
	border: '1px',
	fontSize: '14px',
	color: STEPTheme.colors.darkGray,
	'& .MuiInputBase-root': {
		borderRadius: '8px',
	},
	'& .MuiOutlinedInput-root': {
		padding: 0,
	},
});

const StyledInputBox = styled(Box)({
	marginLeft: '10px',
	marginRight: '10px',
	border: `1px solid ${STEPTheme.colors.inputBaseBorder}`,
	borderRadius: '10px',
	paddingLeft: '15px',
	paddingRight: '15px',
	width: 'auto',
	color: STEPTheme.colors.darkGray,
	fontFamily: 'JohnsonText-Regular',
});

const ApplicableFormControl = styled(FormControlLabel)({
	'& .MuiTypography-root': {
		fontFamily: 'JohnsonText-Regular',
		fontWeight: 700,
		fontSize: '10px',
		lineHeight: '12px',
	},
});

const NoActiveGroupSelected = styled(Box)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '18px',
	lineHeight: '24px',
	fontWeight: '400',
	fontStyle: 'italic',
	position: 'relative',
	top: '47%',
	color: STEPTheme.colors.darkGray,
	display: 'flex',
	justifyContent: 'center',
});
const LinkButtonDiv = styled('div')({
	marginLeft: 'auto',
});

const StyledTextBlueButton = styled(TextBlueButton)({
	background: 'none !important',
});

const StyledActionButton = styled(Button)({
	color: `${STEPTheme.colors.black}`,
	border: `1px solid ${STEPTheme.colors.shadowGray}`,
	bgcolor: `${STEPTheme.colors.white}`,
	fontWeight: Numbers.fiveHundred,
	minWidth: '24px',
	height: '24px',
	borderRadius: '8px',
	fontSize: '16px',
	'&.Mui-disabled': {
		background: `${STEPTheme.colors.shadowGray}`,
	},
});

const MappingGroupConfig = (props: {
	questionData: ISurveyFormsQuestion[];
	groupCount: number;
	groupSelected: string;
	groupCopied: string;
	validateConfiguration: boolean;
	questionnaireId: string;
	summitSurveyList: string[];
	newGroupCreated: boolean;
	showSaveConfirmation: (action: string) => void;
	checkIfNameDuplicate: (groupName: string) => boolean;
	resetValidationState: () => void;
	checkGroupNameErr: (hasError: boolean) => void;
	checkIfNameMatchSummit: (groupName: string) => void;
	onFormChange: () => void;
	setSaveButtonFalse: () => void;
	isSaveButtonClicked: boolean;
}) => {
	const form = useForm();
	const {
		register,
		watch,
		handleSubmit,
		formState,
		control,
		setError,
		clearErrors,
		getValues,
		setValue,
		reset,
		resetField,
	} = form;
	const { errors } = formState;
	const dispatch = useAppDispatch();
	const summitSurveys = useAppSelector(getSummitSurveyList);
	const saveGroupFormRef = useRef<HTMLInputElement | null>(null);
	const groupList = useAppSelector(groupsList);
	const groupNameCleared = useAppSelector(isGroupNameCleared);
	const configs = useAppSelector(configurationData);
	const surveyDate = useAppSelector(getSurveyStartDate);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	const defaultIDs = ['0', '-1'];

	const [fullDuration, setFullDuration] = React.useState(
		getValues('duration') || Numbers.zero
	);

	const [isAnyQuestionAnswered, setIsAnyQuestionAnswered] =
		React.useState(false);
	const [charsCountForGrpName, setCharsCountForGrpName] = React.useState(
		Numbers.zero
	);
	const [isDefaultGroupState, setIsDefaultGroup] = React.useState(false);
	const remainingCountForGrpName =
		MAX_LENGTH_MAPPING_GROUP_NAME - charsCountForGrpName;
	const uniqueQuestionData = _.uniq(_.map(props?.questionData, 'questionId'));
	const [applicableQuestions, setApplicableQuestions] = React.useState<
		Set<string>
	>(new Set());
	const [visibleQuestions, setVisibleQuestions] = React.useState<Set<string>>(
		new Set()
	);
	const [initialState, setInitialState] = React.useState<FormState[]>([]);
	// This State keeps track of previously selected values of all questions
	const [previousValues, setPreviousValues] = React.useState<{
		[key: string]: string;
	}>({});
	const [hasDurationError, setHasDurationError] = React.useState(false);
	type answerSetProps = {
		[index: string]: string;
	};

	useEffect(() => {
		const subscription = watch((value) => {
			const isAnswered = Object.keys(value).some((key) => {
				return value[key] && key !== 'groupName';
			});
			setIsAnyQuestionAnswered(isAnswered);
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const reInstateDuration = () => {
		setFullDuration(Numbers.zero);
		setHasDurationError(false);
	};

	useEffect(() => {
		reInstateDuration();
	}, [props.groupSelected]);

	// If Question data changes then based on it independent questions are shown by default
	useEffect(() => {
		if (props.groupSelected === '-1' || props.groupSelected === '0') {
			setCharsCountForGrpName(Numbers.zero);
			setPreviousValues({});
			setVisibleQuestions(new Set());
			setApplicableQuestions(new Set());
			const initialVisibleQuestions = new Set(
				getIndependentQuestions(props.questionData)
			);
			setVisibleQuestions(initialVisibleQuestions);
		} else {
			setPreviousValues({});
			setVisibleQuestions(new Set());
			setApplicableQuestions(new Set());
			prePopulateSelectedOptions();
			const defaultViewQuestions = new Set(
				getDefaultViewQuestionsInEditMode(props.questionData)
			);
			setVisibleQuestions(defaultViewQuestions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.questionData, props.groupSelected]);

	// Function to get the current form state
	const getFormState = () => {
		const formData = getValues();
		return props.questionData.map((question) => ({
			questionId: question.questionId,
			answer: formData[question.questionId] || '',
			applicable:
				formData[`${commonLabels.applicableQIdText}${question.questionId}`] ||
				false,
		}));
	};

	// Use useEffect to set the initial state when the component mounts or when groupSelected changes
	useEffect(() => {
		setInitialState([]);
		reset();
		const initialFormState = getFormState();
		setInitialState(initialFormState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.groupSelected, props.newGroupCreated]);
	// Function to compare the initial state with the current state
	const hasMeaningfulChanges = () => {
		const currentState = getFormState();
		let hasChanges = false;

		initialState.forEach((initialStateItem, index) => {
			const currentStateItem = currentState[index];

			if (
				initialStateItem.questionId !== currentStateItem.questionId ||
				initialStateItem.answer !== currentStateItem.answer
			) {
				hasChanges = true;
			}
		});

		return hasChanges;
	};

	// Function to populate previously selected options
	const prePopulateSelectedOptions = () => {
		const currentGroup = groupList.filter(
			(group) => group.groupId === props.groupSelected
		);
		const questionnaireJson = currentGroup[0]?.questionnaireJson;
		const configuredOptions = JSON.parse(questionnaireJson);
		configuredOptions?.forEach((question: IGroupQuestions) => {
			if (question?.applicable) {
				const questionId = question.questionId.split('_')[Numbers.one];
				const condition = getConditionBasedOnAnswer(
					question.answer,
					question.options
				);
				setPreviousValues((prev) => ({
					...prev,
					[questionId]: condition,
				}));
			}
		});
	};

	// Function to get the next question ID based on the condition
	const getNextQuestionId = (
		condition: string,
		questions: ISurveyFormsQuestion[]
	): string | null => {
		if (condition === 'End Questionnaire') return 'end';
		if (condition?.startsWith(commonLabels.continueTo)) {
			const nextQuestionId = parseInt(condition.split(' ')[Numbers.three]);
			const question = questions[nextQuestionId - 1];
			return question && questions?.length < nextQuestionId
				? question?.questionId
				: null;
		}
		return null;
	};

	// Function to find condition of an option based on answer
	const getConditionBasedOnAnswer = (
		answer: string,
		optionsToFindFrom: SurveyOptions[]
	): string => {
		let condition: string = '';
		if (typeof optionsToFindFrom === 'string') {
			optionsToFindFrom = JSON.parse(optionsToFindFrom);
		}
		optionsToFindFrom.forEach((option) => {
			if (option.options === answer) {
				condition = option.condition;
			}
		});
		return condition;
	};

	// Function get all Independent questions
	const getIndependentQuestions = (
		questions: ISurveyFormsQuestion[]
	): string[] => {
		const dependentIds = new Set<string>();
		questions.forEach((q) => {
			q.options.forEach((opt) => {
				const nextId = getNextQuestionId(opt.condition, questions);
				if (nextId && nextId !== 'end') dependentIds.add(nextId);
			});
		});
		return questions
			.map((q) => q.questionId)
			.filter((questionId) => !dependentIds.has(questionId));
	};

	// Function to update the state of visible questions and Applicable questions
	const getDefaultViewQuestionsInEditMode = (
		questions: ISurveyFormsQuestion[]
	): string[] => {
		const visibleIds = new Set<string>();
		const answerSet = [] as unknown as answerSetProps;
		const currentGroup = groupList.filter(
			(group) => group.groupId === props.groupSelected
		);
		const questionnaireJson = currentGroup[0]?.questionnaireJson;
		const configuredOptions = JSON.parse(questionnaireJson);
		configuredOptions?.forEach((question: IGroupQuestions) => {
			if (question?.applicable === true) {
				const questionId = question.questionId.split('_')[Numbers.one];
				visibleIds.add(questionId);
				answerSet[questionId] = question?.answer;
			}
		});
		setApplicableQuestions((prev) => new Set([...prev, ...visibleIds]));
		const dependentIds = new Set<string>();
		const idsToDeleteIfEndQuestionnaire = new Set<string>();
		questions.forEach((q) => {
			q.options.forEach((opt) => {
				const nextId = getNextQuestionId(opt.condition, questions);
				if (nextId && nextId !== 'end') dependentIds.add(nextId);
				if (
					answerSet[q?.questionId]?.includes(opt?.options) &&
					nextId === 'end' &&
					visibleIds.has(q.questionId)
				) {
					const indexOfQuestionToDeleteAfter = props.questionData.findIndex(
						(question) => question?.questionId === q.questionId
					);
					//  Reset and hide all next questions
					for (
						let currentIndexToDelete = indexOfQuestionToDeleteAfter + 1;
						currentIndexToDelete < props.questionData.length;
						currentIndexToDelete++
					) {
						idsToDeleteIfEndQuestionnaire.add(
							props.questionData[currentIndexToDelete].questionId
						);
					}
				}
			});
		});
		const independentQuestions = questions
			.map((q) => q.questionId)
			.filter((questionId) => !dependentIds.has(questionId));
		const mergedVisibleSet = new Set([...visibleIds, ...independentQuestions]);
		idsToDeleteIfEndQuestionnaire.forEach((id) => mergedVisibleSet.delete(id));
		return [...mergedVisibleSet];
	};

	const handleClearQuestionBetweenIndices = (
		startQuestionIndex: number,
		endQuestionIndex: number,
		currentVisibleSetState: Set<string>
	) => {
		if (startQuestionIndex !== -1 && endQuestionIndex !== -1) {
			const minIndex = Math.min(startQuestionIndex, endQuestionIndex);
			const maxIndex = Math.max(startQuestionIndex, endQuestionIndex);
			for (
				let currentIndexToDelete = minIndex + 1;
				currentIndexToDelete < maxIndex;
				currentIndexToDelete++
			) {
				const questionToClear = props.questionData[currentIndexToDelete];
				hideSubQuestions(questionToClear.questionId, currentVisibleSetState);
			}
		}
	};

	// Function to handle the change or selection of an option
	const handleOptionSelect = (questionId: string, condition: string) => {
		//Find next question's id based on received condition
		const nextQuestionId = getNextQuestionId(condition, props.questionData);
		//Find the previous condition if any option had already selected
		const previousCondition = previousValues[questionId];

		if (previousCondition) {
			// If Previous Condition is available find next question of that previous condition
			const previousNextQuestionId = getNextQuestionId(
				previousCondition,
				props.questionData
			);
			// If previousNextQuestionId present then hide all sub questions of the current question
			if (previousNextQuestionId) {
				hideSubQuestions(questionId, visibleQuestions);
			}
		}

		setVisibleQuestions((prevState) => {
			const updated = new Set(prevState);
			if (nextQuestionId === 'end' && applicableQuestions.has(questionId)) {
				hideResetAllQuestionsAfter(questionId, updated);
			} else if (nextQuestionId) {
				updated.add(nextQuestionId);
			} else if (condition === '') {
				// Show the next question if the condition is empty
				const currentIndex = props.questionData.findIndex(
					(q) => q.questionId === questionId
				);
				const nextIndex = currentIndex + 1;
				if (nextIndex < props.questionData.length) {
					const nextQuestion = props.questionData[nextIndex];
					updated.add(nextQuestion.questionId);
				}
			}

			// Clear the form state for all questions between the current question and the next question
			if (nextQuestionId) {
				const currentIndex = props.questionData.findIndex(
					(q) => q.questionId === questionId
				);
				const nextIndex = props.questionData.findIndex(
					(q) => q.questionId === nextQuestionId
				);
				handleClearQuestionBetweenIndices(currentIndex, nextIndex, updated);
			}

			setPreviousValues((prev) => ({
				...prev,
				[questionId]: condition,
			}));

			return updated;
		});

		props.onFormChange();
	};

	const handleMultiSelectOption = (
		questionId: string,
		selectedOptions: string[]
	) => {
		const conditions = selectedOptions.map((option) => {
			return getConditionBasedOnAnswer(
				option,
				props.questionData.find((q) => q.questionId === questionId)?.options ||
					[]
			);
		});

		// Check if any of the conditions is 'End Questionnaire'
		if (conditions.includes('End Questionnaire')) {
			hideResetAllQuestionsAfter(questionId, visibleQuestions);
		} else {
			// Handle each condition
			conditions.forEach((condition) => {
				handleOptionSelect(questionId, condition);
			});

			// If no options are selected or all selected options have empty conditions, show the next questions
			if (
				selectedOptions.length === 0 ||
				conditions.every((condition) => condition === '')
			) {
				const currentIndex = props.questionData.findIndex(
					(q) => q.questionId === questionId
				);
				const nextIndex = currentIndex + 1;
				if (nextIndex < props.questionData.length) {
					setVisibleQuestions((prevState) => {
						const updated = new Set(prevState);
						// Ensure all subsequent questions are shown
						props.questionData
							.slice(nextIndex)
							.forEach((q: ISurveyFormsQuestion) => updated.add(q.questionId));
						return updated;
					});
				}
			}
		}
	};

	// Function to handle the cases if a question is marked applicable or Non-Applicable
	const toggleApplicable = (questionId: string) => {
		//Update the applicable Questions set
		setApplicableQuestions((prevState) => {
			const updated = new Set(prevState);
			// If question already applicable reset its selected options and hide all its sub questions
			if (updated.has(questionId)) {
				const questionData = props.questionData.find(
					(qData) => qData.questionId === questionId
				);
				// Reset answers of multi select
				if (
					questionData?.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
				) {
					for (const option of questionData.options) {
						resetField(questionId + replaceSpecialCharacter(option.options));
					}
				}
				resetField(questionId);
				setValue(questionId, '');
				// remove selected options for multi options
				updated.delete(questionId);
				setVisibleQuestions((prev) => {
					const updatedVisible = new Set(prev);
					hideSubQuestions(questionId, updatedVisible);
					return updatedVisible;
				});
			} else {
				// If previously not applicable then mark it applicable
				updated.add(questionId);
			}
			return updated;
		});
		// Only call onFormChange if there are meaningful changes
		if (hasMeaningfulChanges()) {
			props.onFormChange();
		}
	};
	// Function to hide all questions after a Question (when End Questionnaire condition is encountered)
	const hideResetAllQuestionsAfter = (
		questionId: string,
		visibleSet: Set<string>
	) => {
		// Loop through all questions and find current question index,
		const indexOfQuestionToDeleteAfter = props.questionData.findIndex(
			(question) => question?.questionId === questionId
		);
		//  Reset and hide all next questions
		for (
			let currentIndexToDelete = indexOfQuestionToDeleteAfter + 1;
			currentIndexToDelete < props.questionData.length;
			currentIndexToDelete++
		) {
			const currentQuestion = props.questionData[currentIndexToDelete];
			visibleSet.delete(currentQuestion.questionId);
			resetField(currentQuestion.questionId);
			setValue(
				`${commonLabels.applicableQIdText}${currentQuestion.questionId}`,
				false
			);
			setApplicableQuestions((prev) => {
				const newApplicable = new Set(prev);
				newApplicable.delete(currentQuestion.questionId);
				return newApplicable;
			});

			// If the question is a multi-select, reset each option
			if (
				currentQuestion.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
			) {
				currentQuestion.options.forEach((option) => {
					resetField(
						`${currentQuestion.questionId}${replaceSpecialCharacter(option.options)}`
					);
					setValue(
						`${currentQuestion.questionId}${replaceSpecialCharacter(option.options)}`,
						false
					);
				});
			}
		}
	};

	// Function to hide sub questions
	const hideSubQuestions = (questionId: string, visibleSet: Set<string>) => {
		// Find current question from questionId
		const question = props.questionData.find(
			(q) => q.questionId === questionId
		);
		// If current question available
		if (question) {
			// Loop through each option of the current question
			question.options.forEach((option) => {
				// Find next question based on option
				const nextQuestionId = getNextQuestionId(
					option.condition,
					props.questionData
				);
				const nextQuestion = props.questionData.find(
					(q) => q.questionId === nextQuestionId
				);
				// If nextQuestionId is valid (that is End Questionnaire not encountered)
				if (nextQuestionId && nextQuestionId !== 'end') {
					// Remove it from visible questions and clear its selected options
					visibleSet.delete(nextQuestionId);
					// If question Type is Select Then loop through all options and clear all options
					if (
						nextQuestion &&
						nextQuestion?.questionType ===
							QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
					) {
						nextQuestion.options.forEach((nxtQueOption) => {
							setValue(`${nextQuestionId}${nxtQueOption.options}`, '');
						});
					}
					resetField(nextQuestionId);
					setValue(nextQuestionId, '');
					setValue(`${commonLabels.applicableQIdText}${nextQuestionId}`, false);
					setApplicableQuestions((prev) => {
						const newApplicable = new Set(prev);
						newApplicable.delete(nextQuestionId);
						return newApplicable;
					});

					// Recursively call hideSubQuestions to hide all the child questions possible
					hideSubQuestions(nextQuestionId, visibleSet);
				} else if (nextQuestionId === 'end') {
					// If while hiding sub question End Questionnaire is encountered then add the Independent questions in visible list
					setVisibleQuestions((prev) => {
						const newVisible = new Set(prev);
						const independentQuestions = getIndependentQuestions(
							props.questionData
						);
						independentQuestions.forEach((independentQuestion) =>
							newVisible.add(independentQuestion)
						);
						return newVisible;
					});
					hideSubQuestions(nextQuestionId, visibleSet);
				} else {
					/* empty */
				}
			});
		}
	};

	const groupNameRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (props.validateConfiguration) {
			// clear previous error to revalidate
			clearErrors();
			// click on form submit button to validate form
			if (saveGroupFormRef?.current) {
				saveGroupFormRef?.current.click();
			}
		}
		if (groupNameCleared && groupNameRef?.current) {
			const ele: HTMLElement = groupNameRef?.current.getElementsByClassName(
				'MuiAutocomplete-clearIndicator'
			)[Numbers.zero] as HTMLElement;
			if (ele) {
				ele.click();
				// reset flag of clear group name
				dispatch(clearGroupName(false));
				setCharsCountForGrpName(Numbers.zero);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.validateConfiguration, groupNameCleared]);

	useEffect(() => {
		if (fullDuration === Numbers.zero) {
			setHasDurationError(true);
		} else {
			props.setSaveButtonFalse();
			setHasDurationError(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fullDuration]);

	useEffect(() => {
		const isDefaultGroupCheckbox = getValues(
			`${commonLabels.questionNotFound}`
		);
		if (!defaultIDs.includes(props.groupSelected)) {
			const currentGroup = groupList.filter(
				(group) => group.groupId === props.groupSelected
			);
			const isCurrentGroupDefaulter = currentGroup[Numbers.zero].isDefaulter;
			if (
				(!isDefaultGroupState && !isDefaultGroupCheckbox) ||
				!isCurrentGroupDefaulter
			) {
				setValue(`${commonLabels.questionNotFound}`, false);
				setValue('effectiveFormTyp', '');
				setIsDefaultGroup(isCurrentGroupDefaulter);
			}
			repopulateFormOnModify(
				props.groupSelected,
				props.groupCopied,
				currentGroup[Numbers.zero]
			);
		} else {
			// defaultID group is selected; Reset old values
			reset();
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: JSON.stringify(initialFilterValue),
					excludeFilters: JSON.stringify(initialFilterValue),
					includeClause: '',
					excludeClause: '',
					filterLabelJson: initialFilterLabelJson,
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.groupSelected, props.groupCopied, isDefaultGroupState]);

	/**
	 * @description fetch the default group from response once saved.
	 * @param groupDetails
	 */
	const fetchDefaultGrpResponse = (groupDetails: IGroup) => {
		if (
			groupDetails.isDefaulter &&
			isDefaultGroupState &&
			!getValues(`${commonLabels.questionNotFound}`)
		) {
			setValue(`${commonLabels.questionNotFound}`, groupDetails.isDefaulter);
			if (groupDetails.defaulterApplicableDate) {
				const effectiveDate = new Date(groupDetails.defaulterApplicableDate);
				setValue('effectiveFormTyp', commonLabels.date);
				setValue('effectiveDate', effectiveDate);
			} else if (groupDetails.defaulterDuration) {
				setValue('effectiveFormTyp', commonLabels.duration);
				setValue('duration', groupDetails.defaulterDuration);
				setFullDuration(groupDetails.defaulterDuration);
			} else {
				setValue('effectiveFormTyp', '');
				setValue('effectiveDate', '');
				setValue('duration', '');
			}
		}
	};
	const repopulateFormOnModify = (
		groupSelected: string,
		groupCopied: string,
		groupDetails: IGroup
	) => {
		// clear all previous group errors
		clearErrors();
		reset();
		fetchDefaultGrpResponse(groupDetails);
		const filterJson = groupDetails.filterJson;
		const questionnaireJson = groupDetails.questionnaireJson;
		groupCopied === groupSelected
			? setValue('groupName', '')
			: setValue('groupName', groupDetails.groupName);
		setCharsCountForGrpName(groupDetails?.groupName?.length || Numbers.zero);
		const configuredOptions = JSON.parse(questionnaireJson);
		configuredOptions?.forEach((question: IGroupQuestions) => {
			const questionId = question.questionId.split('_')[Numbers.one];
			setValue(
				`${commonLabels.applicableQIdText}${questionId}`,
				question.applicable
			);
			if (question.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
				const parsedAnswers = JSON.parse(question.answer);
				if (parsedAnswers && parsedAnswers.length > 0) {
					for (const element of parsedAnswers) {
						setValue(
							`${questionId}${replaceSpecialCharacter(element)}`,
							element
						);
					}
				}
			} else {
				setValue(questionId, question.answer);
			}
		});

		const parsedFilterJson = filterJson ? JSON.parse(filterJson) : '';
		const updatedFilter = {
			...filterRequestPayload,
			filterLabelJson: filterJson,
		};

		if (parsedFilterJson?.includeFilters) {
			updatedFilter['includeFilters'] = JSON.stringify(
				parsedFilterJson?.includeFilters
			);
		}

		if (parsedFilterJson?.excludeFilters) {
			updatedFilter['excludeFilters'] = JSON.stringify(
				parsedFilterJson?.excludeFilters
			);
		}
		dispatch(setSelectedFilterRequestPayload(updatedFilter));
	};

	const validateGroupName = (groupName: string) => {
		return props.checkIfNameDuplicate(groupName);
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const answerBasedOnQuestionType = (
		question: ISurveyFormsQuestion,
		formData: any
	) => {
		if (question.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
			let answers: string[] = [];
			/* eslint-disable @typescript-eslint/no-explicit-any */
			Object.keys(formData).forEach((entry: any) => {
				if (
					entry.includes(question.questionId) &&
					entry !== `${commonLabels.applicableQIdText}${question.questionId}` &&
					formData[entry]
				) {
					answers.push(formData[entry]);
				}
			});
			// sort array before sending
			answers = answers.sort();
			return answers;
		}
		return formData[question.questionId];
	};
	// This is based on dynamic question id- will update any
	/* eslint-disable @typescript-eslint/no-explicit-any */
	const createSaveGroupReq = (formData: any) => {
		const questionConfig: IGroupQuestions[] = [];
		props.questionData.forEach((ques) => {
			const questionConfigObj: IGroupQuestions = {
				applicable:
					formData[`${commonLabels.applicableQIdText}${ques.questionId}`] ??
					false,
				questionId: `${props.questionnaireId}_${ques.questionId}`,
				question: ques.question ?? '',
				questionType: ques.questionType,
				options: ques.options,
				answer: answerBasedOnQuestionType(ques, formData),
				freeText: '',
			};
			questionConfig.push(questionConfigObj);
		});
		const isSummitGroup = summitSurveys.includes(formData.groupName);
		const saveGroupRequest: IGroupSaveReq = {
			groupName: formData.groupName,
			includeClause: '',
			excludeClause: '',
			questionnaireJson: JSON.stringify(questionConfig),
			filterJson: '',
			groupStatus: 'Active',
			questionnaireId: props.questionnaireId,
			isDefaulter: formData[`${commonLabels.questionNotFound}`],
			isSummitGroup: isSummitGroup,
		};
		const currentGroup = groupList.filter(
			(group) => group.groupId === props.groupSelected
		)[0];
		if (formData.duration !== '') {
			saveGroupRequest.defaulterDuration = formData.duration;
		}
		if (
			formData.effectiveDate &&
			formData.effectiveDate !== '' &&
			isFinite(formData.effectiveDate)
		) {
			saveGroupRequest.defaulterApplicableDate = getFormattedDate(
				formData.effectiveDate
			);
		}
		saveGroupRequest.includeClause = currentGroup?.includeClause;
		saveGroupRequest.excludeClause = currentGroup?.excludeClause;
		saveGroupRequest.filterJson = filterRequestPayload.filterLabelJson;

		// Adding groupId in save request if not a default group
		if (
			props.groupCopied !== props.groupSelected &&
			!defaultIDs.includes(props.groupSelected)
		) {
			saveGroupRequest['groupId'] = props.groupSelected;
		}
		dispatch(updateSaveGroupReq(saveGroupRequest));
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const handleSubmitFormValidation = (formData: any) => {
		const multiSelectIds: string[] = [];
		props.questionData.forEach((question) => {
			if (question.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
				multiSelectIds.push(question.questionId);
			}
		});

		props.resetValidationState();
		let errorSetForConfiguration = false;
		// add extra validation if all question which are selected as appliable have answers
		let questionsNotConfiguredCount = 0;
		Object.keys(formData).forEach((element: string) => {
			if (element.includes(commonLabels.applicableQIdText)) {
				const isApplicable = formData[element];
				const questionId = element.split('_')[Numbers.one];
				if (
					isApplicable &&
					!formData[questionId] &&
					!multiSelectIds.includes(questionId)
				) {
					setError(questionId, {
						type: 'manual',
						message: commonLabels.selectOptionIfQuestionApplicableLabel,
					});
					errorSetForConfiguration = true;
				}
				// count number of questions not configured for group
				if (!isApplicable && !formData[`${commonLabels.questionNotFound}`]) {
					questionsNotConfiguredCount += 1;
				}
			}
		});
		if (uniqueQuestionData?.length === questionsNotConfiguredCount) {
			errorSetForConfiguration = true;
			setError('questions', {
				type: 'manual',
				message: commonLabels.noQuestionConfigured,
			});
		}

		// check for multiple select questions
		const multiSelect = props?.questionData?.filter(
			(question) =>
				question.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
		);

		const multiSelectErrors: boolean[] = [];
		multiSelect.forEach((question) => {
			const selectedValues = answerBasedOnQuestionType(question, formData);
			if (
				formData[`${commonLabels.applicableQIdText}${question.questionId}`] &&
				selectedValues.length === 0
			) {
				setError(question.questionId, {
					type: 'manual',
					message: commonLabels.selectOptionIfQuestionApplicableLabel,
				});
				multiSelectErrors.push(true);
			} else {
				clearErrors(question.questionId);
				multiSelectErrors.push(false);
			}
		});
		if (multiSelectErrors.includes(true)) {
			errorSetForConfiguration = true;
		}
		if (
			watch('effectiveFormTyp') === commonLabels.duration &&
			(hasDurationError || fullDuration === Numbers.zero)
		)
			errorSetForConfiguration = true;
		if (
			watch('effectiveFormTyp') === commonLabels.date &&
			!JSON.parse(JSON.stringify(watch('effectiveDate')))
		) {
			errorSetForConfiguration = true;
			setError('effectiveDate', {
				type: 'manual',
				message: commonLabels.effectiveDateRequiredWarningMsg,
			});
		}
		if (!errorSetForConfiguration) {
			// create save group req and store in redux
			createSaveGroupReq(formData);
			// Call the save confirmation popup method
			props.showSaveConfirmation('save');
		}
	};
	const questionTextColor = (questionType: string) => {
		if (questionType === QUECONSTANT.QUESTION_TYPE_OPEN) {
			return { color: STEPTheme.colors.linkGray };
		}
		return {
			color: STEPTheme.colors.black,
		};
	};

	const handleClearQuestionConfig = (isResetLinkClick = false) => {
		const groupName = getValues('groupName');
		const isDefaultGroup = isResetLinkClick
			? false
			: getValues(`${commonLabels.questionNotFound}`);
		if (!isDefaultGroup) {
			reInstateDuration();
		}
		reset();
		setValue(`${commonLabels.questionNotFound}`, isDefaultGroup);
		setValue('groupName', groupName);
		initiateGroupNameError(groupName);
		const initialVisibleQuestions = new Set(
			getIndependentQuestions(props.questionData)
		);
		setVisibleQuestions(initialVisibleQuestions);
	};

	/**
	 * @method
	 * @summary sets the character count of the group-name
	 * @summary sets/resets the error for group-name if the value have changed
	 * @param grpName<string>
	 * @returns void
	 */
	const initiateGroupNameError = (grpName: string) => {
		setCharsCountForGrpName(grpName.length);
		if (grpName.length === Numbers.zero) {
			clearErrors('groupName');
		} else if (
			!RegExp(VALIDATIONS_RULES.VALIDATION_QUESTION_TITLE).exec(grpName)
		) {
			setError('groupName', {
				type: 'manual',
				message: commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
			});
		} else if (validateGroupName(grpName)) {
			// Duplicate name exists
			setError('groupName', {
				type: 'manual',
				message: commonLabels.duplicateGroupNameLabel,
			});
		} else {
			clearErrors('groupName');
		}
		props.checkGroupNameErr(Boolean(errors?.groupName));
	};

	const handleGroupNameChange = (event: { target: { value: string } }) => {
		initiateGroupNameError(event.target.value);
		props.onFormChange(); // Notify parent of form change
	};
	/** Duration selection function */
	/**DefaulGroup option checkbox template */
	const renderDefaultGroupOption = () => {
		const isDefaultGroup = getValues(`${commonLabels.questionNotFound}`);
		return (
			<Fragment>
				{isDefaultGroup && (
					<RadioGroup
						sx={Styles.defaultRadioGroup}
						value={watch('effectiveFormTyp')}
					>
						<StyledDateCriteriaLabel>
							{commonLabels.effectiveFrom}
						</StyledDateCriteriaLabel>
						<FormControlLabel
							control={
								<Radio
									sx={Styles.radioControl}
									data-testid="date-radio"
									{...register('effectiveFormTyp', {
										required: {
											value: true,
											message: commonLabels.effectiveFromRequired,
										},
										onChange() {
											clearErrors('duration');
											props.resetValidationState();
										},
									})}
								/>
							}
							checked={watch('effectiveFormTyp') === commonLabels.date}
							value={commonLabels.date}
							label={commonLabels.date}
							sx={Styles.controlLabel}
						/>
						<FormControlLabel
							control={
								<Radio
									sx={Styles.radioControl}
									data-testid="duration-radio"
									{...register('effectiveFormTyp', {
										required: {
											value: true,
											message: commonLabels.effectiveDateRequired,
										},
										onChange() {
											reInstateDuration();
											clearErrors('effectiveDate');
											props.resetValidationState();
											setValue('effectiveDate', '');
										},
									})}
								/>
							}
							checked={watch('effectiveFormTyp') === commonLabels.duration}
							value={commonLabels.duration}
							label={commonLabels.duration}
							sx={Styles.controlLabel}
						/>
					</RadioGroup>
				)}
			</Fragment>
		);
	};
	/**Default Group Effective date format */
	const incrementDecrement = (type: string) => {
		let finalDuration = Numbers.zero;
		if (type.localeCompare('increment') === Numbers.zero) {
			if (fullDuration + Numbers.one > Numbers.threeSixtyFive) return;
			setFullDuration(fullDuration + Numbers.one);
			finalDuration = fullDuration + Numbers.one;
		} else {
			setFullDuration(
				fullDuration === Numbers.zero
					? Numbers.zero
					: fullDuration - Numbers.one
			);
			finalDuration =
				fullDuration === Numbers.zero
					? Numbers.zero
					: fullDuration - Numbers.one;
		}
		setValue('duration', finalDuration);
		clearErrors('duration');
	};

	const durationChange = (duration: string) => {
		if (duration === '') {
			setFullDuration(Numbers.zero);
		}
		// To check only numeric valus are inputed
		if (!/^\d+$/.test(duration)) {
			return;
		}
		if (
			Number(duration) < Numbers.zero ||
			Number(duration) > Numbers.threeSixtyFive
		)
			return;
		setFullDuration(Number(duration));
		setValue('duration', Number(duration || Numbers.zero));
		clearErrors('duration');
	};

	// This method is used to dynamically set the Duration Box width
	const inputBaseWidth = () => {
		const durationLength = `${fullDuration}`.length;
		// eslint-disable-next-line prettier/prettier
		if (durationLength <= Numbers.two)
			return `${durationLength * Numbers.eight + Numbers.seven}px`;
		return `${durationLength * Numbers.eight + Numbers.eight}px`;
	};

	const renderDefaultGroupEffectiveType = () => {
		const isDateSelected = getValues('effectiveFormTyp') === commonLabels.date;
		const isDurationSelected =
			getValues('effectiveFormTyp') === commonLabels.duration;
		const isDefaultGroup = getValues(`${commonLabels.questionNotFound}`);
		let dateCriteriaLabel = '';
		if (isDateSelected) {
			dateCriteriaLabel = 'Set Date';
		}
		if (isDurationSelected) {
			dateCriteriaLabel = ' ';
		}

		return (
			<Fragment>
				{isDefaultGroup && (
					<Box>
						<StyledDateCriteriaLabel>
							{dateCriteriaLabel}
						</StyledDateCriteriaLabel>
						{isDateSelected ? (
							<Controller
								control={control}
								name="effectiveDate"
								render={({
									// eslint-disable-next-line @typescript-eslint/no-unused-vars
									field: { ref, onBlur, name, onChange, ...field },
								}) => (
									<DatePicker
										data-testid="date-picker"
										minDate={
											new Date(
												surveyDate ??
													new Date().getTime() +
														Numbers.twentyFour *
															Numbers.sixty *
															Numbers.sixty *
															Numbers.thousand
											)
										}
										{...field}
										{...register('effectiveDate', {
											valueAsDate: true,
											required: {
												value: true,
												message: commonLabels.effectiveDateRequired,
											},
										})}
										format="dd/MM/yyyy"
										value={watch('effectiveDate')}
										onChange={(newValue) => {
											setValue('effectiveDate', newValue);
											setValue('duration', '');
											clearErrors('effectiveDate');
										}}
										sx={Styles.effectiveDate}
										slotProps={{
											field: {
												readOnly: true,
											},
										}}
									/>
								)}
							/>
						) : isDurationSelected ? (
							<>
								{configs?.duration && (
									<>
										<Box sx={{ display: 'flex', marginTop: '20px' }}>
											<StyledActionButton
												onClick={() => incrementDecrement('decrement')}
											>
												{'-'}
											</StyledActionButton>
											<StyledInputBox>
												<InputBase
													sx={{
														height: '18px',
														// eslint-disable-next-line prettier/prettier
														width: inputBaseWidth(),
														color: STEPTheme.colors.darkGray,
														paddingTop: '5px',
														fontSize: '14px',
														fontFamily: 'JohnsonText-Regular',
													}}
													value={fullDuration}
													onChange={(e) => durationChange(e.target.value)}
												/>
												<span
													style={{
														fontSize: '14px',
														fontFamily: 'JohnsonText-Regular',
													}}
												>
													Days
												</span>
											</StyledInputBox>
											<StyledActionButton
												onClick={() => incrementDecrement('increment')}
											>
												{'+'}
											</StyledActionButton>
										</Box>
										{(fullDuration === Numbers.zero || hasDurationError) && (
											<Box>
												<span className="error-msg">{`${commonLabels.errorMessageDuration}`}</span>
											</Box>
										)}
									</>
								)}
							</>
						) : (
							''
						)}
					</Box>
				)}
			</Fragment>
		);
	};

	// Main function to render required UI based on groups availability
	const renderScreen = () => {
		if (props.groupCount === 0) {
			// No group exist in current mapping
			return (
				<NoActiveGroupSelected>
					{commonLabels.noGroupLabel}
				</NoActiveGroupSelected>
			);
		} else if (props.groupSelected === '-1') {
			// Groups exists in current mapping but none selected
			return (
				<NoActiveGroupSelected>
					{commonLabels.selectGroupLabel}
				</NoActiveGroupSelected>
			);
		} else {
			// Create group configuration
			return (
				<Box sx={{ padding: '10px 15px 50px 15px' }}>
					<Box>
						<StyledCriteriaLabel>
							{commonLabels.setGroupingCriteriaLabel}
						</StyledCriteriaLabel>
					</Box>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<form onSubmit={handleSubmit(handleSubmitFormValidation)}>
							<Typography
								className="d-flex justify-between"
								sx={Styles.groupName}
							>
								{commonLabels.groupName}
								<Box
									sx={Styles.groupCharecter}
								>{`${remainingCountForGrpName} Characters`}</Box>
							</Typography>
							<StyledTooltip
								title={commonLabels.groupNameAllowedCharacterMsg}
								placement="top-start"
							>
								<StyledGroupNameInput
									disableClearable={charsCountForGrpName === Numbers.zero}
									options={props.summitSurveyList}
									freeSolo={true}
									defaultValue={watch('groupName') ?? ''}
									value={watch('groupName') ?? ''}
									ref={groupNameRef}
									onChange={(event, value) => {
										const name: string = value ? String(value) : '';
										const e = { target: { value: name } };
										handleGroupNameChange(e);
									}}
									renderInput={(
										params: JSX.IntrinsicAttributes & {
											variant?: TextFieldVariants;
										} & Omit<
												| FilledTextFieldProps
												| OutlinedTextFieldProps
												| StandardTextFieldProps,
												'variant'
											>
									) => (
										<TextField
											{...params}
											inputProps={{
												...params.inputProps,
												maxLength: MAX_LENGTH_MAPPING_GROUP_NAME,
											}}
											data-testid={'GroupNameInput'}
											defaultValue={watch('groupName') ?? ''}
											placeholder={commonLabels.enterTextLabel}
											{...register('groupName', {
												required: {
													value: true,
													message: commonLabels.groupNameRequiredLabel,
												},
												value: watch('groupName') ?? '',
												maxLength: {
													value: MAX_LENGTH_MAPPING_GROUP_NAME,
													message: commonLabels.groupNameCharExceedLabel,
												},
												pattern: {
													value: VALIDATIONS_RULES.VALIDATION_QUESTION_TITLE,
													message:
														commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
												},
												onChange(event) {
													handleGroupNameChange(event);
												},
												onBlur(event) {
													props.checkIfNameMatchSummit(event.target.value);
												},
											})}
										/>
									)}
								/>
							</StyledTooltip>
							{errors.groupName && (
								<Box>
									<span className="error-msg">{`${errors.groupName?.message}`}</span>
								</Box>
							)}
							<MappingGroupCriteria groupId={props.groupSelected} />
							<Divider sx={{ paddingTop: '2vh' }} />
							<Box sx={Styles.dflexRow}>
								<Box>
									<ApplicableFormControl
										{...register(`${commonLabels.questionNotFound}`, {
											onChange() {
												handleClearQuestionConfig();
												props.resetValidationState();
											},
										})}
										sx={{
											'& .MuiTypography-root': {
												fontFamily: 'JohnsonText-Regular',
												fontWeight: 700,
												fontSize: '14px !important',
												lineHeight: '16px  !important',
											},
										}}
										control={
											<Checkbox
												sx={Styles.defaultCheckBox}
												data-testid={'default-group-checkbox'}
												checked={
													watch(`${commonLabels.questionNotFound}`) ?? false
												}
											/>
										}
										label={commonLabels.questionNotFound}
									/>
									{renderDefaultGroupOption()}
								</Box>
								{renderDefaultGroupEffectiveType()}
								<LinkButtonDiv>
									<StyledTextBlueButton
										onClick={() => {
											handleClearQuestionConfig(true);
										}}
										disabled={!isAnyQuestionAnswered}
									>
										{commonLabels.reset}
									</StyledTextBlueButton>
								</LinkButtonDiv>
							</Box>
							{errors.effectiveDate && (
								<Box>
									<span className="error-msg">{`${errors.effectiveDate?.message}`}</span>
								</Box>
							)}
							{errors.duration && (
								<Box>
									<span className="error-msg">{`${errors.duration?.message}`}</span>
								</Box>
							)}
							{errors.effectiveFormTyp && (
								<Box>
									<span className="error-msg">{`${errors.effectiveFormTyp?.message}`}</span>
								</Box>
							)}
							<Box {...register('questions')}></Box>
							{errors.questions && (
								<Box>
									<span className="error-msg">{`${errors.questions?.message}`}</span>
								</Box>
							)}
							{sortedQuestionList([...props.questionData]).map(
								(question, questionIndex) => {
									const isVisible = visibleQuestions.has(question.questionId);
									const index = question.order
										? question.order + Numbers.one
										: Numbers.one;
									const showDivider =
										props.questionData.length - Numbers.one !== questionIndex;
									const applicableText = watch(
										`${commonLabels.applicableQIdText}${question.questionId}`
									)
										? commonLabels.applicableLabel
										: commonLabels.notApplicableLabel;
									const questionId = question.questionId;
									const errorMessage = errors[`${questionId}`]?.message;
									return (
										<Box
											key={question.questionId}
											sx={{ display: isVisible ? 'block' : 'none' }}
										>
											<ApplicableFormControl
												{...register(
													`${commonLabels.applicableQIdText}${question.questionId}`
												)}
												disabled={
													watch(`${commonLabels.questionNotFound}`) ||
													question.questionType ===
														`${QUECONSTANT.QUESTION_TYPE_OPEN}`
												}
												control={
													<Checkbox
														sx={Styles.defaultCheckBox}
														checked={
															watch(
																`${commonLabels.applicableQIdText}${question.questionId}`
															) ?? false
														}
													/>
												}
												onClick={() => toggleApplicable(question.questionId)}
												label={applicableText}
											/>
											{errors[`${questionId}`] && (
												<Box sx={{ paddingBottom: '10px' }}>
													<span className="error-msg">{`${errorMessage}`}</span>
												</Box>
											)}
											<Box sx={{ display: 'flex', paddingBottom: '1vh' }}>
												<Box sx={Styles.questionText}>
													<Box
														sx={
															watch(
																`${commonLabels.applicableQIdText}${question.questionId}`
															)
																? questionTextColor(question.questionType)
																: { color: STEPTheme.colors.linkGray }
														}
													>
														{index}.
													</Box>
												</Box>
												<StyledQuestionsText
													sx={
														watch(
															`${commonLabels.applicableQIdText}${question.questionId}`
														)
															? questionTextColor(question.questionType)
															: { color: STEPTheme.colors.linkGray }
													}
												>
													{question.question}
												</StyledQuestionsText>
											</Box>
											{question.questionType ===
												QUECONSTANT.QUESTION_TYPE_YES_NO && (
												<Box>
													<RadioGroup
														sx={Styles.radioGroups}
														value={watch(question.questionId) ?? ''}
													>
														{question.options.map((option) => (
															<FormControlLabel
																key={option.options}
																control={
																	<Radio
																		sx={Styles.radioControl}
																		{...register(question.questionId)}
																		size="small"
																	/>
																}
																checked={
																	option.options === watch(question.questionId)
																}
																value={option.options}
																label={option.options}
																disabled={
																	!watch(
																		`${commonLabels.applicableQIdText}${question.questionId}`
																	)
																}
																sx={Styles.controlLabel}
																onClick={() => {
																	handleOptionSelect(
																		question.questionId,
																		option.condition
																	);
																}}
															/>
														))}
													</RadioGroup>
												</Box>
											)}
											{question.questionType ===
												QUECONSTANT.QUESTION_TYPE_DROPDOWN && (
												<Box>
													<FormControl
														variant="standard"
														sx={{
															m: 1,
															minWidth: Numbers.sixFifty,
															marginLeft: '0',
														}}
													>
														<Typography
															sx={
																watch(
																	`${commonLabels.applicableQIdText}${question.questionId}`
																)
																	? {
																			color: STEPTheme.colors.black,
																			fontSize: '14px',
																			fontWeight: 400,
																		}
																	: {
																			color: STEPTheme.colors.linkGray,
																			fontSize: '14px',
																			fontWeight: 400,
																		}
															}
														>
															{commonLabels.selectReason}
														</Typography>
														<Select
															{...register(question.questionId)}
															label="Select"
															data-testid={`${question.questionId}-dropdown-select`}
															disabled={
																!watch(
																	`${commonLabels.applicableQIdText}${question.questionId}`
																)
															}
															value={watch(question.questionId) ?? ''}
															sx={{ fontSize: '14px' }}
														>
															{question.options.map((option) => (
																<MenuItem
																	key={option.options}
																	value={option.options}
																	selected={
																		watch(question.questionId) ===
																		option.options
																	}
																	sx={Styles.controlLabel}
																	onClick={() => {
																		handleOptionSelect(
																			question.questionId,
																			option.condition
																		);
																	}}
																>
																	{option.options}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Box>
											)}
											{question.questionType ===
												QUECONSTANT.QUESTION_TYPE_OPEN && (
												<Box>
													<TextField
														sx={{
															maxWidth: '650px',
															height: '16px',
															paddingLeft: '2vw',
															paddingBottom: '2vh',
														}}
														margin="dense"
														placeholder={commonLabels.enterTxt}
														id="name"
														type="text"
														fullWidth
														disabled={true}
														variant="standard"
														error={false}
														helperText={''}
													/>
												</Box>
											)}
											{question.questionType ===
												QUECONSTANT.QUESTION_TYPE_MULTI_SELECT && (
												<Box sx={{ paddingLeft: '25px' }}>
													{question.options.map((option) => (
														<FormControlLabel
															key={option.options}
															control={
																<Checkbox
																	disabled={false}
																	{...register(
																		question.questionId +
																			replaceSpecialCharacter(option.options)
																	)}
																	sx={Styles.radioControl}
																	onClick={() => {
																		const currentValue = watch(
																			question.questionId +
																				replaceSpecialCharacter(option.options)
																		);
																		setValue(
																			question.questionId +
																				replaceSpecialCharacter(option.options),
																			!currentValue
																		);
																		const selectedOptions = question.options
																			.filter(
																				(opt) =>
																					!!watch(
																						question.questionId +
																							replaceSpecialCharacter(
																								opt.options
																							)
																					)
																			)
																			.map((opt) => opt.options);
																		handleMultiSelectOption(
																			question.questionId,
																			selectedOptions
																		);
																	}}
																/>
															}
															checked={
																!!watch(
																	question.questionId +
																		replaceSpecialCharacter(option.options)
																)
															}
															value={option.options}
															label={option.options}
															disabled={
																!watch(
																	`${commonLabels.applicableQIdText}${question.questionId}`
																)
															}
															sx={Styles.controlLabel}
														/>
													))}
												</Box>
											)}
											{question.questionType ===
												QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION && (
												<Box>
													<RadioGroup
														sx={Styles.radioGroups}
														value={watch(question.questionId) ?? ''}
													>
														{question.options.map((option) => (
															<FormControlLabel
																key={option.options}
																control={
																	<Radio
																		disabled={false}
																		sx={Styles.radioControl}
																		{...register(question.questionId)}
																		size="small"
																	/>
																}
																checked={
																	option.options === watch(question.questionId)
																}
																value={option.options}
																label={option.options}
																onClick={() => {
																	handleOptionSelect(
																		question.questionId,
																		option.condition
																	);
																}}
																disabled={
																	!watch(
																		`${commonLabels.applicableQIdText}${question.questionId}`
																	)
																}
																sx={Styles.controlLabel}
															/>
														))}
													</RadioGroup>
												</Box>
											)}
											{showDivider && <Divider sx={{ paddingTop: '3vh' }} />}
										</Box>
									);
								}
							)}
							<input
								className="hiddenButton"
								ref={saveGroupFormRef}
								type="submit"
							/>
						</form>
					</LocalizationProvider>
				</Box>
			);
		}
	};

	return renderScreen();
};

export default MappingGroupConfig;
