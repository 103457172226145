import React, { useEffect } from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CreateQuestionnaireNotificationsProps } from '../../types/CommonTypes';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrowRight.svg';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	updateQuestionnaireData,
	resetAPIFlag,
	APILoadingStatus,
	getSurveyDueDate,
	getSurveyStartDate,
	selectedAudienceCount,
	surveyQuestionnaireData,
	configurationData,
	errorMessage,
	SetErrorMessage,
	isFromNotification,
} from '../../redux/SurveySlice';
import { commonLabels } from '../../utils/CommonLabels';
import EmailSegments from '../../components/notification/EmailSegments';
import QuestionSubHeader from '../../components/questions/QuestionSubHeader';
import { getQuestionnairePayload } from '../../utils/Helpers';
import GenericConfirmationPopup from '../../components/common/GenericConfirmationPopup';
import { STEPTheme } from '../../utils/Theme';
import GenericModalPopup from '../../components/common/GenericModalPopup';

import { formatDate } from '../../utils/DateFormatting';
import Loader from '../../components/Loader';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import { QUE_JOURNEY_STEPS } from '../../utils/Enum';
import StyledTooltip from '../../components/StyledTooltip';

const StyledHeadingTypography = styled(Typography)({
	marginInline: 'auto',
	marginBottom: '40px',
});

const StyledNotificationSelectionContainer = styled(Box)({
	width: '100%',
	background: STEPTheme.colors.background,
	display: 'flex',
	flexDirection: 'column',
	minHeight: '400px',
	padding: '20px 0px',
});

const StyledButtonsContainer = styled(Box)({
	width: '80%',
	gap: '20px',
	display: 'inline-flex',
	justifyContent: 'flex-end',
	margin: 'auto',
	paddingBottom: '20px',
});

const CreateSurveyNotifications: React.FC<
	CreateQuestionnaireNotificationsProps
> = () => {
	const [open, setOpen] = React.useState(false);
	const handleClosePopup = () => {
		setOpen(false);
		dispatch(resetAPIFlag('idle'));
	};
	const totalAudience = useAppSelector(selectedAudienceCount);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [popupOpenIns, setPopupOpenIns] = React.useState(false);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const [isVisibility, setIsVisibility] = React.useState(true);
	const surveyStartDate = useAppSelector(getSurveyStartDate);
	const errMsgInPublished = useAppSelector(errorMessage);
	const [errorPopOpen, SetErrorPopOpen] = React.useState(false);
	const surveyDueDate = useAppSelector(getSurveyDueDate);
	const fromNotification = useAppSelector(isFromNotification);
	const formattedDueDate = formatDate(surveyDueDate);
	const formattedStartDate = formatDate(surveyStartDate);
	const configs = useAppSelector(configurationData);
	const [showLoader, setLoaderOpen] = React.useState(false);
	const [isSubmitted, setIsSubmitted] = React.useState(false);
	const APICompletionStatus = useAppSelector(APILoadingStatus);
	const [activeStep, setActiveStep] = React.useState(
		QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS
	);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
			setLoaderOpen(false);
			if (isSubmitted) {
				dispatch(resetAPIFlag('idle'));
				navigate('/');
				navigate(0);
			} else {
				setOpen(true);
			}
		} else {
			setLoaderOpen(false);
			setOpen(false);
			dispatch(resetAPIFlag('idle'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);
	const handleSaveAsDraft = () => {
		setLoaderOpen(true);
		setIsSubmitted(false);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			userEmailId: configs?.user?.userEmailId || '',
		});
		dispatch(updateQuestionnaireData(payload));
	};
	const handleClickOpen = () => {
		if (fromNotification) {
			handleSubmitPopup();
		} else {
			setPopupOpenIns(true);
		}
	};

	const handleCloseSubmitPopup = () => {
		setPopupOpenIns(false);
	};
	const handleSubmitPopup = () => {
		setPopupOpenIns(false);
		setLoaderOpen(true);
		setIsSubmitted(true);

		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			userEmailId: configs?.user?.userEmailId || '',
		});
		dispatch(
			updateQuestionnaireData({
				...payload,
				basicDetails: {
					...payload.basicDetails,
					status:
						payload?.basicDetails?.status === 'Active' ? 'Active' : 'Published',
				},
			})
		);
	};
	useEffect(() => {
		if (errMsgInPublished.code === 'ERR_BAD_REQUEST') {
			setLoaderOpen(false);
			SetErrorPopOpen(true);
		} else {
			SetErrorPopOpen(false);
		}
	}, [errMsgInPublished]);

	const handleSubmitPopupError = () => {
		SetErrorPopOpen(false);
		dispatch(SetErrorMessage({ code: '', errorMessage: '' }));
	};
	useEffect(() => {
		if (
			surveyQuestionnaireInfo.notificationConfig.initialEmailBody &&
			surveyQuestionnaireInfo.notificationConfig.initialEmailSubject &&
			surveyQuestionnaireInfo.notificationConfig.reminderEmailBody &&
			surveyQuestionnaireInfo.notificationConfig.reminderEmailSubject &&
			surveyQuestionnaireInfo.notificationConfig.reminderEmailFrequency.length >
				0
		) {
			if (
				surveyQuestionnaireInfo.notificationConfig.overdueEmailSubject
					.length === 0
			) {
				setIsVisibility(false);
			} else if (
				surveyQuestionnaireInfo.notificationConfig.overdueEmailSubject &&
				surveyQuestionnaireInfo.notificationConfig.overdueEmailBody &&
				surveyQuestionnaireInfo.notificationConfig.overdueEmailFrequency
					.length > 0
			) {
				setIsVisibility(false);
			} else setIsVisibility(true);
		} else {
			setIsVisibility(true);
		}
	}, [surveyQuestionnaireInfo]);

	const handleBack = () => {
		if (activeStep > 1) {
			setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
		}
	};

	const backToDetailsPage = () => {
		setActiveStep(QUE_JOURNEY_STEPS.STEP_BASIC_DETAILS);
	};

	return (
		<>
			<Loader isLoading={showLoader} />
			<StyledNotificationSelectionContainer>
				<StyledHeadingTypography variant="h1">
					{commonLabels.configureNotificationAlerts}
				</StyledHeadingTypography>
				<Box
					sx={{
						margin: '0 auto',
						marginLeft: '100px',
						width: '86%',
						wordBreak: 'break-all',
					}}
				>
					<QuestionSubHeader
						showEditIcon={false}
						handleBack={handleBack}
						backToDetailsPage={backToDetailsPage}
						activeStep={activeStep}
						width="101%"
					/>
				</Box>
				<EmailSegments
					data-testid="initial"
					type={'initial-email'}
					dateValue={formattedStartDate}
					isRequired={true}
					showTriggerDays={false}
					headingText={commonLabels.initialMail}
					dateText={commonLabels.startDate}
				/>
				<EmailSegments
					data-testid="reminder"
					type={'reminder-email'}
					dateValue={formattedDueDate}
					isRequired={true}
					showTriggerDays={true}
					headingText={commonLabels.reminderMail}
					dateText={commonLabels.dueDate}
				/>
				<EmailSegments
					data-testid="overdue"
					type={'overdue-email'}
					dateValue={formattedDueDate}
					isRequired={false}
					showTriggerDays={true}
					headingText={commonLabels.overdueMail}
					dateText={commonLabels.dueDate}
				/>
				<StyledButtonsContainer>
					<Button
						data-testid="saveAsDraft"
						variant="outlined"
						color="secondary"
						onClick={handleSaveAsDraft}
					>
						{commonLabels.saveAsDraft}
					</Button>
					<Button
						data-testid="submit"
						sx={{ background: '#EB1700', color: '#fff' }}
						variant="contained"
						color="primary"
						endIcon={<ArrowRightIcon />}
						disabled={isVisibility}
						onClick={handleClickOpen}
					>
						{commonLabels.submit}
					</Button>
				</StyledButtonsContainer>
				<GenericModalPopup
					openProp={open}
					msgBody={commonLabels.saveAsDraftText}
					mainMsg=""
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleClosePopup}
					leftBtnHandler={handleClosePopup}
				/>
				<GenericConfirmationPopup
					title={commonLabels.confirmSubmitText}
					msgBody={commonLabels.publishSurveyConfirmationText}
					msgNote={`${commonLabels.totalSelectedUser} ${totalAudience}`}
					additionalMsgNote={`${commonLabels.startDatePopupLabel} ${formattedStartDate}`}
					leftBtnText={
						<StyledTooltip title={commonLabels.noTooltip} placement="top">
							<span>{commonLabels.no}</span>
						</StyledTooltip>
					}
					rightBtnText={
						<StyledTooltip title={commonLabels.yesTooltip} placement="top">
							<span>{commonLabels.yes}</span>
						</StyledTooltip>
					}
					isLeftBtnVisible={true}
					isRightBtnVisible={true}
					open={popupOpenIns}
					leftBtnHandler={handleCloseSubmitPopup}
					rightBtnHandler={handleSubmitPopup}
				/>
				<GenericConfirmationPopup
					title={commonLabels.errorTitle}
					msgBody={errMsgInPublished?.errorMessage || commonLabels.errorMsg}
					leftBtnText={commonLabels.no}
					rightBtnText={commonLabels.ok}
					isLeftBtnVisible={false}
					isRightBtnVisible={true}
					open={errorPopOpen}
					leftBtnHandler={handleSubmitPopupError}
					rightBtnHandler={handleSubmitPopupError}
				/>
			</StyledNotificationSelectionContainer>
		</>
	);
};

export default CreateSurveyNotifications;
