import { commonLabels } from './CommonLabels';

interface ErrorCategory {
	[customCode: string]: string;
}

interface ErrorMapping {
	[status: number]: ErrorCategory;
}

export const errorMapping: ErrorMapping = {
	400: {
		BR1000: commonLabels.issueProcessingRequest,
		BR1001: commonLabels.issueProcessingRequest,
		BR1002: commonLabels.issueProcessingRequest,
		BR1003: commonLabels.issueProcessingRequest,
	},
	401: {
		UA1000: commonLabels.issueValidatingIdentity,
		UA1001: commonLabels.issueValidatingIdentity,
		UA1002: commonLabels.issueValidatingIdentity,
	},
	403: {
		F1000: commonLabels.permissionLevelRequired,
		F1001: commonLabels.permissionLevelRequired,
	},
	404: {
		NF1000: commonLabels.itemNotExist,
		NF1001: commonLabels.linkNotExist,
	},
	413: {
		PL1000: commonLabels.issueProcessingRequest,
	},
	503: {
		SU1000: commonLabels.serviceUnavailable,
		SU1001: commonLabels.serviceUnavailable,
	},
	500: {
		IE1000: commonLabels.serverError,
		IE1001: commonLabels.serverError,
		IED1000: commonLabels.databaseConnectionError,
		IED1001: commonLabels.databaseTimeoutError,
		IED1002: commonLabels.issueProcessingRequest,
		IED1003: commonLabels.recordNotFound,
		IED1004: commonLabels.duplicateRecord,
		IED1005: commonLabels.invalidRequest,
		IED1006: commonLabels.invalidRequest,
		IED1007: commonLabels.invalidRequest,
		IED1008: commonLabels.processingRequestIssue,
		IED1009: commonLabels.processingRequestIssue,
	},
};
