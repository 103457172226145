import styled from '@emotion/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { STEPTheme } from '../utils/Theme';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ placement }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: STEPTheme.colors.black,
		borderRadius: '14px',
		padding: '8px 16px 8px 7px',
		gap: '7px',
		whiteSpace: 'pre-line',
		position: 'relative',
		span: {
			fontFamily: '"JohnsonText-Regular"',
			fontSize: '14px',
			fontWeight: '400',
			lineHeight: '16px',
			letterSpacing: '0px',
			textAlign: 'left',
			cursor: 'pointer',
			margin: '-3px 0',
			display: 'block',
			svg: {
				marginBottom: '-3px',
				marginRight: '5px',
			},
			'&.disabled-action': {
				cursor: 'none',
				color: `${STEPTheme.colors.linkGray}`,
			},
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			width: '0',
			height: '0',
			borderLeft:
				placement === 'left'
					? `6px solid ${STEPTheme.colors.black}`
					: '6px solid transparent',
			borderRight:
				placement === 'right'
					? `6px solid ${STEPTheme.colors.black}`
					: '6px solid transparent',
			borderTop:
				placement === 'bottom'
					? `6px solid ${STEPTheme.colors.black}`
					: '6px solid transparent',
			borderBottom:
				placement === 'top'
					? `6px solid ${STEPTheme.colors.black}`
					: '6px solid transparent',
			top:
				placement === 'top' ? '100%' : placement === 'bottom' ? '-6px' : '50%',
			bottom: placement === 'bottom' ? '100%' : 'auto',
			left:
				placement === 'left'
					? '100%'
					: placement === 'right'
						? '-6px'
						: 'calc(50% - 6px)',
			right: placement === 'right' ? '100%' : 'auto',
			transform:
				placement === 'top'
					? 'rotate(180deg)'
					: placement === 'left'
						? 'rotate(90deg)'
						: placement === 'right'
							? 'rotate(-90deg)'
							: 'none',
			marginTop: placement === 'left' || placement === 'right' ? '-3px' : '0',
			marginLeft: placement === 'top' || placement === 'bottom' ? '-3px' : '0',
		},
	},
}));

export default StyledTooltip;
