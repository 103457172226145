import { Box, Button, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CheckIcon } from '../../assets/images/Check-icon.svg';
import { commonLabels } from '../../utils/CommonLabels';
import { IChipGroup } from '../../types/CommonTypes';
import { chipDropdownOptions } from '../../utils/CommonMethods';
import { Numbers } from '../../utils/Enum';
import CustomChip from './CustomChip';

const useStyles = makeStyles({
	checkIcon: {
		marginRight: '2px',
		left: '2.66px',
	},
});

const StyledButton = styled(Button)({
	border: '1px',
	borderRadius: '25px',
	textTransform: 'none',
	height: '24px',
	margin: '5px',
	cursor: 'pointer',
});

const generateStyles = (
	color: string,
	backgroundColor: string,
	selected: boolean,
	visible = true
) => {
	if (selected) {
		return {
			color: color,
			border: `1px solid ${color}`,
			background: backgroundColor,
			display: visible ? 'flex' : 'none',
		};
	} else {
		return {
			color: color,
			border: `1px solid ${color}`,
			display: visible ? 'flex' : 'none',
		};
	}
};

// Component displays a group of chips which can be customized according to size, color and selected color. onSelect function passed in props is called when chip is selected
const ChipGroup = (props: IChipGroup) => {
	const classes = useStyles();
	const chipsDropdownOptions = chipDropdownOptions();
	return (
		<Box
			sx={{ display: 'flex', gap: 0.5, alignItems: 'center', flexWrap: 'wrap' }}
		>
			{props.chips.map(
				(chip: { text: string; selected: boolean; visible?: boolean }) =>
					chip.text === commonLabels.NON_COMPLIANT ||
					chip.text === commonLabels.RISKOVERDUE ? (
						<>
							{chip.visible && (
								<CustomChip
									key={chip.text}
									onSelect={props.onSelect}
									label={chip.text}
									selected={chip.selected}
									color={props.color}
									backgroundColor={props.backgroundColor}
									dropdownOptions={chipsDropdownOptions}
									preSelectedDays={props.preSelectedDays}
								/>
							)}
						</>
					) : (
						<StyledButton
							key={chip.text}
							sx={generateStyles(
								props.color,
								props.backgroundColor,
								chip.selected,
								chip.visible
							)}
							onClick={() => props.onSelect(chip.text, Numbers.one, false)}
						>
							{chip.selected && <CheckIcon className={classes.checkIcon} />}
							{chip.text}
						</StyledButton>
					)
			)}
		</Box>
	);
};

export default ChipGroup;
