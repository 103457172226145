import React, { useState } from 'react';
import {
	Box,
	Stack,
	Typography,
	styled,
	Button,
	MenuItem,
	InputLabel,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { STEPTheme } from '../../utils/Theme';
import { ReactComponent as DropDownIcon } from '../../assets/images/DropDownIcon.svg';
import { commonLabels } from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';
import { ReactComponent as Refresh } from '../../assets/images/refresh-cw-01.svg';
import { ReactComponent as InfoCircle } from '../../assets/images/info-circle.svg';
import { ReactComponent as AlertCircle } from '../../assets/images/alert-circle.svg';

const StyledMenuItem = styled(MenuItem)({
	fontFamily: '"JohnsonText-Bold"',
	fontSize: '14px',
});

const StyledHeader = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '15px',
	fontWeight: Numbers.sevenHundred,
	lineHeight: '23.22px',
});

const MetricsContainer = styled(Box)({
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	background: STEPTheme.colors.white,
	display: 'flex',
	width: '30%',
	borderRadius: '8px',
	padding: '20px 10px',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '24px',
});

const TextStack = styled(Stack)({
	alignItems: 'baseline',
	minWidth: '140px',
});

const SecondLineTextWrapper = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	gap: '10px',
});

const DaySelectionContainer = styled(Box)({
	display: 'inline-block',
	padding: 0,
});

const RefreshButton = styled(Button)({
	fontFamily: 'JohnsonText-Regular',
	color: STEPTheme.colors.primary,
	backgroundColor: STEPTheme.colors.white,
	height: '20px',
	marginTop: '50px',
	textTransform: 'none',
	alignItems: 'center',
});

const StyledBox = styled(Box)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: Numbers.fourHundred,
	lineHeight: '16px',
	textAlign: 'left',
});
const SelectStyles = {
	textDecoration: 'none',
	padding: 0,
	height: '14px',
	textTransform: 'uppercase',
	fontSize: '14px',
	'& .MuiSelect-select': {
		paddingTop: '8px',
		paddingBottom: '8px',
		fontFamily: '"JohnsonText-Medium"',
		color: STEPTheme.colors.lightRed,
	},
	'& .MuiInputBase-input': {
		fontFamily: '"JohnsonText-Bold"',
		fontSize: '14px',
		fontWeight: 900,
		padding: 0,
	},
	'& .MuiSelect-icon': {
		marginTop: '3px',
	},
};

const StyledLabel = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: Numbers.sevenHundred,
	lineHeight: '16px',
	textAlign: 'left',
});
interface ChartSummaryProps {
	metricsType: string;
	metricsLabelLineOne: string;
	metricsLabelLineTwo?: string;
	triggerSelectedDays: (selectedDay: number, metricsType: string) => void;
	selectedDay: number;
	status?: string;
	refreshFn?: () => void;
}
export const KeyMetricsItemError: React.FC<ChartSummaryProps> = ({
	metricsType,
	metricsLabelLineOne,
	metricsLabelLineTwo,
	triggerSelectedDays,
	selectedDay,
	status = 'Error',
	refreshFn,
}) => {
	const [selectedDays, setSelectedDays] = useState(
		selectedDay ? selectedDay?.toString() : Numbers.seven.toString()
	);
	const handleSelectedDaysChange = (event: SelectChangeEvent) => {
		setSelectedDays(event?.target?.value);
		triggerSelectedDays(Number(event.target.value), metricsType);
	};
	return (
		<MetricsContainer>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ marginLeft: status === 'Error' ? '5px' : '15px' }}>
					{status === 'Error' ? <AlertCircle /> : <InfoCircle />}
				</Box>
				<StyledHeader>{status === 'Error' ? 'Error' : 'No Data'}</StyledHeader>
				<StyledHeader>{status === 'Error' ? '' : 'Found'}</StyledHeader>
			</Box>
			<TextStack>
				<StyledLabel>{metricsLabelLineOne}</StyledLabel>
				<SecondLineTextWrapper>
					<StyledLabel>{metricsLabelLineTwo}</StyledLabel>
					{metricsType === commonLabels.metricsTypeDefaulter && (
						<DaySelectionContainer component="span">
							<InputLabel
								variant="standard"
								htmlFor="uncontrolled-native"
							></InputLabel>
							<Select
								defaultValue={Numbers.seven.toString()}
								IconComponent={DropDownIcon}
								disableUnderline
								value={selectedDays}
								onChange={handleSelectedDaysChange}
								variant="standard"
								MenuProps={{
									PaperProps: {
										style: {
											borderRadius: '12px',
										},
									},
								}}
								sx={SelectStyles}
								data-testid="select-time"
							>
								<StyledMenuItem value={Numbers.seven.toString()}>
									{commonLabels.sevenDays}
								</StyledMenuItem>
								<StyledMenuItem value={Numbers.thirty.toString()}>
									{commonLabels.thirtyDays}
								</StyledMenuItem>
								<StyledMenuItem value={Numbers.ninety.toString()}>
									{commonLabels.ninetyDays}
								</StyledMenuItem>
							</Select>
						</DaySelectionContainer>
					)}
				</SecondLineTextWrapper>
				<StyledBox
					sx={{
						color: 'grey',
						fontSize: '14px',
						lineHeight: '16px',
						fontWeight: 400,
						paddingTop: '5px',
					}}
				>
					{status === 'Error'
						? 'Click “Refresh” to try again'
						: 'Please try with different filters.'}
				</StyledBox>
			</TextStack>
			{status === 'Error' && (
				<RefreshButton onClick={refreshFn}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>{commonLabels.refreshTxt}</Box>
						<div style={{ padding: '4px 0 0 2px' }}>
							<Refresh />
						</div>
					</Box>
				</RefreshButton>
			)}
		</MetricsContainer>
	);
};

export default KeyMetricsItemError;
