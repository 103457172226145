import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { commonLabels } from '../../utils/CommonLabels';
import { ISurveyFormsQuestion, SurveyOptions } from '../../types/SurveyProps';
import {
	MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import {
	selectSurveyFormsQuestion,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { useAppSelector } from '../../app/Hooks';
import { RootState } from '../../app/Store';
import { getCheckBoxState } from '../../redux/QuestionnaireSurveySlice';
import StyledTooltip from '../StyledTooltip';
import { isIdenticalMultiOptionName } from '../../utils/Helpers';
import { isDuplicateQuestion } from '../../utils/CommonMethods';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import QuestionInstruction from './QuestionInstruction';
import QuestionActions from './QuestionActions';
import ConfigureOptions from './ConfigureOptions';
import InputCharacterRemaining from './InputCharacterRemaining';
import {
	UseStyles,
	QuestionBox,
	QuestionTitle,
	QuestionType,
	QuestionOptions,
	QuestionNumber,
	OptionRadio,
} from './common/QuestionStyles';

const QuestionSingleSelect: React.FC<{
	item: ISurveyFormsQuestion;
	index: number;
	onDelete: (i: string) => void;
	onQueClone: (item: ISurveyFormsQuestion) => void;
	onQueSave: (item: ISurveyFormsQuestion) => void;
	onQueInputChange: (inputValue: string, questionId: string) => void;
}> = ({ item, index, onDelete, onQueClone, onQueSave, onQueInputChange }) => {
	const yesNoOptions: SurveyOptions[] = [
		{ options: commonLabels.yes, condition: '' },
		{ options: commonLabels.no, condition: '' },
	];
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const [isQuestionEmpty, setIsQuestionEmpty] = useState(false);
	const [isPopUpOpen, setIsPopupOpen] = useState(false);
	const [instructionError, setInstructionError] = useState(false);
	const surveyFormsQuestion = useSelector(selectSurveyFormsQuestion);
	const classes = UseStyles();
	const questionFormRef = useRef<HTMLInputElement | null>(null);
	const form = useForm<ISurveyFormsQuestion>();
	const {
		register,
		handleSubmit,
		control,
		formState,
		watch,
		setError,
		clearErrors,
		setValue,
	} = form;
	const { errors } = formState;
	const { fields, append } = useFieldArray({
		control,
		name: 'options',
	});
	const [isEditable, setIsEditable] = useState(
		item.questionButtonLabel === commonLabels.save ? true : false
	);
	const [label, setLabel] = useState(item.questionButtonLabel);
	const [isIdenticalOptionName, setIsIdenticalOptionName] = useState(false);
	const [data, setData] = useState<ISurveyFormsQuestion>({
		questionId: '',
		questionType: QUECONSTANT.QUESTION_TYPE_YES_NO,
		options: [],
		question: '',
		instructions: '',
		order: 0,
		questionIsValid: false,
		questionButtonLabel: commonLabels.save,
	});
	const [charsCountForName, setCharsCountForName] = React.useState(0);
	const remainingCountForQuestionTitle: number =
		QUECONSTANT.MAX_LENGTH_QUESTION_TITLE - charsCountForName;

	const handleQueClone = () => {
		const newData = JSON.parse(JSON.stringify(data));
		newData.options = controlledFields.map((s) => {
			return { options: s?.options, condition: s?.condition };
		});
		onQueClone(newData);
	};
	const handleDeleteQue = () => {
		onDelete(item?.questionId);
	};

	const checkboxState = useSelector((state: RootState) =>
		getCheckBoxState(item.questionId)(state)
	);

	const handleSaveQue = () => {
		if (questionFormRef?.current) {
			questionFormRef.current.click();
		}
	};

	const handleConfirmPopup = () => {
		setIsPopupOpen(false);
		return;
	};

	const handleAddOption = (options: string, condition: string) => {
		append({ options: options, condition: condition });
	};
	const watchFieldArray = watch('options');
	const controlledFields = fields.map((field, i) => {
		if (watchFieldArray)
			return {
				...field,
				...watchFieldArray[i],
			};
	});
	const updateFormData = (
		prevData: ISurveyFormsQuestion,
		key: string,
		value: string
	) => ({
		...prevData,
		[key]: value,
	});

	useEffect(() => {
		setData(item);
		item.question && setCharsCountForName(item.question.length);
		let options = yesNoOptions;
		if (item?.options?.length > 0) {
			options = item.options;
		}
		for (const iterator of options) {
			handleAddOption(iterator.options, iterator.condition);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	/**This effect run to update & sync the store arrays with watch arrays of option */
	useEffect(() => {
		if (surveyFormsQuestion.length > 0 && item && item.options?.length > 0) {
			const updatedStateOptions =
				surveyFormsQuestion.find(
					(question) => question.questionId === item.questionId
				)?.options || item.options;
			setValue('options', updatedStateOptions as SurveyOptions[]);
		}
	}, [surveyFormsQuestion, item, setValue]);

	/**This Effect run when the local state item is getting sync with redux */
	useEffect(() => {
		if (item) {
			setData(item);
			if (!item.isNew) {
				setIsEditable(item.questionButtonLabel === commonLabels.save);
				setLabel(item.questionButtonLabel);
			}
		}
	}, [item]);

	const handleFormChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		key: string,
		questionId: string
	) => {
		setData((prevData) => updateFormData(prevData, key, event.target.value));
		setCharsCountForName(event.target.value.length);
		onQueInputChange(event.target.value, questionId);
	};

	const onSubmit = (formData: ISurveyFormsQuestion) => {
		// Below parameter are not part of form, need to add in submit object
		formData.questionType = QUECONSTANT.QUESTION_TYPE_YES_NO;
		formData.questionId = item.questionId;
		formData.order = item.order;
		formData.instructions = data.instructions;
		formData.question = data.question;

		const isDuplicate = isDuplicateQuestion(formData, surveyFormsQuestion);

		if (isDuplicate && isEditable) {
			setIsPopupOpen(true);
			return;
		}

		// Validation
		const questionValue = data.question;
		const CheckBoxState = checkboxState;
		const Instructions = data.instructions;
		/**this logic will validate same Multi select option name */
		if (isIdenticalMultiOptionName(controlledFields as SurveyOptions[])) {
			formData.questionIsValid = false;
			setIsIdenticalOptionName(true);
			return;
		}

		if (CheckBoxState && (!Instructions || Instructions.trim() === '')) {
			alert('Please add instruction');
			formData.questionIsValid = false;
			return;
		}
		if (instructionError) {
			return;
		}
		if (questionValue && questionValue.trim() !== '') {
			setIsEditable(!isEditable);
			const buttonLabel = isEditable ? commonLabels.edit : commonLabels.save;
			setLabel(buttonLabel);
			formData.questionButtonLabel = buttonLabel;
			formData.isNew =
				(!isEditable && formData.questionButtonLabel === commonLabels.save) ||
				isEditable
					? true
					: item.isNew;
			setIsQuestionEmpty(false);
			formData.questionIsValid = true;
		} else {
			setIsQuestionEmpty(true);
			formData.questionIsValid = false;
		}

		onQueSave(formData);
	};

	const updateInstructions = (instructions: string) => {
		setData((prevData) =>
			updateFormData(prevData, 'instructions', instructions)
		);
	};

	const handleInstructionError = (error: boolean) => {
		setInstructionError(error);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			data-testid="question-single-select"
		>
			<QuestionBox>
				<Grid container spacing={2}>
					<Grid item xs={10}>
						<QuestionType>
							<Box>{commonLabels.yesNoQue} </Box>
							<Box>
								<InputCharacterRemaining
									remainingCharacterCount={remainingCountForQuestionTitle}
								/>
							</Box>
						</QuestionType>

						<QuestionTitle>
							<QuestionNumber>{index + 1}.</QuestionNumber>
							<StyledTooltip
								title={commonLabels.questionFieldTooltip}
								placement="top"
							>
								<TextField
									data-testid="question-id"
									className={classes.titleInput}
									InputProps={{ classes: { root: classes.formControlOuter } }}
									margin="dense"
									id="name"
									type="text"
									fullWidth
									disabled={!isEditable}
									value={data.question}
									variant="standard"
									onKeyPress={(event) => {
										const inputValue = (event.target as HTMLInputElement).value;
										if (event.key === ' ' && !inputValue) {
											event.preventDefault();
										}
									}}
									error={isQuestionEmpty}
									helperText={isQuestionEmpty ? commonLabels.queRequired : ''}
									inputProps={{
										maxLength: QUECONSTANT.MAX_LENGTH_QUESTION_TITLE,
									}}
									{...register('question', {
										onChange(event) {
											handleFormChange(event, 'question', data.questionId);
											const inputValue = event.target.value;
											if (inputValue.length === 0) {
												clearErrors('question');
											} else if (
												!inputValue.match(
													VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS
												)
											) {
												setError('question', {
													type: 'manual',
													message:
														commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
												});
											} else {
												clearErrors('question');
											}
										},
										maxLength: {
											value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
											message: commonLabels.queMaxLength,
										},
										pattern: {
											value: VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS,
											message:
												commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
										},
									})}
								/>
							</StyledTooltip>
							{errors.question && (
								<p className={classes.errorMsg}>
									<span className="error-msg">{errors.question?.message}</span>
								</p>
							)}
						</QuestionTitle>
						<QuestionOptions>
							{controlledFields?.map((option, i) => (
								<Box key={option?.id}>
									<FormControl
										className={classes.formControlMultiOuter}
										component="fieldset"
									>
										<OptionRadio disabled />
										<Box className={classes.formControlInputBox}>
											<TextField
												className={classes.formControlInputCustom}
												margin="dense"
												id="name"
												type="text"
												fullWidth
												disabled={!isEditable}
												variant="standard"
												onKeyPress={(event) => {
													const inputValue = (event.target as HTMLInputElement)
														.value;
													if (event.key === ' ' && !inputValue) {
														event.preventDefault();
													}
												}}
												inputProps={{
													maxLength:
														QUECONSTANT.MAX_LENGTH_QUESTION_OPTIONS_TEXT_LENGTH,
												}}
												{...register(`options.${i}.options` as const, {
													required: commonLabels.optionRequired,
													maxLength: {
														value: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
														message: commonLabels.queMaxLength,
													},
													pattern: {
														value:
															VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS,
														message:
															commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
													},
													onChange(event) {
														const inputValue = event.target.value;
														setIsIdenticalOptionName(false);
														if (inputValue.length === 0) {
															clearErrors(`options.${i}.options`);
														} else if (
															!inputValue.match(
																VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS
															)
														) {
															setError(`options.${i}.options`, {
																type: 'manual',
																message:
																	commonLabels.incorrectQuestionnaireDescFormatErrorMsg,
															});
														} else {
															clearErrors(`options.${i}.options`);
														}
													},
												})}
											/>
											{errors.options && errors.options[i]?.options && (
												<p className={classes.errorOptionMsg}>
													{errors.options &&
														errors.options[i]?.options?.message && (
															<span className="error-msg">
																{errors.options[i]?.options?.message}
															</span>
														)}
												</p>
											)}
										</Box>
										{
											<ConfigureOptions
												option={option}
												isEditable={isEditable}
												index={index}
												questions={surveyQuestionnaireInfo.surveyFormsQuestion}
												classN={classes.formControlSelectCustom}
												register={register}
												i={i}
											/>
										}
									</FormControl>
								</Box>
							))}
							{isIdenticalOptionName ? (
								<p className={classes.errorOptionMsg}>
									<span className="error-msg">
										{commonLabels.identicalOptionsValidation}
									</span>
								</p>
							) : (
								''
							)}
						</QuestionOptions>
						<Box>
							<QuestionInstruction
								instructions={data.instructions}
								handleError={handleInstructionError}
								onUpdateInstructions={(instructions) =>
									updateInstructions(instructions)
								}
								readonly={!isEditable}
								questionId={item.questionId}
							></QuestionInstruction>
						</Box>
					</Grid>
					<Grid item xs={2}>
						<QuestionActions
							onCloneQue={handleQueClone}
							item={item}
							onSaveQue={handleSaveQue}
							onOpenDelete={handleDeleteQue}
							label={label}
							index={index}
						/>
					</Grid>
				</Grid>
				<input
					data-testid="submit-single-select"
					ref={questionFormRef}
					type="submit"
					className={classes.submitHide}
				/>
			</QuestionBox>
			<GenericConfirmationPopup
				open={isPopUpOpen}
				msgBody={commonLabels.duplicateQuestionMessage}
				title={commonLabels.duplicateQuestionTitle}
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.cancel}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={handleConfirmPopup}
				leftBtnHandler={handleConfirmPopup}
			/>
		</form>
	);
};
export default QuestionSingleSelect;
