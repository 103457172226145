import React, { useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CheckIcon } from '../../assets/images/Check-icon.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../assets/images/arrow-down.svg';
import { ReactComponent as ArrowUpwardIcon } from '../../assets/images/arrow-up.svg';
import { STEPTheme } from '../../utils/Theme';
import { commonLabels } from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';
import { DropdownOption } from '../../types/CommonTypes';

interface CustomChipProps {
	onSelect: (
		chipText: string,
		days: number,
		isMenuItemClicked: boolean,
		isFirstPreSelected: boolean
	) => void;
	label: string;
	selected: boolean;
	color: string;
	backgroundColor: string;
	dropdownOptions: DropdownOption[];
	preSelectedDays: number;
}

const useStyles = makeStyles({
	checkIcon: {
		marginRight: '2px',
		left: '2.66px',
	},
	arrowIcon: {
		marginLeft: '8px',
		cursor: 'pointer',
	},
});

const StyledButton = styled(Button)({
	border: '1px',
	borderRadius: '25px',
	textTransform: 'none',
	height: '24px',
	margin: '5px',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
});

const StyledMenuItem = styled(MenuItem)(
	({
		selectedDays,
		value,
	}: {
		selectedDays: number | null;
		value: number;
	}) => ({
		backgroundColor:
			selectedDays === value
				? STEPTheme.colors.powderBlue
				: STEPTheme.colors.white,
		borderBottom: '0.5px solid ' + STEPTheme.colors.lightPink,
		padding: '8px 16px',
		fontFamily: 'Johnson Text',
		fontSize: '14px',
		color: STEPTheme.colors.blue,
		lineHeight: '14px',
		'&:last-child': {
			borderBottom: 'none',
		},
	})
);

const generateStyles = (
	color: string,
	backgroundColor: string,
	selected: boolean,
	visible = true
) => {
	if (selected) {
		return {
			color: color,
			border: `1px solid ${color}`,
			background: backgroundColor,
			display: visible ? 'flex' : 'none',
		};
	} else {
		return {
			color: color,
			border: `1px solid ${color}`,
			display: visible ? 'flex' : 'none',
		};
	}
};

const CustomChip: React.FC<CustomChipProps> = ({
	onSelect,
	label,
	selected,
	color,
	backgroundColor,
	dropdownOptions,
	preSelectedDays,
}) => {
	const classes = useStyles();
	const isFirstPreSelected = preSelectedDays > Numbers.zero;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [menuWidth, setMenuWidth] = useState<number | null>(null);
	const [days, setDays] = useState(preSelectedDays);
	const [selectedDays, setSelectedDays] = useState<number>(Numbers.one);
	const [isChipSelected, setIsChipSelected] = useState(selected);

	useEffect(() => {
		if (selected && isFirstPreSelected) {
			setDays(preSelectedDays);
		}

		setIsChipSelected(selected);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected, preSelectedDays]);

	/**This Effect is required to reset the Days on deselection of chips */
	useEffect(() => {
		if (!isChipSelected && label === commonLabels.RISKOVERDUE) {
			setDays(Numbers.seven);
		} else if (!isChipSelected) {
			setDays(Numbers.zero);
		} else {
			setDays(preSelectedDays);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isChipSelected]);

	const handleChipClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (event.currentTarget !== event.target) {
			return;
		}
		const newSelectedState = !isChipSelected;
		setIsChipSelected(newSelectedState);
		if (!newSelectedState) {
			setSelectedDays(Numbers.one);
		}
		onSelect(
			label,
			newSelectedState ? days : Numbers.zero,
			false,
			isFirstPreSelected
		);
	};

	const handleArrowClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget.parentElement);
		setMenuWidth(event.currentTarget.parentElement?.clientWidth || null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuItemClick = (selectedDays: number) => {
		setSelectedDays(selectedDays);
		handleClose();
		onSelect(label, selectedDays, true, isFirstPreSelected);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<StyledButton
				onClick={handleChipClick}
				sx={generateStyles(color, backgroundColor, isChipSelected)}
			>
				{isChipSelected && <CheckIcon className={classes.checkIcon} />}
				{label}{' '}
				{label === commonLabels.NON_COMPLIANT
					? commonLabels.after
					: commonLabels.inNext}{' '}
				{days} {commonLabels.days}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						height: '25px',
						width: '30px',
					}}
					onClick={handleArrowClick}
				>
					{anchorEl ? (
						<ArrowUpwardIcon
							style={{ marginLeft: '11px', marginRight: '8px' }}
							className={classes.arrowIcon}
						/>
					) : (
						<ArrowDropDownIcon
							style={{ marginLeft: '11px', marginRight: '8px' }}
							className={classes.arrowIcon}
						/>
					)}
				</Box>
			</StyledButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				MenuListProps={{
					sx: {
						padding: Numbers.zero,
						margin: Numbers.zero,
						borderRadius: '12px',
					},
				}}
				PaperProps={{
					sx: {
						borderRadius: '12px',
						boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
						width: menuWidth || 'auto',
					},
				}}
			>
				{dropdownOptions.map((option) => (
					<StyledMenuItem
						key={option.value}
						onClick={() => handleMenuItemClick(option.value)}
						selectedDays={selectedDays}
						value={option.value}
					>
						{option.label}
					</StyledMenuItem>
				))}
			</Menu>
		</Box>
	);
};

export default CustomChip;
