import { commonLabels } from '../utils/CommonLabels';
import { errorMapping } from '../utils/ErrorMapping';
export const getErrorMessage = (
	status: number,
	customCode: string,
	message: string
): string => {
	const errorCategory = errorMapping[status];
	if (errorCategory) {
		// Checking for the specific message and error code combination
		if (
			customCode === commonLabels.apiExceptionCode &&
			message === commonLabels.apiErrorMsgTxt
		) {
			return '';
		}
		return errorCategory[customCode] || commonLabels.apiError;
	}
	return commonLabels.apiError;
};

export let globalApiFailure = false;

export const setGlobalApiFailure = (status: boolean) => {
	globalApiFailure = status;
};
